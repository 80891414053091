/* Variables */
@import "ej2-popups/dialog";
@import "ej2-popups/popup";
@import "ej2-popups/spinner";
@import "ej2-popups/tooltip";

/* Layouts Themes and Icons */

/* Popups */
@import "~@syncfusion/ej2-popups/styles/dialog/icons/bootstrap4";
@import "~@syncfusion/ej2-popups/styles/dialog/theme";
@import "~@syncfusion/ej2-popups/styles/dialog/layout";

/* Tooltip */
@import "~@syncfusion/ej2-popups/styles/tooltip/theme";
@import "~@syncfusion/ej2-popups/styles/tooltip/layout";
@import "~@syncfusion/ej2-popups/styles/tooltip/icons/bootstrap4";

/* Customizations */
@import "ej2-popups/customization/dialog";
@import "ej2-popups/customization/tooltip";
