//default
$acrdn-skin: "bootstrap4" !default;
$acrdn-border-size: 1px !default;
$acrdn-border-radius: 4px !default;
$acrdn-default-bg-color: normal-color(--white) !default;
$acrdn-item-panel-content-select-bg: $content-bg !default;
$acrdn-exp-hdr-hover-color: normal-color(--black) !default;
$acrdn-exp-hdr-hover-focus-hdr-color: normal-color(--black) !default;
$acrdn-disable-color: disabled-color(--black-300) !default;
$acrdn-opacity: inherit !default;
$acrdn-header-font-weight: normal !default;
$acrdn-icon-font-size: $font-size !default;
$acrdn-bgr-arrow-icon-font-size: 14px !default;
$acrdn-bgr-icon-font-size: 16px !default;
$acrdn-content-line-height: 1.5 !default;
$acrdn-tab-highlight-color: set-opacity(--black, 0) !default;
$acrdn-border-size: $acrdn-border-size !default;
$acrdn-active-bg: $acrdn-default-bg-color !default;
$acrdn-item-select-bg: normal-color(--white-100) !default;
$acrdn-header-hover-bg: hover-color(--white-100) !default;
$acrdn-header-active-bg: active-color(--white) !default;
$acrdn-selected-border-size: $acrdn-border-size !default;
$acrdn-selected-border-color: normal-color(--black) !default;
$acrdn-nested-header-font: normal-color(--black) !default;
$acrdn-nrml-header-minheight: 40px !default;
$acrdn-bgr-header-minheight: 50px !default;
$acrdn-nrml-header-lineheight: 39px !default;
$acrdn-bgr-header-lineheight: 48px !default;
$acrdn-nrml-header-paddingpix: 15px !default;
$acrdn-bgr-header-paddingpix: 15px !default;
// Header, Nested header and RTL mode Padding
$acrdn-nrml-header-padding: 0 16px 0 16px !default;
$acrdn-bgr-header-padding: 0 20px 0 20px !default;
$acrdn-nest-nrml-header-padding: 0 30px !default;
$acrdn-nest-bgr-header-padding: 0 30px !default;
$acrdn-nest-second-nrml-header-padding: 0 30px 0 45px !default;
$acrdn-nest-second-bgr-header-padding: 0 30px 0 45px !default;
$acrdn-rtl-nrml-header-padding: 0 15px 0 30px !default;
$acrdn-rtl-bgr-header-padding: $acrdn-rtl-nrml-header-padding !default;
$acrdn-rtl-nest-nrml-header-padding: 0 30px !default;
$acrdn-rtl-nest-bgr-header-padding: $acrdn-rtl-nest-nrml-header-padding !default;
$acrdn-rtl-nest-second-nrml-header-padding: 0  45px 0 30px !default;
$acrdn-rtl-nest-second-bgr-header-padding: $acrdn-rtl-nest-second-nrml-header-padding !default;
// Content, Nested content and RTL mode Padding
$acrdn-nrml-content-padding: 16px !default;
$acrdn-bgr-content-padding: 20px !default;
$acrdn-nest-nrml-content-padding: 15px 15px 15px 45px !default;
$acrdn-nest-bgr-content-padding: $acrdn-nest-nrml-content-padding !default;
$acrdn-nest-second-nrml-content-padding: 15px 15px 15px 60px !default;
$acrdn-nest-second-bgr-content-padding: $acrdn-nest-second-nrml-content-padding !default;
$acrdn-rtl-nest-nrml-content-padding: 15px 45px 15px 15px !default;
$acrdn-rtl-nest-bgr-content-padding: $acrdn-rtl-nest-nrml-content-padding !default;
$acrdn-rtl-nest-second-nrml-content-padding: 15px 60px 15px 15px !default;
$acrdn-rtl-nest-second-bgr-content-padding: $acrdn-rtl-nest-second-nrml-content-padding !default;
$acrdn-nrml-icn-minwidth: 24px !default;
$acrdn-bgr-icn-minwidth: 24px !default;
$acrdn-header-font-size: 14px !default;
$acrdn-nest-header-font-size: 16px !default;
$acrdn-content-font-size: 14px !default;
$acrdn-header-font-bgr-size: 16px !default;
$acrdn-content-font-bgr-size: 14px !default;
$acrdn-nrml-header-icon-padding: 8px !default;
$acrdn-bgr-header-icon-padding: 12px !default;
$acrdn-item-padding-size: 0 !default;
$acrdn-border-type: solid !default;
$acrdn-selected-border-type: none !default;
$acrdn-slct-header-font-weight: normal !default;
$acrdn-font-family: "Montserrat", sans-serif !default;
$acrdn-default-border: transparent !default;
$acrdn-selected-header-color: normal-color(--white) !default;
$acrdn-bg-color: normal-color(--white) !default;
$acrdn-content-color: normal-color(--black) !default;
$acrdn-content-icon-color: normal-color(--black) !default;
$acrdn-header-focus-bg: normal-color(--white) !default;
$acrdn-header-font: normal-color(--black) !default;
$acrdn-icon-color: normal-color(--black) !default;
$acrdn-item-header-content-font-face: "Montserrat" !default;
$acrdn-bgr-item-header-content-line-height: 24px !default;
$acrdn-bgr-item-header-content-font-size: 16px !default;
$acrdn-selected-header-font-color: normal-color(--black) !default;
$acrdn-selected-icon-color: normal-color(--primary) !default;
//enddefault
