$skin-name: "bootstrap4" !default;
$theme: "Bootstrap4" !default;
$spin-overlay-background: set-opacity(--black, 0.5) !default;
$spin-overlay-stroke-color: normal-color(--warning) !default;
$spin-overlay-font-color: normal-color(--black) !default;
$spin-material-stroke-color: normal-color(--warning) !default;
$spin-boot4-stroke-color: normal-color(--warning) !default;
$spin-boot4-stroke-width: 4 !default;
$spin-fabric-stroke-color: normal-color(--warning) !default;
$spin-fabric-stroke-width: 1.5 !default;
$spin-fabric-arc-color: normal-color(--black) !default;
$spin-label-font-family: "Montserrat" !default;
$spin-bootstrap-stroke-color: normal-color(--warning) !default;
$spin-label-font-size: 14px !default;
$spin-label-margin-top: 10px !default;
$spin-label-color: normal-color(--black) !default;
$spin-padding: 10px !default;

@mixin mat-spinner-rotate {
  animation: material-spinner-rotate 1568.63ms linear infinite;
}

@mixin fb-spinner-rotate {
  animation: fabric-spinner-rotate 1.3s infinite cubic-bezier(0.53, 0.21, 0.29, 0.67);
}

@mixin boot4-spinner-rotate {
  animation: material-spinner-rotate 0.75s linear infinite;
}

// sass-lint:disable no-vendor-prefixes
@keyframes material-spinner-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// sass-lint:disable no-vendor-prefixes
@keyframes fabric-spinner-rotate {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@import "~@syncfusion/ej2-popups/styles/spinner/theme";
@import "~@syncfusion/ej2-popups/styles/spinner/layout";
@import "~@syncfusion/ej2-popups/styles/spinner/icons";
