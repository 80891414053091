// Calculate box shadow
@function custom-box-shadow($bg-color) {
  @return (0 0 0 0.2rem set-opacity($bg-color, 0.5));
}

//General
$chip-bg-color: normal-color(--secondary) !default;
$chip-hover-bg-color: hover-color(--secondary) !default;
$chip-focus-bg-color: focused-color(--secondary) !default;
$chip-choice-active-bg-color: normal-color(--secondary) !default;
$chip-active-bg-color: active-color(--white) !default;
$chip-focus-active-bg-color: $chip-focus-bg-color !default;
$chip-choice-focus-active-bg-color: $chip-bg-color !default;
$chip-pressed-bg-color: focused-color(--secondary) !default;
$chip-disabled-bg-color: disabled-color(--secondary) !default;
$chip-pressed-active-bg-color: active-color(--secondary) !default;
$chip-avatar-bg-color: set-opacity(--black, 0.3) !default;
$chip-avatar-hover-bg-color: set-opacity(--black, 0.3) !default;
$chip-avatar-focus-bg-color: set-opacity(--black, 0.3) !default;
$chip-avatar-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-avatar-choice-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-avatar-focus-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-avatar-choice-focus-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-avatar-pressed-bg-color: set-opacity(--black, 0.3) !default;
$chip-avatar-pressed-active-bg-color: set-opacity(--black, 0.3);
$chip-avatar-disabled-bg-color: set-opacity(--black, 0.3) !default;
$chip-font-color: normal-color(--white) !default;
$chip-hover-font-color: hover-color(--white) !default;
$chip-focus-font-color: focused-color(--white) !default;
$chip-active-font-color: active-color(--white) !default;
$chip-choice-active-font-color: active-color(--white) !default;
$chip-focus-active-font-color: focused-color(--white) !default;
$chip-choice-focus-active-font-color: focused-color(--white) !default;
$chip-pressed-font-color: $chip-font-color !default;
$chip-disabled-font-color: normal-color(--white) !default;
$chip-close-icon-font-color: normal-color(--white) !default;
$chip-close-icon-hover-font-color: $chip-font-color !default;
$chip-close-icon-pressed-font-color: $chip-font-color !default;
$chip-icon-font-color: normal-color(--white) !default;
$chip-icon-hover-font-color: normal-color(--white) !default;
$chip-icon-focus-font-color: normal-color(--white) !default;
$chip-icon-active-font-color: normal-color(--white) !default;
$chip-icon-focus-active-font-color: normal-color(--white) !default;
$chip-choice-icon-focus-active-font-color: normal-color(--white) !default;
$chip-icon-pressed-font-color: normal-color(--white) !default;
$chip-avatar-font-color: $chip-icon-font-color !default;
$chip-avatar-hover-font-color: $chip-icon-hover-font-color !default;
$chip-avatar-focus-font-color: $chip-icon-focus-font-color !default;
$chip-avatar-active-font-color: $chip-icon-active-font-color !default;
$chip-avatar-choice-active-font-color: $chip-choice-active-font-color !default;
$chip-avatar-focus-active-font-color: $chip-icon-focus-active-font-color !default;
$chip-avatar-choice-focus-active-font-color: $chip-choice-icon-focus-active-font-color !default;
$chip-avatar-pressed-font-color: $chip-icon-pressed-font-color !default;
$chip-avatar-pressed-active-font-color: $chip-icon-pressed-font-color !default;
$chip-avatar-disabled-font-color: $chip-disabled-font-color !default;
$chip-border-color: normal-color(--secondary) !default;
$chip-hover-border-color: hover-color(--secondary) !default;
$chip-focus-border-color: focused-color(--secondary) !default;
$chip-choice-active-border-color: hover-color(--secondary) !default;
$chip-active-border-color: active-color(--secondary) !default;
$chip-focus-active-border-color: active-color(--secondary) !default;
$chip-choice-focus-active-border-color: focused-color(--secondary) !default;
$chip-pressed-border-color: focused-color(--white) !default;
$chip-disabled-border-color: transparent !default;
$chip-focus-box-shadow: custom-box-shadow(--white) !default;
$chip-list-padding: 4px 0 !default;
$chip-height: 24px !default;
$chip-border-radius: 4px !default;
$chip-box-shadow: none !default;
$chip-pressed-box-shadow: custom-box-shadow(--white) !default;
$chip-avatar-wrapper-border-radius: 14px 4px 4px 14px !default;
$chip-avatar-border-radius: 50% !default;
$chip-leading-icon-border-radius: 0% !default;
$chip-delete-icon-border-radius: 0% !default;
$chip-padding: 0 8px !default;
$chip-margin: 4px !default;
$chip-overflow: hidden !default;
$chip-border-size: 1px !default;
$chip-font-size: 12px !default;
$chip-line-height: 1.5em !default;
$chip-font-weight: 400 !default;
$chip-avatar-size: 24px !default;
$chip-avatar-margin: 0 8px 0 -10px !default;
$chip-avatar-content-font-size: 10px !default;
$chip-leading-icon-font-size: 14px !default;
$chip-leading-icon-size: 18px !default;
$chip-leading-icon-margin: 0 8px 0 -6px !default;
$chip-multiselection-icon-margin-top: 2px !default;
$chip-delete-icon-font-size: 8px !default;
$chip-delete-icon-size: 14px !default;
$chip-delete-icon-margin: 0 -2px 0 8px !default;
//RTL
$chip-rtl-avatar-wrapper-border-radius: 4px 14px 14px 4px !default;
$chip-rtl-avatar-margin: 0 -10px 0 8px !default;
$chip-rtl-leading-icon-margin: 0 -6px 0 8px !default;
$chip-rtl-delete-icon-margin: 0 8px 0 -2px !default;
//Touch
$chip-touch-border-radius: 4px !default;
$chip-touch-avatar-wrapper-border-radius: 16px 4px 4px 16px !default;
$chip-touch-font-size: 14px !default;
$chip-touch-height: 30px !default;
$chip-touch-padding: 0 12px !default;
$chip-touch-avatar-size: 30px !default;
$chip-touch-avatar-margin: -2px 8px 0 -12px !default;
$chip-touch-avatar-content-font-size: 15px !default;
$chip-touch-leading-icon-font-size: 16px !default;
$chip-touch-leading-icon-size: 24px !default;
$chip-touch-leading-icon-margin: -2px 8px 0 -8px !default;
$chip-touch-delete-icon-font-size: 10px !default;
$chip-touch-delete-icon-size: 18px !default;
$chip-touch-delete-icon-margin: 0 -4px 0 8px !default;
//Touch-RTL
$chip-touch-rtl-avatar-wrapper-border-radius: 4px 16px 16px 4px !default;
$chip-touch-rtl-avatar-margin: 0 -12px 0 8px !default;
$chip-touch-rtl-leading-icon-margin: 0 -8px 0 8px !default;
$chip-touch-rtl-delete-icon-margin: 0 8px 0 -4px !default;
//outline
$chip-outline-hover-bg-color: normal-color(--secondary) !default;
$chip-outline-focus-bg-color: transparent !default;
$chip-outline-choice-active-bg-color: transparent !default;
$chip-outline-active-bg-color: transparent !default;
$chip-outline-focus-active-bg-color: $chip-outline-focus-bg-color !default;
$chip-outline-choice-focus-active-bg-color: transparent !default;
$chip-outline-pressed-bg-color: transparent !default;
$chip-outline-pressed-active-bg-color: transparent !default;
$chip-outline-disabled-bg-color: transparent !default;
$chip-outline-avatar-bg-color: normal-color(--secondary) !default;
$chip-outline-avatar-hover-bg-color: hover-color(--secondary) !default;
$chip-outline-avatar-focus-bg-color: normal-color(--secondary) !default;
$chip-outline-avatar-active-bg-color: normal-color(--secondary) !default;
$chip-outline-avatar-choice-active-bg-color: normal-color(--secondary) !default;
$chip-outline-avatar-focus-active-bg-color: normal-color(--secondary) !default;
$chip-outline-avatar-choice-focus-active-bg-color: normal-color(--secondary) !default;
$chip-outline-avatar-pressed-bg-color: normal-color(--secondary) !default;
$chip-outline-avatar-pressed-active-bg-color: normal-color(--secondary) !default;
$chip-outline-avatar-disabled-bg-color: disabled-color(--secondary) !default;
$chip-outline-font-color: normal-color(--primary) !default;
$chip-outline-hover-font-color: normal-color(--white) !default;
$chip-outline-focus-font-color: focused-color(--primary) !default;
$chip-outline-active-font-color: active-color(--primary);
$chip-outline-choice-active-font-color: active-color(--primary) !default;
$chip-outline-focus-active-font-color: $chip-outline-focus-font-color !default;
$chip-outline-choice-focus-active-font-color: focused-color(--primary) !default;
$chip-outline-pressed-font-color: normal-color(--primary) !default;
$chip-outline-disabled-font-color: focused-color(--primary) !default;
$chip-outline-close-icon-font-color: normal-color(--primary) !default;
$chip-outline-close-icon-hover-font-color: $chip-close-icon-hover-font-color !default;
$chip-outline-close-icon-pressed-font-color: $chip-close-icon-pressed-font-color !default;
$chip-outline-icon-font-color: normal-color(--primary) !default;
$chip-outline-icon-hover-font-color: normal-color(--primary) !default;
$chip-outline-icon-focus-font-color: focused-color(--primary) !default;
$chip-outline-icon-active-font-color: normal-color(--primary) !default;
$chip-outline-icon-focus-active-font-color: $chip-outline-icon-focus-font-color !default;
$chip-outline-choice-icon-focus-active-font-color: focused-color(--primary) !default;
$chip-outline-icon-pressed-font-color: focused-color(--primary) !default;
$chip-outline-avatar-font-color: normal-color(--white) !default;
$chip-outline-avatar-hover-font-color: hover-color(--white) !default;
$chip-outline-avatar-focus-font-color: focused-color(--white) !default;
$chip-outline-avatar-active-font-color: active-color(--white) !default;
$chip-outline-avatar-choice-active-font-color: active-color(--white) !default;
$chip-outline-avatar-focus-active-font-color: focused-color(--white) !default;
$chip-outline-avatar-choice-focus-active-font-color: focused-color(--white) !default;
$chip-outline-avatar-pressed-font-color: focused-color(--white) !default;
$chip-outline-avatar-pressed-active-font-color: active-color(--white);
$chip-outline-avatar-disabled-font-color: disabled-color(--white) !default;
$chip-outline-border-color: normal-color(-white-300) !default;
$chip-outline-hover-border-color: hover-color(-white-300) !default;
$chip-outline-focus-border-color: focused-color(-white-300) !default;
$chip-outline-choice-active-border-color: active-color(-white-300) !default;
$chip-outline-active-border-color: active-color(-white-300) !default;
$chip-outline-focus-active-border-color: $chip-outline-focus-border-color !default;
$chip-outline-choice-focus-active-border-color: focused-color(-white-300) !default;
$chip-outline-pressed-border-color: focused-color(-white-300) !default;
$chip-outline-disabled-border-color: disabled-color(-white-300) !default;
$chip-outline-border-size: 1px !default;
$chip-outline-avatar-margin: 0 4px 0 -13px !default;
$chip-outline-icon-margin: 0 4px 0 -4px !default;
$chip-outline-delete-icon-margin: 0 -4px 0 4px !default;
//outline-rtl
$chip-outline-rtl-avatar-margin: 0 -13px 0 4px !default;
$chip-outline-rtl-icon-margin: 0 -4px 0 4px !default;
$chip-outline-rtl-delete-icon-margin: 0 4px 0 -4px !default;
//theme
//primary
$chip-primary-bg-color: normal-color(--primary) !default;
$chip-primary-hover-bg-color: hover-color(--primary) !default;
$chip-primary-focus-bg-color: focused-color(--primary) !default;
$chip-primary-active-bg-color: active-color(--primary) !default;
$chip-primary-focus-active-bg-color: $chip-primary-focus-bg-color !default;
$chip-primary-pressed-bg-color: focused-color(--primary) !default;
$chip-primary-disabled-bg-color: disabled-color(--primary) !default;
$chip-primary-avatar-bg-color:  set-opacity(--black, 0.3) !default;
$chip-primary-avatar-hover-bg-color: set-opacity(--black, 0.3) !default;
$chip-primary-avatar-focus-bg-color: set-opacity(--black, 0.3) !default;
$chip-primary-avatar-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-primary-avatar-focus-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-primary-avatar-pressed-bg-color: set-opacity(--black, 0.3) !default;
$chip-primary-avatar-disabled-bg-color: set-opacity(--black, 0.3) !default;
$chip-primary-font-color: normal-color(--white) !default;
$chip-primary-hover-font-color: normal-color(--white) !default;
$chip-primary-focus-font-color: normal-color(--white) !default;
$chip-primary-active-font-color: normal-color(--white) !default;
$chip-primary-focus-active-font-color: normal-color(--white) !default;
$chip-primary-pressed-font-color: normal-color(--white) !default;
$chip-primary-disabled-font-color: normal-color(--white) !default;
$chip-primary-close-icon-font-color: normal-color(--white) !default;
$chip-primary-close-icon-hover-font-color: normal-color(--white) !default;
$chip-primary-close-icon-pressed-font-color: normal-color(--white) !default;
$chip-primary-avatar-font-color: normal-color(--white) !default;
$chip-primary-avatar-hover-font-color: normal-color(--white) !default;
$chip-primary-avatar-focus-font-color: normal-color(--white) !default;
$chip-primary-avatar-active-font-color: normal-color(--white) !default;
$chip-primary-avatar-focus-active-font-color: normal-color(--white) !default;
$chip-primary-avatar-pressed-font-color: normal-color(--white) !default;
$chip-primary-avatar-disabled-font-color: normal-color(--white) !default;
$chip-primary-border-color: normal-color(--primary) !default;
$chip-primary-hover-border-color: hover-color(--primary) !default;
$chip-primary-focus-border-color: focused-color(--primary) !default;
$chip-primary-active-border-color: active-color(--primary) !default;
$chip-primary-focus-active-border-color: $chip-primary-focus-border-color !default;
$chip-primary-pressed-border-color: focused-color(--primary) !default;
$chip-primary-disabled-border-color: transparent !default;
//success
$chip-success-bg-color: normal-color(--success) !default;
$chip-success-hover-bg-color: hover-color(--success) !default;
$chip-success-focus-bg-color: focused-color(--success) !default;
$chip-success-active-bg-color: active-color(--success) !default;
$chip-success-focus-active-bg-color: $chip-success-focus-bg-color !default;
$chip-success-pressed-bg-color: focused-color(--success) !default;
$chip-success-disabled-bg-color: disabled-color(--success) !default;
$chip-success-avatar-bg-color: set-opacity(--black, 0.3) !default;
$chip-success-avatar-hover-bg-color: set-opacity(--black, 0.3) !default;
$chip-success-avatar-focus-bg-color: set-opacity(--black, 0.3) !default;
$chip-success-avatar-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-success-avatar-focus-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-success-avatar-pressed-bg-color: set-opacity(--black, 0.3) !default;
$chip-success-avatar-disabled-bg-color: set-opacity(--black, 0.3) !default;
$chip-success-font-color: normal-color(--white) !default;
$chip-success-hover-font-color: $chip-success-font-color !default;
$chip-success-focus-font-color: $chip-success-font-color !default;
$chip-success-active-font-color: $chip-success-font-color !default;
$chip-success-focus-active-font-color: $chip-success-font-color !default;
$chip-success-pressed-font-color: $chip-success-font-color !default;
$chip-success-disabled-font-color: $chip-success-font-color !default;
$chip-success-close-icon-font-color: normal-color(--white) !default;
$chip-success-close-icon-hover-font-color: normal-color(--white) !default;
$chip-success-close-icon-pressed-font-color: normal-color(--white) !default;
$chip-success-avatar-font-color: $chip-success-font-color !default;
$chip-success-avatar-hover-font-color: $chip-success-font-color !default;
$chip-success-avatar-focus-font-color: $chip-success-font-color !default;
$chip-success-avatar-active-font-color: $chip-success-font-color !default;
$chip-success-avatar-focus-active-font-color: $chip-success-font-color !default;
$chip-success-avatar-pressed-font-color: $chip-success-font-color !default;
$chip-success-avatar-disabled-font-color: $chip-success-font-color !default;
$chip-success-border-color: normal-color(--success) !default;
$chip-success-hover-border-color: hover-color(--success) !default;
$chip-success-focus-border-color: focused-color(--success) !default;
$chip-success-active-border-color: active-color(--success) !default;
$chip-success-focus-active-border-color: focused-color(--success) !default;
$chip-success-pressed-border-color: focused-color(--success) !default;
$chip-success-disabled-border-color: transparent !default;
//info
$chip-info-bg-color: normal-color(--link) !default;
$chip-info-hover-bg-color: hover-color(--link) !default;
$chip-info-focus-bg-color: focused-color(--link) !default;
$chip-info-active-bg-color: active-color(--link) !default;
$chip-info-focus-active-bg-color: $chip-info-focus-bg-color !default;
$chip-info-pressed-bg-color: focused-color(--link) !default;
$chip-info-disabled-bg-color: disabled-color(--link) !default;
$chip-info-avatar-bg-color: set-opacity(--black, 0.3) !default;
$chip-info-avatar-hover-bg-color: set-opacity(--black, 0.3) !default;
$chip-info-avatar-focus-bg-color: set-opacity(--black, 0.3) !default;
$chip-info-avatar-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-info-avatar-focus-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-info-avatar-pressed-bg-color: set-opacity(--black, 0.3) !default;
$chip-info-avatar-disabled-bg-color: set-opacity(--black, 0.3) !default;
$chip-info-font-color: normal-color(--white) !default;
$chip-info-hover-font-color: $chip-info-font-color !default;
$chip-info-focus-font-color: $chip-info-font-color !default;
$chip-info-active-font-color: $chip-info-font-color !default;
$chip-info-focus-active-font-color: $chip-info-font-color !default;
$chip-info-pressed-font-color: $chip-info-font-color !default;
$chip-info-disabled-font-color: $chip-info-font-color !default;
$chip-info-close-icon-font-color: set-opacity(--white, 0.8) !default;
$chip-info-close-icon-hover-font-color: $chip-info-hover-font-color !default;
$chip-info-close-icon-pressed-font-color: $chip-info-pressed-font-color !default;
$chip-info-avatar-font-color: $chip-info-font-color !default;
$chip-info-avatar-hover-font-color: $chip-info-hover-font-color !default;
$chip-info-avatar-focus-font-color: $chip-info-focus-font-color !default;
$chip-info-avatar-active-font-color: $chip-info-active-font-color !default;
$chip-info-avatar-focus-active-font-color: $chip-info-focus-active-font-color !default;
$chip-info-avatar-pressed-font-color: $chip-info-pressed-font-color !default;
$chip-info-avatar-disabled-font-color: $chip-info-disabled-font-color !default;
$chip-info-border-color: variant-color(--link) !default;
$chip-info-hover-border-color: hover-color(--link) !default;
$chip-info-focus-border-color: focused-color(--link) !default;
$chip-info-active-border-color: active-color(--link) !default;
$chip-info-focus-active-border-color: $chip-info-focus-border-color !default;
$chip-info-pressed-border-color: focused-color(--link) !default;
$chip-info-disabled-border-color: transparent !default;
//warning
$chip-warning-bg-color: normal-color(--warning) !default;
$chip-warning-hover-bg-color: hover-color(--warning) !default;
$chip-warning-focus-bg-color: focused-color(--warning) !default;
$chip-warning-active-bg-color: active-color(--warning) !default;
$chip-warning-focus-active-bg-color: $chip-warning-focus-bg-color !default;
$chip-warning-pressed-bg-color: focused-color(--warning) !default;
$chip-warning-disabled-bg-color: disabled-color(--warning) !default;
$chip-warning-avatar-bg-color: set-opacity(--black, 0.3) !default;
$chip-warning-avatar-hover-bg-color: set-opacity(--black, 0.3) !default;
$chip-warning-avatar-focus-bg-color: set-opacity(--black, 0.3) !default;
$chip-warning-avatar-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-warning-avatar-focus-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-warning-avatar-pressed-bg-color: set-opacity(--black, 0.3) !default;
$chip-warning-avatar-disabled-bg-color: set-opacity(--black, 0.3) !default;
$chip-warning-font-color: normal-color(--black) !default;
$chip-warning-hover-font-color: $chip-warning-font-color !default;
$chip-warning-focus-font-color: $chip-warning-font-color !default;
$chip-warning-active-font-color: $chip-warning-font-color !default;
$chip-warning-focus-active-font-color: $chip-warning-font-color !default;
$chip-warning-pressed-font-color: $chip-warning-font-color !default;
$chip-warning-disabled-font-color: $chip-warning-font-color !default;
$chip-warning-close-icon-font-color: set-opacity(--black, 0.8) !default;
$chip-warning-close-icon-hover-font-color: $chip-warning-hover-font-color !default;
$chip-warning-close-icon-pressed-font-color: $chip-warning-pressed-font-color !default;
$chip-warning-avatar-font-color: normal-color(--white) !default;
$chip-warning-avatar-hover-font-color: normal-color(--white) !default;
$chip-warning-avatar-focus-font-color: normal-color(--white) !default;
$chip-warning-avatar-active-font-color: normal-color(--white) !default;
$chip-warning-avatar-focus-active-font-color: normal-color(--white) !default;
$chip-warning-avatar-pressed-font-color: normal-color(--white) !default;
$chip-warning-avatar-disabled-font-color: normal-color(--white) !default;
$chip-warning-border-color: variant-color(--warning) !default;
$chip-warning-hover-border-color: hover-color(--warning) !default;
$chip-warning-focus-border-color: focused-color(--warning) !default;
$chip-warning-active-border-color: active-color(--warning) !default;
$chip-warning-focus-active-border-color: $chip-warning-focus-border-color !default;
$chip-warning-pressed-border-color: focused-color(--warning) !default;
$chip-warning-disabled-border-color: transparent !default;
//danger
$chip-danger-bg-color: normal-color(--error) !default;
$chip-danger-hover-bg-color: hover-color(--error) !default;
$chip-danger-focus-bg-color: focused-color(--error) !default;
$chip-danger-active-bg-color: active-color(--error) !default;
$chip-danger-focus-active-bg-color: $chip-danger-focus-bg-color !default;
$chip-danger-pressed-bg-color: focused-color(--error) !default;
$chip-danger-disabled-bg-color: disabled-color(--error) !default;
$chip-danger-avatar-bg-color: set-opacity(--black, 0.3) !default;
$chip-danger-avatar-hover-bg-color: set-opacity(--black, 0.3) !default;
$chip-danger-avatar-focus-bg-color: set-opacity(--black, 0.3) !default;
$chip-danger-avatar-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-danger-avatar-focus-active-bg-color: set-opacity(--black, 0.3) !default;
$chip-danger-avatar-pressed-bg-color: set-opacity(--black, 0.3) !default;
$chip-danger-avatar-disabled-bg-color: set-opacity(--black, 0.3) !default;
$chip-danger-font-color: normal-color(--white) !default;
$chip-danger-hover-font-color: $chip-danger-font-color !default;
$chip-danger-focus-font-color: $chip-danger-font-color !default;
$chip-danger-active-font-color: $chip-danger-font-color !default;
$chip-danger-focus-active-font-color: $chip-danger-font-color !default;
$chip-danger-pressed-font-color: $chip-danger-font-color !default;
$chip-danger-disabled-font-color: $chip-danger-font-color !default;
$chip-danger-avatar-font-color: $chip-danger-font-color !default;
$chip-danger-avatar-hover-font-color: $chip-danger-font-color !default;
$chip-danger-avatar-focus-font-color: $chip-danger-font-color !default;
$chip-danger-avatar-active-font-color: $chip-danger-font-color !default;
$chip-danger-avatar-focus-active-font-color: $chip-danger-font-color !default;
$chip-danger-avatar-pressed-font-color: $chip-danger-font-color !default;
$chip-danger-avatar-disabled-font-color: $chip-danger-font-color !default;
$chip-danger-close-icon-font-color: set-opacity(--, 0.8) !default;
$chip-danger-close-icon-hover-font-color: normal-color(--white) !default;
$chip-danger-close-icon-pressed-font-color: normal-color(--white) !default;
$chip-danger-border-color: variant-color(--error) !default;
$chip-danger-hover-border-color: hover-color(--error) !default;
$chip-danger-focus-border-color: focused-color(--error) !default;
$chip-danger-active-border-color: active-color(--error) !default;
$chip-danger-focus-active-border-color: $chip-danger-focus-border-color !default;
$chip-danger-pressed-border-color: focused-color(--error) !default;
$chip-danger-disabled-border-color: transparent !default;
//outline-theme
//primary
$chip-outline-primary-bg-color: transparent !default;
$chip-outline-primary-hover-bg-color: hover-color(--primary) !default;
$chip-outline-primary-focus-bg-color: transparent !default;
$chip-outline-primary-active-bg-color: active-color(--primary) !default;
$chip-outline-primary-focus-active-bg-color: $chip-outline-primary-focus-bg-color !default;
$chip-outline-primary-pressed-bg-color: focused-color(--primary) !default;
$chip-outline-primary-disabled-bg-color: transparent !default;
$chip-outline-primary-avatar-bg-color: normal-color(--primary) !default;
$chip-outline-primary-avatar-hover-bg-color: normal-color(--primary) !default;
$chip-outline-primary-avatar-focus-bg-color: normal-color(--primary) !default;
$chip-outline-primary-avatar-active-bg-color: normal-color(--primary) !default;
$chip-outline-primary-avatar-focus-active-bg-color: normal-color(--primary) !default;
$chip-outline-primary-avatar-pressed-bg-color: normal-color(--primary) !default;
$chip-outline-primary-avatar-disabled-bg-color: disabled-color(--primary) !default;
$chip-outline-primary-font-color: normal-color(--primary) !default;
$chip-outline-primary-hover-font-color: hover-color(--white) !default;
$chip-outline-primary-focus-font-color: focused-color(--primary) !default;
$chip-outline-primary-active-font-color: normal-color(--white) !default;
$chip-outline-primary-focus-active-font-color: focused-color(--primary) !default;
$chip-outline-primary-pressed-font-color: normal-color(--white) !default;
$chip-outline-primary-disabled-font-color: disabled-color(--primary) !default;
$chip-outline-primary-close-icon-font-color: $chip-outline-primary-font-color !default;
$chip-outline-primary-close-icon-hover-font-color: $chip-outline-primary-hover-font-color !default;
$chip-outline-primary-close-icon-pressed-font-color: $chip-outline-primary-pressed-font-color !default;
$chip-outline-primary-avatar-font-color: normal-color(--white) !default;
$chip-outline-primary-avatar-hover-font-color: normal-color(--white) !default;
$chip-outline-primary-avatar-focus-font-color: normal-color(--white) !default;
$chip-outline-primary-avatar-active-font-color: normal-color(--white) !default;
$chip-outline-primary-avatar-focus-active-font-color: normal-color(--white) !default;
$chip-outline-primary-avatar-pressed-font-color: normal-color(--white) !default;
$chip-outline-primary-avatar-disabled-font-color: normal-color(--white) !default;
$chip-outline-primary-border-color: variant-color(--primary) !default;
$chip-outline-primary-hover-border-color: hover-color(--primary) !default;
$chip-outline-primary-focus-border-color: focused-color(--primary) !default;
$chip-outline-primary-active-border-color: active-color(--primary) !default;
$chip-outline-primary-focus-active-border-color: focused-color(--primary) !default;
$chip-outline-primary-pressed-border-color: focused-color(--primary) !default;
$chip-outline-primary-disabled-border-color: disabled-color(--primary) !default;
//success
$chip-outline-success-bg-color: transparent !default;
$chip-outline-success-hover-bg-color: hover-color(--success) !default;
$chip-outline-success-focus-bg-color: transparent !default;
$chip-outline-success-active-bg-color: active-color(--success) !default;
$chip-outline-success-focus-active-bg-color: transparent !default;
$chip-outline-success-pressed-bg-color: focused-color(--success) !default;
$chip-outline-success-disabled-bg-color: disabled-color(--success) !default;
$chip-outline-success-avatar-bg-color: normal-color(--success) !default;
$chip-outline-success-avatar-hover-bg-color: normal-color(--success) !default;
$chip-outline-success-avatar-focus-bg-color: normal-color(--success) !default;
$chip-outline-success-avatar-active-bg-color: normal-color(--success) !default;
$chip-outline-success-avatar-focus-active-bg-color: normal-color(--success) !default;
$chip-outline-success-avatar-pressed-bg-color: normal-color(--success) !default;
$chip-outline-success-avatar-disabled-bg-color: disabled-color(--success) !default;
$chip-outline-success-font-color: normal-color(--success) !default;
$chip-outline-success-hover-font-color: normal-color(--white) !default;
$chip-outline-success-focus-font-color: focused-color(--success) !default;
$chip-outline-success-active-font-color: normal-color(--white) !default;
$chip-outline-success-focus-active-font-color: focused-color(--success) !default;
$chip-outline-success-pressed-font-color: normal-color(--white) !default;
$chip-outline-success-disabled-font-color: disabled-color(--success) !default;
$chip-outline-success-close-icon-font-color: normal-color(--success) !default;
$chip-outline-success-close-icon-hover-font-color: normal-color(--white) !default;
$chip-outline-success-close-icon-pressed-font-color: normal-color(--white) !default;
$chip-outline-success-avatar-font-color: normal-color(--white) !default;
$chip-outline-success-avatar-hover-font-color: normal-color(--white) !default;
$chip-outline-success-avatar-focus-font-color: normal-color(--white) !default;
$chip-outline-success-avatar-active-font-color: normal-color(--white) !default;
$chip-outline-success-avatar-focus-active-font-color: normal-color(--white) !default;
$chip-outline-success-avatar-pressed-font-color: normal-color(--white) !default;
$chip-outline-success-avatar-disabled-font-color: normal-color(--white) !default;
$chip-outline-success-border-color: variant-color(--success) !default;
$chip-outline-success-hover-border-color: hover-color(--success) !default;
$chip-outline-success-focus-border-color: focused-color(--success) !default;
$chip-outline-success-active-border-color: active-color(--success) !default;
$chip-outline-success-focus-active-border-color: focused-color(--success) !default;
$chip-outline-success-pressed-border-color: focused-color(--success) !default;
$chip-outline-success-disabled-border-color: transparent !default;
//info
$chip-outline-info-bg-color: transparent !default;
$chip-outline-info-hover-bg-color: hover-color(--link) !default;
$chip-outline-info-focus-bg-color: transparent !default;
$chip-outline-info-active-bg-color: active-color(--link) !default;
$chip-outline-info-focus-active-bg-color: transparent !default;
$chip-outline-info-pressed-bg-color: focused-color(--link) !default;
$chip-outline-info-disabled-bg-color: transparent !default;
$chip-outline-info-avatar-bg-color: normal-color(--link) !default;
$chip-outline-info-avatar-hover-bg-color: normal-color(--link) !default;
$chip-outline-info-avatar-focus-bg-color: normal-color(--link) !default;
$chip-outline-info-avatar-active-bg-color: normal-color(--link) !default;
$chip-outline-info-avatar-focus-active-bg-color: normal-color(--link) !default;
$chip-outline-info-avatar-pressed-bg-color: normal-color(--link) !default;
$chip-outline-info-avatar-disabled-bg-color: disabled-color(--link) !default;
$chip-outline-info-font-color: normal-color(--link) !default;
$chip-outline-info-hover-font-color: normal-color(--white) !default;
$chip-outline-info-focus-font-color: focused-color(--link) !default;
$chip-outline-info-active-font-color: normal-color(--white) !default;
$chip-outline-info-focus-active-font-color: $chip-outline-info-focus-font-color !default;
$chip-outline-info-pressed-font-color: normal-color(--white) !default;
$chip-outline-info-disabled-font-color: disabled-color(--link) !default;
$chip-outline-info-close-icon-font-color: normal-color(--link) !default;
$chip-outline-info-close-icon-hover-font-color: normal-color(--white) !default;
$chip-outline-info-close-icon-pressed-font-color: normal-color(--white) !default;
$chip-outline-info-avatar-font-color: normal-color(--white) !default;
$chip-outline-info-avatar-hover-font-color: normal-color(--white) !default;
$chip-outline-info-avatar-focus-font-color: normal-color(--white) !default;
$chip-outline-info-avatar-active-font-color: normal-color(--white) !default;
$chip-outline-info-avatar-focus-active-font-color: normal-color(--white) !default;
$chip-outline-info-avatar-pressed-font-color: normal-color(--white) !default;
$chip-outline-info-avatar-disabled-font-color: normal-color(--white) !default;
$chip-outline-info-border-color: variant-color(--link) !default;
$chip-outline-info-hover-border-color: hover-color(--link) !default;
$chip-outline-info-focus-border-color: focused-color(--link) !default;
$chip-outline-info-active-border-color: active-color(--link) !default;
$chip-outline-info-focus-active-border-color: $chip-outline-info-focus-border-color !default;
$chip-outline-info-pressed-border-color: focused-color(--link) !default;
$chip-outline-info-disabled-border-color: disabled-color(--link) !default;
//warning
$chip-outline-warning-bg-color: transparent !default;
$chip-outline-warning-hover-bg-color: hover-color(--warning) !default;
$chip-outline-warning-focus-bg-color: transparent !default;
$chip-outline-warning-active-bg-color: active-color(--warning) !default;
$chip-outline-warning-focus-active-bg-color: transparent !default;
$chip-outline-warning-pressed-bg-color: focused-color(--warning) !default;
$chip-outline-warning-disabled-bg-color: transparent !default;
$chip-outline-warning-avatar-bg-color: normal-color(--warning) !default;
$chip-outline-warning-avatar-hover-bg-color: normal-color(--warning) !default;
$chip-outline-warning-avatar-focus-bg-color: normal-color(--warning) !default;
$chip-outline-warning-avatar-active-bg-color: normal-color(--warning) !default;
$chip-outline-warning-avatar-focus-active-bg-color: normal-color(--warning) !default;
$chip-outline-warning-avatar-pressed-bg-color: normal-color(--warning) !default;
$chip-outline-warning-avatar-disabled-bg-color: disabled-color(--warning) !default;
$chip-outline-warning-font-color: normal-color(--warning) !default;
$chip-outline-warning-hover-font-color: hover-color(--black) !default;
$chip-outline-warning-focus-font-color: focused-color(--warning) !default;
$chip-outline-warning-active-font-color: normal-color(--black) !default;
$chip-outline-warning-focus-active-font-color: focused-color(--warning) !default;
$chip-outline-warning-pressed-font-color: normal-color(--black) !default;
$chip-outline-warning-disabled-font-color: disabled-color(--warning) !default;
$chip-outline-warning-close-icon-font-color: normal-color(--warning) !default;
$chip-outline-warning-close-icon-hover-font-color: normal-color(--black) !default;
$chip-outline-warning-close-icon-pressed-font-color: normal-color(--black) !default;
$chip-outline-warning-avatar-font-color: normal-color(--black) !default;
$chip-outline-warning-avatar-hover-font-color: normal-color(--black) !default;
$chip-outline-warning-avatar-focus-font-color: normal-color(--black) !default;
$chip-outline-warning-avatar-active-font-color: normal-color(--black) !default;
$chip-outline-warning-avatar-focus-active-font-color: normal-color(--black) !default;
$chip-outline-warning-avatar-pressed-font-color: normal-color(--black) !default;
$chip-outline-warning-avatar-disabled-font-color: normal-color(--black) !default;
$chip-outline-warning-border-color: variant-color(--warning) !default;
$chip-outline-warning-hover-border-color: hover-color(--warning) !default;
$chip-outline-warning-focus-border-color: focused-color(--warning) !default;
$chip-outline-warning-active-border-color: active-color(--warning) !default;
$chip-outline-warning-focus-active-border-color: focused-color(--warning) !default;
$chip-outline-warning-pressed-border-color: focused-color(--warning) !default;
$chip-outline-warning-disabled-border-color: disabled-color(--warning) !default;
//danger
$chip-outline-danger-bg-color: transparent !default;
$chip-outline-danger-hover-bg-color: hover-color(--error) !default;
$chip-outline-danger-focus-bg-color: transparent !default;
$chip-outline-danger-active-bg-color: active-color(--error) !default;
$chip-outline-danger-focus-active-bg-color: transparent !default;
$chip-outline-danger-pressed-bg-color: focused-color(--error) !default;
$chip-outline-danger-disabled-bg-color: transparent !default;
$chip-outline-danger-avatar-bg-color: normal-color(--error) !default;
$chip-outline-danger-avatar-hover-bg-color: normal-color(--error) !default;
$chip-outline-danger-avatar-focus-bg-color: normal-color(--error) !default;
$chip-outline-danger-avatar-active-bg-color: normal-color(--error) !default;
$chip-outline-danger-avatar-focus-active-bg-color: normal-color(--error) !default;
$chip-outline-danger-avatar-pressed-bg-color: normal-color(--error) !default;
$chip-outline-danger-avatar-disabled-bg-color: disabled-color(--error) !default;
$chip-outline-danger-font-color: normal-color(--error) !default;
$chip-outline-danger-hover-font-color: normal-color(--white) !default;
$chip-outline-danger-focus-font-color: focused-color(--error) !default;
$chip-outline-danger-active-font-color: normal-color(--white) !default;
$chip-outline-danger-focus-active-font-color: active-color(--error) !default;
$chip-outline-danger-pressed-font-color: normal-color(--white) !default;
$chip-outline-danger-disabled-font-color: disabled-color(--error) !default;
$chip-outline-danger-close-icon-font-color: normal-color(--error) !default;
$chip-outline-danger-close-icon-hover-font-color: normal-color(--white) !default;
$chip-outline-danger-close-icon-pressed-font-color: normal-color(--white) !default;
$chip-outline-danger-avatar-font-color: normal-color(--white) !default;
$chip-outline-danger-avatar-hover-font-color: normal-color(--white) !default;
$chip-outline-danger-avatar-focus-font-color: normal-color(--white) !default;
$chip-outline-danger-avatar-active-font-color: normal-color(--white) !default;
$chip-outline-danger-avatar-focus-active-font-color: normal-color(--white) !default;
$chip-outline-danger-avatar-pressed-font-color: normal-color(--white) !default;
$chip-outline-danger-avatar-disabled-font-color: normal-color(--white) !default;
$chip-outline-danger-border-color: variant-color(--error) !default;
$chip-outline-danger-hover-border-color: hover-color(--error) !default;
$chip-outline-danger-focus-border-color: focused-color(--error) !default;
$chip-outline-danger-active-border-color: active-color(--error) !default;
$chip-outline-danger-focus-active-border-color: focused-color(--error) !default;
$chip-outline-danger-pressed-border-color: focused-color(--error) !default;
$chip-outline-danger-disabled-border-color: disabled-color(--error) !default;

// box shadows
// primary
$chip-primary-box-shadow: custom-box-shadow(--primary) !default;
// success
$chip-success-box-shadow: custom-box-shadow(--success) !default;
// danger
$chip-danger-box-shadow: custom-box-shadow(--error) !default;
// warning
$chip-warning-box-shadow: custom-box-shadow(--warning) !default;
// info
$chip-info-box-shadow: custom-box-shadow(--link) !default;
