$progress-btn-color: normal-color(--secondary) !default;
$progress-btn-bgcolor: set-opacity(--white, 0.25) !default;
$progress-btn-bgcolor-normal: set-opacity(--white, 0.25) !default;
$progress-btn-warning-progress-color: set-opacity(--black, 0.25) !default;
$progress-btn-circle-opacity: 1 !default;

$progress-btn-transition: all 0.3s linear !default;

$progress-btn-spinner-padding: 12px !default;
$progress-btn-spinner-padding-small: 10px !default;
$progress-btn-spinner-padding-bigger: 14px !default;
$progress-btn-spinner-padding-bigger-small: 12px !default;

$progress-btn-spin-btn-padding: 16px !default;
$progress-btn-small-spin-btn-padding: 14px !default;
$progress-btn-bigger-spin-btn-padding: 20px !default;
$progress-btn-bigger-small-spin-btn-padding: 16px !default;

$progress-btn-flat-primary-progress-color: normal-color(--primary) !default;
$progress-btn-flat-success-progress-color: normal-color(--success) !default;
$progress-btn-flat-info-progress-color: normal-color(--link) !default;
$progress-btn-flat-alert-progress-color: normal-color(--alert) !default;
$progress-btn-flat-warning-progress-color: normal-color(--warning) !default;
$progress-btn-flat-danger-progress-color: normal-color(--error) !default;

$progress-bar-opacity: 0.3 !default;

* {
  @import "~@syncfusion/ej2-splitbuttons/styles/progress-button/layout";
}

@mixin spinner-color($color: $btn-color) {
  .e-path-circle {
    stroke: $color;
  }

  .e-path-arc {
    stroke: $color;
  }
}

@mixin progress-bar-color($color-name, $default-color: $progress-btn-bgcolor) {
  .e-progress {
    background-color: $default-color;
  }

  &.e-outline:not(:focus):not(:hover),
  &.e-flat:not(:focus):not(:hover) {
    .e-progress {
      background-color: set-opacity($color-name, 0.25);
    }
  }
}

@mixin spinner-style($spinner-color: $progress-btn-color) {
  fill: $spinner-color;
  stroke: $spinner-color;

  .e-path-circle {
    opacity: $progress-btn-circle-opacity;
    stroke-width: 1px;
    transition: visibility 0s 2s, opacity 2s linear;
  }
}
