$blue: #00acc8;
$darkBlue: #175563;
$yellow: #ffd100;
$green: #61B992;
$greenYellow: #AFCB5D;
$red: #ED2D33;
$orange: #E16736;
$purple: #800080;

$primary: $blue;
$secondary: $yellow;
$fontColor: $darkBlue;

$body-bg: $gray-200;
$body-color: $fontColor;

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
);
