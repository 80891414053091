$tooltip-drop-shadow: 0 0 4px set-opacity(--black-900, 0.16), 0 0 16px set-opacity(--primary, 0.25) !default;
$tooltip-content-bg-color: normal-color(--white-100) !default;
$tooltip-content-border-color: normal-color(-white-300) !default;
$tooltip-content-font-color: normal-color(--black-900) !default;
$tooltip-default-icon-color: normal-color(--black-900) !default;
$tooltip-icon-bg-normal: normal-color(--white) !default;
$tooltip-hover-icon-color: hover-color(--black-900) !default;
$tooltip-icon-bg-hover: hover-color(--white) !default;
$tooltip-font-size: 12px !default;
$tooltip-mobile-font-size: 14px !default;
$tooltip-border-radius: 4px !default;
$tooltip-opacity: 1 !default;
$tooltip-arrow-outer-border: 8px !default;
$tooltip-arrow-inner-border: 7px !default;
$tooltip-horizontal-padding: 4px !default;
$tooltip-vertical-padding: 8px !default;
$tooltip-mobile-horizontal-padding: 4px !default;
$tooltip-mobile-vertical-padding: 8px !default;
$tooltip-line-height: 17px !default;
$tooltip-arrow-icon-font-size: 16px !default;
$tooltip-icon-border-color: normal-color(--white) !default;
$border-size: 1px !default;
$border-type: solid !default;
$tooltip-close-icon-border: 1px solid normal-color(--white);

$tooltip-tip-bottom-height: 8px !default;
$tooltip-tip-bottom-left: 50% !default;
$tooltip-tip-bottom-top: 8px !default;
$tooltip-tip-bottom-width: 16px !default;

$tooltip-tip-top-height: 8px !default;
$tooltip-tip-top-left: 50% !default;
$tooltip-tip-top-top: -9px !default;
$tooltip-tip-top-width: 16px !default;

$tooltip-tip-left-height: 16px !default;
$tooltip-tip-left-left: -9px !default;
$tooltip-tip-left-top: 48% !default;
$tooltip-tip-left-width: 8px !default;

$tooltip-tip-right-height: 16px !default;
$tooltip-tip-right-left: 100% !default;
$tooltip-tip-right-top: 50% !default;
$tooltip-tip-right-width: 8px !default;
