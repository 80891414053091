/* Variables */

@import "ej2-buttons/button";
@import "ej2-buttons/check-box";
@import "ej2-buttons/chips";
@import "ej2-buttons/radio-button";
@import "ej2-buttons/switch";

/* Layouts Themes and Icons */

/* Buttons */
@import "~@syncfusion/ej2-buttons/styles/button/layout";
@import "~@syncfusion/ej2-buttons/styles/button/theme";

/* Checkboxes */
@import "~@syncfusion/ej2-buttons/styles/check-box/icons/bootstrap4";
@import "ej2-buttons/customization/checkbox";
@import "~@syncfusion/ej2-buttons/styles/check-box/theme";

/* Chips */
@import "~@syncfusion/ej2-buttons/styles/chips/icons/bootstrap4";
@import "~@syncfusion/ej2-buttons/styles/chips/layout";
@import "~@syncfusion/ej2-buttons/styles/chips/theme";

/* Radio buttons */
@import "~@syncfusion/ej2-buttons/styles/radio-button/layout";
@import "~@syncfusion/ej2-buttons/styles/radio-button/theme";

/* Switch */
@import "~@syncfusion/ej2-buttons/styles/switch/layout";
@import "~@syncfusion/ej2-buttons/styles/switch/theme";


/* Customizations */
@import "ej2-buttons/customization/button";
