@import "variables/sizing";
@import "functions";

$field-border-width: 1px;
$field-background: #fff !important;
$field-border-radius: 0.75rem !important;

.e-input-group .e-input-group-icon:last-child, .e-input-group.e-control-wrapper .e-input-group-icon:last-child {
  border-bottom-right-radius: $field-border-radius;
  border-top-right-radius: $field-border-radius;
}

input.e-input,
.e-input-group input.e-input,
.e-input-group input,
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper input,
.e-float-input input,
.e-float-input.e-input-group input,
.e-float-input.e-control-wrapper input,
.e-float-input.e-control-wrapper.e-input-group input,
.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-control-wrapper {
  border-radius: $field-border-radius;
}

.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-input-group,
.e-float-input.e-control-wrapper,
.e-float-input.e-input-group.e-control-wrapper {
  background: var(--white-color);
  color: var(--black-900-color);
}

::ng-deep span.e-custom-datepicker {
  height: 3.1rem;
  padding-left: 2rem;
}

::ng-deep span.e-input-group.e-control-wrapper.e-custom-datepicker.e-date-wrapper.e-non-edit.e-valid-input.e-error {
  border: $field-border-width solid #e2e1e1;

  .e-input-group-icon.e-date-icon.e-icons {
    border: $field-border-width solid #e2e1e1;
  }
}

::ng-deep #datepicker_input {
  margin: auto 0;
}

#datepicker.e-warning span:first-child {
  border: $field-border-width solid var(--warning-color) !important;
  box-shadow: none;

  .e-input-group-icon.e-date-icon.e-icons {
    border-color: var(--warning-color) !important;
  }
}

#datepicker.e-error span:first-child {
  border: $field-border-width solid var(--error-color) !important;
  box-shadow: none;

  .e-input-group-icon.e-date-icon.e-icons {
    border-color: var(--error-color) !important;
  }
}

.e-disabled {
  color: var(--black-300-color);
}
