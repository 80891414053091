﻿  @include export-module('pager-layout') {
  /*! Pager layout */
  .e-bigger  #{&}.e-pager,
  #{&}.e-pager.e-bigger {
    font-size: $pager-bigger-font-size;
    padding: $pager-bigger-padding;

    div.e-icons {
      font-size: $pager-bigger-icon-font-size;
    }

    div.e-parentmsgbar {
      padding-bottom: $pager-bigger-messagebar-padding-bottom;
      padding-right: $pager-bigger-messagebar-padding-right;
      padding-top: $pager-bigger-messagebar-padding-top;
    }

    .e-pagerdropdown {
      height: 40px;
      margin: $pager-bigger-dropdown-margin;
      margin-top: $pager-bigger-dropdown-margin-top;
    }

    .e-pagerconstant {
      margin-bottom: $pager-bigger-dropdown-constant-margin;
      margin-left: $pager-bigger-constant-margin-left;
    }

    .e-pagercontainer {
      margin: $pager-bigger-pagercontainer-margin;
    }

    &.e-rtl .e-pagercontainer {
      margin: $pager-bigger-new-container-rtl-margin;
	}

    @media (max-width: 769px) {
      padding: $pager-device-padding;

      &.e-rtl div.e-parentmsgbar {
        margin-right: 0;
      }

      div.e-parentmsgbar {
        padding: $pager-device-messabar-padding;
      }
}

    .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
    .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
      margin-left: $pager-bigger-nexticon-marign-left;
    }

    &.e-rtl .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
    &.e-rtl e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
      margin-left: 0;
      margin-right: $pager-bigger-rtl-nexticon-marign-right;
  }

    &.e-rtl div.e-parentmsgbar {
      margin-left: $pager-bigger-rtl-parentmsgbar-margin-left;
      margin-top: $pager-bigger-rtl-parentmsgbar-margin-top;
   }

    .e-numericitem {
      margin: $pager-bigger-numeric-item-margin;
    }

    .e-pp.e-spacing,
    .e-np.e-spacing,
    .e-pp.e-spacing:hover,
    .e-np.e-spacing:hover {
      padding: $pager-bigger-letter-spacing;
    }

    .e-spacing,
    .e-numericitem:hover,
    .e-currentitem {
      border-radius: $pager-bigger-current-item-border-radius;
      padding: $pager-bigger-current-item-padding;
    }

    .e-numericitem:hover:not(.e-np):not(.e-pp):not(.e-active) {
      padding: $pager-bigger-current-item-hover-padding;
    }

    &.e-rtl .e-spacing,
    &.e-rtl .e-numericitem:hover,
    &.e-rtl .e-currentitem {
      margin: $pager-bigger-rtl-numeric-margin;
      padding: $pager-bigger-rtl-activeitem-padding;
    }

    &.e-rtl .e-numericitem:hover:not(.e-np):not(.e-pp):not(.e-active) {
      padding: $pager-bigger-rtl-activeitem-hover-padding;
    }

    &.e-rtl {
      & .e-pp,
      & .e-np,
      & .e-pp:hover,
      & .e-np:hover {
        margin-top: $pager-tripledot-rtl-bigger-margin-top;
        }

      & .e-pagerdropdown {
        margin: $pager-rtl-pagerdropdown-bigger-margin;
        }

      & .e-pagerconstant {
        margin: $pager-rtl-pagerconstant-bigger-margin-top;
            }
      }

    .e-pagercontainer .e-firstpage,
    .e-pagercontainer .e-prevpage,
    .e-pagercontainer .e-firstpagedisabled,
    .e-pagercontainer .e-prevpagedisabled,
    .e-pagercontainer .e-nextpage,
    .e-pagercontainer .e-lastpage,
    .e-pagercontainer .e-nextpagedisabled,
    .e-pagercontainer .e-lastpagedisabled {
      margin-right: $pager-bigger-pagercontainer-icons-marign-right;
      padding: $pager-bigger-pagercontainer-icons-padding;
    }
   }

  #{&}.e-pager {
    border-style: $pager-border-type;
    border-width: 1px;
    display: inline-block;
    font-size: $pager-font-size;
    padding: $pager-padding;
    white-space: normal;
    width: 100%;

    [class^='e-'] {
      box-sizing: border-box;
    }

    div.e-parentmsgbar {
      float: right;
      padding-bottom: $pager-messagebar-padding-bottom;
      padding-right: $pager-messagebar-padding-right;
      padding-top: $pager-messagebar-padding-top;
    }

    .e-pagesizes {
      display: inline;
    }

    .e-pagerdropdown {
      display: inline-block;
      height: $pager-dropdown-height;
      margin-left: $pager-dropdown-margin-left;
      margin-right: $pager-dropdown-margin-right;
      margin-top: $pager-dropdown-margin-top;
      overflow: $pager-dropdown-overflow;
      vertical-align: middle;
      width: 90px;
    }

    .e-pagerconstant {
      display: inline-block;
      margin: $pager-constant-margin;
      overflow: hidden;
      width: auto;
    }

    .e-icons {
      font-size: $pager-icon-size;
    }

    .e-numericitem {
      border-right-style: $pager-border-type;
      border-right-width: $pager-border-size;
      display: inline-block;
      line-height: 1;
      margin-right: $pager-numericitem-margin-right;
      min-width: $pager-numericitem-min-width;
      padding: $pager-numericitem-padding;
      text-align: center;
    }

    div.e-pagermsgdiv {
      padding-right: 6px;
      padding-top: 4px;
      text-align: right;
    }

    .e-firstpagedisabled,
    .e-prevpagedisabled,
    .e-nextpagedisabled,
    .e-lastpagedisabled {
      opacity: .3;
    }

    .e-spacing,
    .e-numericitem:hover,
    .e-currentitem {
      border-radius: $pager-numericitem-border-radius;
      cursor: pointer;
      padding: $pager-numeric-icon-padding;
      text-decoration: none;
    }

    .e-numericitem:hover:not(.e-np):not(.e-pp):not(.e-active) {
      padding: $pager-numeric-icon-hover-padding;
    }

    .e-currentitem {
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
    }

    div,
    a {
      display: inline;
      user-select: none;
    }

    .e-icons::before {
      display: inline-block;
    }

    .e-pagercontainer .e-firstpage,
    .e-pagercontainer .e-prevpage,
    .e-pagercontainer .e-firstpagedisabled,
    .e-pagercontainer .e-prevpagedisabled,
    .e-pagercontainer .e-nextpage,
    .e-pagercontainer .e-lastpage,
    .e-pagercontainer .e-nextpagedisabled,
    .e-pagercontainer .e-lastpagedisabled {
      border-right-style: $pager-border-type;
      border-right-width: $pager-border-size;
      display: inline-block;
      margin-right: $pager-navigationicon-right;
      margin-top: $pager-navigationicon-top;
      min-width: 26px;
      padding: $pager-pagecontainer-icon-padding;
    }

    .e-pagercontainer .e-lastpage,
    .e-pagercontainer .e-lastpagedisabled {
      border-right: $pager-last-border-size;
    }

    .e-firstpage:hover,
    .e-prevpage:hover,
    .e-lastpage:hover,
    .e-nextpage:hover {
      cursor: pointer;
      text-decoration: none;
    }

    a.e-nextprevitemdisabled {
      display: none;
      margin-left: $pager-border-size;
      margin-right: $pager-border-size;
      padding-left: 5px;
      padding-right: 5px;
      text-decoration: none;
    }

    .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
    .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
      margin-left: $pager-next-icon-margin-left;
    }

    .e-pagercontainer {
      border-radius: 4px;
      border-style: $pager-border-type;
      border-width: $pager-border-size;
      display: inline-block;
      margin: $pager-container-margin;
      overflow: hidden;
    }

    .e-lastpage:Hover {
      border-radius: $pager-last-page-hover-border-radius;
    }

    .e-firstpage:Hover {
      border-radius: $pager-first-page-hover-border-radius;
    }

    .e-pagermessage,
    .e-pagerexternalmsg {
      display: block;
      margin: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .e-mfirst,
    .e-mprev,
    .e-mnext,
    .e-mlast {
      display: none;
    }

    .e-mprev {
      text-indent: -3px;
    }

    .e-mnext {
      text-indent: -2px;
    }

    // sass-lint:disable-all
    .e-mfirst,
    .e-mprev,
    .e-mnext,
    .e-mlast {
      -webkit-tap-highlight-color: $pager-bg-color;
    }

    // sass-lint:enable-all  

    .e-pp,
    .e-np,
    .e-pp:hover,
    .e-np:hover {
      font-size: $pager-tripledot-font-size;
      font-weight: $pager-numeric-font-weight;
      letter-spacing: $pager-letter-spacing;
      padding: $pager-tripledot-padding;
    }

    @media (max-width: 769px) {

      padding: 13px 0;

      div.e-parentmsgbar {
        box-sizing: border-box;
        display: inline-block;
        float: initial;
        padding-bottom: 0;
        padding-right: 0;
        padding-top: 0;
        text-align: center;
        vertical-align: top;
        width: calc(60% - 48px);
      }

      .e-pagesizes {
        display: none;
      }

      .e-pagecountmsg {
        display: none;
      }

      .e-pagercontainer {
        display: none;
      }

      .e-icons {
        font-size: $pager-mobile-icon-size;
      }

      .e-mfirst,
      .e-mprev,
      .e-mnext,
      .e-mlast {
        border: 0;
        box-sizing: border-box;
        display: inline-block;
        padding: 1% 5%;
      }

      .e-mfirst {
        margin-right: 4px;
        text-align: right;
        width: calc(10% + 11px);
      }

      .e-mprev {
        margin: 0 4px;
        text-align: right;
        width: 10%;
      }

      .e-mnext {
        margin: 0 4px;
        text-align: left;
        width: 10%;
      }

      .e-mlast {
        margin-left: 4px;
        text-align: left;
        width: calc(10% + 11px);
      }

    }

    &.e-rtl {
      direction: rtl;

      & .e-pagercontainer,
      & div,
      & a {
        float: initial;
      }

      & .e-parentmsgbar {
        float: left;
        margin-left: $pager-rtl-parentmsgbar-margin-left;
      }

      & .e-pagerdropdown {
        margin: $pager-rtl-pagerdropdown-margin;
      }

      & .e-pagerconstant {
        margin: $pager-rtl-pagerconstant-margin-top;
      }

      & .e-pagerexternalmsg {
        float: none;
      }

      & .e-pagercontainer {
        float: none;
        margin: $pager-container-rtl-margin;
        padding: 0;
	    }

      & .e-firstpage,
      & .e-prevpage,
      & .e-firstpagedisabled,
      & .e-prevpagedisabled,
      & .e-nextpage,
      & .e-lastpage,
      & .e-nextpagedisabled,
      & .e-lastpagedisabled {
        padding: $pager-rtl-navigation-icon-padding;
      }

      & .e-firstpage::before,
      & .e-firstpage:hover,
      & .e-firstpagedisabled::before {
        content: '\e701';
      }

      & .e-prevpage::before,
      & .e-prevpage:hover,
      & .e-prevpagedisabled::before {
        content: '\e848';
      }

      & .e-nextpage::before,
      & .e-nextpage:hover,
      & .e-nextpagedisabled::before {
        content: '\e84b';
      }

      & .e-lastpage::before,
      & .e-lastpage:hover,
      & .e-lastpagedisabled::before {
        content: '\e716';
      }

      & .e-nextpage,
      & .e-nextpagedisabled,
      & .e-prevpage,
      & .e-prevpagedisabled,
      & .e-firstpage,
      & .e-firstpagedisabled {
        border-left-style: $pager-border-type;
        border-left-width: $pager-border-size;
        }

      & .e-nextpage,
      & .e-nextpagedisabled,
      & .e-prevpage,
      & .e-prevpagedisabled {
        border-right-style: none;
        border-right-width: 0;
      }

      & .e-firstpage,
      & .e-firstpagedisabled {
        border-right: medium none;
      }

      & .e-firstpage:hover {
        border-radius: 0 4px 4px 0;
      }

      & .e-lastpage:hover {
        border-radius: 4px 0 0 4px;
      }

      & .e-numericcontainer {
        float: none;
      }

      & .e-numericitem {
        border-left-style: $pager-border-type;
        border-left-width: $pager-border-size;
        border-right-style: none;
        border-right-width: 0;
        min-width: 26px;
      }

      & .e-next.e-icons.e-icon-next.e-nextpagedisabled.e-disable,
      &.e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
        margin-left: 0;
        margin-right: $pager-next-icon-margin-left;
    }

      & .e-spacing,
      & .e-numericitem:hover,
      & .e-currentitem {
        margin: $pager-rtl-numeric-margin;
        padding: $pager-activeitem-padding;
      }

      & .e-numericitem:hover:not(.e-np):not(.e-pp):not(.e-active) {
        padding: $pager-activeitem-hover-padding;
      }

      & .e-pp,
      & .e-np,
      & .e-pp:hover,
      & .e-np:hover {
        margin-top: $pager-tripledot-rtl-margin-top;
        padding: $pager-tripledot-rtl-padding;
      }

      @media (max-width: 769px) {
        .e-mfirst,
        .e-mprev,
        .e-mnext,
        .e-mlast {
          border: 0;
        }

        &.e-rtl div.e-parentmsgbar {
          float: initial;
          margin-left: 0;
          margin-top: $pager-rtl-pagermsgbar-device-margin-top;
           }

        .e-parentmsgbar {
          float: right;
        }
       }
   }

    &.sf-pager {
      .e-last,
      .e-mnext,
      .e-mlast {
        margin-left: -4px;
      }
    }

    &.sf-pager {
      .e-prev {
        margin-left: -3px;
      }
    }

    &.sf-pager {
      .e-link,
      .e-next.e-icons.e-icon-next.e-nextpage.e-pager-default {
        margin-left: 0;
      }
    }
}
}
