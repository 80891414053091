$datepicker-icon-color: normal-color(--white) !default;
$datepicker-active-icon-color: normal-color(--black) !default;
$datepicker-popup-box-shadow: none !default;
$datepicker-input-border-style: none !default;
$datepicker-popup-bg-color: $content-bg !default;
$datepicker-popup-border-radius: 4px !default;
$datepicker-calendar-border-style: none !default;
$datepicker-icon: "\e960" !default;
$datepicker-popup-border: 1px solid set-opacity(--black, 0.15) !default;
$datepicker-icon-font-size: 14px !default;
$datepicker-icon-container-min-height: 29px !default;
$datepicker-icon-container-min-width: 3.1rem !default;
$datepicker-bigger-icon-container-min-height: 36px !default;
$datepicker-bigger-icon-container-min-width: 34px !default;
$datepicker-bigger-icon-font-size: 16px !default;
$datepicker-overlay: set-opacity(--black, 0.5) !default;
$datepicker-icon-normal-margin: 0 !default;
$datepicker-icon-bigger-margin: 0 !default;
$datepicker-icon-hover-color: normal-color(--black) !default;
$datepicker-icon-active-bg-color: active-color(--black-700) !default;
$datepicker-active-border-color: active-color(--primary) !default;
$datepicker-icon-border-radius: 0 !default;
$datepicker-box-sizing: border-box !default;
$datepicker-othermonth-row: table-row !default;
$datepicker-modal-header-bg: transparent !default;
$datepicker-modal-header-color: transparent !default;
$datepicker-modal-header-display: none !default;
$datepicker-calendar-tbody-landscape-height: 150px !default;
// mouse small icon
$datepicker-small-icon-font-size: 12px !default;
// Touch small icon
$datepicker-bigger-small-icon-font-size: 15px !default;
