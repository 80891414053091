@mixin avatar {
  border: 1px solid normal-color(-white-300);
  border-radius: 50%;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  object-fit: cover;
}

@mixin status-dot {
  border-radius: 50%;
  bottom: -10px;
  height: 8px;
  position: absolute;
  right: 0;
  width: 8px;
}

img.avatar {
  @include avatar;
}

.avatar {
  position: relative;

  img {
    @include avatar;
  }

  .status-online {
    @include status-dot;

    background-color: normal-color(--success);
  }

  .status-offline {
    @include status-dot;

    background-color: normal-color(--error);
  }

  .status-pending {
    @include status-dot;

    background-color: normal-color(--error);
  }
}
