@import "~bootstrap/scss/card";
@import "theme/variables.scss";

.zdb-card {
  @extend .card;
  color: $darkBlue;
  background-color: transparent;
  border-radius: $zdb-card-border-radius;
  border: 0px;
  flex: 1 0 auto;
  justify-content: space-between;

  .card-header {
    border-bottom: 0px;
    border-radius: $zdb-card-border-radius;
    background-color: transparent;
  }
  .card-body {
    font-weight: 800;
    background-color: transparent;
    .card-text {
      font-size: 1rem;
      display: inline-block;
      background-color: rgb(255, 255, 255, 0.75);
      border-radius: $zdb-card-border-radius;
    }
  }
  .card-footer {
    border-radius: $zdb-card-border-radius;
    border: 0px;
    background-color: transparent;
  }
}

.card-deck {
  .card {
    padding: 0.15rem 1rem;
    // margin: 0;
  }
  .card-header {
    padding: 1.25rem 0.15rem 0.15rem;
  }
  .card-body {
    padding: 0 0.25rem;
    .card-text {
      padding: 0.25rem;
    }
  }
  .card-footer {
    padding: 0.75rem 0.15rem 0;
  }
}

.card-archive {
  &.card-body {
    padding: 0 1.25rem;
  }
  .card-archive-item,
  .card-archive-header {
    background-color: #fff;
    padding: 0 1.25rem;

    .row {
      padding: 0.25rem;
    }

    p {
      padding: 0.65rem 0.25rem;
      margin-bottom: 0;
    }

    .badge-pill {
      font-size: inherit;
    }
  }
  .card-archive-item {
    border-radius: $zdb-card-border-radius;
    margin-bottom: 0.25rem;
  }
  .card-archive-header {
    background: none;
    border-bottom: 1px solid $gray-200;
    border-radius: none !important;
    margin: 1.75rem 0 1rem;
    padding-bottom: 0.5rem;


    .row {
      padding: 0 0.25rem;
    }
    p {
      padding: 0.25rem;
      font-size: 1rem;
      font-weight: 200;
    }
  }
}
