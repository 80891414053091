/* Variables */

@import "ej2-inputs/input";
@import "ej2-inputs/numericinput";
@import "ej2-inputs/maskedtextbox";
@import "ej2-inputs/slider";
@import "ej2-inputs/uploader";
@import "ej2-inputs/color-picker";

/* Layouts Themes and Icons */

/* Color Picker */
@import "~@syncfusion/ej2-inputs/styles/color-picker/icons/bootstrap4";
@import "~@syncfusion/ej2-inputs/styles/color-picker/layout";
@import "~@syncfusion/ej2-inputs/styles/color-picker/theme";

/* Input */
@import "~@syncfusion/ej2-inputs/styles/input/icons/bootstrap4";
@import "~@syncfusion/ej2-inputs/styles/input/layout";
@import "~@syncfusion/ej2-inputs/styles/input/theme";

/* Masked Text Box */
@import "~@syncfusion/ej2-inputs/styles/maskedtextbox/layout";
@import "~@syncfusion/ej2-inputs/styles/maskedtextbox/theme";

/* Numeric Input */
@import "~@syncfusion/ej2-inputs/styles/numerictextbox/icons/bootstrap4";
@import "~@syncfusion/ej2-inputs/styles/numerictextbox/layout";
@import "~@syncfusion/ej2-inputs/styles/numerictextbox/theme";

/* Slider */
@import "~@syncfusion/ej2-inputs/styles/slider/icons";

/* Text Box */
@import "~@syncfusion/ej2-inputs/styles/textbox/layout";
@import "~@syncfusion/ej2-inputs/styles/textbox/theme";

/* Uploader */
@import "~@syncfusion/ej2-inputs/styles/uploader/icons/bootstrap4";
@import "~@syncfusion/ej2-inputs/styles/uploader/theme";
@import "~@syncfusion/ej2-inputs/styles/uploader/layout";

/* Customizations */
@import "ej2-inputs/customization/label";
@import "ej2-inputs/customization/slider-layout";
@import "ej2-inputs/customization/slider-theme";
