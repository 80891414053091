@include export-module('excel-filter-bootstrap4-icons') {
  /*! component icons */

  .e-icon-check::before {
    content: '\e718';
  }

  #{&}.e-excelfilter {
    .e-icon-filter::before {
      content: '\e714';
    }

    .e-excl-filter-icon::before {
      content: '\e714';
    }

    .e-excl-filter-icon.e-filtered::before {
      content: '\e748';
    }

    .e-ccsearch-icon::before {
      content: '\e724';
    }

    .e-chkcancel-icon::before {
      content: '\e745';
    }
  }
}
