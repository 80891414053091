@mixin status {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  z-index: 2;
}

.status-background {
  align-items: center;
  background-color: normal-color(--white);
  border-radius: 50%;
  bottom: 0;
  display: flex;
  height: 10px;
  justify-content: center;
  position: absolute;
  right: 8px;
  width: 10px;
  z-index: 1;
}

.status-online {
  @include status;

  background-color: var(--success-color);
}

.status-away {
  @include status;

  background-color: var(--warning-color);
}

.status-offline {
  @include status;

  border: 1px solid var(--black-color);
}

.status-busy {
  @include status;

  background-color: var(--error-color);
}
