/* component's theme wise override definitions and variables */
$vscroll-skin: "bootstrap4" !default;
$vscroll-nav-nrml-height: 40px !default;
$vscroll-hover-font: hover-color(--black) !default;
$vscroll-active-font-color: active-color(--black) !default;
$vscroll-border-size: 1px !default;
$vscroll-border-type: solid !default;

$vscroll-default-bg: normal-color(--white) !default;
$vscroll-nav-nrml-minheight: 40px !default;
$vscroll-nav-bgr-minheight: 50px !default;
$vscroll-nav-nrml-width: 40px !default;
$vscroll-nav-bgr-width: 50px !default;
$vscroll-nrml-padding: 0 $vscroll-nav-nrml-width !default;
$vscroll-bgr-padding: $vscroll-nav-bgr-width 0 !default;
$vscroll-box-shadow: none !default;
$vscroll-overlay-opacity: 0.5 !default;
$vscroll-overlay-bg: normal-color(--black) !default;
$vscroll-overlay-start: set-opacity(--black, 0) !default;
$vscroll-overlay-end: set-opacity(--black, 1) !default;
$vscroll-right-bg: linear-gradient(-270deg, $vscroll-overlay-start 0%, $vscroll-overlay-end 100%) !default;
$vscroll-left-bg:  linear-gradient(-270deg, $vscroll-overlay-end 0%, $vscroll-overlay-start 100%) !default;

$vscroll-device-arrow-box-shadow: -4px 0 8px 0 set-opacity(--black-700, 0.06) !default;
$vscroll-device-arrow-rtl-box-shadow: 4px 0 8px 0 set-opacity(--black-700, 0.06) !default;
$vscroll-device-arrow-bg: #f8f8f8 !default;
$vscroll-device-arrow-border-size: 1px !default;
$vscroll-device-arrow-border-color: #ccc !default;
$vscroll-device-arrow-color: #317ab9 !default;
$vscroll-device-arrow-size: 14px !default;
$vscroll-device-arrow-width: 52px !default;

$vscroll-default-icon-color: #333 !default;
$vscroll-hover-bg: #e6e6e6 !default;
$vscroll-press-bg: #e6e6e6 !default;
$vscroll-default-border: #ccc !default;
$vscroll-focus-border: 0 !default;
$vscroll-active-border: 0 !default;
$vscroll-hover-border: 0 !default;
$vscroll-hover-border-color: transparent !default;
$vscroll-active-box-shadow: inset 0 3px 5px rgba(0 , 0 , 0 , 0.125) !default;

@mixin vscroll-btn-animation {
  content: "";
}

@mixin vscroll-btn-animation-after {
  content: "";
}
