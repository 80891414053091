$timepicker-default-text-indent: 20px !default;
$timepicker-list-bigger-line-height: 32px !default;
$timepicker-list-normal-line-height: 26px !default;
$timepicker-list-normal-font-size: 14px !default;
$timepicker-list-bigger-font-size: 16px !default;
$timepicker-list-normal-text-indent: $timepicker-default-text-indent !default;
$timepicker-list-bigger-text-indent: 24px !default;
$timepicker-popup-border-radius: 4px !default;
$timepicker-list-font-weight: regular !default;
$timepicker-popup-shadow: none !default;
$timepicker-list-default-font-color: normal-color(--black) !default;
$timepicker-list-default-border-style: none !default;
$timepicker-list-hover-border-color: none !default;
$timepicker-list-border-color: 1px solid set-opacity(--black, 0.15) !default;
$timepicker-list-bg-color: $content-popup-bg !default;
$timepicker-list-active-bg-color: normal-color(--primary) !default;
$timepicker-list-active-icon-color: normal-color(--white) !default;
$timepicker-icon-active-bg-color: active-color(--black-700) !default;
$timepicker-active-border-color: active-color(--black-700) !default;
$timepicker-list-active-font-color: normal-color(--black) !default;
$timepicker-list-hover-bg-color: hover-color(--white) !default;
$timepicker-list-hover-font-color: normal-color(--black) !default;
$timepicker-list-popup-icon-active-color: normal-color(--white) !default;
$timepicker-list-active-hover-bg-color: hover-color(--white) !default;
$timepicker-list-active-hover-font-color: normal-color(--black) !default;
$timepicker-list-normal-padding: 0 !default;
$timepicker-list-bigger-padding: 0 !default;
$timepicker-font-icon: "\e93e" !default;
$timepicker-icon-normal-font-size: 14px !default;
$timepicker-icon-bigger-font-size: 16px !default;
$timepicker-normal-input-min-height: 29px !default;
$timepicker-normal-input-min-width: 23px !default;
$timepicker-bigger-input-min-height: 36px !default;
$timepicker-bigger-input-min-width: 33px !default;
$timepicker-disable-text: normal-color(--black) !default;
$timepicker-disable-opacity: 1 !default;
$timepicker-default-overlay: set-opacity(--black, 0.5) !default;
// mouse small
$timepicker-list-small-font-size: 13px !default;
$timepicker-list-small-line-height: 22px !default;
$timepicker-list-small-text-indent: 12px !default;
$timepicker-list-small-font-color: normal-color(--black) !default;
// icon small
$timepicker-icon-small-font-size: 12px !default;
// Touch small
$timepicker-list-bigger-small-font-size: 14px !default;
$timepicker-list-bigger-small-line-height: 34px !default;
$timepicker-list-bigger-small-text-indent: 20px !default;
// Touch small icon
$timepicker-icon-bigger-small-font-size: 15px !default;
//enddefault
