@import "~bootstrap/scss/dropdown";

.dropdown-item {
  padding-bottom: 12px;
  padding-top: 12px;

  &.active,
  &:active {
    background-color: normal-color(--primary);
    color: normal-color(--white);
    text-decoration: none;
  }
}
