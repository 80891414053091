// sass-lint:disable-all
$ddt-skin-name: "bootstrap4" !default;
$ddt-box-shadow: 0 5px 5px -3px set-opacity(--black, 0.2), 0 8px 10px 0 set-opacity(--black, 0.14), 0 3px 14px 0 set-opacity(--black, 0.12) !default;
$ddt-label-top: 14px !default;
$ddt-close-icon-min-height: 29px !default;
$ddt-close-icon-bigger-min-height: 36px !default;
$ddt-close-icon-width: 24px !default;
$ddt-close-icon-bigger-width: 35px !default;
$ddt-close-icon-bottom: 0 !default;
$ddt-spin-icon-margin: inherit !default;
$ddt-dd-icon-bottom: 0 !default;
$ddt-dd-icon-bigger-bottom: 0 !default;
$ddt-dd-icon-width: 23px !default;
$ddt-dd-icon-bigger-width: 32px !default;
$ddt-rtl-close-left: -7px !default;
$ddt-list-filter-text-indent: 0 20px !default;
$ddt-filter-border: set-opacity(--black, 0.12) !default;
$ddt-filter-bg: set-opacity(--black, 0.03) !default;
$ddt-filter-top-border: 0 !default;
$ddt-filter-padding: 0 !default;
$ddt-filter-box-shadow: none !default;
$ddt-filter-background-color: normal-color(--white) !default;
$ddt-chip-margin: 2px 1px !default;
$ddt-chip-padding: 0 !default;
$ddt-chip-bg-color: transparent !default;
$ddt-chip-height: 25px !default;
$ddt-chip-bigger-height: 32px !default;
$ddt-chip-radius: 4px !default;
$ddt-chip-content-padding: 0 8px 0 8px !default;
$ddt-chip-close-font: 8px !default;
$ddt-chip-close-height: 24px !default;
$ddt-chip-close-width: 24px !default;
$ddt-chip-icon-line-height: 24px !default;
$ddt-last-chip-right-margin: 48px !default;
$ddt-last-chip-bigger-right-margin: 68px !default;
$ddt-select-all-height: 36px !default;
$ddt-select-all-checkbox-margin: 10px !default;
$ddt-select-all-text-indent: 38px !default;
$ddt-select-all-bigger-text-indent: 43px !default;
$ddt-select-all-text-font-size: 14px !default;
$ddt-popup-reorder-border: set-opacity(--black, 0.15) !default;
$ddt-treeview-padding: 0 8px !default;
$ddt-treeview-fullrow-height: 36px !default;
$ddt-treeview-fullrow-bigger-height: 44px !default;
$ddt-treeview-content-padding-top: 2px !default;
$ddt-treeview-content-padding-bottom: 2px !default;
$ddt-popup-background-color: $content-popup-bg !default;
$ddt-popup-border-color: set-opacity(--black, 0.15) !default;
$ddt-chip-close: normal-color(--primary) !default;
$ddt-chip-font-color: normal-color(--primary) !default;
$ddt-chip-hover-bg-color: transparent !default;
$ddt-chip-hover-font-color: normal-color(--primary) !default;
$ddt-readonly-input-bg-color: normal-color(--white) !default;
$ddt-nodata-font-color: normal-color(--black) !default;
$ddt-select-all-font-color: normal-color(--black) !default;
$ddt-icon-bg-color: $content-bg !default;
$ddt-chip-width: calc(100% - 2px) !default;
$ddt-chip-ddi-width: calc(100% - 24px) !default;
$ddt-chip-ci-width: calc(100% - 25px) !default;
$ddt-chip-ddi-ci-width: calc(100% - 48px) !default;
$ddt-big-chip-ddi-width: calc(100% - 34px) !default;
$ddt-big-chip-ci-width: calc(100% - 35px) !default;
$ddt-big-chip-ddi-ci-width: calc(100% - 68px) !default;
$ddt-icon-bg-color: $content-bg !default;
$ddt-remains-font-color: normal-color(--black-700) !default;
$ddt-remains-padding: 0 0 0 8px !default;
$ddt-rtl-remains-padding: 0 8px 0 0 !default;
$ddt-chip-font-size: 14px !default;
$ddt-overflow-count-padding-top: 4px !default;
$ddt-delim-padding: 4px 8px 0 8px !default;
$ddt-delim-bigger-padding: 6px 12px 0 12px !default;
$ddt-remains-count-padding-top: 4px !default;
