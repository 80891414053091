@include export-module("progress-button-theme") {
  /*! Progress Button Theme */
  .e-progress-btn {
    .e-spinner-pane .e-spinner-inner svg {
      @include spinner-color();
      @include spinner-style($progress-btn-color);
    }

    &.e-outline:not(:hover):not(:focus), &.e-flat {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color(normal-color(--secondary));
      }
    }

    &.e-outline.e-primary:not(:hover):not(:focus) {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color(normal-color(--primary));
      }
    }

    &.e-outline.e-info:not(:hover):not(:focus) {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color(normal-color(--link));
      }
    }

    &.e-outline.e-success:not(:hover):not(:focus) {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color(normal-color(--success));
      }
    }

    &.e-outline.e-warning:not(:hover):not(:focus) {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color(normal-color(--warning));
      }
    }

    &.e-outline.e-alert:not(:hover):not(:focus) {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color(normal-color(--alert));
      }
    }

    &.e-outline.e-danger:not(:hover):not(:focus) {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color(normal-color(--error));
      }
    }

    &.e-primary,
    &.e-primary.e-outline:hover,
    &.e-primary.e-outline:focus {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color($btn-primary-color);
        @include spinner-style($btn-primary-color);
      }

      &.e-flat {
        .e-spinner-pane .e-spinner-inner svg {
          @include spinner-color($btn-flat-primary-color);
          @include spinner-style($btn-flat-primary-color);
        }
      }
    }

    &.e-success,
    &.e-success.e-outline:hover,
    &.e-success.e-outline:focus {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color($btn-success-color);
        @include spinner-style($btn-success-color);
      }

      &.e-flat {
        .e-spinner-pane .e-spinner-inner svg {
          @include spinner-color($btn-flat-success-color);
          @include spinner-style($btn-flat-success-color);
        }
      }
    }

    &.e-info,
    &.e-info.e-outline:hover,
    &.e-info.e-outline:focus {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color($btn-info-color);
        @include spinner-style($btn-info-color);
      }

      &.e-flat {
        .e-spinner-pane .e-spinner-inner svg {
          @include spinner-color($btn-flat-info-color);
          @include spinner-style($btn-flat-info-color);
        }
      }
    }

    &.e-warning,
    &.e-warning.e-outline:hover,
    &.e-warning.e-outline:focus {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color($btn-warning-color);
        @include spinner-style($btn-warning-color);
      }

      &.e-flat {
        .e-spinner-pane .e-spinner-inner svg {
          @include spinner-color($btn-flat-warning-color);
          @include spinner-style($btn-flat-warning-color);
        }
      }
    }

    &.e-alert,
    &.e-alert.e-outline:hover,
    &.e-alert.e-outline:focus {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color($btn-alert-color);
        @include spinner-style($btn-alert-color);
      }

      &.e-flat {
        .e-spinner-pane .e-spinner-inner svg {
          @include spinner-color($btn-flat-alert-color);
          @include spinner-style($btn-flat-alert-color);
        }
      }
    }

    &.e-danger,
    &.e-danger.e-outline:hover,
    &.e-danger.e-outline:focus {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color($btn-danger-color);
        @include spinner-style($btn-danger-color);
      }

      &.e-flat {
        .e-spinner-pane .e-spinner-inner svg {
          @include spinner-color($btn-flat-danger-color);
          @include spinner-style($btn-flat-danger-color);
        }
      }
    }

    &.e-outline {
      &.e-primary {
        .e-spinner-pane .e-spinner-inner svg {
          @include spinner-color($btn-primary-bgcolor);
          @include spinner-style($btn-primary-bgcolor);
        }
      }

      &.e-success {
        .e-spinner-pane .e-spinner-inner svg {
          @include spinner-color($btn-success-bgcolor);
          @include spinner-style($btn-success-bgcolor);
        }
      }

      &.e-info {
        .e-spinner-pane .e-spinner-inner svg {
          @include spinner-color($btn-info-bgcolor);
          @include spinner-style($btn-info-bgcolor);
        }
      }

      &.e-warning {
        .e-spinner-pane .e-spinner-inner svg {
          @include spinner-color($btn-warning-bgcolor);
          @include spinner-style($btn-warning-bgcolor);
        }
      }

      &.e-danger {
        .e-spinner-pane .e-spinner-inner svg {
          @include spinner-color($btn-danger-bgcolor);
          @include spinner-style($btn-danger-bgcolor);
        }
      }
    }

    &.e-link {
      .e-spinner-pane .e-spinner-inner svg {
        @include spinner-color($btn-link-color);
        @include spinner-style($btn-link-color);
      }
    }
  }

  * {
    @include progress-bar-color(--secondary);

    &.e-primary {
      @include progress-bar-color(--primary);
    }

    &.e-success {
      @include progress-bar-color(--success);
    }

    &.e-danger {
      @include progress-bar-color(--error);
    }

    &.e-alert {
      @include progress-bar-color(--alert);
    }

    &.e-info {
      @include progress-bar-color(--link);
    }

    &.e-warning {
      @include progress-bar-color(--warning, $progress-btn-warning-progress-color);
    }

    &.e-flat {
      &.e-primary {
        .e-progress {
          opacity: $progress-bar-opacity;
          stroke: $progress-btn-flat-primary-progress-color;
          transition: visibility 0s 2s, opacity 2s linear;
        }
      }

      &.e-success {
        .e-progress {
          opacity: $progress-bar-opacity;
          stroke: $progress-btn-flat-success-progress-color;
          transition: visibility 0s 2s, opacity 2s linear;
        }
      }

      &.e-info {
        .e-progress {
          opacity: $progress-bar-opacity;
          stroke: $progress-btn-flat-info-progress-color;
          transition: visibility 0s 2s, opacity 2s linear;
        }
      }

      &.e-warning {
        .e-progress {
          opacity: $progress-bar-opacity;
          stroke: $progress-btn-flat-warning-progress-color;
          transition: visibility 0s 2s, opacity 2s linear;
        }
      }

      &.e-danger {
        .e-progress {
          .e-progress {
            opacity: $progress-bar-opacity;
            stroke: $progress-btn-flat-danger-progress-color;
            transition: visibility 0s 2s, opacity 2s linear;
          }
        }
      }

      &.e-outline:not(:focus) {
        &.e-primary {
          .e-progress {
            opacity: $progress-bar-opacity;
            stroke: $btn-primary-bgcolor;
            transition: visibility 0s 2s, opacity 2s linear;
          }
        }

        &.e-success {
          .e-progress {
            opacity: $progress-bar-opacity;
            stroke: $btn-success-bgcolor;
            transition: visibility 0s 2s, opacity 2s linear;
          }
        }

        &.e-info {
          .e-progress {
            opacity: $progress-bar-opacity;
            stroke: $btn-info-bgcolor;
            transition: visibility 0s 2s, opacity 2s linear;
          }
        }

        &.e-warning {
          .e-progress {
            opacity: $progress-bar-opacity;
            stroke: $btn-warning-bgcolor;
            transition: visibility 0s 2s, opacity 2s linear;
          }
        }

        &.e-danger {
          .e-progress {
            opacity: $progress-bar-opacity;
            stroke: $btn-danger-bgcolor;
            transition: visibility 0s 2s, opacity 2s linear;
          }
        }
      }
    }
  }
}
