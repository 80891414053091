// stylelint-disable declaration-no-important

.shadow-sm {
  box-shadow: $box-shadow-sm !important;
}

.shadow-sm-inset {
  box-shadow: $box-shadow-sm-inset !important;
}

.shadow {
  box-shadow: $box-shadow !important;
}

.shadow-inset {
  box-shadow: $box-shadow-inset !important;
}

.shadow-lg {
  box-shadow: $box-shadow-lg !important;
}

.shadow-lg-inset {
  box-shadow: $box-shadow-lg-inset !important;
}

.shadow-none {
  box-shadow: none !important;
}
