// Light theme for sidenav
// Append .zdb-sidenav-light to .zdb-sidenav to use

.zdb-sidenav-light {
  color: $sidenav-light-color;
  background-color: rgba(255,255,255,0.92);

  padding-top: 15px;
  .zdb-sidenav-menu {
    padding: 0;
    .zdb-sidenav-menu-heading {
      color: $sidenav-light-heading-color;
    }
    .nav-item {
      padding: 0 1rem;
      color: $sidenav-light-link-color;
      .zdb-nav-item-icon {
        display: inline;
        padding-right: 1rem;
        color: $sidenav-light-icon-color;
      }
      .zdb-sidenav-collapse-arrow {
        color: $sidenav-light-icon-color;
      }
      &:hover {
        color: $sidenav-light-link-active-color;
      }
      &.active {
        color: $sidenav-light-link-active-color;
        .zdb-nav-link-icon {
          color: $sidenav-light-link-active-color;
        }
      }
    }
  }
  .zdb-sidenav-footer {
    background-color: inherit;
  }
}
