@include export-module("datetimepicker-layout") {
  // datetimepicker layout
  .e-input-group.e-control-wrapper.e-datetime-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
  .e-float-input.e-control-wrapper.e-input-group.e-datetime-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
    display: flex;
  }

  .e-datetime-wrapper {
    // sass-lint:disable no-vendor-prefixes
    -webkit-tap-highlight-color: transparent;

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      font-size: $datetime-icon-normal-font-size;
    }

    #{if(&, '&', '*')}.e-control-wrapper {
      box-sizing: border-box;
    }

    #{if(&, '&', '*')} .e-time-icon.e-icons.e-disabled,
    #{if(&, '&', '*')} .e-date-icon.e-icons.e-disabled {
      pointer-events: none;
    }

    #{if(&, '&', '*')} .e-clear-icon {
      box-sizing: content-box;
    }

    #{if(&, '&', '*')} span {
      cursor: pointer;
    }

    #{if(&, '&', '*')} .e-input-group-icon.e-date-icon,
    #{if(&, '&', '*')} .e-input-group-icon.e-time-icon {
      font-size: 16px;
      margin: $datetime-date-icon-margin;

      outline: none;
      @if $skin-name == "material" or $skin-name == "material-dark" {
        min-height: $datetime-normal-min-height;
        min-width: $datetime-normal-min-width;
      }
    }

    #{if(&, '&', '*')} .e-input-group-icon.e-time-icon {
      border: $datetime-time-icon-border;
      border-style: $datetime-time-icon-border-style;
      margin: $datetime-time-icon-margin;
    }
  }

  .e-datetime-wrapper:not(.e-outline) {
    #{if(&, '&', '*')}.e-rtl .e-input-group-icon.e-time-icon {
      margin: $datetime-time-rtl-icon-margin;
    }
  }

  .e-datetimepicker.e-time-modal {
    background-color: $datetime-default-overlay;
    height: 100%;
    left: 0;
    opacity: 0.5;
    pointer-events: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .e-datetimepicker.e-popup {
    border-style: solid;
    border-width: 1px;
    overflow: auto;

    #{if(&, '&', '*')} .e-content {
      position: relative;
    }

    #{if(&, '&', '*')} .e-list-parent.e-ul {
      margin: 0;
      padding: $datetime-list-normal-padding 0;

      #{if(&, '&', '*')} .e-list-item {
        color: $datetime-list-default-font-color;
        cursor: default;
        font-size: $datetime-list-normal-font-size;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: nowrap;
        width: 100%;
      }

      #{if(&, '&', '*')} .e-list-item.e-hover {
        cursor: pointer;
      }
    }
  }

  //normal styles

  .e-datetimepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
      line-height: $datetime-list-normal-line-height;
      text-indent: $datetime-list-normal-text-indent;
    }
  }

  //bigger styles
  .e-bigger.e-datetime-wrapper,
  #{if(&, '&', '*')}.e-bigger .e-datetime-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      font-size: $datetime-icon-bigger-font-size;
    }

    #{if(&, '&', '*')} .e-input-group-icon.e-time-icon {
      margin: $datetime-time-bigger-icon-margin;
      @if $skin-name == "material" or $skin-name == "material-dark" {
        min-height: $datetime-bigger-min-height;
        min-width: $datetime-bigger-min-width;
      }
    }
  }

  .e-bigger.e-datetime-wrapper:not(.e-outline),
  #{if(&, '&', '*')}.e-bigger .e-datetime-wrapper:not(.e-outline) {
    #{if(&, '&', '*')}.e-rtl .e-input-group-icon.e-time-icon {
      margin: $datetime-time-rtl-bigger-icon-margin;
    }
  }

  .e-bigger .e-datetimepicker.e-popup,
  #{if(&, '&', '*')}.e-bigger.e-datetimepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul {
      padding: $datetime-list-bigger-padding 0;

      #{if(&, '&', '*')} .e-list-item {
        font-size: $datetime-list-bigger-font-size;
        line-height: $datetime-list-bigger-line-height;
        text-indent: $datetime-list-bigger-text-indent;
      }
    }
  }

  .e-small .e-datetimepicker.e-popup,
  #{if(&, '&', '*')}.e-small.e-datetimepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
      font-size: $datetime-list-small-font-size;
      line-height: $datetime-list-small-line-height;
      text-indent: $datetime-list-small-text-indent;
    }
  }

  .e-small.e-bigger .e-datetimepicker.e-popup,
  #{if(&, '&', '*')}.e-small.e-bigger.e-datetimepicker.e-popup {
    #{if(&, '&', '*')} .e-list-parent.e-ul .e-list-item {
      font-size: $datetime-list-bigger-small-font-size;
      line-height: $datetime-list-bigger-small-line-height;
      text-indent: $datetime-list-bigger-small-text-indent;
    }
  }

  .e-small.e-datetime-wrapper,
  #{if(&, '&', '*')}.e-small .e-datetime-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      font-size: $datetime-icon-small-font-size;
    }
  }

  .e-small.e-bigger.e-datetime-wrapper,
  #{if(&, '&', '*')}.e-small.e-bigger .e-datetime-wrapper {

    #{if(&, '&', '*')} .e-time-icon.e-icons::before {
      font-size: $datetime-icon-bigger-small-font-size;
    }
  }

  .e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker {
    background-size: 250px 33px;
    min-height: 33px;
  }

  .e-bigger .e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker,
  .e-bigger.e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker {
    background-size: 250px 3.1rem;
    min-height: 3.1rem;
  }
}
