.zdb-gradient {
  background: rgb(1, 172, 201);
  background: -moz-linear-gradient(
    90deg,
    rgba(1, 172, 201, 1) 0%,
    rgba(78, 185, 153, 1) 25%,
    rgba(178, 202, 91, 1) 75%,
    rgba(251, 215, 45, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(1, 172, 201, 1) 0%,
    rgba(78, 185, 153, 1) 25%,
    rgba(178, 202, 91, 1) 75%,
    rgba(251, 215, 45, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(1, 172, 201, 1) 0%,
    rgba(78, 185, 153, 1) 25%,
    rgba(178, 202, 91, 1) 75%,
    rgba(251, 215, 45, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01acc9",endColorstr="#fbd72d",GradientType=1);
}

.zdb-gradient-muted {
  background: rgb(1, 172, 201);
  background: -moz-linear-gradient(
    90deg,
    rgba(1, 172, 201, 0.5) 0%,
    rgba(78, 185, 153, 0.5) 25%,
    rgba(178, 202, 91, 0.5) 75%,
    rgba(251, 215, 45, 0.5) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(1, 172, 201, 0.5) 0%,
    rgba(78, 185, 153, 0.5) 25%,
    rgba(178, 202, 91, 0.5) 75%,
    rgba(251, 215, 45, 0.5) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(1, 172, 201, 0.5) 0%,
    rgba(78, 185, 153, 0.5) 25%,
    rgba(178, 202, 91, 0.5) 75%,
    rgba(251, 215, 45, 0.5) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01acc9",endColorstr="#fbd72d",GradientType=1);
}

.zdb-bg, .zdb-bg-muted {
  position: fixed;
  width: 100%;
}

.zdb-bg:before {
  content: "";
  width: 2500px;
  height: 700px;
  overflow: hidden;
  -webkit-border-radius: 0 0 0px 67px;
  -moz-border-radius: 0 0 0px 67px;
  border-radius: 0 0 0px 67px;
  -ms-transform: rotate(-15deg); /* IE 9 */
  -webkit-transform: rotate(-15deg); /* Safari */
  transform: rotate(-15deg);
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  top: -325px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#edcc47+0,78b49d+17,43a9c5+26,43a9c5+100 */
  background: #edcc47; /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    #edcc47 0%,
    #78b49d 17%,
    #43a9c5 26%,
    #43a9c5 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    #edcc47 0%,
    #78b49d 17%,
    #43a9c5 26%,
    #43a9c5 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    #edcc47 0%,
    #78b49d 17%,
    #43a9c5 26%,
    #43a9c5 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.zdb-bg-muted:before {
  content: "";
  width: 2500px;
  height: 700px;
  overflow: hidden;
  -webkit-border-radius: 0 0 0px 67px;
  -moz-border-radius: 0 0 0px 67px;
  border-radius: 0 0 0px 67px;
  -ms-transform: rotate(-15deg); /* IE 9 */
  -webkit-transform: rotate(-15deg); /* Safari */
  transform: rotate(-15deg);
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  top: -325px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#edcc47+0,78b49d+17,43a9c5+26,43a9c5+100 */
  background: rgba(1, 172, 201, 0.8);
  background: -moz-linear-gradient(
    -45deg,
    rgba(251, 215, 45, 1) 0%,
    rgba(78, 185, 153, 1) 17%,
    rgba(67, 169, 197, 0.8) 26%,
    rgba(1, 172, 201, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(251, 215, 45, 1) 0%,
    rgba(78, 185, 153, 1) 17%,
    rgba(67, 169, 197, 0.8) 26%,
    rgba(1, 172, 201, 0.8) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(251, 215, 45, 1) 0%,
    rgba(78, 185, 153, 1) 17%,
    rgba(67, 169, 197, 0.8) 26%,
    rgba(1, 172, 201, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01acc9",endColorstr="#fbd72d",GradientType=1);
}
