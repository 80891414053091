// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
  $background,
  $border,
  $active-background: darken-color($background, 10%),
  $active-border: darken-color($border, 12.5%)
) {
  @include gradient-bg(normal-color($background));
  @include box-shadow($btn-box-shadow);
  border-color: normal-color($border);
  color: color-yiq($background);

  @include hover() {
    @include gradient-bg(hover-color($background));
    border-color: hover-color($border);
    color: color-yiq($background);
  }

  &:focus,
  &.focus {
    @include gradient-bg(hover-color($background));
    border-color: hover-color($border);
    color: color-yiq($background);
    @if $enable-shadows {
      @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width set-opacity($border, 0.5));
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: 0 0 0 $btn-focus-width set-opacity($border, 0.5);
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background-color: disabled-color($background);
    border-color: disabled-color($border);
    color: color-yiq($background);
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-color: active-color($background);
    border-color: active-color($border);
    color: color-yiq($background);

    &:focus {
      @if $enable-shadows and $btn-active-box-shadow != none {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width set-opacity($border, 0.5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width set-opacity($border, 0.5);
      }
    }

    @if $enable-gradients {
      background-image: none; // Remove the gradient for the pressed/active state
    }
  }
}

@mixin button-outline-variant(
  $color,
  $color-hover: hover-color($color),
  $active-background: normal-color(--white),
  $active-border: set-opacity($color, 0.5)
) {
  border-color: set-opacity($color, 0.75);
  border-width: 2px;
  color: normal-color($color);
  font-weight: bold;

  @include hover() {
    background-color: $active-background;
    border-color: set-opacity($color, 0.15);
    color: hover-color($color);
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width set-opacity($color, 0.5);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: normal-color($color);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-color: $active-background;
    border-color: $active-border;
    color: active-color($color);

    &:focus {
      @if $enable-shadows and $btn-active-box-shadow != none {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width set-opacity($color, 0.5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width set-opacity($color, 0.5);
      }
    }
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  @include font-size($font-size);
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);
  line-height: $line-height;
  padding: $padding-y $padding-x;
}
