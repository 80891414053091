$pager-numericitem-border-radius: 0 !default;
$pager-border-type: solid !default;
$pager-mobile-icon-size: 11px !default;
$pager-icon-width: "auto" !default;
$pager-icon-height: "auto" !default;
$pager-navigationicon-padding: 11px 9px 8px !default;
$pager-navigationicon-right: 0 !default;
$pager-navigationicon-top: 0 !default;
$pager-numericitem-margin-right: 0 !default;
$pager-messagebar-padding-bottom: 9px !default;
$pager-bigger-messagebar-padding-bottom: 8px !default;
$pager-content-opacity: 1 !default;
$pager-bigger-new-container-rtl-margin: 5px 0 -4px 0 !default;
$pager-next-icon-margin-left: 0 !default;
$pager-container-rtl-margin: 0 6px -4px 0 !default;
$pager-rtl-navigation-icon-padding: 13px 9px 12px 2px !default;
$pager-rtl-numeric-margin: 0 !default;
$pager-item-hover-borders-width: 0 !default;
$pager-tripledot-font-size: 16px !default;
$pager-last-page-hover-border-radius:  0 4px 4px 0 !default;
$pager-first-page-hover-border-radius: 4px 0 0 4px !default;
$pager-rtl-parentmsgbar-margin-left: 6px !default;
$pager-bigger-nexticon-marign-left: $pager-next-icon-margin-left / 0.75 !default;
$pager-bigger-current-item-border-radius: $pager-numericitem-border-radius / 0.75 !default;
$pager-bigger-rtl-nexticon-marign-left: 0 !default;
$pager-bigger-rtl-nexticon-marign-right: $pager-next-icon-margin-left / 0.75 !default;
$pager-device-padding: 19px 0 !default;
$pager-device-messabar-padding: 0 !default;
$pager-bigger-rtl-parentmsgbar-margin-left: 6px !default;
$pager-bigger-rtl-activeitem-padding: 12px 14px 13px 14px !default;
$pager-numericitem-min-width: 26px !default;
$pager-font-size: 14px !default;
$pager-bg-color: normal-color(--white-300) !default;
$pager-border-color: normal-color(--white-300) !default;
$pager-color: normal-color(--primary) !default;
$pager-icon-size: 10px !default;
$pager-border-size: 1px !default;
$pager-numericitem-padding: 13px 12px 10px 12px !default;
$pager-numeric-hover-bg-color: hover-color(--white-300) !default;
$pager-numeric-txt-bg-color: hover-color(--black-300) !default;
$pager-padding: 8px !default;
$pager-numeric-font-weight: bold !default;
$pager-messagebar-padding-top: 8px !default;
$pager-number-hover-bg-color: darken-color(--white-200, 2%) !default;
$pager-font-family: Helvertica !default;
$pager-font-weight: bold !default;
$pager-messagebar-padding-right: 0 !default;
$pager-tripledot-padding: 10px 11px 10px !default;
$pager-numeric-icon-padding: 11px 11px 11px 11px !default;
$pager-pagecontainer-icon-padding: 13px 10px 12px 8px !default;
$pager-hover-color: normal-color(--primary) !default;
$pager-mhover-color: hover-color(--primary) !default;
$pager-pagermessage-weight: 500 !default;
$pager-active-color: normal-color(--white-300) !default;
$pager-active-bg-color: $primary !default;
$pager-last-border-size: 1px !default;
$page-content-color: $primary !default;
$pager-container-margin: 0 0 -4px !default;
$pager-bigger-numeric-item-margin: 0 !default;
$pager-bigger-icon-font-size: 12px !default;
$pager-bigger-font-size: 16px !default;
$pager-bigger-padding: 10px 10px 12px 10px !default;
$pager-bigger-pagercontainer-margin: 0 !default;
$pager-bigger-pagercontainer-icons-padding: 13px 10px 16px !default;
$pager-bigger-pagercontainer-icons-marign-right: 0 !default;
$pager-bigger-current-item-padding: 12px 14px 13px !default;
$pager-letter-spacing: 0 !default;
$pager-bigger-icons-align: middle !default;
$pager-bigger-messagebar-padding-right: 0 !default;
$pager-bigger-messagebar-padding-top: 11px !default;
$pager-last-content-icon: "e235" !default;
$pager-next-content-icon: "e236" !default;
$pager-prev-content-icon: "e237" !default;
$pager-first-content-icon: "e238" !default;
$pager-icon-color: $primary !default;
$pager-hover-bg-color: normal-color(--white-300) !default;
$pager-dropdown-margin-top: -20px !default;
$pager-dropdown-margin-left: 10px !default;
$pager-dropdown-margin-right: 8px !default;
$pager-dropdown-overflow: unset !default;
$pager-bigger-dropdown-constant-margin: 12px !default;
$pager-bigger-dropdown-margin: -35px  10px 0 18px !default;
$pager-dropdown-height: 34px !default;
$pager-constant-margin: 0 0 6px 8px !default;
$pager-bigger-letter-spacing: 9px 10px 12px !default;
$pager-tripledot-rtl-margin-top: 2px !default;
$pager-tripledot-rtl-padding: 8px 11px 9px 11px !default;
$pager-rtl-pagerconstant-margin-top: 8px !default;
$pager-rtl-pagerdropdown-margin: -20px 16px 0 0 !default;
$pager-tripledot-rtl-bigger-margin-top: -5px !default;
$pager-bigger-rtl-numeric-margin: 0 !default;
$pager-bigger-container-rtl-margin: 0 0 -4px 0 !default;
$pager-activeitem-padding: 11px 12px 10px 11px !default;
$pager-rtl-pagerdropdown-bigger-margin: -25px 16px 0 0 !default;
$pager-rtl-pagerconstant-bigger-margin-top: 13px 16px 13px 0 !default;
$pager-bigger-rtl-parentmsgbar-margin-top: 2px !default;
$pager-bigger-rtl-pagercontainer-margin-top: 5px !default;
$pager-bigger-device-rtl-parentmsgbar-margin-top: 5px !default;
$pager-rtl-pagermsgbar-device-margin-top: 10px !default;
$pager-bigger-constant-margin-left: 16px !default;
$pager-rtl-last-content-icon: "e238" !default;
$pager-rtl-first-content-icon: "e235" !default;
$pager-rtl-prev-content-icon: "e236" !default;
$pager-rtl-next-content-icon: "e237" !default;
$pager-item-hover-border-width: 0 1px 0 0 !default;
$pager-item-hover-border-color: $pager-border-color !default;
$pager-bigger-current-item-hover-padding: $pager-bigger-current-item-padding !default;
$pager-numeric-icon-hover-padding: $pager-numeric-icon-padding !default;
$pager-bigger-rtl-activeitem-hover-padding:  $pager-bigger-rtl-activeitem-padding !default;
$pager-activeitem-hover-padding: $pager-activeitem-padding !default;
$pager-bigger-dropdown-margin-top: -35px !default;
