/*! calendar bootstrap theme variables */


//month view
$calendar-normal-month-view-height: 28px !default;
$calendar-normal-month-view-width: 28px !default;
$calendar-normal-month-cell-padding: 0 !default;
$calendar-bigger-table-month-padding: 0 16px 16px 16px !default;
$calendar-bigger-month-font-size: 16px !default;
$calendar-bigger-month-view-padding: 0 !default;
$calendar-bigger-month-view-height: 32px !default;
$calendar-bigger-month-view-width: 38px !default;

//header dimension
$calendar-header-font-size: 14px !default;
$calendar-bigger-header-padding: 16px 16px 0 16px !default;
$calendar-bigger-day-header-height: 40px !default;
$calendar-bigger-header-height: 40px !default;
$calendar-normal-header-padding: 12px 12px 0 12px !default;
$calendar-normal-day-header-height: 36px !default;
$calendar-normal-header-height: 36px !default;
$calendar-bigger-header-font-size: 18px !default;
$calendar-normal-day-header-font-weight: 500 !default;
$calendar-title-font-size: 16px !default;
$calendar-title-margin-left-style: 8px !default;
$calendar-title-font-weight-style: 600 !default;
$calendar-title-decoration-style: none !default;

//header color
$calendar-header-font-color: normal-color(--black) !default;
$calendar-header-icon-color: normal-color(--black) !default;
$calendar-title-font-color: $calendar-header-icon-color !default;
$calendar-icon-font-color: normal-color(--black) !default;
$calendar-active-icon-color: normal-color(--white) !default;
$calendar-title-hover-color: normal-color(--black) !default;
$calendar-icon-hover-color: normal-color(--black) !default;
$calendar-icon-hover-bg-color: normal-color(--white) !default;
$calendar-default-border-color: none !default;
$calendar-icon-hover-border-color: $calendar-default-border-color !default;
$calendar-active-state-icon-bg-color: active-color(--black-700) !default;

// common styles

$calendar-none-style: none !default;
$calendar-cursor-default-style: default !default;
$calendar-cursor-pointer-style: pointer !default;
$calendar-block-style: block !default;
$calendar-inline-block-style: inline-block !default;
$calendar-display-style: inline-block !default;
$calendar-wrapper-border-radius: 4px !default;
$calendar-full-width: 100% !default;
$calendar-lg-day-header-format-max-width: 100% !default;
$calendar-lg-day-header-format-min-width: 540px !default;
$calendar-pointer-events: initial !default;
$calendar-float-right-style: right !default;
$calendar-float-left-style: left !default;
$calendar-zero-value: 0 !default;
$calendar-disable-opacity: 1 !default;

//popup styles
$calendar-normal-max-width: 262px !default;
$calendar-normal-min-width: 256px !default;
$calendar-bigger-max-width: 310px !default;
$calendar-bigger-min-width: 306px !default;
$calendar-weeknumber-min-width: 300px !default;
$calendar-weeknumber-bigger-width: 325px !default;
$calendar-border-style: 1px solid set-opacity(--black, 0.15) !default;
$calendar-bg-color: $content-bg !default;
$calendar-popup-padding: 0 !default;
$calendar-popup-bigger-padding: 0 !default;
$calendar-box-shadow: none !default;

// today button
$calendar-bigger-today-button-height: 48px !default;
$calendar-normal-today-button-height: 36px !default;


//  year decade view

//color
$calendar-yeardecade-bg-color: none !default;
$calendar-yeardecade-hover-bg: normal-color(--white) !default;
$calendar-yeardecade-selected-hover-bg: hover-color(--primary) !default;

//dimension
$calendar-normal-year-decade-height: 53px !default;
$calendar-normal-year-decade-width: 53px !default;
$calendar-normal-year-decade-padding: 2px !default;
$calendar-yeardeacde-span-padding: 0 !default;
$calendar-yeardecade-padding: 0 12px 12px 12px !default;
$calendar-bigger-year-decade-padding: 1px !default;
$calendar-bigger-yeardecade-font-size: $calendar-bigger-month-font-size !default;
$calendar-bigger-table-yeardecade-padding: 0 18px 16px 18px !default;
$calendar-bigger-yeardecade-font-weight: normal !default;
$calendar-yeardecade-font-weight: normal !default;
$calendar-bigger-year-decade-height: 64px !default;
$calendar-bigger-year-decade-width: 64px !default;
$calendar-yeardecade-header-padding: 12px 12px 8px 12px !default;
$calendar-bigger-yeardecade-header-padding: 16px 18px 10px 18px !default;
$calendar-other-decade-cell-color: disabled-color(--black-300) !default;
$calendar-decade-title-left-margin-style: 15px !default;

// end year decade

//other months
$calendar-other-month-display-style: inline-block !default;
$calendar-other-month-row-display-style: none !default;
$calendar-other-month-date: normal-color(--black-700) !default;
$calendar-other-month-date-hover-bg: $calendar-other-month-date !default;

// table related styles
$calendar-table-padding: 0 12px 12px 12px !default;

//font icons
$calendar-prev-icon: "\e910" !default;
$calendar-next-icon: "\e916" !default;
$calendar-next-prev-icon-size: 14px !default;
$calendar-icon-line-height: 16px !default;
$calendar-bigger-icon-size: 40px !default;
$calendar-normal-icon-size: 36px !default;
$calendar-spanicon-font-weight-style: normal !default;
//pending
$calendar-icon-font-size-style: 14px !default;
$calendar-bigger-icon-font-size: 16px !default;


// td styles

//color
$calendar-light-font: normal-color(--black) !default;
$calendar-active-font-color: normal-color(--white) !default;
$calendar-active-hover-font-color: normal-color(--white) !default;
$calendar-active-today-font-color: normal-color(--white) !default;
$calendar-active-today-hover-font-color: normal-color(--white) !default;
$calendar-active-bg-color: active-color(--primary) !default;
$calendar-active-bg-border-color: 1px solid active-color(--primary) !default;
$calendar-active-hover-bg-color: hover-color(--primary) !default;
$calendar-hover-color: normal-color(--white) !default;
$calendar-text-color: normal-color(--black) !default;
$calendar-hover-text: $calendar-text-color !default;
$calendar-focus-bg-color: normal-color(--white) !default;
$calendar-focused-today-bg-style: normal-color(--white) !default;
$calendar-focused-today-border-style:  1px solid focused-color(--black) !default;
$calendar-focus-border-color: none !default;
$calendar-hover-border-color: none !default;
$calendar-disable-font-color: disabled-color(--black-300) !default;

//thead style
$calendar-thead-padding: 0 0 6px !default;

//dimension
$calendar-date-font-size: 14px !default;
$calendar-bigger-day-font-size: 15px !default;
$calendar-link-font-weight-style: normal !default;
$calendar-focused-date-bg-style: normal-color(--white) !default;
$calendar-disable-font-weight-style: normal !default;
$calendar-border-radius: 100% !default;

// end td styles

//today date
$calendar-today-bg-style: normal-color(--white) !default;
$calendar-today-bg-hover-color: normal-color(--white) !default;
$calendar-today-border-color: 1px solid normal-color(--black) !default;
$calendar-selected-box-shadow: none !default;
$calendar-selected-border-color: 1px solid transparent !default;
//pending
$calendar-today-color: normal-color(--black) !default;
$calendar-today-focused-font-color: normal-color(--black) !default;
$calendar-today-focus-color: normal-color(--black) !default;

//week header
$calendar-week-header-font-color: normal-color(--black) !default;
$calendar-week-header-bg-style: none !default;
$calendar-bigger-week-header-font-size: 16px !default;

//week number
$calendar-week-number-font-style: italic !default;
$calendar-week-number-color-style: normal-color(--black-700) !default;
$calendar-week-number-font-size-style: 14px !default;
$calendar-week-number-font-color: $calendar-text-color !default;
$calendar-week-normal-max-width: 294px !default;
$calendar-week-normal-min-width: 288px !default;
$calendar-week-bigger-max-width: 320px !default;
$calendar-week-bigger-min-width: 314px !default;
$calendar-icon-padding: 10px !default;
$calendar-bigger-icon-padding: 11px !default;

//today button
$calendar-footer-container-padding: 12px !default;
$calendar-footer-border: 1px solid  normal-color(--white-100) !default;
$calendar-bigger-footer-container-padding: 16px !default;
$calendar-bigger-footer-border: 1px solid  normal-color(--white-100) !default;
$calendar-today-disabled-background-style: normal-color(--primary) !default;
$calendar-today-disabled-border-style: disabled-color(--black-300) !default;
$calendar-today-disabled-box-shadow: none !default;
$calendar-today-disabled-color: disabled-color(--black-300) !default;

// small size
$calendar-small-icon-size: 32px !default;
$calendar-small-icon-font-size-style: 12px !default;
$calendar-small-title-font-size: 13px !default;
$calendar-small-icon-padding: 6px !default;
$calendar-small-header-height: 32px !default;
$calendar-small-day-header-height: 24px !default;
$calendar-small-month-view-height: 24px !default;
$calendar-small-date-font-size: 12px !default;
$calendar-small-month-view-width: 34px !default;
$calendar-small-popup-padding: 0 !default;
$calendar-small-title-font-color: normal-color(--black) !default;
$calendar-bigger-small-table-padding: 0 12px 12px 12px !default;
$calendar-bigger-small-yeardecade-font-size: 14px !default;
$calendar-small-font-size: 12px !default;
