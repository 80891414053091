//default
$cbox-bgcolor: normal-color(--white) !default;
$cbox-bigger-check-fontsize: 8px !default;
$cbox-bigger-font-size: 16px !default;
$cbox-bigger-height: 16px !default;
$cbox-bigger-indeterminate-fontsize: 8px !default;
$cbox-bigger-indeterminate-lineheight: 15px !default;
$cbox-bigger-lineheight: 15px !default;
$cbox-bigger-margin: 10px !default;
$cbox-bigger-small-check-fontsize: 8px !default;
$cbox-bigger-small-font-size: 15px !default;
$cbox-bigger-small-height: 15px !default;
$cbox-bigger-small-indeterminate-fontsize: 8px !default;
$cbox-bigger-small-indeterminate-lineheight: 14px !default;
$cbox-bigger-small-lineheight: 14px !default;
$cbox-bigger-small-width: 15px !default;
$cbox-bigger-width: 16px !default;
$cbox-border: 1px solid !default;
$cbox-border-color: normal-color(--hit-grey) !default;
$cbox-border-radius: 4px !default;
$cbox-check-fontsize: 10px !default;
$cbox-checkmark-bgcolor: normal-color(--success) !default;
$cbox-checkmark-border-color: normal-color(--success) !default;
$cbox-checkmark-color: normal-color(--white) !default;
$cbox-checked-ripple-bgcolo: transparent !default;
$cbox-checkmark-disabled-bgcolor: disabled-color(--success) !default;
$cbox-checkmark-disabled-border-color: disabled-color(--success) !default;
$cbox-checkmark-disabled-color: disabled-color(--success) !default;
$cbox-checkmark-hover-bgcolor: hover-color(--success) !default;
$cbox-checkmark-hover-border-color: hover-color(--success) !default;
$cbox-checkmark-hover-color: hover-color(--white) !default;
$cbox-color: normal-color(--black-700) !default;
$cbox-disabled-bgcolor: disabled-color(--white) !default;
$cbox-disabled-border-color: disabled-color(--white) !default;
$cbox-disabled-color: disabled-color(--black-700) !default;
$cbox-focus-outline:  normal-color(--success) !default;
$cbox-focus-outline-offset: 0 !default;
$cbox-focussed-box-shadow: 0 0 0 1px set-opacity(--primary, 0.25) !default;
$cbox-font-size: 14px !default;
$cbox-height: 18px !default;
$cbox-hover-bgcolor: normal-color(--white) !default;
$cbox-hover-border-color: hover-color(--black) !default;
$cbox-hover-color: hover-color(--black-700) !default;
$cbox-indeterminate-bgcolor: normal-color(--black) !default;
$cbox-indeterminate-border-color: normal-color(--black) !default;
$cbox-indeterminate-color: normal-color(--white) !default;
$cbox-indeterminate-content: "\e99f" !default;
$cbox-indeterminate-disabled-bgcolor: set-opacity(--black, 0.55) !default;
$cbox-indeterminate-disabled-border-color: disabled-color(--black-300) !default;
$cbox-indeterminate-disabled-color: disabled-color(--black-300) !default;
$cbox-indeterminate-fontsize: 8px !default;
$cbox-indeterminate-hover-color: normal-color(--white) !default;
$cbox-indeterminate-lineheight: 17px !default;
$cbox-key-focussed-bgcolor: transparent !default;
$cbox-lineheight: 17px !default;
$cbox-margin: 1rem !default;
$cbox-padding: 0 !default;
$cbox-ripple-bgcolor: transparent !default;
$cbox-ripple-size: -9px !default;
$cbox-ripple-height: 36px !default;
$cbox-ripple-width: 36px !default;
$cbox-ripple-small-size: -13px !default;
$cbox-ripple-small-height: 30px !default;
$cbox-ripple-small-width: 30px !default;
$cbox-ripple-bigger-size: -15px !default;
$cbox-ripple-bigger-height: 38px !default;
$cbox-ripple-bigger-width: 38px !default;
$cbox-ripple-bigger-small-size: -16px !default;
$cbox-ripple-bigger-small-height: 38px !default;
$cbox-ripple-bigger-small-width: 38px !default;
$cbox-small-check-fontsize: 6px !default;
$cbox-small-font-size: 12px !default;
$cbox-small-height: 12px !default;
$cbox-small-indeterminate-fontsize: 8px !default;
$cbox-small-indeterminate-lineheight: 11px !default;
$cbox-small-lineheight: 11px !default;
$cbox-small-width: 12px !default;
$cbox-width: 18px !default;
$cbox-focussed-bgcolor: normal-color(--white) !default;
$cbox-focussed-border-color: normal-color(--black) !default;
$cbox-focussed-checkmark-bgcolor: normal-color(--success) !default;
$cbox-focussed-checkmark-border-color: normal-color(--success) !default;
$cbox-focussed-checkmark-color: normal-color(--white) !default;
$cbox-border-style: solid !default;
//enddefault
