$ddl-chip-mobile-close-font: 16px !default;
$ddl-multiselect-skin-name: "bootstrap4" !default;
$ddl-delim-font-size: 14px !default;
$ddl-rtl-wrapper: 0 2px 0 32px !default;
$ddl-chip-mobile-bg: transparent !default;
$ddl-chip-mobile-font: normal-color(--primary) !default;
$ddl-chip-bg-color:  transparent !default;
$ddl-chip-hover-bg-color: transparent !default;
$ddl-chip-hover-font-color: normal-color(--primary) !default;
$ddl-chip-font-color: normal-color(--primary) !default;
$ddl-chip-close:  normal-color(--primary) !default;
$ddl-sel-chip-font-color: normal-color(--white) !default;
$ddl-sel-chip-close: normal-color(--white) !default;
$ddl-sel-chip-color: normal-color(--white) !default;
$ddl-sel-chip-bg-color: focused-color(--black-700) !default;
$ddl-chip-selected-shadow-value: none !default;
$ddl-popup-shadow-value: none !default;
$ddl-remains-font-color: normal-color(--black-700) !default;
$ddl-remains-padding-left: 8px !default;
$ddl-chip-margin: 2px 4px 2px 0 !default;
$ddl-chip-margin-bigger: 4px 4px 4px 0 !default;
$ddl-chip-radius: 4px !default;
$ddl-search-wrapper-width: calc(100% - 34px) !default;
$ddl-bigger-search-wrapper-width: calc(100% - 44px) !default;
$ddl-bigger-small-search-wrapper-width: calc(100% - 45px) !default;
$ddl-small-search-wrapper-width: calc(100% - 34px) !default;
$ddl-chip-content-padding: 6px 8px 6px 8px !default;
$ddl-chip-mobile-content-padding: 7px 12px 7px 12px !default;
$ddl-chip-bigger-content-padding: 7px 8px 7px 12px !default;
$ddl-rtl-chip-mobile-content-padding: $ddl-chip-mobile-content-padding !default;
$ddl-chip-padding: 0 !default;
$ddl-closer-margin-top: -2.3rem !default;
$ddl-closer-margin-top-bigger: -3.5em !default;
$ddl-chip-close-top: 8px !default;
$ddl-chip-close-left: 10px !default;
$ddl-chip-close-margin: 0 0 0 !default;
$ddl-chip-close-square: 24px !default;
$ddl-chip-close-font: 8px !default;
$ddl-chip-height: 24px !default;
$ddl-chip-mobile-height: 28px !default;
$ddl-chip-collection-top: 0 !default;
$ddl-input-height: 30px !default;
$ddl-input-bigger-height: 36px !default;
$ddl-delimviewheight-bigger: 36px !default;
$ddl-input-mob-height: 30px !default;
$ddl-control-height: 29px !default;
$ddl-control-bigger-height: 36px !default;
$ddl-delimviewheight: 29px !default;
$ddl-closer-hooker-font: 10px !default;
$ddl-closer-hooker-font-bigger: 12px !default;
$ddl-chip-hooker-square: 29px !default;
$ddl-sel-hover-chip-bg-color: normal-color(--black-700) !default;
$ddl-sel-chip-hover-font-color: normal-color(--white) !default;
$ddl-chip-hooker-right: 0 !default;
$ddl-chip-font-family: inherit !default;
$ddl-chip-mobile-radius: 4px !default;
$ddl-chip-sel-mobile-height: 46px !default;
$ddl-chip-selected-padding: 13px 0 13px 0 !default;
$ddl-chip-mobile-close-margin: 1px 0 0 !default;
$ddl-chip-close-mob-margin: 0 0 0 !default;
$ddl-closer-hooker-top: 100% !default;
$ddl-chip-mob-left: 0 !default;
$ddl-chip-mob-padding: 0 0 0 20px !default;
$ddl-chip-font-size: 14px !default;
$ddl-chip-bigger-font-size: 16px !default;
$ddl-rtl-chip-close-left: -10px !default;
$ddl-rtl-chip-content-padding: 6px 10px 6px 10px !default;
$ddl-rtl-mob-chip-content-padding: 8px 4px !default;
$ddl-rtl-mob-chip-content-margin: 2px 0 2px 4px !default;
$ddl-rtl-mob-sel-chip-content-padding: 12px 4px !default;
$ddl-rtl-chip-padding: 0 8px 0 0 !default;
$ddl-rtl-mob-sel-chip-padding: 0 8px 0 4px !default;
$ddl-rtl-mob-chip-padding: 0 4px 0 8px !default;
$ddl-overall-close-left: 10px !default;
$ddl-overall-close-top: 10px !default;
//$ddl-overall-bigger-close-top: 15px !default;
$ddl-rtl-hooker-left: -15px !default;
$ddl-chip-close-mob-top: 0 !default;
$ddl-chip-close-mobile-top: 10px !default;
$ddl-control-placholder-padding: 9px 42px !default;
$ddl-sel-chip-close-mobile-top: 8px !default;
$ddl-sel-chip-close-mobile-left: 17px !default;
$ddl-chip-sel-mobile-close-height: 40px !default;
$ddl-chip-sel-mobile-close-margin: 0 0 0 0 !default;
$ddl-input-text-indent: 6px !default;
$ddl-rtl-chip-sel-close-left: -10px !default;
$ddl-popup-active-focus-bg-color: normal-color(--primary) !default;
$ddl-popup-active-focus-font-color: normal-color(--white) !default;
$ddl-popup-active-focus-border-color: transparent !default;
$ddl-popup-active-focus-border-width: 0 !default;
$ddl-popup-active-focus-shadow-item: transparent !default;
$ddl-input-placeholder: normal-color(--black-700) !default;
$ddl-popup-active-border-color: normal-color(--primary) !default;
$ddl-popup-active-border-width: 0 !default;
$ddl-delim-text-indent: 6px !default;
$ddl-popup-normal-border-width: 1px !default;
$ddl-check-right: 21px !default;
$ddl-check-left: 12px !default;
$ddl-bigger-select-all-height: 40px !default;
$ddl-select-all-height: 26px !default;
$ddl-back-icon-margin: -2px 10px 0 -54px !default;
$ddl-back-icon-padding: 0 !default;
$ddl-filterbar-padding: 8px !default;
$ddl-bigger-filterbar-padding: 4px 0 !default;
$ddl-delimviewheight-check-bigger: 36px !default;
$ddl-popup-reorder-border: set-opacity(--black-700, 0.15) !default;
$ddl-check-right-margin: 8px !default;
$ddl-bigger-check-right-margin: 12px !default;
$ddl-selectall-font-size: 14px !default;
$ddl-close-icon-left: 24px !default;
$ddl-check-icon-top: 0 !default;
$ddl-bigger-check-right: 25px !default;
$ddl-multi-list-default-font-color: normal-color(--black-700) !default;
$ddl-multi-list-hover-bg-color: hover-color(--white) !default;
$ddl-delim-text-padding-right: 6px !default;
$ddl-list-rtl-padding-right: 15px !default;
$ddl-close-rtl-icon-left: 38px !default;
$ddl-chip-close-rtl-left: -15px !default;
// //$ddl-delim-font-color: $grey-light-font !default;
$ddl-close-icon-color: normal-color(--black-700) !default;
$ddl-bigger-chip-close-font: 10px !default;
$ddl-bigger-chip-close-square: 30px !default;
$ddl-bigger-close-top: 9px !default;
$ddl-multi-checkbox-bgcolor: normal-color(--black-700) !default;
$ddl-multi-checkbox-border-color: normal-color(--black-700) !default;
$ddl-multi-checkbox-color: normal-color(--primary) !default;
$ddl-multiselect-group-list-item-rtl-padding-right: 29px !default;
$ddl-bigger-multiselect-group-list-item-rtl-padding-right: 5px !default;
$ddl-multiselect-group-list-group-item-padding-left: 24px !default;
$ddl-multiselect-group-checkbox-wrapper-padding-left: 14px !default;
$ddl-bigger-multiselect-group-checkbox-wrapper-padding-left: 20px !default;
$ddl-multiselect-group-list-group-item-rtl-padding-right: 20px !default;
$ddl-multiselect-label-position: 15px !default;
// Small size
$ddl-select-all-height-small: 22px !default;
$ddl-small-chip-height: 20px !default;
$ddl-chip-small-font-size: 12px !default;
$ddl-small-chip-close-square: 18px !default;
$ddl-small-chip-close-font: 8px !default;
$ddl-control-small-height: 22px !default;
$ddl-small-input-height: 22px !default;
$ddl-delim-small-font-size: 12px !default;
$ddl-small-closer-margin-top: -2.4em !default;
$ddl-chip-small-content-padding: 0 8px 0 8px !default;
$ddl-chip-close-small-left: 6px !default;
$ddl-chip-close-small-top: 5px !default;
$ddl-chip-close-hooker-small-left: 4px !default;
$ddl-chip-close-hooker-small-top: 7px !default;
$ddl-delimviewheight-small: 22px !default;
// Touch Small size
$ddl-select-all-height-bigger-small: 34px !default;
$ddl-bigger-small-chip-height: 30px !default;
$ddl-chip-bigger-small-font-size: 15px !default;
$ddl-bigger-small-chip-close-square: 24px !default;
$ddl-bigger-small-chip-close-font: 10px !default;
$ddl-control-bigger-small-height: 30px !default;
$ddl-bigger-small-input-height: 34px !default;
$ddl-delim-bigger-small-font-size: 14px !default;
$ddl-chip-close-bigger-left: 13px !default;
$ddl-chip-close-bigger-top: 11px !default;
$ddl-chip-bigger-content-padding: 7px 8px 7px 12px !default;
$ddl-delimviewheight-bigger-small: 34px !default;
$ddl-closer-margin-top-bigger-small: -2.9em !default;
$multiselect-closer-bigger-height: 36px !default;
$multiselect-closer--bigger-margin-top: -3em !default;
$multiselect-closer-bigger-width: 34px !default;
