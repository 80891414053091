/* Montserrat Black */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
  src: url("/assets/medrecord/fonts/Montserrat-Black.ttf") format("truetype");
}

/* Montserrat Black Italic */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 900;
  src: url("/assets/medrecord/fonts/Montserrat-BlackItalic.ttf") format("truetype");
}

/* Montserrat Bold */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  src: url("/assets/medrecord/fonts/Montserrat-Bold.ttf") format("truetype");
}

/* Montserrat Bold Italic */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: bold;
  src: url("/assets/medrecord/fonts/Montserrat-BoldItalic.ttf") format("truetype");
}

/* Montserrat ExtraBold */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 800;
  src: url("/assets/medrecord/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

/* Montserrat ExtraBold Italic */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 800;
  src: url("/assets/medrecord/fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype");
}

/* Montserrat ExtraLight */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 200;
  src: url("/assets/medrecord/fonts/Montserrat-ExtraLight.ttf") format("truetype");
}

/* Montserrat ExtraLight Italic */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 200;
  src: url("/assets/medrecord/fonts/Montserrat-ExtraLightItalic.ttf") format("truetype");
}

/* Montserrat Italic */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: normal;
  src: url("/assets/medrecord/fonts/Montserrat-Italic.ttf") format("truetype");
}

/* Montserrat Light */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  src: url("/assets/medrecord/fonts/Montserrat-Light.ttf") format("truetype");
}

/* Montserrat Light Italic */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 300;
  src: url("/assets/medrecord/fonts/Montserrat-LightItalic.ttf") format("truetype");
}

/* Montserrat Medium */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  src: url("/assets/medrecord/fonts/Montserrat-Medium.ttf") format("truetype");
}

/* Montserrat Medium Italic */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 500;
  src: url("/assets/medrecord/fonts/Montserrat-MediumItalic.ttf") format("truetype");
}

/* Montserrat Regular */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  src: url("/assets/medrecord/fonts/Montserrat-Regular.ttf") format("truetype");
}

/* Montserrat SemiBold */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  src: url("/assets/medrecord/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

/* Montserrat SemiBold Italic */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 600;
  src: url("/assets/medrecord/fonts/Montserrat-SemiBoldItalic.ttf") format("truetype");
}

/* Montserrat Thin */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 200;
  src: url("/assets/medrecord/fonts/Montserrat-Thin.ttf") format("truetype");
}

/* Montserrat Thin Italic */
@font-face {
  font-family: "Montserrat";
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 200;
  src: url("/assets/medrecord/fonts/Montserrat-ThinItalic.ttf") format("truetype");
}
