/* Bootstrap vars

 grey-100: --white;
 grey-200: --white-100;
 grey-300: -white-300;
 grey-400: --geyser;
 grey-500: --hit-grey;
 grey-600: --black-700;
 grey-700 - grey-900 : --black;

*/

// Client dependent colors

body {
  /*
    @desc
    This Color is respond for the action dropdown menus,  Secondary Button’s fonts,
    Link’s normal states, task’s selected states, and selection buttons
  */
  --primary-color-h: 194;
  --primary-color-s: 23%;
  --primary-color-l: 48%;

  --primary-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));

  /*
    @desc
    This Color is correspond to primary-color.
     Automatically selects by the system.  Cannot be changed.
  */
  --primary-variant-color: hsl(var(--primary-color-h),
  calc(var(--primary-color-s) - 1%),
  calc(var(--primary-color-l) + 41%));
  --primary-hover-color: hsl(calc(var(--primary-color-h) + 1),
  calc(var(--primary-color-s) + 3%),
  calc(var(--primary-color-l) + 12%));
  --primary-active-color: hsl(calc(var(--primary-color-h) + 1),
  calc(var(--primary-color-s) + 11%),
  calc(var(--primary-color-l) - 4%));
  --primary-focused-color: hsl(calc(var(--primary-color-h) + 1),
  calc(var(--primary-color-s) + 11%),
  calc(var(--primary-color-l) - 14%));
  --primary-disabled-color: hsl(calc(var(--primary-color-h) + 3),
  calc(var(--primary-color-s) - 4%),
  calc(var(--primary-color-l) + 45%));

  /*
    @desc
    This Color is respond for the secondary button’s borders, active field inputs,
    inactive checkboxes, radio-buttons and switchers
  */
  --secondary-color-h: 194;
  --secondary-color-s: 15%;
  --secondary-color-l: 63%;

  --secondary-color: hsl(var(--secondary-color-h), var(--secondary-color-s), var(--secondary-color-l));

  /*
    @desc
    This Color is correspond to secondary-color.
     Automatically selects by the system.  Cannot be changed.
  */
  --secondary-variant-color: hsl(var(--secondary-color-h),
  calc(var(--secondary-color-s) - 3%),
  calc(var(--secondary-color-l) + 28%));
  --secondary-hover-color: hsl(calc(var(--secondary-color-h) - 1),
  calc(var(--secondary-color-s) + 1%),
  calc(var(--secondary-color-l) + 8%));
  --secondary-active-color: hsl(calc(var(--secondary-color-h) - 1),
  calc(var(--secondary-color-s) - 3%),
  calc(var(--secondary-color-l) - 6%));
  --secondary-focused-color: hsl(calc(var(--secondary-color-h) - 1),
  calc(var(--secondary-color-s) - 3%),
  calc(var(--secondary-color-l) - 16%));
  --secondary-disabled-color: hsl(calc(var(--secondary-color-h) + 1),
  var(--secondary-color-s),
  calc(var(--secondary-color-l) + 32%));

  /*
    @desc
    This Color is respond for the links, task states, Information notifications
  */
  --link-color-h: 205;
  --link-color-s: 100%;
  --link-color-l: 42%;

  --link-color: hsl(var(--link-color-h), var(--link-color-s), var(--link-color-l));

  /*
    @desc
    This Color is correspond to link-color and complements link colors.
     Automatically selects by the system.  Cannot be changed.
  */
  --link-variant-color: hsl(var(--link-color-h), calc(var(--link-color-s) - 27%), calc(var(--link-color-l) + 46%));
  --link-hover-color: hsl(var(--link-color-h), calc(var(--link-color-s) - 27%), calc(var(--link-color-l) + 14%));
  --link-active-color: hsl(var(--link-color-h), var(--link-color-s), calc(var(--link-color-l) - 3%));
  --link-focused-color: hsl(var(--link-color-h), var(--link-color-s), calc(var(--link-color-l) - 3%));
  --link-disabled-color: hsl(var(--link-color-h), calc(var(--link-color-s) - 24%), calc(var(--link-color-l) + 50%));

  /*
    @desc
    This Color is respond for the primary buttons, Error Input states, Link hover states
  */
  --warning-color-h: 38;
  --warning-color-s: 100%;
  --warning-color-l: 49%;

  --warning-color: hsl(var(--warning-color-h), var(--warning-color-s), var(--warning-color-l));

  /*
    @desc
    This Color is correspond to primary-button-color and complements default buttons colors.
     Automatically selects by the system.  Cannot be changed.
  */
  --warning-variant-color: hsl(var(--warning-color-h),
  calc(var(--warning-color-s) - 3%),
  calc(var(--warning-color-l) + 39%));
  --warning-hover-color: hsl(calc(var(--warning-color-h) - 1),
  calc(var(--warning-color-s) - 2%),
  calc(var(--warning-color-l) + 13%));
  --warning-active-color: hsl(calc(var(--warning-color-h) - 1),
  var(--warning-color-s),
  calc(var(--warning-color-l) - 4%));
  --warning-focused-color: hsl(calc(var(--warning-color-h) - 1),
  var(--warning-color-s),
  calc(var(--warning-color-l) - 14%));
  --warning-disabled-color: hsl(calc(var(--warning-color-h) - 1),
  var(--warning-color-s),
  calc(var(--warning-color-l) + 43%));

  /*
    @desc
    This Color is respond for the primary buttons, Error Input states, Link hover states
  */
  --primary-button-color-h: 38;
  --primary-button-color-s: 100%;
  --primary-button-color-l: 49%;

  --primary-button-color: hsl(var(--primary-button-color-h),
  var(--primary-button-color-s),
  var(--primary-button-color-l));

  /*
    @desc
    This Color is correspond to primary-button-color and complements default buttons colors.
     Automatically selects by the system.  Cannot be changed.
  */

  --primary-button-variant-color: hsl(var(--primary-button-color-h),
  calc(var(--primary-button-color-s) - 3%),
  calc(var(--primary-button-color-l) + 39%));
  --primary-button-hover-color: hsl(calc(var(--primary-button-color-h) - 1),
  calc(var(--primary-button-color-s) - 2%),
  calc(var(--primary-button-color-l) + 13%));
  --primary-button-active-color: hsl(calc(var(--primary-button-color-h) - 1),
  var(--primary-button-color-s),
  calc(var(--primary-button-color-l) - 4%));
  --primary-button-focused-color: hsl(calc(var(--primary-button-color-h) - 1),
  var(--primary-button-color-s),
  calc(var(--primary-button-color-l) - 14%));
  --primary-button-disabled-color: hsl(calc(var(--primary-button-color-h) - 1),
  var(--primary-button-color-s),
  calc(var(--primary-button-color-l) + 43%));

  /*
    @desc
    This Color is respond for the secondary action buttons, active field inputs,
    inactive checkboxes, radio buttons and switchers
  */
  --alert-color-h: 54;
  --alert-color-s: 100%;
  --alert-color-l: 64%;

  --alert-color: hsl(var(--alert-color-h), var(--alert-color-s), var(--alert-color-l));

  /*
    @desc
    This Color is correspond to warning-color and complements some warning states.
     Automatically selects by the system.  Cannot be changed.
  */
  --alert-variant-color: hsl(calc(var(--alert-color-h) - 1),
  calc(var(--alert-color-s) - 3%),
  calc(var(--alert-color-l) + 27%));
  --alert-hover-color: hsl(var(--alert-color-h), var(--alert-color-s), calc(var(--alert-color-l) + 9%));
  --alert-active-color: hsl(var(--alert-color-h), calc(var(--alert-color-s) - 19%), calc(var(--alert-color-l) - 5%));
  --alert-focused-color: hsl(var(--alert-color-h), calc(var(--alert-color-s) - 19%), calc(var(--alert-color-l) - 15%));
  --alert-disabled-color: hsl(calc(var(--alert-color-h) - 1), var(--alert-color-s), calc(var(--alert-color-l) + 31%));

  /*
    @desc
    This Color is respond for the Positive actions, notifications and states.
  */
  --success-color-h: 161;
  --success-color-s: 98%;
  --success-color-l: 39%;

  --success-color: hsl(var(--success-color-h), var(--success-color-s), var(--success-color-l));

  /*
    @desc
    This Color is correspond to success-color and complements some positive states.
     Automatically selects by the system.  Cannot be changed.
  */
  --success-variant-color: hsl(calc(var(--success-color-h) + 1),
  calc(var(--success-color-s) - 37%),
  calc(var(--success-color-l) + 40%));
  --success-hover-color: hsl(var(--success-color-h),
  calc(var(--success-color-s) - 36%),
  calc(var(--success-color-l) + 14%));
  --success-active-color: hsl(var(--success-color-h), var(--success-color-s), calc(var(--success-color-l) - 4%));
  --success-focused-color: hsl(var(--success-color-h), var(--success-color-s), calc(var(--success-color-l) - 14%));
  --success-disabled-color: hsl(calc(var(--success-color-h) - 1),
  calc(var(--success-color-s) - 35%),
  calc(var(--success-color-l) + 53%));

  /*
    @desc
    This Color is respond for the Error states, negative notifications, Delete button styling
  */
  --error-color-h: 11;
  --error-color-s: 100%;
  --error-color-l: 54%;

  --error-color: hsl(var(--error-color-h), var(--error-color-s), var(--error-color-l));

  /*
    @desc
    This Color is correspond to error-color and complements some negative states.
     Automatically selects by the system.  Cannot be changed.
  */
  --error-variant-color: hsl(calc(var(--error-color-h) - 1), var(--error-color-s), calc(var(--error-color-l) + 41%));
  --error-hover-color: hsl(var(--error-color-h), var(--error-color-s), calc(var(--error-color-l) + 11%));
  --error-active-color: hsl(var(--error-color-h), calc(var(--error-color-s) - 16%), calc(var(--error-color-l) - 4%));
  --error-focused-color: hsl(var(--error-color-h), calc(var(--error-color-s) - 16%), calc(var(--error-color-l) - 14%));
  --error-disabled-color: hsl(var(--error-color-h), var(--error-color-s), calc(var(--error-color-l) + 40%));


  /** WHITE-COLORS */
  --white-color-h: 0;
  --white-color-s: 0%;
  --white-color-l: 100%;
  --white-color: hsl(0, 0%, 100%);
  --white-hover-color: get-light-hover-color(--white);
  --white-active-color: get-light-active-color(--white);
  --white-focused-color: get-light-focused-color(--white);
  --white-disabled-color: get-light-disabled-color(--white);

  --white-100-color-h: 214;
  --white-100-color-s: 47%;
  --white-100-color-l: 97%;
  --white-100-color: hsl(214, 47%, 97%);
  --white-100-hover-color: get-light-hover-color(--white-100);
  --white-100-active-color: get-light-active-color(--white-100);
  --white-100-focused-color: get-light-focused-color(--white-100);
  --white-100-disabled-color: get-light-disabled-color(--white-100);

  --white-200-color-h: 180;
  --white-200-color-s: 14%;
  --white-200-color-l: 96%;
  --white-200-color: hsl(180, 14%, 96%);
  --white-200-hover-color: get-light-hover-color(--white-200);
  --white-200-active-color: get-light-active-color(--white-200);
  --white-200-focused-color: get-light-focused-color(--white-200);
  --white-200-disabled-color: get-light-disabled-color(--white-200);

  --white-300-color-h: 195;
  --white-300-color-s: 20%;
  --white-300-color-l: 96%;
  --white-300-color: hsl(195, 20%, 96%);
  --white-300-hover-color: get-light-hover-color(--white-300);
  --white-300-active-color: get-light-active-color(--white-300);
  --white-300-focused-color: get-light-focused-color(--white-300);
  --white-300-disabled-color: get-light-disabled-color(--white-300);

  /** BLUE COLORS */
  --blue-100-color-h: 194;
  --blue-100-color-s: 32%;
  --blue-100-color-l: 71%;
  --blue-100-color: hsl(194, 32%, 71%);
  --blue-100-hover-color: get-light-hover-color(--blue-100);
  --blue-100-active-color: get-light-active-color(--blue-100);
  --blue-100-focused-color: get-light-focused-color(--blue-100);
  --blue-100-disabled-color: get-light-disabled-color(--blue-100);

  --blue-200-color-h: 194;
  --blue-200-color-s: 38%;
  --blue-200-color-l: 82%;
  --blue-200-color: hsl(194, 38%, 82%);
  --blue-200-hover-color: get-light-hover-color(--blue-200);
  --blue-200-active-color: get-light-active-color(--blue-200);
  --blue-200-focused-color: get-light-focused-color(--blue-200);
  --blue-200-disabled-color: get-light-disabled-color(--blue-200);

  --blue-300-color-h: 195;
  --blue-300-color-s: 21%;
  --blue-300-color-l: 74%;
  --blue-300-color: hsl(195, 21%, 74%);
  --blue-300-hover-color: get-light-hover-color(--blue-300);
  --blue-300-active-color: get-light-active-color(--blue-300);
  --blue-300-focused-color: get-light-focused-color(--blue-300);
  --blue-300-disabled-color: get-light-disabled-color(--blue-300);

  /** BLACK COLORS */
  --black-300-color-h: 0;
  --black-300-color-s: 0%;
  --black-300-color-l: 80%;
  --black-300-color: hsl(0, 0%, 80%);
  --black-300-hover-color: get-light-hover-color(--black-300);
  --black-300-active-color: get-light-active-color(--black-300);
  --black-300-focused-color: get-light-focused-color(--black-300);
  --black-300-disabled-color: get-light-disabled-color(--black-300);

  --black-400-color-h: 0;
  --black-400-color-s: 0%;
  --black-400-color-l: 85%;
  --black-400-color: hsl(0, 0%, 85%);
  --black-400-hover-color: get-light-hover-color(--black-400);
  --black-400-active-color: get-light-active-color(--black-400);
  --black-400-focused-color: get-light-focused-color(--black-400);
  --black-400-disabled-color: get-light-disabled-color(--black-400);

  --black-700-color-h: 0;
  --black-700-color-s: 0%;
  --black-700-color-l: 31%;
  --black-700-color: hsl(0, 0%, 31%);
  --black-700-hover-color: get-dark-hover-color(--black-700);
  --black-700-active-color: get-dark-active-color(--black-700);
  --black-700-focused-color: get-dark-focused-color(--black-700);
  --black-700-disabled-color: get-dark-disabled-color(--black-700);

  --black-900-color-h: 0;
  --black-900-color-s: 0%;
  --black-900-color-l: 0%;
  --black-900-color: hsl(0, 0%, 0%);
  --black-900-hover-color: get-dark-hover-color(--black-900);
  --black-900-active-color: get-dark-active-color(--black-900);
  --black-900-focused-color: get-dark-focused-color(--black-900);
  --black-900-disabled-color: get-dark-disabled-color(--black-900);

}
