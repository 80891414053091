// uploader bootstrap theme
$zero-value: 0 !default;
$upload-root-font-family: $font-family !default;
$upload-element-opacity: $zero-value !default;
$upload-element-width: $zero-value !default;
$select-file-margin: $zero-value !default;
$select-file-padding: $zero-value !default;
$list-items-font-size: 0.9375rem !default;
$list-item-height: 2.1875rem !default;
$list-item-margin-right: 5.0625rem !default;
$list-item-name-width: 75% !default;
$progress-bar-wrapper-height: 0.3125rem !default;
$progress-bar-height: 90% !default;
$delete-icon-opacity: 1 !default;
$float-right: right !default;
$float-left: left !default;
$right: 100% !default;
$file-status-rtl-top-margin: 7% !default;
$remove-icon-height: 2rem !default;
$remove-icon-width: 2rem !default;
$remove-bigger-icon-height: 2.25rem !default;
$remove-bigger-icon-width: 2.25rem !default;
$remove-icon-before: 0.25rem !default;
$file-size-top: $zero-value !default;
$action-buttons-margin: 0.625rem !default;
$file-status-rtl: 1.875rem !default;
$progress-inner-wrap-height: 6px !default;
$progress-inner-wrap-top: 3px !default;
$progress-bar-wrap-top: 2.125rem !default;
$file-status-top-bigger: 2.1875rem !default;
$file-drop-rtl-smaller: -0.625rem !default;
$file-drop-rtl-bigger: -0.625rem !default;
$footer-height: 3.125px !default;
$footer-height-smaller: 1.875rem !default;
$footer-buttons-height: 1.875rem !default;
$footer-buttons-height-bigger: 2.5px !default;
$file-name-top: $zero-value !default;
$file-container-top-bigger: $zero-value !default;
$remove-icon-rtl: 1.25rem !default;
$reload-btn-right: 2.25rem !default;
$icons-small-ie-padding: 18px 13px 18px 23px !default;
$icons-bigger-ie-padding: 1.25rem 1.075rem 1.25rem 26px !default;

// Smaller values
$list-items-font-size-smaller: 0.875rem !default;
$list-items-height-smaller: 3.75rem !default;
$list-item-status-margin-smaller: 8px !default;
$progress-bar-top-smaller: $zero-value !default;
$progress-bar-wrapper-height-smaller: 0.625rem !default;
$file-status-top-smaller: 19px !default;
$list-item-status-top-smaller: 0.625rem !default;
$file-size-top-smaller: 0.625rem !default;
$file-size-left-smaller: 0.625rem !default;
$file-type-top-smaller: $zero-value !default;
$file-size-rtl-right: 0.625rem !default;
$list-item-size-top: 1.875rem !default;
$file-container-height: 100% !default;
$progress-bar-paddding: 6px 6px !default;
$remove-icon-before-bigger: 0.9375rem !default;

// Smaller RTL values
$file-status-rtl-smaller: 21px !default;
$progress-bar-wrap-top-smaller: 23px !default;
$rtl-progress-left-smaller: -23px !default;
$remove-icon-top-bigger: 0.625rem !default;
$file-container-top: $zero-value !default;

// themes files
$progress-bar-transition: width 2s !default;
$remove-icon-disabled-color:  $zero-value !default;
$progress-text-rtl-smaller: 0 !default;
$pause-play-button-right-value: 2.25rem !default;
$pause-play-button-right-value-bigger: 45px !default;
$pause-play-button-right-value-bigger-rtl: 41px !default;
$file-container-left: 3.75rem !default;
$ie-icons-position-value: 0.625rem !default;
$ie-abort-icon-position-value: 0.75rem !default;

$upload-font-family: inherit !default;
$drop-area-font-size-smaller: 0.875rem !default;
$drop-area-font-size: 1rem !default;
$drop-text-color: $gray-900 !default;
$drag-hover-border: dashed 0.125rem hover-color(--primary) !default;
$drop-zone-margin: 3px !default;
$header-padding: 1rem 0 1rem 1rem !default;
$drop-area-padding-left: 1rem !default;
$drop-area-padding-left-smaller: 0.875rem !default;
$file-name-font-size-smaller: 0.875rem !default;
$file-name-font-size: 0.9375rem !default;
$file-size-color: normal-color(--black-700) !default;
$list-item-status-font-size-smaller: 0.75rem !default;
$list-item-status-font-size: 0.875rem !default;
$list-item-margin-left-smaller: 0.75rem !default;
$list-item-margin-left: 0.9375rem !default;
$list-line-height: normal !default;
$remove-icon-margin: 1.25rem !default;
$remove-icon-margin-smaller: 1rem !default;
$action-upload-buttons-margin-smaller: 1rem 1rem 1rem 0 !default;
$action-clear-buttons-margin-smaller: 1rem 6px 1rem 1rem !default;
$footer-upload-buttons-margin: 1.25rem 1.25rem 1.25rem 0 !default;
$footer-clear-buttons-margin: 1.25rem 8px 1.25rem 1.25rem !default;
$list-bottom-border: 1px solid normal-color(--white-100) !default;
$delete-icon-padding-smaller: 0.9375rem !default;
$delete-icon-padding: 0 !default;
$remove-icon-right: $zero-value !default;
$remove-icon-top-smaller: 50% !default;
$remove-icon-before-left: 9px !default;
$remove-icon-before-top: 9px !default;
$remove-icon-margin-top: -1.5rem !default;
$file-name-padding-top-smaller: 1rem !default;
$file-name-padding-top: 1.25rem !default;
$file-size-padding: 8px $zero-value !default;
$file-size-padding-smaller: 6px $zero-value !default;
$progress-bar-wrapper-width: 95% !default;
$progress-bar-top-paddding: 6px !default;
$progress-bar-bottom-paddding: 0.6875rem !default;
$progress-bar-text-right-smaller: $zero-value !default;
$progress-bar-text-top-smaller: -2.075rem !default;
$li-min-height: 5.75rem !default;
$inner-wrap-radius: 0.125rem !default;
$progress-bar-radius: 1px !default;
$progress-inner-wrap-height-smaller: 0.25rem !default;
$progress-bar-height-smaller: 0.25rem !default;
$upload-success-color: normal-color(--success) !default;
$upload-failure-color: normal-color(--error) !default;
$upload-in-progress-color: normal-color(--primary) !default;
$progress-background-color: normal-color(-white-300) !default;
$remove-icon-before-left-bigger: 0.75rem !default;
$remove-icon-before-top-bigger: 0.75rem !default;
$list-items-height: 108px !default;
$progress-bar-text-right: 1rem !default;
$progress-bar-text-top: -2.25rem !default;
$progress-bar-wrapper-width-bigger: 270px !default;
$header-padding-rtl-bigger: 1.25rem 0.9375rem 1.25rem $zero-value !default;
$header-padding-bigger: 1.25rem 0 1.25rem 1.25rem !default;
$margin-rtl-header: $zero-value !default;
$margin-rtl: 0.9375rem !default;
$remove-icon-rtl-bigger: $zero-value !default;
$progress-bar-width-rtl: 86% !default;
$header-padding-rtl-smaller: 1rem 0.75rem 1rem $zero-value !default;
$margin-rtl-header-smaller: $zero-value !default;
$margin-rtl-smaller: 0.6875rem !default;
$left: $zero-value !default;
$rtl-progress-top: -2rem !default;
$remove-icon-rtl-top-smaller: 50% !default;
$file-name-font-family: inherit !default;
$progress-text-color: normal-color(--black) !default;
$remove-icon-top: 50% !default;
$clear-icon-focus-color: normal-color(--white-100) !default;

$remove-icon-font-size-smaller: 0.875rem !default;
$remove-icon-font-size: 1rem !default;
$delete-icon-font-size: 1rem !default;
$abort-icon-font-size: 1rem !default;
$delete-icon-font-size-smaller: 0.875rem !default;
$abort-icon-font-size-smaller: 0.875rem !default;
$upload-border: 1px solid set-opacity(--black, 0.15) !default;
$remove-icon-padding-smaller: 0 !default;
$remove-icon-margin-top-smaller: -2.125rem !default;

$remove-icon-color: rgba($black, 0.5) !default;
$upload-hover-icon-color: normal-color(--black) !default;
$disabled-state: disabled-color(--black-300) !default;

$uploader-icons-hover-radius: 0.25rem !default;
// mouse small
$header-padding-small: 0.75rem 0 0.75rem 0.75rem !default;
$drop-area-small-font-size: 13px !default;
$file-small-name-font-size: 0.75rem !default;
$file-small-name-padding-top: 0.75rem !default;
$list-small-item-status-font-size: 0.75rem !default;
$file-small-size-padding: 6px 0 !default;
$remove-small-icon-font-size: 0.625rem !default;
$remove-small-icon-height: 1.5rem !default;
$delete-small-icon-padding: 0.75rem !default;
$remove-small-icon-width: 1.5rem !default;
$progress-bar-bottom-paddding-small: 0.125rem !default;
$drop-small-area-padding-left: 0.625rem !default;
$li-min-height-small: 4.875rem !default;
$header-padding-rtl-small: 0.75rem 0.75rem 0.75rem 0 !default;

// form upload
$form-upload-file-name-line-height: 1.5 !default;
$form-upload-file-list-height: $list-item-height !default;
$form-upload-file-list-padding-top: $file-name-padding-top - 4 !default;
$form-upload-file-list-padding-bottom: $file-name-padding-top !default;
$form-upload-file-name-padding: 0.25rem !default;
$form-upload-file-status-font-size: $list-item-status-font-size-smaller !default;
$form-upload-remove-icon-margin: -26px !default;
$form-upload-remove-icon-top: 3rem !default;
$form-upload-bigger-remove-icon-top: 3.125px !default;
$form-upload-small-remove-icon-top: 2.6875rem !default;

// form upload - bigger
$form-upload-bigger-file-name-line-height: 1.5 !default;
$form-upload-bigger-file-list-height: $list-item-height !default;
$form-upload-bigger-file-list-padding-top: $file-name-padding-top - 4 !default;
$form-upload-bigger-file-list-padding-bottom: $file-name-padding-top !default;
$form-upload-bigger-file-name-padding: 0.25rem !default;
$form-upload-bigger-file-status-font-size: $list-item-status-font-size !default;

// form upload - small
$form-upload-small-file-name-line-height: 1.5 !default;
$form-upload-small-file-list-height: $list-item-height !default;
$form-upload-small-file-list-padding-top: $file-small-name-padding-top - 4 !default;
$form-upload-small-file-list-padding-bottom: $file-small-name-padding-top !default;
$form-upload-small-file-name-padding: 0.25rem !default;
$form-upload-small-file-status-font-size: $list-item-status-font-size-smaller !default;

$footer-buttons-margin: 1rem !default;
$action-buttons-margin-smaller: 12px !default;
