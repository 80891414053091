@include export-module("checkbox-layout") {
  /*! checkbox layout */
  .e-checkbox-wrapper,
  .e-css.e-checkbox-wrapper {
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    outline: none;
    user-select: none;
    @at-root {
      & label {
        cursor: pointer;
        display: inline-block;
        line-height: 0;
        margin: 0;
        position: relative;
        white-space: nowrap;
      }

      &:focus {
        & .e-frame {
          box-shadow: $cbox-focussed-box-shadow;
        }
      }

      & .e-ripple-container {
        border-radius: 50%;
        bottom: $cbox-ripple-size;
        height: $cbox-ripple-height;
        left: $cbox-ripple-size;
        pointer-events: none;
        position: absolute;
        right: $cbox-ripple-size;
        top: $cbox-ripple-size;
        width: $cbox-ripple-width;
        z-index: 1;
      }

      & .e-label {
        cursor: pointer;
        display: inline-block;
        font-family: $font-family;
        font-size: $cbox-font-size;
        font-weight: 600;
        line-height: $cbox-height;
        user-select: none;
        vertical-align: middle;
        white-space: normal;
      }

      & .e-checkbox {
        height: 1px;
        opacity: 0;
        position: absolute;
        width: 1px;

        +.e-label {
          margin-right: $cbox-margin;
        }
      }

      & .e-frame {
        border: $cbox-border;
        border-radius: $cbox-border-radius;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        font-family: "e-icons";
        height: $cbox-height;
        line-height: $cbox-lineheight;
        padding: $cbox-padding;
        text-align: center;
        vertical-align: middle;
        width: $cbox-width;

        +.e-label {
          margin-left: $cbox-margin;
        }

        +.e-ripple-container {
          left: auto;
        }
      }

      & .e-check {
        font-size: $cbox-check-fontsize;
      }

      & .e-stop {
        font-size: $cbox-indeterminate-fontsize;
        line-height: $cbox-indeterminate-lineheight;
      }

      &.e-checkbox-disabled {
        cursor: default;
        pointer-events: none;

        & .e-frame {
          cursor: default;
        }

        & .e-label {
          cursor: default;
        }
      }

      &.e-rtl {
        .e-ripple-container {
          right: $cbox-ripple-size;
        }

        & .e-frame {
          margin: 0;

          + .e-ripple-container {
            left: $cbox-ripple-size;
            right: auto;
          }
        }

        & .e-label {
          margin-left: 0;
          margin-right: $cbox-margin;

          +.e-frame {
            margin: 0;
          }
        }

        & .e-checkbox {
          +.e-label {
            margin-left: $cbox-margin;
            margin-right: 0;
          }
        }
      }

      &.e-small {
        & .e-frame {
          height: $cbox-small-height;
          line-height: $cbox-small-lineheight;
          width: $cbox-small-width;
        }

        & .e-check {
          font-size: $cbox-small-check-fontsize;
        }

        & .e-stop {
          font-size: $cbox-small-indeterminate-fontsize;
          line-height: $cbox-small-indeterminate-lineheight;
        }

        & .e-label {
          font-size: $cbox-small-font-size;
          line-height: $cbox-small-height;
        }

        & .e-ripple-container {
          bottom: $cbox-ripple-small-size;
          height: $cbox-ripple-small-height;
          left: $cbox-ripple-small-size;
          right: $cbox-ripple-small-size;
          top: $cbox-ripple-small-size;
          width: $cbox-ripple-small-width;
        }
      }
    }
  }

  .e-small .e-checkbox-wrapper,
  .e-small.e-checkbox-wrapper,
  .e-small .e-css.e-checkbox-wrapper,
  .e-small.e-css.e-checkbox-wrapper {
    & .e-frame {
      height: $cbox-small-height;
      line-height: $cbox-small-lineheight;
      width: $cbox-small-width;
    }

    & .e-check {
      font-size: $cbox-small-check-fontsize;
    }

    & .e-stop {
      font-size: $cbox-small-indeterminate-fontsize;
      line-height: $cbox-small-indeterminate-lineheight;
    }

    & .e-label {
      font-size: $cbox-small-font-size;
      line-height: $cbox-small-height;
    }

    & .e-ripple-container {
      bottom: $cbox-ripple-small-size;
      height: $cbox-ripple-small-height;
      left: $cbox-ripple-small-size;
      right: $cbox-ripple-small-size;
      top: $cbox-ripple-small-size;
      width: $cbox-ripple-small-width;
    }
  }

  .e-bigger.e-small .e-checkbox-wrapper,
  .e-bigger.e-small.e-checkbox-wrapper,
  .e-bigger.e-small .e-css.e-checkbox-wrapper,
  .e-bigger.e-small.e-css.e-checkbox-wrapper {
    & .e-frame {
      height: $cbox-bigger-small-height;
      line-height: $cbox-bigger-small-lineheight;
      width: $cbox-bigger-small-width;
    }

    & .e-check {
      font-size: $cbox-bigger-small-check-fontsize;
    }

    & .e-stop {
      font-size: $cbox-bigger-small-indeterminate-fontsize;
      line-height: $cbox-bigger-small-indeterminate-lineheight;
    }

    & .e-label {
      font-size: $cbox-bigger-small-font-size;
      line-height: $cbox-bigger-small-height;
    }

    & .e-ripple-container {
      bottom: $cbox-ripple-bigger-small-size;
      height: $cbox-ripple-bigger-small-height;
      left: $cbox-ripple-bigger-small-size;
      right: $cbox-ripple-bigger-small-size;
      top: $cbox-ripple-bigger-small-size;
      width: $cbox-ripple-bigger-small-width;
    }
  }

  .e-bigger .e-checkbox-wrapper,
  .e-bigger.e-checkbox-wrapper,
  .e-bigger .e-css.e-checkbox-wrapper,
  .e-bigger.e-css.e-checkbox-wrapper {
    & .e-frame {
      height: $cbox-bigger-height;
      line-height: $cbox-bigger-lineheight;
      width: $cbox-bigger-width;

      +.e-label {
        font-size: $cbox-bigger-font-size;
        line-height: $cbox-bigger-height;
        margin-left: $cbox-bigger-margin;
      }
    }

    & .e-check {
      font-size: $cbox-bigger-check-fontsize;
    }

    & .e-stop {
      font-size: $cbox-bigger-indeterminate-fontsize;
      line-height: $cbox-bigger-indeterminate-lineheight;
    }

    & .e-label {
      font-size: $cbox-bigger-font-size;
      font-weight: bold;
    }

    & .e-ripple-container {
      bottom: $cbox-ripple-bigger-size;
      height: $cbox-ripple-bigger-height;
      left: $cbox-ripple-bigger-size;
      right: $cbox-ripple-bigger-size;
      top: $cbox-ripple-bigger-size;
      width: $cbox-ripple-bigger-width;
    }

    &.e-rtl {
      & .e-frame {
        margin: 0;

        +.e-label {
          margin-left: 0;
          margin-right: $cbox-bigger-margin;
        }

        +.e-ripple-container {
          right: auto;
        }
      }
    }

    &.e-small {
      & .e-frame {
        height: $cbox-bigger-small-height;
        line-height: $cbox-bigger-small-lineheight;
        width: $cbox-bigger-small-width;
      }

      & .e-check {
        font-size: $cbox-bigger-small-check-fontsize;
      }

      & .e-stop {
        font-size: $cbox-bigger-small-indeterminate-fontsize;
        line-height: $cbox-bigger-small-indeterminate-lineheight;
      }

      & .e-label {
        font-size: $cbox-bigger-small-font-size;
        line-height: $cbox-bigger-small-height;
      }

      & .e-ripple-container {
        bottom: $cbox-ripple-bigger-small-size;
        height: $cbox-ripple-bigger-small-height;
        left: $cbox-ripple-bigger-small-size;
        right: $cbox-ripple-bigger-small-size;
        top: $cbox-ripple-bigger-small-size;
        width: $cbox-ripple-bigger-small-width;
      }
    }
  }
}
