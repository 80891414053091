// Tables

@mixin table-row-variant($state, $background, $border: null) {
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.
  .table-#{$state} {
    &,
    > th,
    > td {
      background-color: normal-color($background);
    }

    @if $border != null {
      th,
      td,
      thead th,
      tbody + tbody {
        border-color: normal-color($border);
      }
    }
  }

  // Hover states for `.table-hover`
  // Note: this is not available for cells or rows within `thead` or `tfoot`.
  .table-hover {
    .table-#{$state} {
      @include hover() {
        background-color: hover-color($background);

        > td,
        > th {
          background-color: hover-color($background);
        }
      }
    }
  }
}
