// List Groups

@mixin list-group-item-variant($state, $background, $color) {
  .list-group-item-#{$state} {
    background-color: normal-color($background);
    color: normal-color($color);

    &.list-group-item-action {
      @include hover-focus() {
        background-color: hover-color($background);
        color: hover-color($color);
      }

      &.active {
        background-color: active-color($color);
        border-color: active-color($color);
        color: active-color(--white);
      }
    }
  }
}
