$slider-bg-color: normal-color(--black-700) !default;
$slider-disabled-bg-color: disabled-color(--black-700) !default;
$slider-active-bg-color: active-color(--black-700) !default;
$slider-disabled-opacity: 0.65 !default;
$slider-start-handle-border-size: 1px solid normal-color(--black) !default;
$slider-border-radius: 4px !default;
$slider-height: 10px !default;
$slider-handle-border-size: 1px solid !default;
$slider-handle-border-color: normal-color(--black) !default;
$slider-handle-height: 14px !default;
$slider-handle-width: 50px !default;
$slider-handle-mobile-height: 16px !default;
$slider-handle-mobile-width: 16px !default;
$slider-handle-mobile-margin-left: -8px !default;
$slider-handle-mobile-handle-top: calc(50% - 20px) !default;
$slider-handle-mobile-buttons-width: 18px !default;
$slider-handle-mobile-buttons-height: 18px !default;
$slider-handle-border-radius: 100% !default;
$slider-button-icon-color: normal-color(--black) !default;
$slider-handle-top: -4px !default;
$slider-handle-mobile-top: -4px !default;
$slider-h-handle-margin: 0 !default;
$slider-h-handle-mobile-margin: 0 !default;
$rtl-slider-h-handle-margin: 0 -8px 0 0 !default;
$rtl-slider-h-handle-mobile-margin: 0 !default;
$slider-v-handle-margin: -7px !default;
$slider-v-handle-mobile-margin: 0 !default;
$rtl-slider-v-handle-margin: 0 !default;
$rtl-slider-v-handle-mobile-margin: 0 !default;
$slider-range-bar-color: normal-color(--primary) !default;
$slider-range-bar-hover-color: hover-color(--primary) !default;
$slider-color: normal-color(--black) !default;
$slider-handle-color: normal-color(--black) !default;
$slider-handle-start-border-color: normal-color(-white-300) !default;
$slider-button-bg-color: normal-color(--black) !default;
$slider-button-color-border: transparent !default;
$slider-container-height: 6px !default;
$slider-normal-handle-border-size: 1px solid !default;
$slider-normal-handle-border-color: normal-color(--primary) !default;
$slider-handle-bg-color: normal-color(--white) !default;
$slider-tooltip-padding: 2px 12px !default;
$slider-start-transform: none !default;
$slider-mid-transform: none !default;
$slider-final-transform: none !default;
$slider-handle-after-position: relative !default;
$slider-handle-hover-start: scale(1) !default;
$slider-handle-hover-end: scale(1) !default;
$slider-start-handle-hover-end: scale(1) !default;
$tick-after-height: 6px !default;
$tick-after-top: -9px !default;
$largetick-before-top: -8px !default;
$largetick-before-height: 10px !default;
$largetick-after-height: 10px !default;
$largetick-after-top: 4px !default;
$tick-before-height: 6px !default;
$tick-before-top: 1px !default;
$slider-btn-container-height: 10px !default;
$slider-horizontal-btn-icon-prev: ""\e904"" !default;
$slider-vertical-btn-icon-prev: ""\e95d"" !default;
$slider-horizontal-btn-icon-next: ""\e895"" !default;
$slider-vertical-btn-icon-next: ""\e98a"" !default;
$slider-vertical-width: 10px !default;
$slider-border-radius-handle: 5px !default;
$slider-btn-top: 0 !default;
$slider-btn-after-height: 0 !default;
$slider-btn-after-width: 0 !default;
$slider-btn-after-padding: 0 !default;
$slider-btn-margin-right: 2px !default;
$slider-btn-margin-top: -1.5px !default;
$slider-vertical-left: -4px !default;
$slider-button-font-size: 9px !default;
$slider-scale-font-family: $font-family !default;
$slider-scale-font-size: 12px !default;
$slider-mobile-scale-font-size: 14px !default;
$slider-scale-tick-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEXO1Nq/Gb1BAAAACklEQVR4nGNiAAAABgADNjd8qAAAAABJRU5ErkJggg==");
$slider-small-scale-tick-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEWttb1auGYlAAAACklEQVR4nGNiAAAABgADNjd8qAAAAABJRU5ErkJggg==");
$slider-disabled-handle-show: none !default;
$slider-button-border-color: normal-color(--black) !default;
$slider-first-btn-margin: -0.5px !default;
$slider-second-btn-margin: -4.5px !default;
$slider-button-size: 18px !default;
$slider-button-horizon-padding: 0 30px !default;
$slider-button-vertical-padding: 38px 0 !default;
$slider-scale-value-position-top: -16px !default;
$slider-scale-value-position-bottom: -16px !default;
$tick-after-left: 5px !default;
$largetick-before-left: 2px !default;
$tick-before-left: 16px !default;
$largetick-after-left: 15px !default;
$slider-scale-value-position-left: -36px !default;
$slider-scale-value-position-right: -36px !default;
$slider-scale-value-position-both-left: -18px !default;
$slider-scale-value-position-both-right: -74px !default;
$slider-vertical-btn-right: 0 !default;
$slider-horizon-prve-btn-left: 0 !default;
$slider-horizon-next-btn-left: 0 !default;
$slider-vertical-prve-btn-left: -1px !default;
$slider-vertical-next-btn-left: 0 !default;
$slider-handle-start-top: calc(50% - 20px) !default;
$slider-handle-start-left: calc(50% - 8px) !default;
$slider-wrap-height: 26px !default;
$slider-wrap-width: 26px !default;
$slider-center-align: -5px !default;
$slider-btn-center-align: -8px !default;
$tick-both-top: -3px !default;
$tick-both-left: 3px !default;
$largetick-both-top: 0 !default;
$largetick-both-left: 0 !default;
$slider-range-height: $slider-container-height !default;
$slider-range-width: $slider-vertical-width - 2px !default;
$slider-range-border-radius: $slider-border-radius - 1px !default;
$slider-first-btn-font-size: 6px !default;
$slider-first-btn-height: 24px !default;
$slider-first-btn-line-height: 20px !default;
$slider-second-btn-margin-right: 0 !default;
$slider-second-btn-font-size: 6px !default;
$slider-second-btn-height: 24px !default;
$slider-second-btn-line-height: 20px !default;
$slider-button-hover-transform: 0 !default;
$slider-cursor: pointer !default;
$slider-handle-hover-cursor: pointer !default;
$slider-handle-active-cursor: pointer !default;
$slider-scale-top: 16px !default;
$slider-scale-right: -15px !default;

$slider-tab-background: normal-color(--primary) !default;
$slider-tab-border-color: normal-color(--primary) !default;
$slider-tab-active-box-shadow: 0 0 0 1px normal-color(--black), 0 0 0 3px active-color(--primary) !default;

$slider-handle-active-bg-color: normal-color(--white) !default;
$slider-handle-active-border-color: active-color(--primary) !default;
$slider-handle-active-box-shadow: 0 0 0 1px $white, 0 0 0 3px active-color(--primary) !default;

$slider-button-hleft-bg-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAolBMVEUAAAD////////////////////////+/v7////+/v7////+/v7////+/v7////////+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7////+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7///+y6E2hAAAANXRSTlMABAgMEBQYHCAkKCwwNEBESExUWFxkaGx0fICDh4ubn6Orr7O3u7/Hy8/T19vf4+fr7/P3+0e6nekAAAEFSURBVHjalZXJVgJBEASjnXHAEUQURZBFFAfZt+H/f82jedCXVpyjD1mvs4q/aR4uP1QJR7YUf1tgmYl/amEZXIRHLF31X7GUJ/E/fOB8I/46w5Hm4h9LLGPx63ssPQ08wNI+i/+GpdiLv/xH4IX4+waWqQa+w9LXwE9YOrX4ExzxCqzE3xVY3oMVGGrgHpYH9UdYWlqBuQ9cbLUCOY5UaeCS4INbPMVO19A1wdBVIli0CXhG+uIZYsui7uDJ1/Li0MRTHuXFKouOakZ0VMPwqLqBUckfiWzuTU7wNnwmgtdnDPFyxy7ouQ3hBeW50RW4SMSXrOdFg/chfCg8V1/RcjR+PXbfOBx/sErjBl8AAAAASUVORK5CYII=");
$slider-button-hright-bg-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAnFBMVEUAAAD////////////////////////+/v7////////+/v7////+/v7+/v7////////+/v7+/v7+/v7////+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7////uN4ajAAAAM3RSTlMABAgMEBQYHCAoLDA0ODxASExcYGRobHR8g4uTl5ufo6ert7u/w8fLz9fb3+Pn6+/z9/tbKk6UAAABBklEQVR42q2VyRKCAAxDQRBZVMAFcRdxVxD0///Nk2M8OCQz9tx3SJu0xrvWz09VXaO5rCMQN4cgOgUQB5Mg+g8glgZRoydUwhALAB4hAZg7IEqXIJwrECeLIIIKiIyRMUDhKUNMkYgZ4Vv0iM945AzExSYID4XnjEci9MiMEZ6i8CFDYDhqKhwnIAomHO5XOFoEEaLwFSNjjMInDLHEcESMR/ZA3D0mHDcgzhYBFBrQOqJtPWLbouhUjF6Mi9s09/uVdj7sC5rPbd5ZjvYmTuBMHNBQHFC31gbkFNqAzIN4wVfij0ikL6RH2S2lT6qfowwd0fv/SY2xfy6/BS0C17b42oLfjS/tEHrYzreszwAAAABJRU5ErkJggg==");
$slider-button-vdown-bg-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAApVBMVEUAAAD////////////////////+/v7////+/v7////+/v7////+/v7+/v7////////+/v7+/v7+/v7////+/v7+/v7+/v7+/v7+/v7////+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7////Ss6t3AAAANnRSTlMABAwQFBgcICQoLDA0OEBESExcYGRobHR8gIOHi4+Tl5ujq7O3u7/Dx8vP09fb3+Pn6+/z9/vRpbpFAAABHklEQVR4Ae3TW1PCMBAF4LOFgsj9ooCichcFoVBy/v9P0xGndktS8uT4wPeYmZPZnZPgf7h6EMCfjMF5Ad7CFUFuKvBUj0iQjNvw0jM8BcghLpMXMglwFuCCcMl0gJsb5KrtqAM8tJCje2Q2QDOAizwxAf6aBrAqLmgPcF2GRXVLV4D7Js50YqbBMM30ockjlRiNiMpELVKYU9lWgdIblfcSErcfVBZFfAkmVKIGfrRjKiPBST+zyB2+DakcO0g091TGAgRTKrsaUsprKq9h9mQZQjm7b0/lWZA1MHQyPVi0DnSI6rCqbGi1CuEQzGgxFrgNmWXu4WYp99BAPv18dFsOhblqy4OMdFt51Cc7duGttvMYXwmXHuMrIrj6Q58aX4DtQxE2xQAAAABJRU5ErkJggg==");
$slider-button-vup-bg-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAApVBMVEUAAAD////////////////////+/v7////+/v7////+/v7////+/v7+/v7////////+/v7+/v7+/v7+/v7////+/v7+/v7+/v7+/v7////+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7+/v7////4hhd5AAAANnRSTlMABAgMEBgcICQoLDA0OEBESExUXGBkbHR8gIOHi5Obn6Onq6+zt7u/x8vP09fb3+Pn6+/z9/tWLLgwAAABGElEQVR4Ae3UyXLyMBAE4NYvzPJDDARCyMKShbBAgo1wv/+jhSRVqhojqeRDqnLgu/nQh3GPBhd/mZ7PNSpobMhNA9HSjCd5F5FGBb8Vt4ihprRiBklWtGIG6WQU9i0EDY8sMX34qQkdxvBJlnR61nBqf9Bj24TDwNDr0EOZeqRg7PTuDmtvFHat8v+VHf5/p7ColRuUHV4bCg8KJ2pGIU/xQ91TMAO5hVYxwhf9SmHfhtXNKcz+Aa0dhWVSeknCug5DYaIg6CcKGcTncYgzYzkIRLwDh97BF1glcGpu3YGpgod+cQSKGwTcnQWyKwT1jQys6wizfcE2GGY3AnZHwuzOgcxShImtRuTRte8G1c56bYGKFC5+wyfN5oD6Q24erwAAAABJRU5ErkJggg==");
$slider-limit-bar-horizontal-height: 6px !default;
$slider-limit-bar-top: calc(50% - 3px) !default;
$slider-limit-bar-vertical-width: 6px !default;
$slider-limit-bar-left: calc(50% - 3px) !default;
$slider-limit-bar-color: active-color(--black) !default;
$slider-tick-font-color: normal-color(--black) !default;

$slider-disabled-range-color: disabled-color(--black-300) !default;
$slider-disabled-handle-color: disabled-color(--black-300) !default;
$slider-disabled-track-color: disabled-color(-white-300);

$slider-hover-color: hover-color(--hit-grey) !default;
$slider-handle-hover-border-color: hover-color(--primary) !default;
$slider-icons-font-family: "e-icons";
