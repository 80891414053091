$field-border-width: 1px;
$field-background: #fff !important;
$field-border-radius: 0.75rem !important;

.field {
  background-color: $field-background;
  font-size: 16px;
  font-weight: 500;

  &:focus {
    background-color: $field-background;
    border: 0;
    box-shadow: none;
  }
}

.form-control {
  border-radius: $field-border-radius;
}

.input-group {
  box-sizing: border-box;
  border: $field-border-width solid #e2e1e1;
  padding: 0.15rem;
  border-radius: $field-border-radius;
  background-color: $field-background;

  input {
    @extend .field;
    border: 0 !important;
    outline: none !important;
  }
}

.input-group.warning {
  border-color: transparent;
  box-shadow: 0 0 0 $field-border-width var(--warning-color);
}

.input-group.error {
  border-color: transparent;
  box-shadow: 0 0 0 $field-border-width var(--error-color);
}

.input-group-text {
  background-color: $field-background;
  border: 0;
  border-radius: $field-border-radius;
}

.input-group-prepend {
  border-bottom-left-radius: $field-border-radius;
  border-top-left-radius: $field-border-radius;

  & > .input-group-text {
    border-bottom-left-radius: $field-border-radius;
    border-top-left-radius: $field-border-radius;
  }
}

.input-group-append {
  border-bottom-right-radius: $field-border-radius;
  border-top-right-radius: $field-border-radius;

  & > .input-group-text {
    border-bottom-right-radius: $field-border-radius;
    border-top-right-radius: $field-border-radius;
  }
}

input {
  @extend .field;
}

.text.text-warning {
  display: block;
  font-size: 14px;
  margin-top: 5px;
}
