@mixin alert-variant($background, $border, $color) {
  @include gradient-bg(normal-color($background));
  border-color: normal-color($border);
  color: normal-color($color);

  hr {
    border-top-color: darken-color($background, 5%);
  }

  .alert-link {
    color: darken-color($color, 10%);
  }
}
