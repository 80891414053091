.auth-ui-background {
    flex-direction: column;
    justify-content: center;

    .auth-img {
      padding-top: 0 !important;
      max-width: 300px !important;
    }
}

.right-panel {
    flex-direction: column;
    justify-content: center;
}