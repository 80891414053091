/*! component's theme wise override definitions and variables */
$hscroll-skin: "bootstrap4" !default;
$hscroll-border-type: solid !default;
$hscroll-border-size: 1px !default;
$hscroll-hover-font: hover-color(--primary) !default;
$hscroll-active-font-color: active-color(--primary) !default;
$hscroll-default-bg: normal-color(--white) !default;
$hscroll-nav-nrml-minheight: 38px !default;
$hscroll-nav-bgr-minheight: 50px !default;
$hscroll-mob-nav-nrml-width: 50px !default;
$hscroll-nav-nrml-width: 32px !default;
$hscroll-nav-bgr-width: 38px !default;
$hscroll-nrml-padding: 0 $hscroll-nav-nrml-width !default;
$hscroll-bgr-padding: 0 $hscroll-nav-bgr-width !default;
$hscroll-box-shadow: none !default;
$hscroll-overlay-opacity: 0.5 !default;
$hscroll-overlay-bg: $white !default;
$hscroll-overlay-start: rgba($hscroll-overlay-bg, 0) !default;
$hscroll-overlay-end: rgba($hscroll-overlay-bg, 1) !default;
$hscroll-right-bg: linear-gradient(-270deg, $hscroll-overlay-start 0%, $hscroll-overlay-end 100%) !default;
$hscroll-left-bg:  linear-gradient(-270deg, $hscroll-overlay-end 0%, $hscroll-overlay-start 100%) !default;

$hscroll-device-arrow-box-shadow: -4px 0 8px 0 set-opacity(--border, 0.06) !default;
$hscroll-device-arrow-rtl-box-shadow: 4px 0 8px 0 set-opacity(--border, 0.06) !default;
$hscroll-device-arrow-bg: #f8f8f8 !default;
$hscroll-device-arrow-border-size: 1px !default;
$hscroll-device-arrow-border-color: #ccc !default;
$hscroll-device-arrow-color: #317ab9 !default;
$hscroll-device-arrow-size: 14px !default;
$hscroll-device-arrow-size-bigger: 14px !default;
$hscroll-device-arrow-width: 52px !default;

$hscroll-default-icon-color: #333 !default;
$hscroll-hover-bg: hover-color(--black-700) !default;
$hscroll-press-bg:  active-color(--black-700) !default;
$hscroll-default-border: #ccc !default;
$hscroll-focus-border: 1px focused-color(--black-700) !default;
$hscroll-active-border: 3px solid active-color(--hit-grey) !default;
$hscroll-hover-border: 1px hover-color(--black-700) !default;
$hscroll-hover-border-color: "" !default;
$hscroll-active-box-shadow: none !default;

@mixin hscroll-btn-animation {
  content: "";
}

@mixin hscroll-btn-animation-after {
  content: "";
}
