@import "./ej2-pager";
@import "~@syncfusion/ej2-grids/styles/pager/icons/bootstrap4";
@import "~@syncfusion/ej2-grids/styles/pager/all";
@import "~@syncfusion/ej2-angular-grids/styles/excel-filter/bootstrap4";

$grid-content-padding: 0 !default;
$grid-headecell-height: 80px !default;
$grid-font-size: 1rem !default;
$grid-icon-color: var(--primary-color) !default;
$grid-command-icon-color: var(--white-color) !default;
$grid-border-type: solid !default;
$grid-vertical-border-size: 1px !default;
$grid-horizontal-border-size: 1px !default;
$grid-font-family: $font-family !default;

$grid-header-font-size: 16px !default;
$grid-bigger-header-font-size: 1rem !default;
$grid-header-icon-color: var(--white-color) !default;
$grid-sorted-header-color: var(--black-700-color) !default;

$grid-popup-bg-color: $content-popup-bg !default;

$grid-sortnumber-bg-color: #bbbdc0 !default;
$grid-sortnumber-color: var(--black) !default;
$grid-table-background-color: transparent !default;

$grid-row-selection-color: #333 !default;
$grid-cell-selection-color: #333 !default;

$grid-drag-clone-color: var(--black) !default;

$group-droparea-hover-color: var(--black-color) !default;

$group-row-bg-stcolor: #ececec !default;
$grid-group-broder-color:  $gray-600 !default;
$grid-group-hover-broder-color: $gray-100 !default;
$grid-group-text-line-height: 19px !default;
$grid-group-rtl-right-arrow-icon: "e854" !default;
$filter-text-color: #333 !default;
$grid-wrap-line-height: 18px !default;
$grid-wrap-margin-bottom: 2px !default;
$grid-wrap-margin-top: 0 !default;

$grid-header-bottom-padding: 0 !default;
$grid-headerconent-font-opacity: 1 !default;
$grid-conent-font-opacity: 1 !default;
$grid-filtercell-line-height: 27px !default;
$grid-group-captioncell-line-height: 14px !default;
$group-text-padding-right: 3px !default;
$group-sorticon-margin-right: 0 !default;
$group-ungroupicon-padding: 5px 3px !default;
$grid-rtl-group-sorticon-margin-left: 0 !default;
$grid-ungroup-icon-opactiy: 1 !default;
$grid-default-color: "" !default;
$grid-stackedheadercell-border-size: 0 0 1px 0 !default;
$grid-stackedheadercell-botttom-padding: 13px !default;
$group-text-align: center !default;
$grid-right-border: 1px !default;
$grid-hide-icon-content: "e825" !default;
$grid-filterbar-cancel-icon-content: "e842" !default;
$grid-searchbar-cancel-icon-content: "e825" !default;
$grid-headercelldiv-line-height: 21px !default;
$grid-bigger-headercelldiv-line-height: 24px !default;
$grid-filterbarcell-text-indent: 1px !default;
$grid-groupdroparea-rtl-text-align: center !default;
$grid-rtl-th-firstcell-border-left: 1px !default;
$group-ungroupicon-font-size: 11px !default;
$grid-checkbox-cell-padding-top-bottom: 8px !default;
$grid-rowselect-text-indent: 1px !default;
$grid-toolbar-border-width: 1px solid !default;
$grid-filterdiv-padding: 0 !default;
$grid-headercelldiv-height: 29px !default;
$grid-filterbarcell-input-height: 26px !default;
$grid-sortnumber-border-radius: 65% !default;
$grid-textwrap-srotnumber-rightalign-margin: 3px 2px 0 5px !default;
$grid-textwrap-srotnumber-margin: 3px 5px 0 2px !default;
$grid-textwrap-sorticon-margin: -9px 10px !default;
$grid-columnchooser-header-padding: 11px 18px 15px !default;
$grid-columnchooser-toolbar-button-padding: 0 12px !default;
$grid-columnchooser-ul-padding: 0 !default;
$grid-column-chooser-footdiv: 1px 0 0 !default;
$grid-column-chosser-searchdiv-opactiy: 0.6 !default;
$grid-column-chosser-searchdiv-fopactiy: 1 !default;
$grid-columnchooser-search-border-color: var(-white-300-color) !default;
$grid-columnchooser-search-border-fcolor: #ff4081 !default;
$grid-columnchooser-footer-border-opacity: 1 !default;
$grid-columnchooser-toolbar-div-padding: 0 10px !default;
$grid-columnchooser-toolbar-icon-line-height: 1.9 !default;
$grid-columnchooser-toolbar-icon-font-size: 15px !default;
$grid-columnchooser-btn-font-size: 9px !default;
$grid-columnchooser-btn-line-height: 3 !default;
$grid-columnchooser-toolbardiv-padding: 3px 0 0 !default;
$grid-columnchooser-toolbardiv-margin-top: 0 !default;
$grid-columnchooser-toolbar-icon-vertical-align: middle !default;
$grid-columnchooser-cancel-icon-content: "e953" !default;
$grid-columnchooser-cancel-icon-content-f-opactiy: 1 !default;
$grid-columnchooser-cancel-btn-color: rgba(0, 0, 0, 0.87) !default;
$grid-columnchooser-hover-bg-color: #000 !default;
$grid-columnchooser-active-bg-color: #bdbdbd !default;
$grid-columnchooser-toolbar-div-margin-top: -1px !default;
$grid-columnchooser-searchbar-cancel-icon-content: "e7a7" !default;
$grid-columnchooser-input-border: 0 !default;
$grid-columnchooser-input-padding-top: 6px !default;
$grid-column-chooser-search-icon-padding: 0 2px !default;
$grid-column-chooser-cancel-icon-padding: 5px 2px 6px !default;
$grid-columnchooser-input-padding-bottom: 6px !default;
$grid-columnchooser-input-padding-left: 4px !default;
$grid-columnchooser-ul-margin: 13px 0 !default;
$grid-column-chooser-dlg-footer-padding: 8px !default;
$grid-rtl-bigger-sortnumber-margin: 6px 0 0 6px !default;
$grid-rtl-group-text-marign-right: 0 !default;
$grid-rtl-group-text-margin-right: 0 !default;
$grid-rtl-textwrap-srotnumber-margin: 3px 5px 0 2px !default;
$grid-rtl-headercell-both-border-width:  0 1px 1px 0 !default;
$grid-rtl-textwrap-srotnumber-rightalign-margin: 3px 5px 0 2px !default;
$grid-rtl-stackedheadercell-border-size: 0 1px 1px 0 !default;
$grid-rtl-stackedhader-firstcell-left-border-size: 0 !default;
$grid-rtl-stackedhader-firstcell-right-border-size: 1px !default;
$grid-rtl-bothlines-stackedhader-firstcell-left-border-size: 1px !default;
$grid-rtl-bothlines-stackedhader-firstcell-right-border-size: 0 !default;
$grid-bigger-headercell-bootom-padding: round($grid-header-bottom-padding/0.75) !default;
$grid-bigger-grightarrow-icon-font-size: 12px !default;
$group-bigger-sorticon-margin-right: 0 !default;
$grid-bigger-groupdroparea-padding: 22px 24px !default;
$grid-bigger-group-headercell-height: 30px !default;
$grid-bigger-ungroup-button-font-size: 14px !default;
$grid-bigger-group-text-line-height: 3px !default;
$grid-bigger-groupcaption-line-height: 32px !default;
$grid-bigger-rtl-group-headercell-margin: 14px 24px 0 0 !default;
$grid-bigger-rtl-group-headercell-padding: 4px 6px 4px 9px !default;
$grid-bigger-rtl-ungroup-icon-margin-top: 6px !default;
$grid-bigger-rtl-ungroup-icon-margin-right: -15px !default;
$grid-bigger-textwrap-srotnumber-rightalign-margin: 3px 2px 0 5px !default;
$grid-bigger-textwrap-sorticon-rightalign-margin: -9px 5px !default;
$grid-bigger-textwrap-sorticon-margin: -9px 10px !default;
$grid-bigger-textwrap-srotnumber-margin: 3px 5px 0 2px !default;
$grid-bigger-columnchooser-header-padding: 24px 24px 38px !default;
$grid-bigger-columnchooser-ul-padding: 0 !default;
$grid-bigger-columnchooser-li-padding: 8px 0 !default;
$grid-bigger-columnchooser-toolbar-div-margin-top: 0 !default;
$grid-device-headercell-padding: 0 12px $grid-header-bottom-padding !default;
$grid-device-headercell-fistchild-padding: 0 12px $grid-header-bottom-padding 16px !default;
$grid-device-headercell-lastchild-padding: 0 16px $grid-header-bottom-padding 12px !default;
$grid-device-rowcell-padding: $grid-content-padding 12px !default;
$grid-device-rowcell-firstchild-padding: $grid-content-padding 12px $grid-content-padding 16px !default;
$grid-device-rowcell-lastchild-padding: $grid-content-padding 16px $grid-content-padding 12px !default;
$grid-device-filterbarcell-padding: $grid-content-padding 12px !default;
$grid-device-filterbarcell-firstchild-padding: $grid-content-padding 12px $grid-content-padding 16px !default;
$grid-device-filterbarcell-lastchild-padding: $grid-content-padding 16px $grid-content-padding 12px !default;
$grid-device-gridheader-row-span-padding: 20px !default;
$grid-edit-input-bigger-padding-bottom: 0 !default;
$grid-edit-input-bigger-padding-top: 0 !default;
$grid-edit-input-bigger-device-padding-bottom: 0 !default;
$grid-edit-input-bigger-device-padding-top: 0 !default;
$grid-edit-icon: "e81e" !default;
$grid-add-icon: "e7f9" !default;
$grid-print-icon: "e813" !default;
$grid-delete-icon: "e84e" !default;
$frozen-border: 2px solid !default;
$grid-fltrdiv-float: right !default;
$grid-fltrdiv-text-align: right !default;
$grid-fltrdiv-padding: 6px !default;
$grid-rtl-fltrdiv-padding: 6px !default;
$grid-flmenu-padding: 14px 0 0 !default;
$grid-flmenu-boolean-content-padding: 18px 18px 0 !default;
$grid-flmenu-boolean-button-width: 110px !default;
$grid-flmenu-device-padding: 24px 0 0 !default;
$grid-rtlfltrdiv-float: left !default;
$grid-checkbox-wrapper-height: 20px !default;
$grid-checkbox-wrapper-line-height: 20px !default;
$grid-rtl-headercelldiv-fltricon-padding: 0 1.6em 0 10px !default;
$grid-sortnumber-filtericon-rightalign-margin: 8px 2px 0 5px !default;
$grid-xl-match-margin-left: 6px !default;
$grid-bigger-xl-match-margin-left: 8px !default;
$grid-rows-bg-color: transparent !default;
$grid-resize-border-width: 1px !default;
$grid-xl-rtl-radio-padding-left: 0 !default;
$grid-xl-rtl-radio-padding-right: 25px !default;
$grid-filterbarcell-input-padding: 0 26px 0 10px !default;
$grid-icon-default-color: var(--black-700-color) !default;
$grid-resize-helper-color: $primary !default;
$grid-header-first-last-cell-padding: 12px !default;
$grid-header-bg-color: var(--white-200-color) !default;
$grid-header-color: var(--primary-color) !default;
$grid-headercell-both-border-width: 0 0 0 1px !default;
$grid-headercelldiv-margin: -5px !default;
$grid-icon-font-size: 14px !default;
$grid-border-size: 1px !default;
$grid-header-padding: 12px !default;
$grid-header-wrap-sortfilter-div-margin: -30px 10px !default;
$grid-rtl-textwrap-sorticon-margin: -30px 5px !default;
$grid-rtl-textwrap-sorticon-rightalign-margin: -30px 17px !default;
$grid-headercelldiv-right-align-padding: 0 5px 0 !default;
$grid-headercell-line-height: 80px !default;
$grid-header-font-weight: 600 !default;
$grid-filtercell-both-border-width: 0 1px 1px 0 !default;
$grid-header-border-color: transparent !default;
$grid-rowcell-line-height: 21px !default;
$grid-content-font-color: var(--black-color) !default;
$grid-hover-content-font-color: var(--black-hover-color) !default;
$grid-cell-border-color: var(--black-300-color) !default;
$grid-content-bg-color: var(--white-color) !default;
$grid-row-selection-bg-color: var(--white-dark-color) !default;
$grid-cell-selection-bg-color: var(--white-dark-color) !default;
$grid-hover-bg-color: var(--white-dark-color) !default;
$grid-altrow-bg-color: #f9f9f9 !default;
$grid-content-font-size: 14px !default;
$grid-header-height: 21px !default;
$grid-bigger-header-height: 24px !default;
$grid-rowcell-broder-width: 0 0 1px !default;
$grid-content-right-padding: 12px !default;
$grid-first-last-rowcell-padding: 12px !default;
$grid-toolbar-search-margin-bottom: 0 !default;
$grid-toolbar-search-width: 230px !default;
$grid-toolbar-search-icon-min-width: 32px !default;
$grid-toolbar-search-wrapper-padding-bottom: 3px !default;
$grid-toolbar-search-wrapper-padding-top: 3px !default;
$grid-bigger-toolbar-search-icon-min-width: 38px !default;
$grid-bigger-toolbar-search-wrapper-padding-bottom: 5px !default;
$grid-bigger-toolbar-search-wrapper-padding-top: 5px !default;
$grid-toolbar-search-icon-content: "e961" !default;
$grid-summary-cell-font-size: 14px !default;
$grid-filterbarcell-border-width: 2px 0 0 !default;
$grid-filterbarcell-first-last-child-padding-left: 2px !default;
$grid-filterbarcell-padding: 1px 2px !default;
$grid-filterbar-input-border-width: 0 !default;
$grid-border-radius: 16px !default;
$grid-bottom-border-size: 0 !default;
$group-droparea-bg-color: $gray-100 !default;
$group-header-bg-color: $gray-600 !default;
$group-row-bg-color: $gray-100 !default;
$grid-summary-template-row-bg-color: "" !default;
$grid-summary-row-bg-color: var(--white-color) !default;
$grid-sortdiv-width: 16px !default;
$grid-sortdiv-height: 16px !default;
$grid-sortnumber-margin: 2px 8px 0 8px !default;
$grid-sortnumber-rightalign-margin: 2px 8px 0 8px !default;
$grid-sortnumer-line-height: 16px !default;
$grid-sortdiv-right-align-margin: -28px -9px !default;
$grid-sortdiv-margin:  -28px -10px !default;
$grid-sortdiv-padding: 15px 0 !default;
$grid-ungroup-button-line-height: 17px !default;
$grid-ungroup-button-font-size: 10px !default;
$grid-grouparea-first-cell-margin: 0 6px 0 12px !default;
$grid-grouparea-font-size: 14px !default;
$grid-grouparea-margin: 13px 0 0 8px !default;
$grid-group-headercell-line-height: 0.875rem !default;
$grid-grouped-headercell-padding: 3px 0 !default;
$grid-grouped-headercell-span-padding: 0 8px !default;
$group-droparea-hover-bg-color: #f5f5f5 !default;
$group-header-hover-bg-color: var(--white-100-color) !default;
$grid-grouped-padding: 0 0 6px !default;
$group-area-padding: 15px 0 !default;
$grid-grouptext-margin-right: 0 !default;
$group-header-color: var(--white-color) !default;
$group-grouped-droparea-bg-color: $content-bg !default;
$group-border-width: 1px !default;
$grouped-text-font-size: 12px !default;
$group-header-border-radius: 16px !default;
$grid-hover-icon-color: var(--white-color) !default;
$grid-headecell-span-height: 24px !default;
$grid-drag-clone-opacity: 0.4 !default;
$grouped-droparea-bg-color: #f5f5f5 !default;
$grid-drag-clone-border-color: var(-white-300-color) !default;
$grid-drag-clone-bg-color: $content-bg !default;
$group-caption-font-color: var(--black-700-color) !default;
$grid-group-down-arrow-icon-font-size: 10px !default;
$group-expand-icon-text-indent: 11px !default;
$group-droparea-color: $gray-600 !default;
$group-sorticon-font-size: 10px !default;
$grid-rowcell-both-border-width: 0 0 0 1px !default;
$grid-indentcell-border-width: 0 1px 0 0 !default;
$grid-group-right-arrow-icon-font-size: 10px !default;
$grid-group-unpgroupicon-margin-top: 1px !default;
$grid-summary-cell-line-height: 21px !default;
$grid-batch-updated-bg-color: #dcfacf !default;
$grid-val-error-color: #f44336 !default;
$grid-val-error-bg-color: #fcdbe4 !default;
$grid-edit-input-padding-bottom: 1px !default;
$grid-edit-input-padding-top: 2px !default;
$grid-edit-input-margin: 2px !default;
$grid-edit-cell-padding: 2px !default;
$grid-group-height: 52px !default;
$grid-ungroup-rtl-padding-top: 1px !default;
$grid-rtl-group-headercell-margin-right: 8px !default;
$grid-rtl-group-headercell-margin-left: 0 !default;
$grid-rtl-sortnumber-margin: 1px 0 0 12px !default;
$grid-rtl-sortnumber-rightalign-margin: 1px 0 0 20px !default;
$grid-rtl-sortcelldiv-margin: -29px 10px !default;
$grid-rtl-sortcelldiv-right-align-margin: -29px 10px !default;
$grid-rtl-bigger-sortcelldiv-right-align-margin: -31px 22px !default;
$grid-rtl-bigger-sortcelldiv-margin: -31px 5px !default;
$grid-rtl-grouped-headercell-padding: 3px 0 !default;
$grid-rtl-group-text-margin-left: 0 !default;
$grid-rtl-group-sorticon-margin-right: -10px !default;
$grid-rtl-headercell-border-width: 0 !default;
$grid-rtl-headercelldiv-padding: 0 0.4em 0 1.8em !default;
$grid-ungroup-rtl-padding-right: -8px !default;
$grid-headercelldiv-padding: 0 5px 0 !default;
$grid-headercell-div-padding: 0 0.4em !default;
$grid-group-text-width: auto !default;
$grid-grouptopleftcell-border-top: 2px solid !default;
$grid-group-caption-font-size: 14px !default;
$group-sorticon-margin-left: -8px !default;
$group-sorticon-margin-top: -3px !default;
$grid-bigger-headercell-line-height: 50px !default;
$grid-bigger-rowcell-line-height: 24px !default;
$grid-bigger-font-size: 16px !default;
$group-unpgrouicon-margin-left: -8px !default;
$grid-group-clone-text-align: left !default;
$grid-clone-padding: 2px 8px 1px !default;
$grid-bigger-first-last-rowcell-padding: 12px !default;
$grid-bigger-content-padding: 12px !default;
$grid-bigger-group-line-height: round($grid-group-height*1.25) !default;
$grid-bigger-content-right-padding: 12px !default;
$grid-bigger-headercell-font-size: 16px !default;
$grid-bigger-content-font-size: 16px !default;
$grid-filterbar-border-radius: 4px !default;
$grid-bigger-sorticon-margin-top: -1px !default;
$grid-header-border-width: 0 !default;
$grid-bigger-groupedcell-padding: 0 0 8px !default;
$grid-bigger-group-headercell-margin: 15px 0 0 8px !default;
$grid-bigger-group-headercell-padding: 6px 0 !default;
$grid-bigger-grouptext-marign: 0 !default;
$grid-bigger-headercell-padding: 12px !default;
$grid-ungroup-icon-content: "e842" !default;
$grid-group-right-arrow-icon: "e826" !default;
$grid-group-down-arrow-icon: "e853" !default;
$grid-grouped-sort-icon: "e234" !default;
$grid-grouped-sort-dec-icon: "e239" !default;
$grid-descending-icon-content: "e239" !default;
$grid-bigger-icons-font-size: 12px !default;
$group-collapse-icon-text-indent: 11px !default;
$grid-bigger-grouparea-font-size: 16px !default;
$grid-bigger-grouped-headercell-border-radius: 16px !default;
$grid-bigger-groupedcell-icons-marign-left: -8px !default;
$grid-bigger-ungroupbutton-icons-font-size: 12px !default;
$grid-bigger-groupcell-span-padding: 8px 4px 8px 12px !default;
$grid-bigger-ungroupbutton-icon-margin-left: -7px !default;
$grid-bigger-ungroupbutton-icon-margin-top: -1px !default;
$grid-bigger-groupcell-span-line-height: 4px !default;
$grid-bigger-gdownarrow-icon-font-size: 10px !default;
$grid-gd-clone-border-radius: 4px !default;
$grid-bigger-group-clone-broder-radius: 6px !default;
$grid-gd-clone-padding: 7px 4px 4px !default;
$grid-bigger-group-clone-padding: 10px 6px 6px !default;
$grid-grey-e6: #e6e6e6 !default;
$grid-columnchooser-footer-border-color: var(-white-300-color) !default;
$grid-column-chooser-searchdiv: 1px !default;
$grid-bigger-columnchooser-toolbartxt-padding: 0 !default;
$grid-columnchooser-toolbartxt-padding: 0 !default;
$grid-bigger-header-first-last-cell-padding: 12px !default;
$grid-columnchooser-content-padding: 14px 14px 14px 14px !default;
$grid-bigger-columnchooser-content-padding: 16px 16px 16px 16px !default;
$grid-bigger-cc-checkbox-padding-left: 8px !default;
$grid-bigger-searchdiv-padding-left: 4px !default;
$grid-cc-checkbox-padding-left: 8px !default;
$grid-column-chooser-searchdiv-left: 1px !default;
$grid-columnchooser-content-margin: 40px 0 0 !default;
$grid-bigger-columnchooser-content-margin: 40px 0 0 !default;
$grid-bigger-columnchooser-btn-font-size: 12px !default;
$grid-columnchooser-search-icon-content: "e961" !default;
$grid-bigger-sortdiv-rightalign-margin: -32px -2px !default;
$grid-bigger-sortdiv-margin: -31px -2px !default;
$grid-bigger-header-icons-font-size: 16px !default;
$grid-bigger-grouptext-width: auto !default;
$grid-edit-input-margin-top: 2px !default;
$grid-td-checkbox-margin-top: 0 !default;
$grid-filterbarcell-text-input: 32px !default;
$grid-bigger-filterbarcell-text-input: 39px !default;
$grid-columnchooser-li-padding: 7px 0 !default;
$grid-edit-input-bigger-margin: 2px !default;
$grid-device-ungroupbutton-line-height: 37px !default;
$grid-device-groupheadercell-span-line-height: 33px !default;
$frozen-border-color: var(-white-300-color) !default;
$grid-edit-checkbox-bigger-padding-left: 8px !default;
$grid-edit-checkbox-bigger-padding-top: 8px !default;
$grid-cell-focus-shadow: 0 0 0 1px #66afe9 inset !default;
$grid-headercelldiv-fltricon-margin: -5px 15px -5px -12px !default;
$grid-headercelldiv-rightalign-fltricon-margin: -5px 15px -5px 0 !default;
$grid-rtl-headercelldiv-fltricon-margin: -5px !default;
$grid-rtl-headercelldiv-rightalign-fltricon-margin: -5px -12px -5px 0 !default;
$grid-filtered-color: #317ab9 !default;
$grid-autofill-color: #317ab9 !default;
$grid-e-firstrow-dragborder-bcolor: #317ab9 !default;
$grid-filtermenu-icon: "e946" !default;
$grid-checkbox-padding-topbottom: 8px !default;
$grid-checkbox-content-padding-left: 6px !default;
$grid-rtl-checkbox-content-padding-left: 14px !default;
$grid-bigger-checkbox-padding-topbottom: 9px !default;
$grid-bigger-checkbox-content-padding-left: 7px !default;
$grid-rtl-bigger-checkbox-content-padding-left: 16px !default;
$grid-fltrdiv-margin: -19px -7px !default;
$grid-rtl-fltrdiv-margin: -19px 0 -20px -36px !default;
$grid-rtl-bigger-fltrdiv-margin: -20px 0 -18px -52px !default;
$grid-bigger-rtl-headercelldiv-fltricon-padding: -5px -10px -5px 0 !default;
$grid-bigger-fltrdiv-margin: -22px -7px !default;
$grid-icon-save: "e964" !default;
$grid-sortdiv-filtericon-margin: -28px 10px -29px 0 !default;
$grid-sortdiv-filtericon-rightalign-margin: -28px -11px -5px -5px !default;
$grid-rtl-sortdiv-filtericon-rightalign-margin: -29px 0 0 10px !default;
$grid-rtl-sortdiv-filtericon-margin: -29px 0 0 10px !default;
$grid-bigger-fltricon-sortdiv-rightalign-margin: -32px -20px 0 2px !default;
$grid-bigger-fltricon-sortdiv-margin: -32px 18px -31px 0 !default;
$grid-rtl-bigger-fltricon-sortdiv-margin: -29px 0 0 14px !default;
$grid-rtl-bigger-fltricon-sortdiv-rightalign-margin: -29px 0 0 33px !default;
$grid-shadow: #000 !default;
$grid-column-menu-sub-menu-box-shadow: 0 6px 12px rgba($grid-shadow, 0.175) !default;
$grid-bigger-checkbox-filter-min-height: 360px !default;
$grid-checkbox-filter-min-height: 348px !default;
$grid-column-menu-margin: -20px !default;
$grid-textwrap-column-menu-margin: -19px -20px -20px -20px !default;
$grid-bigger-column-menu-margin: 0 -3px !default;
$grid-column-menu-top-touch: 10px !default;
$grid-excelmenu-filter-icon: "e249" !default;
$grid-excelmenu-clear-filter-icon: "e246" !default;
$grid-selection-font-weight: normal !default;
$group-hover-border: $group-border-width !default;
$grid-grouped-headercell-hover-padding: $grid-grouped-headercell-padding !default;
$grid-drag-clone-font-weight: $grid-header-font-weight !default;
$grid-content-batch-font-color: $grid-content-font-color !default;
$grid-bigger-group-headercell-hover-padding: $grid-bigger-group-headercell-padding !default;

$grid-header-wrap-rightalign-sortfilter-div-margin: -30px -5px !default;
$grid-header-wrap-filtericon-sortfilter-div-margin: -24px 28px -29px 0 !default;
$grid-bigger-wrap-sortdiv-margin: -31px 8px !default;
$grid-bigger-wrap-rightalign-sortdiv-margin: -30px -5px !default;
$grid-bigger-wrap-rightalign-fltricon-sortdiv-margin: -26px -20px 0 0 !default;
$grid-bigger-wrap-fltricon-sortdiv-margin: -30px 28px -31px 0 !default;
$grid-header-wrap-filtericon-rightalign-sortfilter-div-margin: -24px 0 !default;
$grid-drag-font-size: 12px !default;
$grid-drag-icon-font-weight: bold !default;
$grid-drag-move-icon: 10px !default;
$grid-dragdrop-padding: 2px !default;
$grid-dragborder-box-shadow: 0 2px 0 0 #317ab9 !default;
$grid-dragborder-position: relative !default;
$grid-dragborder-z-index: 5 !default;
$grid-dragicon-cell-border: 1px 0 0 0 !default;
$grid-border-bottom-width: 1px !default;
$grid-dragcell-border-style: solid !default;
$grid-dragcell-border-color: var(-white-300-color) !default;
$grid-row-drag-header-top-border: 1px solid #ddd !default;
$grid-cloneproperties-table-td-border: 1px solid !default;
$grid-vertical-rowdragdrop-border-right: 1px solid #ddd !default;
$grid-drap-drop-icon-color: $gray-600 !default;
$grid-clone-dropitemscount-bg-color: #317ab9 !default;
$grid-clone-dropitemscount-color: #333 !default;
$grid-clone-row-border-color: var(-white-300-color) !default;
$grid-dropitemscount-color: var(--white-color) !default;
$grid-grey-base: #000 !default;
$grid-clone-prop-box-shadow: 0 6px 12px rgba($grid-grey-base, 0.175) !default;
$grid-drag-icon-opacity: 0.54 !default;
$grid-clone-dropitemscount-border: #ddd !default;
$grid-clone-dragdrop-bg-color: $grid-table-background-color !default;
$grid-group-clone-box-shadow: 0 1px 4px set-opacity(var(--black-color), 40) !default;
$grid-unboundcelldiv-margin: 0 4px !default;
$grid-bigger-flmenu-padding: 16px 0 0 !default;
$grid-rowcell-wrap-height: 21px !default;
$grid-rowcell-wrap-max-height: 36px !default;
$grid-bigger-sortnumber-rightalign-margin: 2px 0 0 19px !default;
$grid-bigger-columnmenu-bottom: 13px !default;
$grid-bigger-sortnumber-margin: 3px 19px 0 2px !default;
$grid-rtl-searchclear: 0 !default;
$grid-column-chosser-searchdiv-top: -41px !default;
$grid-reorderarrow-font-size: 8px !default;
$grid-bigger-wrap-line-height: 24px !default;
$grid-bigger-clone-padding: 2px 12px 1px !default;
$grouped-bigger-text-font-size: 14px !default;
$grid-bigger-ungroupbutton-icon-margin-right: 8px !default;
$grid-bigger-font-size: 16px !default;
$grid-rtl-bigger-rightalign-fltrdiv-margin: -20px 0 -18px -72px !default;
$grid-bigger-rtl-ungroup-icon-margin-left: -13px !default;
$grid-grouparea-font-weight: normal !default;
$grid-ungroup-rtl-padding-left: -2px !default;
$grid-rtl-group-sorticon-margin-left: -5px !default;
$grid-rtl-column-menu-left: 23px !default;
$grid-checkboxfiltertext-width: 150px !default;
$grid-rtl-column-menu-margin: -19px -18px -18px -22px !default;
$grid-rtl-textwrap-column-menu-margin: -17px -13px -23px -25px !default;

// group animator
$grid-group-animator-bigger-cell-margin: 15px 0 15px 0 !default;
$grid-group-animator-bigger-drop-height: 54px;
$grid-animator-bigger-area-margin: 22px !default;
$grid-group-animator-drop-height: 48px;
$grid-group-animator-cell-margin: 12px 0 12px 0 !default;
$grid-animtor-border-bottom-width: 1px !default;
$grid-animator-area-padding: 0 !default;
$grid-animtor-area-border-width: 1px !default;
$grid-animator-area-border: 0 !default;
$grid-animator-area-margin: 9px !default;
$grid-animator-chips-display: inline-block !default;
$grid-animator-drag-icon: 24px !default;
$grid-animator-drag-icon-line-height: 19px !default;
$grid-animator-margin-left: 8px !default;
$grid-animator-padding-bottom: 10px;
$grid-animator-line-height: 21px !default;
$grid-animator-line-height-next: 32px !default;
$grid-animator-font-size-drag: 12px !default;
$grid-animator-opacity: 1 !default;
$grid-animator-drag-margin-left: 8px !default;
$grid-animator-font-icon-color: #333;
$grid-animator-first-group: 12px 0 12px 12px !default;
$grid-animator-rtl-first-group: 12px 12px 12px 0 !default;
$grid-animator-margin-top-rtl: 15px !default;
$grid-filterbar-dropdown: 22px !default;

@import "./ej2-grid/theme";
@import "./ej2-grid/layout";
