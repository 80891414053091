h1, .h1 {
  color: var(--black-color);
  font-size: $h1-font-size;
  font-style: normal;
  font-weight: bold;
  line-height: $h1-line-height;
}

h2, .h2 {
  color: var(--black-color);
  font-size: $h2-font-size;
  font-style: normal;
  font-weight: bold;
  line-height: $h2-line-height;
}

h3, .h3 {
  color: var(--black-color);
  font-size: $h3-font-size;
  font-style: normal;
  font-weight: bold;
  line-height: $h3-line-height;
}


h4, .h4 {
  color: var(--black-color);
  font-size: $h4-font-size;
  font-style: normal;
  font-weight: bold;
  line-height: $h4-line-height;
}

h5, .h5 {
  color: var(--black-color);
  font-size: $h5-font-size;
  font-style: normal;
  font-weight: bold;
  line-height: $h5-line-height;
}

h6, .h6 {
  color: var(--black-color);
  font-size: $h6-font-size;
  font-style: normal;
  font-weight: bold;
  line-height: $h6-line-height;
}
