$input-skin-name: "bootstrap4" !default;
$zero-value: 0 !default;
$one-value: 0.0625rem !default;
$border-type: solid !default;
$border-size: 0 !default;
$hover-border-color: hover-color(--black) !default;
$pressed-border-color: focused-color(--black) !default;
$input-child-padding-bottom: $zero-value !default;
$input-child-small-bigger-padding-bottom: $zero-value !default;
$input-child-small-padding-bottom: $zero-value !default;
$input-child-bigger-padding-bottom: $zero-value !default;
$input-group-full-border: $border-size $border-type !default;
$input-child-small-bigger-padding-top: $zero-value !default;
$input-child-small-padding-top: $zero-value !default;
$input-child-padding-top: $zero-value !default;
$input-child-bigger-padding-top: $zero-value !default;
$textarea-padding: 4px 8px 4px 8px !default;
$textarea-bigger-padding: 6px 0.75rem 6px 0.75rem !default;
$textarea-small-padding: 4px 8px 4px 8px !default;
$textarea-bigger-small-padding: 4px 8px 4px 8px !default;
$textarea-min-height: 2rem !default;
$textarea-bigger-min-height: 2.25rem !default;
$textarea-bigger-small-min-height: 2rem !default;
$textarea-small-min-height: 1.625rem !default;
$input-text-indent: 8px !default;
$input-bigger-text-indent: 0.75rem !default;
$input-small-text-indent: 8px !default;
$input-small-bigger-text-indent: 8px !default;
$input-bg-color: $content-bg !default;
$input-box-border-focus: normal-color(--secondary) !default;
$input-success-color: normal-color(--success) !default;
$input-warning-color:  normal-color(--warning) !default;
$input-error-color: normal-color(--warning) !default;
$input-disable-bg-color: disabled-color(--white) !default;
$input-disable-font-color: disabled-color(--black-300) !default;
$input-font-size: 0.875rem !default;
$input-bigger-font-size: 0.875rem !default;
$input-small-font-size: 0.75rem !default;
$input-bigger-small-font-size: 0.875rem !default;
$input-box-border-radius: 2rem !default;
$input-group-icon-border-radius: 2rem !default;
$input-group-child-border-width: $one-value !default;
$input-padding: $zero-value !default;
$float-input-padding: $zero-value !default;
$float-label-padding: 10px !default;
$float-input-wrap-padding-top: $zero-value !default;
$input-padding-bottom: $zero-value !default;
$input-focus-padding-bottom: $zero-value !default;
$input-clear-icon: 10px !default;
$input-bigger-clear-icon: 0.75rem !default;
$input-small-clear-icon: 8px !default;
$input-bigger-small-clear-icon: 0.75rem !default;
$input-clear-icon-padding: $zero-value !default;
$input-clear-icon-position: center !default;
$input-bigger-padding: $zero-value !default;
$float-input-bigger-padding: $zero-value !default;
$float-input-bigger-wrap-padding-top: $zero-value !default;
$input-bigger-padding-bottom: $zero-value !default;
$input-focus-bigger-padding-bottom: $zero-value !default;
$input-child-padding: $zero-value !default;
$input-child-min-height: 1rem !default;
$input-child-min-width: 23px !default;
$input-bigger-child-padding: $zero-value !default;
$input-bigger-child-min-height: 2.25rem !default;
$input-bigger-child-min-width: 33px !default;
$input-margin-bottom: $zero-value !default;
$input-bigger-margin-bottom: $zero-value !default;
$input-small-margin-bottom: $zero-value !default;
$input-small-bigger-margin-bottom: $zero-value !default;
$input-margin-top: 1.5rem !default;
$input-bigger-margin-top: 1.5rem !default;
$input-small-margin-top: 1.25rem !default;
$input-small-bigger-margin-top: 1.5rem !default;
$input-small-padding: $zero-value !default;
$float-input-small-padding: $zero-value !default;
$float-input-small-wrap-padding-top: $zero-value !default;
$input-small-padding-bottom: $zero-value !default;
$input-focus-small-padding-bottom: $zero-value !default;
$input-bigger-small-padding: $zero-value !default;
$float-input-bigger-small-padding: $zero-value !default;
$float-input-bigger-small-wrap-padding-top: $zero-value !default;
$input-bigger-small-padding-bottom: $zero-value !default;
$input-focus-bigger-small-padding-bottom: $zero-value !default;
$input-bigger-small-child-padding: $zero-value !default;
$input-bigger-small-child-min-height: 2.125rem !default;
$input-bigger-small-child-min-width: 2.125rem !default;
$input-small-child-padding: $zero-value !default;
$input-small-child-min-height: 1.375rem !default;
$input-small-child-min-width: 23px !default;
$input-font-family: $font-family !default;
$input-font-style: normal !default;
$input-font-normal: normal !default;
$input-border: $one-value $border-type !default;
$float-input-border: $one-value $border-type !default;
$input-box-border-width: $zero-value !default;
$float-input-border-width: $one-value !default;
$input-focus-border-width: $one-value !default;
$input-box-border-color: normal-color(--primary) !default;
$input-font-color: normal-color(--black) !default;
$input-icon-font-color: normal-color(--black) !default;
$input-clear-icon-color: set-opacity(--black, 0.5) !default;
$input-clear-icon-hover-color: set-opacity(--black, 0.4) !default;
$input-error-color: normal-color(--warning) !default;
$input-accent:  $content-bg !default;
$input-placeholder: normal-color(--black-700) !default;
$input-active-border-color: $input-box-border-focus !default;
$input-group-active-border-color: normal-color(--primary) !default;
$input-disable-border-type: solid !default;
$input-disable-border-color: disabled-color(-white-300) !default;
$input-disabled-group-bg-color: disabled-color(--white-100) !default;
$input-border-left-width: $one-value !default;
$input-group-active-border: normal-color(--primary) !default;
$input-group-border: 0.0625rem $border-type !default;
$input-group-border-width: $zero-value !default;
$input-group-border-radius: $zero-value !default;
$input-group-pressed-bg: normal-color(--black) !default;
$input-hover-bg-color: hover-color(--black) !default;
$input-valid-group-border-width: 0.125rem !default;
$input-header-font-color: normal-color(--black) !default;
$input-child-margin-bottom: $zero-value !default;
$input-child-margin-top: $zero-value !default;
$float-input-child-margin-top: $zero-value !default;
$input-child-margin-right: $zero-value !default;
$input-child-small-bigger-margin-bottom: $zero-value !default;
$input-child-small-bigger-margin-top: $zero-value !default;
$float-input-child-small-bigger-margin-top: $zero-value !default;
$input-child-small-bigger-margin-right: $zero-value !default;
$input-child-small-margin-bottom: $zero-value !default;
$input-child-small-margin-top: $zero-value !default;
$float-input-child-small-margin-top: $zero-value !default;
$input-child-small-margin-right: $zero-value !default;
$input-child-bigger-margin-bottom: $zero-value !default;
$input-child-bigger-margin-top: $zero-value !default;
$float-input-child-bigger-margin-top: $zero-value !default;
$input-child-bigger-margin-right:  $zero-value !default;
$input-disable-group-border-width: $one-value !default;
$input-opacity: 1 !default;
$input-opacity-filter: 100 !default;
$input-group-border-width-focus: $one-value !default;
$input-group-border-type-focus: $border-type !default;
$input-group-border-color-focus: $input-box-border-focus !default;
$input-group-border-color-hover: hover-color(--black) !default;
$input-group-border-width-hover: 0.0625rem !default;
$input-group-border-right-focus: transparent !default;
$input-group-border-left-focus: transparent !default;
$input-group-full-border-color: $input-box-border-color !default;
$input-group-full-border-width: $one-value !default;
$input-group-success-color: $input-success-color !default;
$input-group-warning-color: $input-warning-color !default;
$input-group-error-color: $input-error-color !default;
$input-valid-border-bottom-width: 0.125rem !default;
$input-group-pressed-color: normal-color(--white) !default;
$input-select-font-color: normal-color(--black) !default;
$input-right-border-width: $zero-value !default;
$float-label-font-color: normal-color(--black-700) !default;
$float-label-disbale-font-color: $input-disable-font-color !default;
$float-label-font-size: 0.75rem !default;
$float-label-bigger-font-size: 0.875rem !default;
$float-label-small-font-size: 0.75rem !default;
$float-label-bigger-small-font-size: 0.875rem !default;
$float-placeholder-font-size: 0.875rem !default;
$float-placeholder-bigger-font-size: 0.8125rem !default;
$float-placeholder-small-font-size: 0.75rem !default;
$float-placeholder-bigger-small-font-size: 0.875rem !default;
$input-border-size: 0.125rem !default;
$input-normal-height: 3rem !default;
$input-bigger-height: 2.25rem !default;
$input-small-height: 1.375rem !default;
$input-bigger-small-height: 2.25rem - $input-border-size !default;
$float-input-normal-height: 3rem !default;
$float-input-bigger-height: 2.25rem !default;
$float-input-small-height: 1.375rem !default;
$float-input-bigger-small-height: 2.25rem !default;
$input-float-font-weight: 600 !default;
$input-full-height: 100% !default;
$textarea-normal-height: auto !default;
$textarea-bigger-height: auto !default;
$textarea-small-height: auto !default;
$textarea-bigger-small-height: auto !default;
$textarea-full-height: 100% !default;
$input-group-disabled-color: disabled-color(--black-300) !default;
$input-group-hovered-color: normal-color(--white) !default;
$input-icon-font-size: 0.875rem !default;
$input-bigger-icon-font-size: 1rem !default;
$input-small-icon-font-size: 0.75rem !default;
$input-bigger-small-icon-font-size: 0.75rem !default;
$input-inner-wrap-margin-left: $zero-value !default;
$input-clear-icon-padding-bottom: $zero-value !default;
$input-clear-icon-padding-right: $zero-value !default;
$input-clear-icon-padding-top: $zero-value !default;
$float-input-clear-icon-padding-top: $zero-value !default;
$input-clear-icon-small-bigger-padding-bottom: $zero-value !default;
$input-clear-icon-small-bigger-padding-right: $zero-value !default;
$input-clear-icon-small-bigger-padding-top: $zero-value !default;
$float-input-clear-icon-small-bigger-padding-top: $zero-value !default;
$input-clear-icon-small-padding-bottom: $zero-value !default;
$input-clear-icon-small-padding-right: $zero-value !default;
$input-clear-icon-small-padding-top: $zero-value !default;
$float-input-clear-icon-small-padding-top: $zero-value !default;
$input-clear-icon-bigger-padding-bottom: $zero-value !default;
$input-clear-icon-bigger-padding-right: $zero-value !default;
$input-clear-icon-bigger-padding-top: $zero-value !default;
$float-input-clear-icon-bigger-padding-top: $zero-value !default;
$input-clear-icon-min-height: $zero-value !default;
$input-clear-icon-min-width: 1.5rem !default;
$input-bigger-clear-icon-min-height: $zero-value !default;
$input-bigger-clear-icon-min-width: 2.125rem !default;
$input-bigger-small-clear-icon-min-height: $zero-value !default;
$input-bigger-small-clear-icon-min-width: 2.125rem !default;
$input-small-clear-icon-min-height: $zero-value !default;
$input-small-clear-icon-min-width: 1.625rem !default;
$input-left-icon-font-size: $zero-value !default;
$input-bigger-left-icon-font-size: $zero-value !default;
$input-small-left-icon-font-size: $zero-value !default;
$input-bigger-small-left-icon-font-size: $zero-value !default;
$input-left-child-min-height: $zero-value !default;
$input-left-child-min-width: $zero-value !default;
$input-bigger-left-child-min-height: $zero-value !default;
$input-bigger-left-child-min-width: $zero-value !default;
$input-small-left-child-min-height: $zero-value !default;
$input-small-left-child-min-width: $zero-value !default;
$input-bigger-small-left-child-min-height: $zero-value !default;
$input-bigger-small-left-child-min-width: $zero-value !default;
$input-icon-inner-width: $zero-value !default;
$input-icon-inner-height: $zero-value !default;
$input-readonly-bg-color: transparent !default;
$input-readonly-color: normal-color(--black) !default;
$input-icon-shadow: inset  0 3px 5px rgba(0, 0, 0, 0.125) !default;
$input-border-shadow: transparent !default;
$input-transition-shadow: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
$input-error-border-shadow: none !default;
$input-success-border-shadow: none !default;
$input-warning-border-shadow: none !default;

@mixin input-sizing {
  box-sizing: border-box;
}
@mixin input-height ($height) {
  height: $height;
}
@mixin input-state-color ($color) {
  border-color: $color;
}
@mixin input-selection {
  background: variant-color(--secondary);
  color: normal-color(--black);
}
// sass-lint:disable no-vendor-prefixes
@mixin float-label-alignment {
  box-sizing: border-box;
  left: 0;
  top: 50%;
  -moz-transform: translate(-0%, -50%);
  -ms-transform: translate(-0%, -50%);
  -webkit-transform: translate(-0%, -50%);
  -o-transform: translate(-0%, -50%);
  transform: translate(-0%, -50%);
  user-select: none;
  width: 100%;
}

@mixin textarea-float-label-alignment {
  box-sizing: border-box;
  left: 0;
  top: 13px;
  -moz-transform: translate(-0%, -50%);
  -ms-transform: translate(-0%, -50%);
  -o-transform: translate(-0%, -50%);
  -webkit-transform: translate(-0%, -50%);
  transform: translate(-0%, -50%);
  width: 100%;
}
@mixin input-group-animation {
  content: "";
}
@mixin input-group-animation-left {
  content: "";
}
@mixin input-group-animation-width {
  content: "";
}
@mixin input-group-animation-right {
  content: "";
}
@mixin input-group-animation-bg {
  content: "";
}
@mixin input-group-hover-bg {
  content: "";
}
@mixin input-group-success-animation-bg {
  content: "";
}
@mixin input-group-warning-animation-bg {
  content: "";
}
@mixin input-group-error-animation-bg {
  content: "";
}
@mixin input-ripple-parent {
  content: "";
}
@mixin input-ripple-style {
  content: "";
}
@mixin input-ripple-animation {
  content: "";
}
