@include export-module("slider-layout") {
  // sass-lint:disable no-vendor-prefixes
  // sass-lint:disable no-important

  .e-control-wrapper.e-slider-container.e-material-slider .e-slider .e-handle {
    &.e-material-handle {
      cursor: default;
      transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      z-index: 3;
    }
  }

  .e-slider-tooltip.e-tooltip-wrap.e-popup {
    .e-tip-content {
      padding: $slider-tooltip-padding;
      text-align: center;
    }
  }

  .e-slider-horizantal-color {
    height: 100%;
    position: absolute;
    top: 0;
  }

  .e-slider-vertical-color {
    left: -1px;
    position: absolute;
    width: 100%;
  }

  .e-bigger {
    .e-slider-tooltip.e-tooltip-wrap.e-popup {
      .e-tip-content {
        padding: $slider-tooltip-padding;
      }
    }

    &.e-slider-tooltip.e-tooltip-wrap.e-popup {
      .e-tip-content {
        padding: $slider-tooltip-padding;
      }
    }
  }

  .e-bigger .e-control-wrapper.e-slider-container,
  .e-control-wrapper.e-slider-container.e-bigger {
    .e-slider {
      .e-handle {
        height: $slider-handle-mobile-height;
        width: $slider-handle-mobile-width;
      }
    }

    .e-slider-button {
      height: 18px;
      width: 18px;
    }

    &.e-vertical {
      &.e-slider-btn {
        padding: 34px 0;
      }

      .e-first-button {
        margin-right: -9px;
      }

      .e-second-button {
        margin-right: -9px;
      }

      .e-slider {
        .e-slider-track {
          left: calc(50% - 4px);
          width: 8px;
        }

        .e-range {
          left: calc(50% - 4px);
          width: 8px;
        }
      }
    }

    &.e-horizontal .e-slider {
      .e-limits {
        height: 8px;
        top: calc(50% - 4px);
      }
    }

    &.e-vertical .e-slider {
      .e-limits {
        left: calc(50% - 4px);
        width: 8px;
      }
    }

    &.e-horizontal {
      &.e-slider-btn {
        padding: 0 34px;
      }

      .e-slider-button {
        height: $slider-handle-mobile-buttons-height;
        margin-top: -9px;
        width: $slider-handle-mobile-buttons-width;
      }

      .e-slider {
        .e-slider-track {
          height: 8px;
          top: calc(50% - 4px);
        }

        .e-range {
          height: 8px;
          top: calc(50% - 4px);
        }

        .e-handle {
          margin-left: $slider-handle-mobile-margin-left;
          top: $slider-handle-mobile-handle-top;
        }
      }
    }

    &.e-vertical {
      .e-slider {
        .e-handle {
          left: calc(50% - 8px);
          margin-bottom: -8px;
        }
      }

      &.e-scale-both {
        .e-slider {
          .e-handle {
            left: calc(50% - 8px);
            margin-bottom: -8px;
          }
        }
      }
    }

    .e-scale .e-tick .e-tick-value {
      font-size: $slider-mobile-scale-font-size;
    }
  }

  .e-control-wrapper.e-slider-container {
    box-sizing: border-box;
    display: inline-block;
    height: $slider-wrap-height;
    line-height: normal;
    outline: none;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    &::after {
      content: $skin-name;
      display: none;
    }

    .e-slider {
      .e-handle {
        &.e-large-thumb-size {
          transform: scale(1.5);
        }
      }
    }

    &.e-rtl {
      &.e-horizontal {
        .e-slider {
          .e-handle {
            margin: $rtl-slider-h-handle-margin;
            top: calc(50% - 8px);
          }
        }

        .e-scale {
          &.e-h-scale {
            .e-tick.e-first-tick {
              background-position: right center;
              left: 0;
            }

            .e-tick.e-last-tick {
              background-position: left center;
            }
          }
        }

        .e-slider-button {
          margin-top: -7px;
        }
      }

      &.e-vertical {
        direction: ltr;
      }
    }

    &.e-disabled {
      .e-btn {
        cursor: default;
      }

      .e-slider {
        .e-handle {
          cursor: default;

          &.e-handle-disable {
            display: $slider-disabled-handle-show;
          }
        }
      }

      .e-slider {
        .e-range {
          background-color: $slider-disabled-range-color;
        }

        .e-handle {
          background-color: $slider-disabled-handle-color;
          border-color: $slider-disabled-handle-color;
        }

        .e-slider-track {
          background-color: $slider-disabled-track-color;
        }
      }

      .e-scale .e-tick {
        .e-tick-value {
          color: $slider-button-bg-color;
        }
      }

      .e-slider-button {
        background-color: $slider-disabled-bg-color;
        border-color: $white;
      }
    }

    &:not(.e-disabled) {
      .e-slider-button {
        &:hover {
          background-color: hover-color(--hit-gray);
          border-color: active-color(--hit-gray);
        }

        &:active {
          background-color: active-color(--hit-gray);
          border-color: active-color(--hit-gray);
        }
      }
    }

    &.e-horizontal {
      height: 48px;
      width: 100%;

      .e-first-button {
        left: 0;
        margin-top: $slider-btn-center-align;
        top: 50%;

        .e-button-icon {
          background-image: $slider-button-hleft-bg-image;
          background-repeat: no-repeat;
          background-size: cover;
          height: 8px;
          left: calc(50% - 5px);
          position: absolute;
          top: calc(50% - 4px);
          width: 8px;
        }
      }

      .e-second-button {
        margin-top: $slider-btn-center-align;
        right: 0;
        top: 50%;

        .e-button-icon {
          background-image: $slider-button-hright-bg-image;
          background-repeat: no-repeat;
          background-size: cover;
          height: 8px;
          left: calc(50% - 3px);
          position: absolute;
          top: calc(50% - 4px);
          width: 8px;
        }
      }

      &.e-slider-btn {
        padding: $slider-button-horizon-padding;
      }

      .e-slider {
        height: 32px;
        position: relative;
        top: calc(50% - 16px);
        width: 100%;
      }

      .e-slider-track {
        background: $slider-handle-start-border-color;
        background: linear-gradient(90deg, normal-color(--alert) 0%, normal-color(--success) 50%, normal-color(--alert) 100%);
        border-radius: 6px;
        height: $slider-container-height;
        left: 0;
        overflow: hidden;
        position: absolute;
        top: calc(50% - 3px);
        width: 100%;
      }

      .e-handle {
        margin-left: -7px;
        top: calc(50% - 7px);
      }

      &.e-scale-both {
        .e-range {
          height: $slider-range-height;
        }
      }

      .e-range {
        height: $slider-range-height;
        top: calc(50% - 3px);
      }

      .e-limits {
        background-color: $slider-limit-bar-color;
        height: $slider-limit-bar-horizontal-height;
        position: absolute;
        top: $slider-limit-bar-top;
      }
    }

    &.e-vertical {
      height: inherit;
      padding: 30px 0;
      width: 48px;

      .e-slider {
        height: 100%;
        left: calc(50% - 16px);
        position: relative;
        width: 32px;
      }

      .e-slider-track {
        border-radius: 6px;
        left: calc(50% - 3px);
        overflow: hidden;
        width: 6px;
      }

      &.e-small-size {
        &.e-slider-btn {
          height: 100%;
          padding: $slider-button-vertical-padding;

          .e-slider {
            height: 100%;
            width: $slider-height;
          }
        }
      }

      .e-first-button {
        bottom: 0;
        margin-right: $slider-btn-center-align;
        right: 50%;

        .e-button-icon {
          font-family: $slider-icons-font-family;
        }

        .e-button-icon::before {
          color: $slider-button-icon-color;
          content: "\e829";
          font-size: 9px;
          font-weight: 600;
          left: calc(50% - 4.15px);
          position: absolute;
          top: calc(50% - 4px);
          transform: rotate(-90deg);
        }
      }

      .e-second-button {
        margin-right: $slider-btn-center-align;
        right: 50%;
        top: 0;

        .e-button-icon {
          font-family: $slider-icons-font-family;
        }

        .e-button-icon::before {
          color: $slider-button-icon-color;
          content: "\e829";
          font-size: 9px;
          font-weight: 600;
          left: calc(50% - 5.5px);
          position: absolute;
          top: calc(50% - 5.8px);
          transform: rotate(90deg);
        }
      }

      &.e-scale-both {
        .e-slider {
          .e-handle {
            margin-bottom: $slider-v-handle-margin;
          }
        }
      }

      .e-slider {
        .e-handle {
          left: calc(50% - 7px);
          margin-bottom: $slider-v-handle-margin;
        }

        .e-range {
          left: calc(50% - 3px);
          width: 6px;
        }

        .e-limits {
          background-color: $slider-limit-bar-color;
          left: $slider-limit-bar-left;
          position: absolute;
          width: $slider-limit-bar-vertical-width;
        }
      }
    }

    .e-range {
      border-radius: $slider-range-border-radius;
      position: absolute;
      transition: left 300ms ease-out, right 300ms ease-out, bottom 300ms ease-out, width 300ms ease-out, height 300ms ease-out;

      &.e-drag-horizontal {
        cursor: ew-resize;
      }

      &.e-drag-vertical {
        cursor: ns-resize;
      }
    }

    .e-slider {
      box-sizing: border-box;
      cursor: $slider-cursor;
      display: block;
      outline: 0 none;
      padding: 0;
      position: relative;

      .e-handle {
        border-radius: $slider-border-radius-handle;
        box-sizing: border-box;
        cursor: $slider-handle-hover-cursor;
        height: $slider-handle-height;
        outline: none;
        position: absolute;
        touch-action: none;
        transition: left 300ms ease-out, right 300ms ease-out, bottom 300ms ease-out, transform 300ms ease-out;
        width: $slider-handle-width;
        z-index: 10;

        &.e-tab-handle {
          background-color: $slider-tab-background;
          border-color: $slider-tab-border-color;
          box-shadow: $slider-tab-active-box-shadow;
          // sass-lint:enable-all
        }
      }
    }

    .e-tick-before {
      &.e-scale {
        &.e-h-scale {
          .e-tick {
            background-position: bottom;
            height: 50%;
            top: 1px;
          }

          .e-large {
            height: $largetick-before-height;
            top: $largetick-before-top;
          }
        }

        &.e-v-scale {
          .e-tick {
            left: $tick-after-left;
            width: $tick-after-height;
          }
        }
      }
    }

    .e-tick-after {
      &.e-scale {
        &.e-h-scale {
          .e-tick {
            height: $tick-before-height;
            top: $tick-before-top;
          }

          .e-large {
            height: $largetick-after-height;
            top: $largetick-after-top;
          }
        }

        &.e-v-scale {
          .e-tick {
            left: $tick-before-left;
            width: $tick-before-height;
          }

          .e-large {
            left: $largetick-after-left;
            width: $largetick-after-height;
          }
        }
      }
    }

    &.e-scale-before {
      .e-scale {
        &.e-v-scale {
          right: 9px;
        }
      }
    }

    &.e-scale-after {
      .e-scale {
        &.e-v-scale {
          right: 9px;
        }
      }
    }

    .e-tick-both {
      &.e-scale {
        &.e-h-scale {
          .e-tick {
            height: 16px;
            top: -8px;
          }

          .e-large {
            height: 22px;
            top: -5px;
          }
        }

        &.e-v-scale {
          .e-tick {
            background-position: center;
            left: 6px;
            width: calc(100% - 12px);
          }

          .e-large {
            left: 3px;
            width: calc(100% - 6px);
          }
        }
      }
    }

    .e-scale {
      box-sizing: content-box;
      font-family: $slider-scale-font-family;
      font-size: $slider-scale-font-size;
      height: 28px;
      line-height: normal;
      list-style: none outside none;
      margin: $slider-center-align 0 0;
      outline: 0 none;
      padding: 0;
      position: absolute;
      top: $slider-scale-top;
      width: 100%;
      z-index: 1;

      .e-tick {
        background-image: $slider-scale-tick-image;
        background-position: center center;
        cursor: $slider-cursor;
        outline: none;
        position: relative;
        user-select: none;

        &.e-large {
          background-image: $slider-small-scale-tick-image;
        }

        .e-tick-value {
          color: $slider-tick-font-color;
          font-family: $slider-scale-font-family;
          font-size: $slider-scale-font-size;
          line-height: 1.5;
          outline: none;
          position: absolute;
          user-select: none;
          white-space: nowrap;
        }
      }

      &.e-v-scale {
        height: 100%;
        left: calc(50% - 14px);
        margin-top: -2px;
        top: 0;
        width: 28px;

        .e-tick {
          background-repeat: repeat-x;

          &.e-first-tick {
            background-position-y: center;
          }

          &.e-last-tick {
            background-position-y: bottom;
            margin-top: 2px;
          }
        }
      }

      &.e-h-scale {
        .e-tick {
          background-repeat: repeat-y;
          display: inline-block;
          height: 100%;
          top: 0;

          .e-tick-value {
            &.e-tick-before {
              top: -18px;
            }

            &.e-tick-after {
              bottom: -20px;
            }

            &.e-tick-both {
              bottom: -20px;

              &:first-child {
                top: -18px;
              }
            }
          }

          &.e-first-tick {
            background-position: left center;
          }

          &.e-last-tick {
            background-position: right center;
          }
        }
      }
    }

    &.e-horizontal {
      &.e-scale-both {
        .e-scale {
          top: 0;
        }
      }
    }
  }


  .e-control-wrapper.e-slider-container {
    &.e-vertical {
      &.e-scale-both {
        .e-scale.e-v-scale {
          right: 10px;
        }
      }
    }

    .e-scale.e-v-scale {
      .e-tick {
        .e-tick-value {
          &.e-tick-before {
            right: 17px;
          }

          &.e-tick-after {
            left: 19px;
          }

          &.e-tick-both {
            right: 30px;

            &:first-child {
              left: 30px;
            }
          }
        }
      }
    }
  }


  .e-control-wrapper.e-slider-container .e-slider {
    .e-handle.e-handle-active {
      background-color: $slider-handle-active-bg-color;
      border-color: $slider-handle-active-border-color;
    }
  }
}
