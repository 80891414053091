// stylelint-disable declaration-no-important

// Typography

@mixin text-colored($parent, $color) {
  #{$parent} {
    color: normal-color($color) !important;
  }

  #{$parent}-hover:hover {
    color: normal-color($color) !important;
  }
}
