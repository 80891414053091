$skin-name: "bootstrap-4";

//normal
$btn-color: normal-color(--white) !default;
$btn-active-color: normal-color(--white) !default;
$btn-focus-color: normal-color(--white) !default;
$btn-hover-color: normal-color(--white) !default;
$btn-disabled-color: normal-color(--white) !default;

$btn-bgcolor: normal-color(--secondary) !default;
$btn-hover-bgcolor: hover-color(--secondary) !default;
$btn-focus-bgcolor: focused-color(--secondary) !default;
$btn-active-bgcolor: active-color(--secondary) !default;
$btn-disabled-bgcolor: disabled-color(--secondary) !default;

$btn-font-size: 14px !default;

$btn-border: 1px solid !default;
$btn-border-color: variant-color(--secondary) !default;
$btn-hover-border-color: variant-color(--secondary) !default;
$btn-focus-border-color: focused-color(--secondary) !default;
$btn-active-border-color: active-color(--secondary) !default;
$btn-disabled-border-color: disabled-color(--secondary) !default;

$btn-active-box-shadow: 0 0 0 0.2rem set-opacity(--black, 0.5) !default;

$btn-icon-margin-top: -2px !default;
$btn-icon-btn-width: 1em !default;
$btn-icon-top-bottom-padding: 6px !default;
$btn-icon-small-width: 1em !default;
$btn-icon-bigger-small-width: 1em !default;
$btn-top-icon-padding: 12px 12px !default;
$btn-top-icon-bigger-padding: 16px 16px !default;
$btn-small-icon-top-bottom-padding: 6px !default;
$btn-icon-bigger-width: 1em !default;
$btn-bigger-icon-top-bottom-padding: 8px !default;

$btn-focus-outline: focused-color(--white-100) 0 solid !default;
$btn-focus-outline-round: focused-color(--white-100) 0 solid !default;
$btn-focus-outline-offset: 0 !default;

$btn-active-outline: active-color(--black) 0 solid !default;
$btn-active-outline-offset: 0 !default;

$btn-ripple-bgcolor: transparent !default;

$btn-link-bgcolor: transparent !default;
$btn-link-border-color: transparent !default;
$btn-link-color: normal-color(--primary) !default;
$btn-link-hover-color: hover-color(--warning) !default;
$btn-link-disabled-bgcolor: transparent !default;
//endnormal
//primary
$btn-primary-color: normal-color(--white) !default;
$btn-primary-hover-color: normal-color(--white) !default;
$btn-primary-focus-color: normal-color(--white) !default;
$btn-primary-active-color: normal-color(--white) !default;
$btn-primary-disabled-color: normal-color(--white) !default;

$btn-primary-outline: normal-color(--primary) 0 solid !default;
$btn-primary-bgcolor: normal-color(--primary) !default;
$btn-primary-hover-bgcolor: hover-color(--primary) !default;
$btn-primary-focus-bgcolor: focused-color(--primary) !default;
$btn-primary-active-bgcolor: active-color(--primary) !default;
$btn-primary-disabled-bgcolor: disabled-color(--primary) !default;
$btn-ripple-primary-bgcolor: transparent !default;

$btn-primary-border-color:  normal-color(--primary) !default;
$btn-primary-hover-border-color: hover-color(--primary) !default;
$btn-primary-focus-border-color: focused-color(--primary) !default;
$btn-primary-active-border-color: active-color(--primary) !default;
$btn-primary-disabled-border-color: transparent !default;
//endprimary
//outline
$btn-outline-color: normal-color(--primary) !default;
$btn-outline-focus-color: normal-color(--white) !default;
$btn-outline-active-color: normal-color(--white) !default;
$btn-outline-hover-color: normal-color(--white) !default;
$btn-outline-disabled-color: disabled-color(--primary) !default;
$btn-outline-primary-color: normal-color(--primary) !default;
$btn-outline-primary-focus-color: normal-color(--white) !default;
$btn-outline-primary-focus-bgcolor: normal-color(--primary) !default;


$btn-outline-active-box-shadow: $btn-active-box-shadow !default;

$btn-outline-bgcolor: transparent !default;
$btn-outline-hover-bgcolor: hover-color(--secondary) !default;
$btn-outline-focus-bgcolor: focused-color(--secondary) !default;
$btn-outline-active-bgcolor: active-color(--secondary) !default;
$btn-outline-disabled-bgcolor: disabled-color(--white) !default;
$btn-outline-border-color: variant-color(--primary) !default;
$btn-outline-hover-border-color: hover-color(--secondary) !default;
$btn-outline-focus-border-color: focused-color(--secondary) !default;
$btn-outline-default-focus-border-color: focused-color(--secondary) !default;
$btn-outline-active-border-color: active-color(--secondary) !default;
$btn-outline-disabled-border-color: disabled-color(--primary) !default;

//endoutline
//flat
$btn-flat-color: normal-color(--secondary) !default;
$btn-flat-hover-color: hover-color(--secondary) !default;
$btn-flat-focus-color: focused-color(--secondary) !default;
$btn-flat-active-color: active-color(--secondary) !default;
$btn-flat-disabled-color: disabled-color(--secondary) !default;

$btn-flat-box-shadow: none !default;
$btn-flat-active-box-shadow: $btn-active-box-shadow !default;

$btn-flat-active-bgcolor: active-color(--white) !default;
$btn-flat-bgcolor: normal-color(--white) !default;
$btn-flat-hover-bgcolor: hover-color(--white) !default;
$btn-flat-focus-bgcolor: focused-color(--white) !default;
$btn-ripple-flat-bgcolor: transparent !default;
$btn-flat-disabled-bgcolor: $btn-disabled-bgcolor !default;
$btn-ripple-flat-primary-bgcolor: transparent !default;
$btn-ripple-flat-success-bgcolor: transparent !default;
$btn-ripple-flat-info-bgcolor: transparent !default;
$btn-ripple-flat-warning-bgcolor: transparent !default;
$btn-ripple-flat-alert-bgcolor: transparent !default;
$btn-ripple-flat-danger-bgcolor: transparent !default;

$btn-flat-border: $btn-border !default;
$btn-flat-border-color: normal-color(--white) !default;
$btn-flat-hover-border-color: hover-color(--white) !default;
$btn-flat-active-border-color: active-color(--white) !default;
$btn-flat-focus-border-color: focused-color(--white) !default;
$btn-flat-disabled-border-color: disabled-color(--white) !default;
//endflat
//success
$btn-success-color: normal-color(--white) !default;
$btn-success-hover-color: normal-color(--white) !default;
$btn-success-focus-color: normal-color(--white) !default;
$btn-success-disabled-color: normal-color(--white) !default;
$btn-success-active-color: active-color(--white) !default;

$btn-success-bgcolor: normal-color(--success) !default;
$btn-success-hover-bgcolor: hover-color(--success) !default;
$btn-success-focus-bgcolor: focused-color(--success) !default;
$btn-success-active-bgcolor: active-color(--success) !default;
$btn-success-disabled-bgcolor: disabled-color(--success) !default;
$btn-ripple-success-bgcolor: transparent !default;

$btn-success-border-color: normal-color(--success) !default;
$btn-success-hover-border-color: hover-color(--success) !default;
$btn-success-focus-border-color: focused-color(--success) !default;
$btn-success-active-border-color: active-color(--success) !default;
$btn-success-disabled-border-color: transparent !default;

//endsuccess
//warning
$btn-warning-color: normal-color(--black) !default;
$btn-warning-focus-color: normal-color(--black) !default;
$btn-warning-active-color: normal-color(--black) !default;
$btn-warning-hover-color: normal-color(--black) !default;
$btn-warning-disabled-color: normal-color(--black) !default;

$btn-warning-bgcolor: normal-color(--warning) !default;
$btn-warning-hover-bgcolor: hover-color(--warning) !default;
$btn-warning-focus-bgcolor: focused-color(--warning) !default;
$btn-warning-active-bgcolor: active-color(--warning) !default;
$btn-ripple-warning-bgcolor: transparent !default;

$btn-warning-border-color: normal-color(--warning) !default;
$btn-warning-hover-border-color: hover-color(--warning) !default;
$btn-warning-focus-border-color: focused-color(--warning) !default;
$btn-warning-active-border-color: active-color(--warning) !default;
$btn-warning-disabled-bgcolor: disabled-color(--warning) !default;
$btn-warning-disabled-border-color: transparent !default;
//endwarning
//alert
$btn-alert-color: normal-color(--black) !default;
$btn-alert-focus-color: normal-color(--black) !default;
$btn-alert-active-color: normal-color(--black) !default;
$btn-alert-hover-color: normal-color(--black) !default;
$btn-alert-disabled-color: normal-color(--black) !default;

$btn-alert-bgcolor: normal-color(--alert) !default;
$btn-alert-hover-bgcolor: hover-color(--alert) !default;
$btn-alert-focus-bgcolor: focused-color(--alert) !default;
$btn-alert-active-bgcolor: active-color(--alert) !default;
$btn-ripple-alert-bgcolor: transparent !default;

$btn-alert-border-color: normal-color(--alert) !default;
$btn-alert-hover-border-color: hover-color(--alert) !default;
$btn-alert-focus-border-color: focused-color(--alert) !default;
$btn-alert-active-border-color: active-color(--alert) !default;
$btn-alert-disabled-bgcolor: disabled-color(--alert) !default;
$btn-alert-disabled-border-color: transparent !default;
//endwarning
//danger
$btn-danger-color: normal-color(--white) !default;
$btn-danger-active-color: normal-color(--white) !default;
$btn-danger-hover-color: normal-color(--white) !default;
$btn-danger-disabled-color: normal-color(--white) !default;

$btn-danger-bgcolor: normal-color(--error) !default;
$btn-danger-hover-bgcolor: hover-color(--error) !default;
$btn-danger-focus-bgcolor: focused-color(--error) !default;
$btn-danger-active-bgcolor: active-color(--error) !default;
$btn-danger-disabled-bgcolor: disabled-color(--error) !default;
$btn-ripple-danger-bgcolor: transparent !default;

$btn-danger-border-color: normal-color(--error) !default;
$btn-danger-hover-border-color: hover-color(--error) !default;
$btn-danger-focus-border-color: focused-color(--error) !default;
$btn-danger-active-border-color: active-color(--error) !default;
$btn-danger-disabled-border-color: transparent !default;
//enddanger
//info
$btn-info-color: normal-color(--white) !default;
$btn-info-active-color: normal-color(--white) !default;
$btn-info-hover-color: normal-color(--white) !default;
$btn-info-disabled-color: normal-color(--white) !default;

$btn-info-bgcolor: normal-color(--link) !default;
$btn-info-hover-bgcolor: hover-color(--link) !default;
$btn-info-focus-bgcolor: focused-color(--link) !default;
$btn-info-active-bgcolor: active-color(--link) !default;
$btn-info-disabled-bgcolor: disabled-color(--link) !default;
$btn-ripple-info-bgcolor: transparent !default;

$btn-info-border-color: normal-color(--link) !default;
$btn-info-hover-border-color: hover-color(--link) !default;
$btn-info-focus-border-color: focused-color(--link) !default;
$btn-info-active-border-color: active-color(--link) !default;
$btn-info-disabled-border-color: transparent !default;
//endinfo
//round
$btn-round-focus-color: focused-color(--white) !default;
$btn-round-active-color: active-color(--white) !default;
$btn-round-bgcolor: $btn-bgcolor !default;
$btn-round-border-color: $btn-border-color !default;
$btn-round-color: $btn-color !default;
$btn-round-hover-bgcolor:  $btn-hover-bgcolor !default;
$btn-round-hover-border-color: $btn-hover-border-color !default;
$btn-round-hover-color: $btn-hover-color !default;
//endround
//flatprimary
$btn-flat-primary-hover-bgcolor: hover-color(--white) !default;
$btn-flat-primary-border-color: normal-color(--white) !default;
$btn-flat-primary-hover-border-color: hover-color(--white) !default;
$btn-flat-primary-active-border-color: active-color(--white) !default;
$btn-flat-primary-focus-border-color: focused-color(--white) !default;
$btn-flat-primary-disabled-border-color: disabled-color(--white) !default;
$btn-flat-primary-focus-bgcolor: focused-color(--white) !default;
$btn-flat-disabled-color: disabled-color(--primary) !default;
$btn-flat-primary-disabled-color: disabled-color(--primary) !default;
$btn-flat-primary-bgcolor: normal-color(--white) !default;
$btn-flat-primary-color: normal-color(--primary) !default;
$btn-flat-primary-hover-color: hover-color(--primary) !default;
$btn-flat-primary-focus-color: focused-color(--primary) !default;
$btn-flat-primary-active-color: active-color(--primary) !default;
$btn-flat-primary-active-bgcolor: active-color(--primary) !default;
//endflatprimary
//flatsuccess
$btn-flat-success-hover-bgcolor: hover-color(--white) !default;
$btn-flat-success-border-color: normal-color(--white) !default;
$btn-flat-success-hover-border-color: hover-color(--white) !default;
$btn-flat-success-active-border-color: active-color(--white) !default;
$btn-flat-success-focus-border-color: focused-color(--white) !default;
$btn-flat-success-disabled-border-color: disabled-color(--white) !default;
$btn-flat-success-focus-bgcolor: focused-color(--white) !default;
$btn-flat-disabled-color: disabled-color(--success) !default;
$btn-flat-success-disabled-color: disabled-color(--success) !default;
$btn-flat-success-bgcolor: normal-color(--white) !default;
$btn-flat-success-color: normal-color(--success) !default;
$btn-flat-success-hover-color: hover-color(--success) !default;
$btn-flat-success-focus-color: focused-color(--success) !default;
$btn-flat-success-active-color: active-color(--success) !default;
$btn-flat-success-active-bgcolor: active-color(--success) !default;
//endflatsuccess
//flatinfo
$btn-flat-info-hover-bgcolor: hover-color(--white) !default;
$btn-flat-info-border-color: normal-color(--white) !default;
$btn-flat-info-hover-border-color: hover-color(--white) !default;
$btn-flat-info-active-border-color: active-color(--white) !default;
$btn-flat-info-focus-border-color: focused-color(--white) !default;
$btn-flat-info-disabled-border-color: disabled-color(--white) !default;
$btn-flat-info-focus-bgcolor: focused-color(--white) !default;
$btn-flat-disabled-color: disabled-color(--link) !default;
$btn-flat-info-disabled-color: disabled-color(--link) !default;
$btn-flat-info-bgcolor: normal-color(--white) !default;
$btn-flat-info-color: normal-color(--link) !default;
$btn-flat-info-hover-color: hover-color(--link) !default;
$btn-flat-info-focus-color: focused-color(--link) !default;
$btn-flat-info-active-color: active-color(--link) !default;
$btn-flat-info-active-bgcolor: active-color(--link) !default;
//endflatinfo
//flatwarning
$btn-flat-warning-hover-bgcolor: hover-color(--white) !default;
$btn-flat-warning-border-color: normal-color(--white) !default;
$btn-flat-warning-hover-border-color: hover-color(--white) !default;
$btn-flat-warning-active-border-color: active-color(--white) !default;
$btn-flat-warning-focus-border-color: focused-color(--white) !default;
$btn-flat-warning-disabled-border-color: disabled-color(--white) !default;
$btn-flat-warning-focus-bgcolor: focused-color(--white) !default;
$btn-flat-disabled-color: disabled-color(--warning) !default;
$btn-flat-warning-disabled-color: disabled-color(--warning) !default;
$btn-flat-warning-bgcolor: normal-color(--white) !default;
$btn-flat-warning-color: normal-color(--warning) !default;
$btn-flat-warning-hover-color: hover-color(--warning) !default;
$btn-flat-warning-focus-color: focused-color(--warning) !default;
$btn-flat-warning-active-color: active-color(--warning) !default;
$btn-flat-warning-active-bgcolor: active-color(--warning) !default;
//endflatwarning
//flatalert
$btn-flat-alert-hover-bgcolor: hover-color(--white) !default;
$btn-flat-alert-border-color: normal-color(--white) !default;
$btn-flat-alert-hover-border-color: hover-color(--white) !default;
$btn-flat-alert-active-border-color: active-color(--white) !default;
$btn-flat-alert-focus-border-color: focused-color(--white) !default;
$btn-flat-alert-disabled-border-color: disabled-color(--white) !default;
$btn-flat-alert-focus-bgcolor: focused-color(--white) !default;
$btn-flat-disabled-color: disabled-color(--alert) !default;
$btn-flat-alert-disabled-color: disabled-color(--alert) !default;
$btn-flat-alert-bgcolor: normal-color(--white) !default;
$btn-flat-alert-color: normal-color(--alert) !default;
$btn-flat-alert-hover-color: hover-color(--alert) !default;
$btn-flat-alert-focus-color: focused-color(--alert) !default;
$btn-flat-alert-active-color: active-color(--alert) !default;
$btn-flat-alert-active-bgcolor: active-color(--alert) !default;
//endflatwarning
//flatdanger

$btn-flat-danger-hover-bgcolor: hover-color(--white) !default;
$btn-flat-danger-border-color: normal-color(--white) !default;
$btn-flat-danger-hover-border-color: hover-color(--white) !default;
$btn-flat-danger-active-border-color: active-color(--white) !default;
$btn-flat-danger-focus-border-color: focused-color(--white) !default;
$btn-flat-danger-disabled-border-color: disabled-color(--white) !default;
$btn-flat-danger-focus-bgcolor: focused-color(--white) !default;
$btn-flat-disabled-color: disabled-color(--error) !default;
$btn-flat-danger-disabled-color: disabled-color(--error) !default;
$btn-flat-danger-bgcolor: normal-color(--white) !default;
$btn-flat-danger-color: normal-color(--error) !default;
$btn-flat-danger-hover-color: hover-color(--error) !default;
$btn-flat-danger-focus-color: focused-color(--error) !default;
$btn-flat-danger-active-color: active-color(--error) !default;
$btn-flat-danger-active-bgcolor: active-color(--error) !default;
//endflatdanger
//outlineprimary
$btn-outline-primary-disabled-color: disabled-color(--primary) !default;
$btn-outline-primary-disabled-border-color: disabled-color(--primary) !default;
$btn-outline-primary-hover-border-color: hover-color(--primary) !default;
$btn-outline-primary-hover-bgcolor: hover-color(--primary) !default;
$btn-outline-primary-focus-border-color: focused-color(--primary) !default;
$btn-outline-primary-active-border-color: active-color(--primary) !default;
//endoutlineprimay
//outlinesuccess
$btn-outline-success-disabled-color: disabled-color(--success) !default;
$btn-outline-success-disabled-border-color: disabled-color(--success) !default;
$btn-outline-success-hover-bgcolor: hover-color(--success) !default;
//endoutlinesuccess
//outlineinfo
$btn-outline-info-disabled-color: disabled-color(--link) !default;
$btn-outline-info-disabled-border-color: disabled-color(--link) !default;
//endoutlineinfo
//outlinewarning
$btn-outline-warning-disabled-color: disabled-color(--warning) !default;
$btn-outline-warning-disabled-border-color: disabled-color(--warning) !default;
//endoutlinealert
//outlinealert
$btn-outline-alert-disabled-color: disabled-color(--alert) !default;
$btn-outline-alert-disabled-border-color: disabled-color(--alert) !default;
//endoutlinewarning
//outlinedanger
$btn-outline-danger-disabled-color: disabled-color(--error) !default;
$btn-outline-danger-disabled-border-color: disabled-color(--error) !default;
//endoutlinedanger
//size
$btn-font-weight: 600 !default;
$btn-font-size: 14px !default;
$btn-small-font-size: 12px !default;
$btn-bigger-font-size: 16px !default;
$btn-bigger-small-font-size: 14px !default;
$btn-border-radius: 4px !default;
$btn-text-transform: none !default;
$btn-box-shadow: none !default;
$btn-hover-focus-box-shadow: none !default;
$btn-icon-font-size: 14px !default;
$btn-small-icon-font-size: 12px !default;
$btn-bigger-icon-font-size: 16px !default;
$btn-bigger-small-icon-font-size: 14px !default;
$btn-round-small-height: 24px !default;
$btn-round-small-width: 24px !default;
$btn-round-height: 32px !default;
$btn-round-width: 32px !default;
$btn-round-bigger-small-height: 32px !default;
$btn-round-bigger-small-width: 32px !default;
$btn-round-bigger-height: 38px !default;
$btn-round-bigger-width: 38px !default;
$btn-round-font-size: 14px !default;
$btn-small-round-font-size: 12px !default;
$btn-bigger-round-font-size: 16px !default;
$btn-bigger-small-round-font-size: 14px !default;
$btn-icon-margin: -0.5em !default;
$btn-small-icon-margin: -0.57143em !default;
$btn-bigger-icon-margin: -0.6111em !default;
$btn-bigger-small-icon-margin: -0.5em !default;
$btn-icon-width: 2em !default;
$btn-small-icon-width: 2em !default;
$btn-bigger-icon-width: 2em !default;
$btn-bigger-small-icon-width: 2em !default;
$btn-round-icon-line-height: 1.6 !default;
$btn-small-round-icon-line-height: 1.5 !default;
$btn-bigger-round-icon-line-height: 1.5 !default;
$btn-bigger-small-round-icon-line-height: 1.6 !default;
$btn-text-line-height: 1.6 !default;
$btn-bigger-text-line-height: 1.6 !default;
$btn-small-text-line-height: 1.6 !default;
$btn-bigger-small-text-line-height: 1.6 !default;
$btn-padding: 4px 8px !default;
$btn-small-padding: 2px 8px 2px !default;
$btn-bigger-padding: 6px 12px 5px 12px !default;
$btn-bigger-small-padding: 4px 8px !default;
$btn-icon-padding: 4px 8px !default;
$btn-small-icon-padding: 2px 5px !default;
$btn-bigger-icon-padding: 4px 9px 5px 9px !default;
$btn-bigger-small-icon-padding: 4px 8px !default;
$btn-flat-primary-disabled-bgcolor: $btn-primary-disabled-bgcolor !default;
$btn-flat-success-disabled-bgcolor: $btn-success-disabled-bgcolor !default;
$btn-flat-info-disabled-bgcolor: $btn-info-disabled-bgcolor !default;
$btn-flat-warning-disabled-bgcolor: $btn-warning-disabled-bgcolor !default;
$btn-flat-alert-disabled-bgcolor: $btn-alert-disabled-bgcolor !default;
$btn-flat-danger-disabled-bgcolor: $btn-danger-disabled-bgcolor !default;
//endsize
