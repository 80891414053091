$range-bg-color: $content-bg !default;
$range-primary-color: normal-color(--primary) !default;
$range-primary-font-color: normal-color(--black) !default;
$range-button-font-color: normal-color(--black) !default;
$range-presets-bg: $content-bg !default;
$range-preset-normal-font-color: normal-color(--black) !default;
$range-font-color: none !default;
$range-active-icon-color: normal-color(--white) !default;
$range-icon-active-bg-color: active-color(--black-700) !default;
$range-active-border-color: active-color(--black-700) !default;
$range-active-state-icon-color: normal-color(--white) !default;
$range-control-border-right: 0 !default;
$range-lg-day-header-format-width: 100% !default;
$range-hover-color: hover-color(--white-100) !default;
$range-hover-content-color: hover-color(--white) !default;
$range-today-color: normal-color(--white-100) !default;
$range-calendar-today-color: normal-color(--black) !default;
$range-selection-bg: normal-color(--primary) !default;
$range-other-hover-color: $content-bg !default;
$range-other-month-date: normal-color(--hit-grey) !default;
$range-box-shadow: none !default;
$range-box-shadow-none: none !default;
$range-btn-font-weight: 500 !default;
$range-btn-width: 50% !default;
$range-btn-normal-height: 34px !default;
$range-btn-bigger-height: 3.1rem !default;
$range-btn-normal-line-height: 32px !default;
$range-btn-bigger-line-height: 38px !default;
$range-btn-height: 39px !default;
$range-device-btn-line-height: 1em !default;
$range-max-width: 730px !default;
$range-device-max-width: 294px !default;
$range-bigger-max-width: 770px !default;
$range-max-height: 500px !default;
$range-normal-nav-icon-width: 36px !default;
$range-normal-nav-icon-height: 36px !default;
$range-calendar-dark-color: normal-color(--black) !default;
$range-calendar-header-text-color: normal-color(--black) !default;
$range-calendar-header-dark-color: normal-color(--black) !default;
$range-calendar-medium-color: normal-color(--black-700) !default;
$range-calendar-light-color: normal-color(--black) !default;
$range-calendar-medium-font-size: 18px !default;
$range-calendar-btn-font-size: 16px !default;
$range-presets-normal-list-font-size: 14px !default;
$range-presets-bigger-list-font-size: 15px !default;
$range-range-btn-font-weight: normal !default;
$range-calendar-small-font-size: 12px !default;
$range-calendar-bigger-font-size: 20px !default;
$range-calendar-header-padding: 12px 12px 0 12px !default;
$range-e-bigger-header-padding: 16px 16px 0 16px !default;
$range-device-control-header-margin: 16px 16px 0 16px !default;
$range-control-header-width: auto !default;
$range-font-weight-normal: bold !default;
$range-flex-style: flex !default;
$range-inline-block-style: inline-block !default;
$range-block-style: block !default;
$range-table-style: table !default;
$range-display-none:  none !default;
$range-align-center: center !default;
$range-float-right: right !default;
$range-float-left: left !default;
$range-float-none: none !default;
$range-float-clear: both !default;
$range-start-end-container-height: 35px !default;
$range-calendar-border: none !default;
$range-calendar-selection-border: none !default;
$range-calendar-hover-border: 1px solid transparent !default;
$range-calendar-hover-border-color: none !default;
$range-calendar-other-month-border: none !default;
$range-calendar-margin: 0 !default;
$range-cursor-default-style: default !default;
$range-cursor-pointer-style: pointer !default;
$range-start-end-label-width: 48% !default;
$range-browser-select-none:  none !default;
$range-change-icon-width: 4% !default;
$range-font-weight-bold: normal !default;
$range-font-size: 16px !default;
$range-bigger-change-icon-size: 18px !default;
$range-change-icon-color: normal-color(--black) !default;
$range-indicator-label-width: 100% !default;
$range-device-indicator-margin: 10px 0 !default;
$range-separator-height: 1px !default;
$range-border-value: 1px solid normal-color(--white-100) !default;
$range-separator-color: normal-color(--white-100) !default;
$range-separator-margin: 0 !default;
$range-footer-height: 58px !default;
$range-bigger-footer-height: 72px !default;
$range-flex-direction-row-reverse: row-reverse !default;
$range-btn-padding: 0 16px !default;
$range-normal-footer-margin: 12px 12px 12px 6px !default;
$range-bigger-footer-margin: 16px 16px 16px 6px !default;
$range-normal-rtl-footer-margin: 0 5px 0 10px !default;
$range-bigger-rtl-footer-margin: 0 5px 0 15px !default;
$range-presets-height: 216px !default;
$range-bigger-presets-height: 240px !default;
$range-calendar-container-height: 100% !default;
$range-list-item-height: 26px !default;
$range-list-item-padding: 0 20px !default;
$range-listview-padding: 0 !default;
$range-width-auto: auto !default;
$range-list-item-line-height: 47px !default;
$range-device-medium-font-size: 20px !default;
$range-device-small-font-size: 14px !default;
$range-week-header-font-size: 14px !default;
$range-device-header-container-height: 36px !default;
$range-device-header-container-width: 100% !default;
$range-device-list-item-padding: 0 12px !default;
$range-value-zero: 0 !default;
$range-btn-border-value: 1px solid normal-color(--primary) !default;
$range-today-border-color: 1px solid normal-color(--primary) !default;
$range-btn-left-radius: 4px 0 0 4px !default;
$range-btn-right-radius: 0 4px 4px 0 !default;
$range-visibility-hidden: hidden !default;
$range-indicator-size: 1px !default;
$range-text-nowrap: nowrap !default;
$range-background: $content-bg !default;
$range-day-span-height: 14px !default;
$range-direction-left: ltr !default;
$range-value-none: none !default;
$range-control-header-margin: 10px 0 !default;
$range-control-bigger-header-margin: 12px 0 !default;
$range-indicator-margin: 0 0 10px 0 !default;
$range-btn-border-transparent: 1px solid transparent !default;
$range-header-label-size: 16px !default;
$range-flex-direction-row: row !default;
$range-flex-justify-content: flex-end !default;
$range-preset-min-width: 659px !default;
$range-header-btn-max-width: 116px !default;
$range-header-btn-bigger-max-width: 141px !default;
$range-presets-width: 160px !default;
$range-hover-start-radius: 50% 0 0 50% !default;
$range-hover-end-radius: 0 50% 50% 0 !default;
$range-text-overflow: ellipsis !default;
$range-icon-hover-color: normal-color(--white) !default;
$range-icon-border-radius: 0 !default;
$range-icon-container-min-height: 29px !default;
$range-icon-container-min-width: 23px !default;
$range-icon-bottom-right-radius: 3px !default;
$range-icon-top-right-radius: 3px !default;
$range-bigger-icon-container-min-height: 36px !default;
$range-bigger-icon-container-min-width: 33px !default;
$range-icon-margin: 0 !default;
$range-icon-bigger-margin: 0 !default;
$range-sart-end-btn-padding: 1px 6px !default;
// fabric theme variable
$range-icon-font-size: 14px !default;
$range-icon: "\e243" !default;
$range-icon-next: "\e913" !default;
$range-icon-prev: "\e904" !default;
$range-change-icon: "\e85f" !default;
$range-rtl-icon-next: "\e98f" !default;
$range-rtl-icon-prev: "\e85c" !default;
$range-rtl-icon-change: "\e85b" !default;
$range-popup-border: 1px solid set-opacity(--black, 0.15) !default;
$range-overlay: set-opacity(--black, 0.5) !default;
$range-popup-padding: 0 !default;
$range-presets-bg-color: normal-color(--primary) !default;
$range-presets-font-color: normal-color(--black) !default;
$range-calendar-normal-table-padding: 0 12px 12px !default;
$range-calendar-bigger-table-padding: 0 16px 16px !default;
$range-popup-border-radius: 4px !default;
$range-list-border-radius: 0 !default;
$range-presets-hover-bg: hover-color(--white) !default;
$range-bigger-nav-icon-width: 40px !default;
$range-bigger-nav-icon-height: 40px !default;
$range-device-medium-header-font-size: 14px !default;
$range-calendar-popup-padding: 0 !default;
$range-icon-bigger-padding: 11px !default;
$range-icon-normal-padding: 10px !default;
$range-preset-normal-list-height: 3.1rem !default;
$range-preset-bigger-list-height: 50px !default;
$range-bigger-icon-font-size: 16px !default;
$range-list-hover-color: $range-calendar-header-dark-color !default;
$range-calendar-active-border: 1px solid active-color(--primary) !default;
$range-calendar-today-start-color: 1px solid normal-color(--primary) !default;
$range-calendar-other-normal-border: none !default;
$range-calendar-bigger-max: 292px !default;
$range-active-font-color: normal-color(--black) !default;
// Mouse small size
$range-small-header-label-size: 16px !default;
$range-small-font-size: 12px !default;
$range-start-end-container-small-height: 32px !default;
$range-calendar-mouse-small-font-size: 12px !default;
$range-indicator-small-margin: 0 0 10px 0 !default;
$range-control-small-header-margin: 10px 0 !default;
$range-separator-small-margin: 0 !default;
$range-small-footer-margin: 10px 10px 10px 8px !default;
$range-preset-small-list-height: 26px !default;
$range-presets-small-list-font-size: 12px !default;
$calendar-bigger-small-max-width: 262px !default;
// mouse small icon
$range-small-icon-font-size: 12px !default;
// touch small icon
$range-bigger-small-icon-font-size: 15px !default;
