/* Variables */
@import "ej2-splitbuttons/button-group";
@import "ej2-splitbuttons/drop-down-button";
@import "ej2-splitbuttons/split-button";
@import "ej2-splitbuttons/progress-button";

/* Layouts Themes and Icons */

/* Split Buttons*/
@import "~@syncfusion/ej2-splitbuttons/styles/button-group/layout";
@import "~@syncfusion/ej2-splitbuttons/styles/button-group/theme";

/* Drop Down Button */
@import "~@syncfusion/ej2-splitbuttons/styles/drop-down-button/icons/bootstrap4";
@import "~@syncfusion/ej2-splitbuttons/styles/drop-down-button/layout";
@import "~@syncfusion/ej2-splitbuttons/styles/drop-down-button/theme";

/* Split button */
@import "~@syncfusion/ej2-splitbuttons/styles/split-button/layout";
@import "~@syncfusion/ej2-splitbuttons/styles/split-button/theme";

/* Customization */
@import "ej2-splitbuttons/customization/progress-button";
