// stylelint-disable declaration-no-important

// Contextual backgrounds

@mixin bg-variant($parent, $color, $ignore-warning: false) {
  @include deprecate("The `bg-variant` mixin", "v4.4.0", "v5", $ignore-warning);
  #{$parent} {
    background-color: normal-color($color) !important;
  }
  a#{$parent},
  button#{$parent} {
    @include hover-focus() {
      background-color: darken-color($color, 10%) !important;
    }
  }
}

@mixin bg-gradient-variant($parent, $color, $ignore-warning: false) {
  @include deprecate("The `bg-gradient-variant` mixin", "v4.5.0", "v5", $ignore-warning);
  #{$parent} {
    background: normal-color($color) linear-gradient(180deg, lighten-color($body-bg, 15%)) repeat-x !important;
  }
}
