/* Variables */

@import "ej2-calendars/calendar";
@import "ej2-calendars/datepicker";
@import "ej2-calendars/timepicker";
@import "ej2-calendars/datetimepicker";
@import "ej2-calendars/daterangepicker";

/* Layouts Themes and Icons */

/* Calendar */
@import "~@syncfusion/ej2-calendars/styles/calendar/icons/bootstrap4";
@import "~@syncfusion/ej2-calendars/styles/calendar/theme";
@import "~@syncfusion/ej2-calendars/styles/calendar/layout";

/* Date Picker */
@import "~@syncfusion/ej2-calendars/styles/datepicker/icons/bootstrap4";
@import "~@syncfusion/ej2-calendars/styles/datepicker/theme";
@import "./ej2-calendars/customization/datepicker";

/* Date Range Picker */
@import "~@syncfusion/ej2-calendars/styles/daterangepicker/icons/bootstrap4";
@import "~@syncfusion/ej2-calendars/styles/daterangepicker/theme";
@import "~@syncfusion/ej2-calendars/styles/daterangepicker/layout";

/* Date Time Picker */
@import "~@syncfusion/ej2-calendars/styles/datetimepicker/icons/bootstrap4";
@import "~@syncfusion/ej2-calendars/styles/datetimepicker/theme";
@import "./ej2-calendars/customization/datetimepicker";


/* Time Picker */
@import "~@syncfusion/ej2-calendars/styles/timepicker/icons/bootstrap4";
@import "~@syncfusion/ej2-calendars/styles/timepicker/theme";
@import "~@syncfusion/ej2-calendars/styles/timepicker/layout";
