/*! Schedule component's bootstrap4 theme definitions and variables */
$border-type: solid !default;
$border-size: 1px !default;
$schedule-skin: "bootstrap4" !default;
$schedule-header-bg-color: $content-bg !default;
$schedule-header-border-color: normal-color(-white-300) !default;
$schedule-header-font-color: normal-color(--black) !default;
$schedule-header-hover-color: hover-color(--secondary) !default;
$schedule-content-bg-color: $content-bg !default;
$schedule-content-border-color: normal-color(-white-300) !default;
$schedule-primary-content-font-color: normal-color(--black) !default;
$schedule-secondary-content-font-color: normal-color(--black-700) !default;
$schedule-select-font-color: $schedule-primary-content-font-color !default;
$schedule-select-bg-color: normal-color(--white-100) !default;
$schedule-work-cells-bg-color: normal-color(--white) !default;
$schedule-current-date-bg-color: normal-color(--white) !default;
$schedule-disable-dates-color: disabled-color(--black-300) !default;
$schedule-disable-font-color: disabled-color(--black-300) !default;
$schedule-workhour-bg-color: $content-bg !default;
$schedule-hover-bg-color: hover-color(--white-100) !default;
$schedule-hover-font-color: hover-color(--black) !default;
$schedule-active-color: active-color(--primary) !default;
$schedule-active-border-color: active-color(--primary) !default;
$schedule-active-font-color: active-color(--primary) !default;

$schedule-timezone-enable-nrml-height: 60px !default;
$schedule-timezone-enable-bgr-height: 64px !default;
$schedule-alternate-cell-border: dashed !default;
$schedule-header-cells-height: 60px !default;
$schedule-header-cells-width: 36px !default;
$schedule-header-cells-padding: 8px !default;
$schedule-month-header-cells-nrml-height: 35px !default;
$schedule-month-header-cells-bgr-height: 35px !default;
$schedule-allday-cells-height: 0 !default;
$schedule-work-cells-height: 50px !default;
$schedule-work-cells-width: 36px !default;
$schedule-date-navigation-font-size: 17px !default;
$schedule-header-nrml-font-size: 16px !default;
$schedule-header-bgr-font-size: 18px !default;
$schedule-header-device-font-size: 18px !default;
$schedule-content-nrml-font-size: 14px !default;
$schedule-content-bgr-font-size: 16px !default;
$schedule-content-device-font-size: 15px !default;
$schedule-date-month-header-wrap-bgr-font-size: 16px !default;
$schedule-date-header-wrap-nrml-font-size: 14px !default;
$schedule-date-header-wrap-bgr-font-size: 16px !default;
$schedule-date-header-wrap-device-font-size: 14px !default;

$schedule-appointment-details-padding: 4px 8px 0 !default;
$schedule-appointment-bg-color: normal-color(--primary) !default;
$schedule-appointment-font-color: normal-color(--white) !default;
$schedule-appointment-border-color: variant-color(--primary) !default;
$schedule-appointment-border-radius: 4px !default;
$schedule-appointment-active-border-color: #333 !default;
$schedule-month-agenda-app-indicator-color: #c3c3c3 !default;
$schedule-month-appointment-height: 22px !default;
$schedule-month-appointment-text-line-height: 19px !default;
$schedule-appointment-text-line-height: 20px !default;
$schedule-month-appointment-time-padding: 1.5px !default;
$schedule-month-appointment-subject-padding: 1px 2px !default;
$schedule-timescale-appointment-height: 54px !default;

$schedule-header-day-nrml-font-size: 13px !default;
$schedule-header-day-bgr-font-size: 14px !default;
$schedule-header-day-device-font-size: 14px !default;
$schedule-header-date-device-font-size: 20px !default;
$schedule-header-date-nrml-font-size: 18px !default;
$schedule-header-date-bgr-font-size: 20px !default;
$schedule-time-scale-nrml-font-size: 12px !default;
$schedule-time-scale-bgr-font-size: 13px !default;
$schedule-time-scale-device-font-size: 11px !default;
$schedule-current-time-nrml-font-size: 12px !default;
$schedule-current-time-bgr-font-size: 13px !default;
$schedule-current-time-bgr-device-font-size: 9px !default;
$schedule-time-scale-width: 85px !default;
$schedule-device-time-scale-width: 35px !default;

$schedule-vertical-work-cells-border: 0 0 1px 1px !default;
$schedule-rtl-vertical-work-cells-border: 0 1px 1px 0 !default;
$schedule-month-work-cells-border: 0 0 1px 1px !default;
$schedule-month-work-cells-height: 75px !default;
$schedule-rtl-month-work-cells-border: 0 1px 1px 0 !default;
$schedule-month-agenda-header-cells-border: 0 0 1px 0 !default;
$schedule-month-agenda-work-cells-border: 0 !default;
$schedule-rtl-month-agenda-work-cells-border: 0 !default;
$schedule-month-agenda-border: 1px solid $schedule-content-border-color !default;
$schedule-agenda-color: $content-bg !default;
$schedule-agenda-date-header-padding: 14px 20px 0 14px !default;
$schedule-recurrence-font-size: 12px !default;
$schedule-agenda-item-padding: 14px 0 !default;
$schedule-agenda-appointment-padding: 0 14px !default;
$schedule-popup-content-padding: 28px 14px !default;
$schedule-event-popup-content-padding: 0 14px 24px 14px !default;
$schedule-event-popup-fields-padding: 10px !default;
$schedule-popup-footer-padding: 0 14px 14px 0 !default;
$schedule-date-header-line-height: 21px !default;
$schedule-date-header-margin: 8px 0 0 8px !default;

$schedule-tbar-bgr-min-height: 54px !default;
$schedule-tbar-border-bottom-size: 1px solid rgba(0, 0, 0, 0.15) !default;
$schedule-tbar-box-shadow: none !default;
$schedule-tbar-box-shadow-bottom-margin: 0 !default;
$schedule-tbar-min-height: 46px !default;
$schedule-tbar-down-arrow-font-size: 11px !default;
$schedule-tbar-down-arrow-padding-top: 2px !default;
$schedule-tbar-default-icon-color: normal-color(--black) !default;
$schedule-tbar-separator-size: 1px !default;
$schedule-tbar-default-bg: normal-color(--white) !default;
$schedule-tbar-separator-border: normal-color(-white-300) !default;
$schedule-tbar-border-nav: 1px !default;
$schedule-tbar-border-nav-type: solid !default;
$schedule-tbar-hover-bg: hover-color(--secondary) !default;
$schedule-tbar-hover-font: hover-color(--black) !default;
$schedule-tbar-nav-bgr-width: 32px !default;
$schedule-tbar-bgr-item-size: 34px !default;
$schedule-tbar-bgr-size: $schedule-tbar-bgr-min-height !default;
$schedule-tbar-bgr-items-size: $schedule-tbar-bgr-min-height !default;
$schedule-tbar-separator-bgr-height: 28px !default;
$schedule-tbar-separator-bgr-mrgn: 0 !default;
$schedule-tbar-separator-bgr-minheight: 20px !default;
$schedule-tbar-btn-icon-bgr-width: 24px !default;
$schedule-tbar-btn-bgr-mrgn: 4px 0 !default;
$schedule-tbar-btn-bgr-minheight: 0 !default;
$schedule-tbar-btn-bgr-minwidth: 34px !default;
$schedule-tbar-btn-bgr-padding: 0 1.5px !default;
$schedule-tbar-btn-icn-bgr-padding: 0 !default;
$schedule-tbar-btn-icn-right-bgr-padding: 2px 0 0 !default;

$schedule-content-nrml-font-text-transform: initial !default;
$schedule-header-hover-border-radius: 4px !default;
$schedule-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15) !default;
$schedule-popup-box-shadow-1: 0 24px 38px 3px rgba(0, 0, 0, 0.14) !default;
$schedule-popup-box-shadow-2: 0 9px 46px 8px rgba(0, 0, 0, 0.12) !default;
$schedule-popup-box-shadow-3: 0 11px 15px -7px rgba(0, 0, 0, 0.2) !default;
$schedule-popup-border-color: rgba($black, 0.2) !default;
$schedule-popup-bg-color: $content-popup-bg !default;
$schedule-popup-header-icon-color: normal-color(--black) !default;
$schedule-popup-header-icon-hover-color: hover-color(--black) !default;
$schedule-popup-header-icon-hover-bg-color: transparent !default;
$schedule-popup-icon-color: normal-color(--black) !default;
$schedule-popup-icon-hover-color: hover-color(--black) !default;
$schedule-popup-icon-hover-bg-color: transparent !default;
$schedule-popup-content-icon-color: normal-color(--black) !default;
$schedule-popup-title-color: hover-color(-white-300) !default;
$schedule-popup-btn-border: none !default;
$schedule-btn-txt-bgr-padding: 5px 12px !default;
$schedule-icon-color: hover-color(--black) !default;

$schedule-event-box-shadow-1: 0 6px 10px 0 rgba(0, 0, 0, 0.14) !default;
$schedule-event-box-shadow-2: 0 1px 18px 0 rgba(0, 0, 0, 0.12) !default;
$schedule-event-box-shadow-3: 0 3px 5px -1px rgba(0, 0, 0, 0.2) !default;
$schedule-more-indicator-shadow-color: #333 !default;
$schedule-more-indicator-nrml-font-size: 12px !default;
$schedule-more-popup-close-margin-top: 3px !default;
$schedule-more-popup-padding: 12px 4px 14px !default;
$schedule-more-appointment-margin-bottom: 4px !default;
$schedule-event-popup-header-icon-padding: 6px 8px 0 0 !default;
$schedule-event-popup-subject-padding: 8px 14px !default;

$schedule-timeline-header-cells-height: 36px !default;
$schedule-timeline-work-cells-height: 60px !default;
$schedule-timeline-work-cells-width: 50px !default;
$schedule-timeline-month-cells-width: 60px !default;
$schedule-timeline-work-cells-border: 0 0 1px 1px !default;
$schedule-rtl-timeline-work-cells-border: 0 1px 1px 0 !default;
$schedule-timeline-resources-width: 224px !default;
$schedule-timeline-appointment-height: 38px !default;
$schedule-timeline-year-work-cells-height: 75px !default;
$schedule-timeline-year-work-cells-width: 65px !default;

$schedule-tree-bg-color: normal-color(--white) !default;
$schedule-popup-overlay-bg-color: #383838 !default;

$schedule-val-error-color: normal-color(--error) !default;
$schedule-val-error-bg-color: variant-color(--error) !default;

$schedule-block-appointment-bg-color: normal-color(-white-300) !default;
$schedule-block-appointment-font-color: normal-color(--black) !default;

$schedule-resource-color-margin-top: 6px !default;
$schedule-bigger-resource-color-margin-top: 8px !default;
$schedule-resource-color-size: 14px !default;
$schedule-bigger-resource-color-size: 16px !default;

@mixin tbar-btn-animation {
  content: "";
}

@mixin tbar-btn-animation-after {
  content: "";
}

$schedule-tbar-border-hover-color: hover-color(--secondary) !default;
