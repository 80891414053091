/* Bootstrap vars

 grey-100: --athens;
 grey-200: --athens-darken;
 grey-300: --mystic;
 grey-400: --geyser;
 grey-500: --hit-grey;
 grey-600: --boulder;
 grey-700 - grey-900 : --cape-cod;

*/

// Client dependent colors

body {
  /*
    @desc
    This Color is respond for the action dropdown menus,  Secondary Button’s fonts,
    Link’s normal states, task’s selected states, and selection buttons
  */
  --primary-color-h: 194;
  --primary-color-s: 23%;
  --primary-color-l: 48%;

  --primary-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));

  /*
    @desc
    This Color is correspond to primary-color.
     Automatically selects by the system.  Cannot be changed.
  */
  --primary-variant-color: hsl(var(--primary-color-h),
  calc(var(--primary-color-s) - 1%),
  calc(var(--primary-color-l) + 41%));
  --primary-hover-color: hsl(calc(var(--primary-color-h) + 1),
  calc(var(--primary-color-s) + 3%),
  calc(var(--primary-color-l) + 12%));
  --primary-active-color: hsl(calc(var(--primary-color-h) + 1),
  calc(var(--primary-color-s) + 11%),
  calc(var(--primary-color-l) - 4%));
  --primary-focused-color: hsl(calc(var(--primary-color-h) + 1),
  calc(var(--primary-color-s) + 11%),
  calc(var(--primary-color-l) - 14%));
  --primary-disabled-color: hsl(calc(var(--primary-color-h) + 3),
  calc(var(--primary-color-s) - 4%),
  calc(var(--primary-color-l) + 45%));

  /*
    @desc
    This Color is respond for the secondary button’s borders, active field inputs,
    inactive checkboxes, radio-buttons and switchers
  */
  --secondary-color-h: 194;
  --secondary-color-s: 15%;
  --secondary-color-l: 63%;

  --secondary-color: hsl(var(--secondary-color-h), var(--secondary-color-s), var(--secondary-color-l));

  /*
    @desc
    This Color is correspond to secondary-color.
     Automatically selects by the system.  Cannot be changed.
  */
  --secondary-variant-color: hsl(var(--secondary-color-h),
  calc(var(--secondary-color-s) - 3%),
  calc(var(--secondary-color-l) + 28%));
  --secondary-hover-color: hsl(calc(var(--secondary-color-h) - 1),
  calc(var(--secondary-color-s) + 1%),
  calc(var(--secondary-color-l) + 8%));
  --secondary-active-color: hsl(calc(var(--secondary-color-h) - 1),
  calc(var(--secondary-color-s) - 3%),
  calc(var(--secondary-color-l) - 6%));
  --secondary-focused-color: hsl(calc(var(--secondary-color-h) - 1),
  calc(var(--secondary-color-s) - 3%),
  calc(var(--secondary-color-l) - 16%));
  --secondary-disabled-color: hsl(calc(var(--secondary-color-h) + 1),
  var(--secondary-color-s),
  calc(var(--secondary-color-l) + 32%));

  /*
    @desc
    This Color is respond for the links, task states, Information notifications
  */
  --link-color-h: 205;
  --link-color-s: 100%;
  --link-color-l: 42%;

  --link-color: hsl(var(--link-color-h), var(--link-color-s), var(--link-color-l));

  /*
    @desc
    This Color is correspond to link-color and complements link colors.
     Automatically selects by the system.  Cannot be changed.
  */
  --link-variant-color: hsl(var(--link-color-h), calc(var(--link-color-s) - 27%), calc(var(--link-color-l) + 46%));
  --link-hover-color: hsl(var(--link-color-h), calc(var(--link-color-s) - 27%), calc(var(--link-color-l) + 14%));
  --link-active-color: hsl(var(--link-color-h), var(--link-color-s), calc(var(--link-color-l) - 3%));
  --link-focused-color: hsl(var(--link-color-h), var(--link-color-s), calc(var(--link-color-l) - 3%));
  --link-disabled-color: hsl(var(--link-color-h), calc(var(--link-color-s) - 24%), calc(var(--link-color-l) + 50%));

  /*
    @desc
    This Color is respond for the primary buttons, Error Input states, Link hover states
  */
  --warning-color-h: 38;
  --warning-color-s: 100%;
  --warning-color-l: 49%;

  --warning-color: hsl(var(--warning-color-h), var(--warning-color-s), var(--warning-color-l));

  /*
    @desc
    This Color is correspond to primary-button-color and complements default buttons colors.
     Automatically selects by the system.  Cannot be changed.
  */
  --warning-variant-color: hsl(var(--warning-color-h),
  calc(var(--warning-color-s) - 3%),
  calc(var(--warning-color-l) + 39%));
  --warning-hover-color: hsl(calc(var(--warning-color-h) - 1),
  calc(var(--warning-color-s) - 2%),
  calc(var(--warning-color-l) + 13%));
  --warning-active-color: hsl(calc(var(--warning-color-h) - 1),
  var(--warning-color-s),
  calc(var(--warning-color-l) - 4%));
  --warning-focused-color: hsl(calc(var(--warning-color-h) - 1),
  var(--warning-color-s),
  calc(var(--warning-color-l) - 14%));
  --warning-disabled-color: hsl(calc(var(--warning-color-h) - 1),
  var(--warning-color-s),
  calc(var(--warning-color-l) + 43%));

  /*
    @desc
    This Color is respond for the primary buttons, Error Input states, Link hover states
  */
  --primary-button-color-h: 38;
  --primary-button-color-s: 100%;
  --primary-button-color-l: 49%;

  --primary-button-color: hsl(var(--primary-button-color-h),
  var(--primary-button-color-s),
  var(--primary-button-color-l));

  /*
    @desc
    This Color is correspond to primary-button-color and complements default buttons colors.
     Automatically selects by the system.  Cannot be changed.
  */

  --primary-button-variant-color: hsl(var(--primary-button-color-h),
  calc(var(--primary-button-color-s) - 3%),
  calc(var(--primary-button-color-l) + 39%));
  --primary-button-hover-color: hsl(calc(var(--primary-button-color-h) - 1),
  calc(var(--primary-button-color-s) - 2%),
  calc(var(--primary-button-color-l) + 13%));
  --primary-button-active-color: hsl(calc(var(--primary-button-color-h) - 1),
  var(--primary-button-color-s),
  calc(var(--primary-button-color-l) - 4%));
  --primary-button-focused-color: hsl(calc(var(--primary-button-color-h) - 1),
  var(--primary-button-color-s),
  calc(var(--primary-button-color-l) - 14%));
  --primary-button-disabled-color: hsl(calc(var(--primary-button-color-h) - 1),
  var(--primary-button-color-s),
  calc(var(--primary-button-color-l) + 43%));

  /*
    @desc
    This Color is respond for the secondary action buttons, active field inputs,
    inactive checkboxes, radio buttons and switchers
  */
  --alert-color-h: 54;
  --alert-color-s: 100%;
  --alert-color-l: 64%;

  --alert-color: hsl(var(--alert-color-h), var(--alert-color-s), var(--alert-color-l));

  /*
    @desc
    This Color is correspond to warning-color and complements some warning states.
     Automatically selects by the system.  Cannot be changed.
  */
  --alert-variant-color: hsl(calc(var(--alert-color-h) - 1),
  calc(var(--alert-color-s) - 3%),
  calc(var(--alert-color-l) + 27%));
  --alert-hover-color: hsl(var(--alert-color-h), var(--alert-color-s), calc(var(--alert-color-l) + 9%));
  --alert-active-color: hsl(var(--alert-color-h), calc(var(--alert-color-s) - 19%), calc(var(--alert-color-l) - 5%));
  --alert-focused-color: hsl(var(--alert-color-h), calc(var(--alert-color-s) - 19%), calc(var(--alert-color-l) - 15%));
  --alert-disabled-color: hsl(calc(var(--alert-color-h) - 1), var(--alert-color-s), calc(var(--alert-color-l) + 31%));

  /*
    @desc
    This Color is respond for the Positive actions, notifications and states.
  */
  --success-color-h: 161;
  --success-color-s: 98%;
  --success-color-l: 39%;

  --success-color: hsl(var(--success-color-h), var(--success-color-s), var(--success-color-l));

  /*
    @desc
    This Color is correspond to success-color and complements some positive states.
     Automatically selects by the system.  Cannot be changed.
  */
  --success-variant-color: hsl(calc(var(--success-color-h) + 1),
  calc(var(--success-color-s) - 37%),
  calc(var(--success-color-l) + 40%));
  --success-hover-color: hsl(var(--success-color-h),
  calc(var(--success-color-s) - 36%),
  calc(var(--success-color-l) + 14%));
  --success-active-color: hsl(var(--success-color-h), var(--success-color-s), calc(var(--success-color-l) - 4%));
  --success-focused-color: hsl(var(--success-color-h), var(--success-color-s), calc(var(--success-color-l) - 14%));
  --success-disabled-color: hsl(calc(var(--success-color-h) - 1),
  calc(var(--success-color-s) - 35%),
  calc(var(--success-color-l) + 53%));

  /*
    @desc
    This Color is respond for the Error states, negative notifications, Delete button styling
  */
  --error-color-h: 11;
  --error-color-s: 100%;
  --error-color-l: 54%;

  --error-color: hsl(var(--error-color-h), var(--error-color-s), var(--error-color-l));

  /*
    @desc
    This Color is correspond to error-color and complements some negative states.
     Automatically selects by the system.  Cannot be changed.
  */
  --error-variant-color: hsl(calc(var(--error-color-h) - 1), var(--error-color-s), calc(var(--error-color-l) + 41%));
  --error-hover-color: hsl(var(--error-color-h), var(--error-color-s), calc(var(--error-color-l) + 11%));
  --error-active-color: hsl(var(--error-color-h), calc(var(--error-color-s) - 16%), calc(var(--error-color-l) - 4%));
  --error-focused-color: hsl(var(--error-color-h), calc(var(--error-color-s) - 16%), calc(var(--error-color-l) - 14%));
  --error-disabled-color: hsl(var(--error-color-h), var(--error-color-s), calc(var(--error-color-l) + 40%));
}

:root {
  /*
  @desc
  There is system color which will not be changed form outside
*/
  --white-color-h: 0;
  --white-color-s: 0%;
  --white-color-l: 100%;
  --white-color: hsl(0, 0%, 100%);
  --white-hover-color: hsl(210, 22%, 96%);
  --white-active-color: hsl(210, 7%, 89%);
  --white-focused-color: hsl(210, 7%, 79%);
  --white-disabled-color: hsl(0, 0%, 100%);

  /*
    @desc
    There is system color which will not be changed form outside
  */
  --athens-color-h: 240;
  --athens-color-s: 14%;
  --athens-color-l: 99%;
  --athens-color: hsl(240, 20%, 99%);
  --athens-hover-color: hsl(240, 20%, 99%);
  --athens-active-color: hsl(240, 2%, 91%);
  --athens-focused-color: hsl(240, 2%, 81%);
  --athens-disabled-color: hsl(240, 100%, 100%);

  /*
    @desc
    There is system color which will not be changed form outside
  */
  --athens-darken-color-h: 240;
  --athens-darken-color-s: 14%;
  --athens-darken-color-l: 98%;
  --athens-darken-color: hsl(240, 14%, 98%);
  --athens-darken-hover-color: hsl(220, 20%, 97%);
  --athens-darken-active-color: hsl(210, 7%, 89%);
  --athens-darken-focused-color: hsl(210, 7%, 79%);
  --athens-darken-disabled-color: hsl(180, 33%, 99%);

  /*
    @desc
    There is system color which will not be changed form outside
  */
  --mystic-color-h: 195;
  --mystic-color-s: 18%;
  --mystic-color-l: 91%;
  --mystic-color: hsl(195, 17%, 91%);
  --mystic-hover-color: hsl(190, 18%, 93%);
  --mystic-active-color: hsl(197, 9%, 84%);
  --mystic-focused-color: hsl(200, 9%, 74%);
  --mystic-disabled-color: hsl(180, 14%, 99%);

  /*
    @desc
    There is system color which will not be changed form outside
  */
  --geyser-color-h: 197;
  --geyser-color-s: 23%;
  --geyser-color-l: 84%;
  --geyser-color: hsl(197, 22%, 84%);
  --geyser-hover-color: hsl(197, 23%, 88%);
  --geyser-active-color: hsl(195, 14%, 77%);
  --geyser-focused-color: hsl(195, 14%, 67%);
  --geyser-disabled-color: hsl(200, 27%, 98%);

  /*
    @desc
    There is system color which will not be changed form outside
  */
  --hit-grey-color-h: 172;
  --hit-grey-color-s: 8%;
  --hit-grey-color-l: 70%;
  --hit-grey-color: hsl(171, 8%, 70%);
  --hit-grey-hover-color: hsl(193, 8%, 77%);
  --hit-grey-active-color: hsl(196, 6%, 65%);
  --hit-grey-focused-color: hsl(196, 6%, 55%);
  --hit-grey-disabled-color: hsl(180, 5%, 96%);

  /*
    @desc
    There is system color which will not be changed form outside
  */
  --boulder-color-h: 0;
  --boulder-color-s: 0%;
  --boulder-color-l: 48%;
  --boulder-color: hsl(0, 0%, 48%);
  --boulder-hover-color: hsl(0, 0%, 60%);
  --boulder-active-color: hsl(0, 0%, 44%);
  --boulder-focused-color: hsl(0, 0%, 54%);
  --boulder-disabled-color: hsl(0, 0%, 93%);

  /*
    @desc
    There is system color which will not be changed form outside
  */
  --cape-cod-color-h: 189;
  --cape-cod-color-s: 5%;
  --cape-cod-color-l: 27%;
  --cape-cod-color: hsl(189, 5%, 27%);
  --cape-cod-hover-color: #6f7475;
  --cape-cod-active-color: hsl(192, 4%, 25%);
  --cape-cod-focused-color: hsl(192, 4%, 35%);
  --cape-cod-disabled-color: hsl(240, 2%, 90%);

  /*
    @desc
    There is system color which will not be changed form outside
  */
  --black-color-h: 0;
  --black-color-s: 0%;
  --black-color-l: 0%;
  --black-color: hsl(0, 0%, 0%);
  --black-hover-color: hsl(0, 0%, 24%);
  --black-active-color: hsl(0, 0%, 0%);
  --black-focused-color: hsl(0, 0%, 10%);
  --black-disabled-color: hsl(0, 0%, 86%);
}
