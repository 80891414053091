@font-face {
  font-family: "OpenSans-Regular";
  src: url("/assets/fonts/OpenSans-Regular.eot");
  src: url("/assets/fonts/OpenSans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/OpenSans-Regular.woff2") format("woff2"),
    url("/assets/fonts/OpenSans-Regular.woff") format("woff"),
    url("/assets/fonts/OpenSans-Regular.svg#OpenSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans-Semibold";
  src: url("/assets/fonts/OpenSans-Semibold.eot");
  src: url("/assets/fonts/OpenSans-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/OpenSans-Semibold.woff2") format("woff2"),
    url("/assets/fonts/OpenSans-Semibold.woff") format("woff"),
    url("/assets/fonts/OpenSans-Semibold.svg#OpenSans-Semibold") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "OpenSans-Bold";
  src: url("/assets/fonts/OpenSans-Bold.eot");
  src: url("/assets/fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/OpenSans-Bold.woff2") format("woff2"),
    url("/assets/fonts/OpenSans-Bold.woff") format("woff"),
    url("/assets/fonts/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Campton-LightDEMO";
  src: url("/assets/fonts/Campton-LightDEMO.eot");
  src: url("/assets/fonts/Campton-LightDEMO.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Campton-LightDEMO.woff2") format("woff2"),
    url("/assets/fonts/Campton-LightDEMO.woff") format("woff"),
    url("/assets/fonts/Campton-LightDEMO.svg#Campton-LightDEMO") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "campton-medium";
  src: url("/assets/fonts/campton-medium.eot");
  src: url("/assets/fonts/campton-medium.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/campton-medium.woff2") format("woff2"),
    url("/assets/fonts/campton-medium.woff") format("woff"),
    url("/assets/fonts/campton-medium.svg#campton-medium") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Campton-BoldDEMO";
  src: url("/assets/fonts/Campton-BoldDEMO.eot");
  src: url("/assets/fonts/Campton-BoldDEMO.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/Campton-BoldDEMO.woff2") format("woff2"),
    url("/assets/fonts/Campton-BoldDEMO.woff") format("woff"),
    url("/assets/fonts/Campton-BoldDEMO.svg#Campton-BoldDEMO") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Avenir-Heavy";
  src: url("/assets/fonts/Avenir-Heavy.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Avenir-Heavy.woff") format("woff"),
    url("/assets/fonts/Avenir-Heavy.ttf") format("truetype"),
    url("/assets/fonts/Avenir-Heavy.svg#Avenir-Heavy") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

$font-family-sans-serif: "Roboto, OpenSans-Regular", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
