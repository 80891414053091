@include export-module("multiselect-bootstrap4") {
  //.e-multi-select-wrapper {
  //  min-height: 3rem !important;
  //}

  .e-multi-select-wrapper .e-chips-collection .e-chips .e-chips-close.e-icon::before {
    line-height: 30px;
    top: 0;
  }

  .e-multiselect .e-input-group-icon.e-ddl-icon {
    border-radius: 0 4px 4px 0;
    border-right-width: 0;
  }

  .e-multiselect.e-rtl .e-input-group-icon.e-ddl-icon {
    border-left-width: 0;
    border-radius: 4px 0 0 4px;
    border-right-width: 1px;
  }

  .e-bigger .e-multi-select-wrapper .e-chips>.e-chipcontent {
    font-size: 14px;
  }

  .e-chips {
    border: 1px solid normal-color(--primary);
  }

  .e-bigger .e-multi-select-wrapper .e-chips-close {
    height: $ddl-bigger-chip-close-square;
    width: $ddl-bigger-chip-close-square;
  }

  .e-popup.e-multi-select-list-wrapper .e-list-item.e-active.e-item-focus.e-hover {
    box-shadow: none;
  }

  .e-bigger .e-ddl.e-popup .e-filter-parent .e-clear-icon {
    display: flex;
  }

  .e-input-group.e-multiselect .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle {
    stroke-width: 1px;
  }

  .e-small .e-multi-select-wrapper .e-chips {
    margin: 1px 4px 1px 0;
  }

  .e-small.e-bigger .e-multi-select-wrapper .e-chips {
    margin: 2px 4px 2px 0;
  }

  .e-multiselect.e-float-input input[readonly],
  .e-multiselect.e-float-input.e-control-wrapper input[readonly] {
    background: transparent;
  }

  .e-small .e-multi-select-wrapper .e-close-hooker::before {
    top: $ddl-chip-close-hooker-small-top;
  }

  .e-bigger .e-multiselect .e-multi-select-wrapper .e-chips-close.e-close-hooker {
    height: $multiselect-closer-bigger-height;
    margin-top: $multiselect-closer--bigger-margin-top;
    right: $multiselect-closer-bigger-width;
  }

  .e-bigger .e-multiselect .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker {
    right: $multiselect-closer-bigger-width;
  }
}
