// Z index variables
$zindex-sidenav: 1080 !default;
$zindex-topnav: 1090 !default;

// Color variables for the sidenav

// -- Sidenav Light
$sidenav-light-bg: $primary;
$sidenav-light-color: $secondary;
$sidenav-light-heading-color: $gray-500;
$sidenav-light-link-color: $sidenav-light-color;
$sidenav-light-link-active-color: $darkBlue;
$sidenav-light-icon-color: $primary;
$sidenav-light-footer-bg: $white;

// Color variables for the topnav
$topnav-dark-toggler-color: fade-out($white, 0.5);
$topnav-light-toggler-color: $gray-900;
