$font-family: "Montserrat", sans-serif !default;
$font-size: 12px !default;
$font-weight: normal !default;
$font-path: "common-images" !default;
$primary-font: normal-color(--black);
$error-font-color: normal-color(--error) !default;
$warning-font-color: normal-color(--black) !default;
$success-font-color: normal-color(--success) !default;
$information-font-color: normal-color(--link) !default;
$overlay-bg-color: set-opacity(--black, 0.75) !default;
$content-popup-bg: normal-color(--white) !default;
$content-bg: normal-color(--white-100);
$gray-900: normal-color(--black);

@import "~@syncfusion/ej2-icons/styles/bootstrap4";
@import "~@syncfusion/ej2-base/styles/all";
@import "~@syncfusion/ej2-base/styles/common/mixin";


