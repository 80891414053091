@include export-module("slider-theme") {
  /*! component theme */
  .e-control-wrapper.e-slider-container {
    .e-slider-button {
      background-color: $slider-button-bg-color;
      border: 0;
      border-radius: 5px;
      box-sizing: border-box;
      cursor: pointer;
      height: 12px;
      outline: none;
      padding: 0;
      position: absolute;
      width: 40px;
    }

    .e-slider {
      .e-range {
        background: $slider-range-bar-color;
      }

      .e-handle {
        background-color: $slider-handle-bg-color;
        border-color: $slider-normal-handle-border-color;
        box-shadow: 0 0 1px $white, 0 0 0 2px set-opacity(--primary, 0.25);

        &.e-material-tooltip {
          background-color: transparent;
          border-color: transparent;
        }
      }
    }

    &.e-slider-hover {
      .e-slider-track {
        background: $slider-hover-color;
        background: linear-gradient(90deg, hover-color(--alert) 0%, hover-color(--success) 50%, hover-color(--alert) 100%);
      }

      .e-range {
        background-color: $slider-range-bar-hover-color;
      }

      .e-handle {
        border-color: $slider-handle-hover-border-color;
      }
    }
  }
}
