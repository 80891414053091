
@mixin alert-focus {
  background-color: $btn-alert-focus-bgcolor;
  border-color: $btn-alert-focus-border-color;
  box-shadow: 0 0 0 0.25em set-opacity(--alert, 0.25);
  color: $btn-alert-hover-color;
}

@mixin alert-active {
  background-color: $btn-alert-active-bgcolor;
  border-color: $btn-alert-active-border-color;
  box-shadow: 0 0 0 0.25em set-opacity(--alert, 0.25);
  color: $btn-alert-active-color;
}

@mixin alert-disabled {
  background-color: $btn-alert-disabled-bgcolor;
  border-color: $btn-disabled-border-color;
  box-shadow: $btn-flat-box-shadow;
  color: $btn-alert-disabled-color;
}

@mixin outline-alert-focus {
  background-color: $btn-alert-focus-bgcolor !important;
  border-color: $btn-alert-focus-border-color !important;
  box-shadow: 0 0 0 0.25em set-opacity(--alert, 0.25);
  color: $btn-alert-color;

}

@mixin outline-alert-active {
  background-color: $btn-alert-bgcolor;
  border-color: transparent;
  box-shadow: 0 0 0 0.25em set-opacity(--alert, 0.25);
  color: $btn-alert-active-color;
}

@mixin outline-alert-disabled {
  background-color: $btn-outline-bgcolor;
  border-color: $btn-outline-alert-disabled-border-color;
  box-shadow: $btn-flat-box-shadow;
  color: $btn-outline-alert-disabled-color;
}

.e-btn {
  padding: 0 1rem;

  &:not(.e-round) {
    min-height: 2.5rem;
  }

  &:not(.e-round):not(.e-dlg-closeicon-btn):not(.e-dropdown-btn):not(.btn-block):not(.e-split-colorpicker) {
    min-width: 6.25rem;
  }

  &.e-pill {
    border-radius: 1.5625rem;
  }

  &.e-alert {
    background-color: $btn-alert-bgcolor;
    border-color: $btn-alert-border-color;
    color: $btn-alert-color;

    &:hover {
      background-color: $btn-alert-hover-bgcolor;
      border-color: $btn-alert-hover-border-color;
      box-shadow: $btn-hover-focus-box-shadow;
      color: $btn-alert-hover-color;
    }

    &:focus {
      @include alert-focus;
    }

    &:active,
    &.e-active {
      @include alert-active;
    }

    &:disabled {
      @include alert-disabled;
    }

    & .e-ripple-element {
      background-color: $btn-ripple-alert-bgcolor;
    }
  }

  &.e-flat {
    font-weight: 600;

    &.e-alert {
      background-color: $btn-flat-alert-bgcolor;
      border-color: $btn-flat-alert-border-color;
      color: $btn-flat-alert-color;

      &:hover {
        background-color: $btn-flat-alert-hover-bgcolor;
        border-color: $btn-flat-alert-hover-border-color;
        box-shadow: $btn-flat-box-shadow;
        color: $btn-flat-alert-hover-color;
      }

      &:focus {
        background-color: $btn-flat-alert-focus-bgcolor;
        border-color: $btn-flat-alert-focus-border-color;
        box-shadow: 0 0 0 0.25em set-opacity(--alert, 0.25);

        color: $btn-flat-alert-focus-color;
      }

      &:active,
      &.e-active {
        background-color: $btn-flat-alert-active-bgcolor;
        border-color: $btn-flat-alert-active-border-color;
        box-shadow: 0 0 0 0.25em set-opacity(--alert, 0.25);
        color: $btn-flat-alert-active-color;
      }

      &:disabled {
        background-color: $btn-flat-alert-disabled-bgcolor;
        border-color: $btn-flat-alert-disabled-border-color;
        color: $btn-flat-alert-disabled-color;
      }

      & .e-ripple-element {
        background-color: $btn-ripple-flat-alert-bgcolor;
      }
    }
  }

  &.e-outline {
    font-weight: 600;

    &.e-alert {
      background-color: $btn-outline-bgcolor;
      border-color: $btn-alert-bgcolor;
      color: darken-color(--alert, 20%);

      &:hover {
        background-color: $btn-alert-hover-bgcolor;
        border-color: $btn-alert-hover-border-color;
        color: $btn-alert-color;
      }

      &:focus {
        @include outline-alert-focus;
      }

      &:active,
      &.e-active {
        @include outline-alert-active;
      }

      &:disabled {
        @include outline-alert-disabled;
      }
    }
  }
}
