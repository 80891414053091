//default
// sass-lint:disable-all 
$treeview-font-size: 0.875rem !default;
$treeview-icon-font-size: 8px !default;

$treeview-default-hover-bg-color: hover-color(--white);
$treeview-default-active-bg-color: normal-color(--white);
$treeview-default-dsiabled-bg-color: disabled-color(--white);

$treeview-default-text-color: normal-color(--secondary);
$treeview-default-hover-text-color: normal-color(--secondary);
$treeview-default-active-text-color: normal-color(--black);
$treeview-default-disable-text-color: disabled-color(--secondary);

$treeview-image-font-size: 18px !default;
$treeview-big-font-size: 16px !default;
$treeview-big-text-color: $treeview-default-text-color !default;
$treeview-big-icon-font-size: 10px !default;
$treeview-big-image-font-size: 16px !default;

$treeview-icon-color: $treeview-default-text-color !default;
$treeview-text-color: $treeview-default-text-color !default;
$treeview-item-border-color: transparent !default;
$treeview-item-active-bg: $treeview-default-active-bg-color !default;
$treeview-icon-active-color: $treeview-default-active-text-color !default;
$treeview-text-active-color: $treeview-default-active-text-color !default;
$treeview-item-active-border-color: normal-color(-white-300) !default;
$treeview-item-hover-bg: $treeview-default-hover-bg-color !default;
$treeview-icon-hover-color: $treeview-default-hover-text-color !default;
$treeview-text-hover-color: $treeview-default-hover-text-color !default;
$treeview-item-hover-border-color: $treeview-default-hover-bg-color !default;
$treeview-item-active-hover-bg: $treeview-default-hover-bg-color !default;
$treeview-icon-active-hover-color: $treeview-default-active-text-color !default;
$treeview-text-active-hover-color: $treeview-default-active-text-color !default;
$treeview-item-active-hover-border-color: normal-color(-white-300) !default;
$treeview-text-disable-color: $treeview-default-disable-text-color !default;
$treeview-icon-disable-color: $treeview-default-disable-text-color !default;
$treeview-drag-line-bg: normal-color(-white-300) !default;
$treeview-drag-line-color: normal-color(--hit-grey) !default;
$treeview-popup-bg-color: transparent !default;
$treeview-popup-border-color: transparent !default;
$treeview-drop-count-bg: normal-color(--primary) !default;
$treeview-drop-count-border: normal-color(--primary) !default;
$treeview-drop-count-color: normal-color(--black) !default;
$treeview-drag-item-bg: normal-color(--white-100) !default;
$treeview-drag-item-color: $treeview-default-text-color !default;
$treeview-drag-icon-color: $treeview-default-text-color !default;
$treeview-bootstrap4-checkmark-bgcolor: normal-color(--black) !default;
$treeview-bootstrap4-checkmark-border-color: normal-color(--black) !default;
$treeview-bootstrap4-checkmark-color:  normal-color(--primary) !default;
//enddefault
//dimensions
$treeview-skin-name: "bootstrap4";
$treeview-item-height: 65px !default;
$treeview-text-height: 65px !default;
$treeview-input-height: 30px !default;
$treeview-root-ul-padding: 0 0 0 14px !default;
$treeview-rtl-root-ul-padding: 0 14px 0 0 !default;
$treeview-child-ul-padding: 0 0 0 14px !default;
$treeview-rtl-child-ul-padding: 0 14px 0 0 !default;
$treeview-text-wrap-padding: 0 0 0 24px !default;
$treeview-rtl-text-wrap-padding: 0 24px 0 0 !default;
$treeview-icon-size: 20px !default;
$treeview-big-icon-size: 24px !default;
$treeview-icon-margin: 0 2px 0 -24px !default;
$treeview-drag-icon-margin: 0 8px 8px -24px;
$treeview-big-icon-margin: 0 0 0 -24px !default;
$treeview-rtl-icon-margin: -0.5px -24px 0 0 !default;
$treeview-rtl-drag-margin: 10px -13px -1px 0;
$treeview-icon-padding: 6px !default;
$treeview-text-padding: 0 8px 0 6px !default;
$treeview-text-margin: 0 !default;
$treeview-image-size: 18px !default;
$treeview-icon-size: 18px !default;
$treeview-image-margin: 2.5px 5px 0 !default;
$treeview-navigable-image-icon-margin: 0 8px 0 12px !default;
$treeview-navigable-uncheck-image-margin: 0 8px 0 2px !default;
$treeview-navigable-image-icon-rtl-margin: 0 12px 0 8px !default;
$treeview-navigable-uncheck-image-rtl-margin: 0 2px 0 8px !default;
$treeview-navigable-icon-image-margin: 0 8px 0 0 !default;
$treeview-navigable-icon-image-margin-reverse: 0 0 0 8px !default;
$treeview-navigable-check-margin-bigger: 0 0 0 10px !default;
$treeview-navigable-check-margin-bigger-reverse: 0 10px 0 0 !default;
$treeview-navigable-icon-image-anchor-margin-bigger: 0 12px 0 4px !default;
$treeview-navigable-icon-image-anchor-margin-reverse-bigger: 0 4px 0 12px !default;
$treeview-navigable-icon-image-anchor-margin: 0 8px 0 4px !default;
$treeview-navigable-icon-image-anchor-margin-reverse: 0 4px 0 8px !default;
$treeview-navigable-rtl-margin-reverse: 0 12px 0 0 !default;
$treeview-rtl-image-margin: 2px 4px 0 0 !default;
$treeview-input-padding: 0 7px !default;
$treeview-image-text-padding: 0 8px !default;
$treeview-icon-image-margin: 0 0 0 10px !default;
$treeview-rtl-icon-image-margin: 0 10px 0 0 !default;
$treeview-check-margin: 0 0 0 5px !default;
$treeview-rtl-check-margin: 0 5px 0 0 !default;
$treeview-check-text-padding: 0 8px !default;
$treeview-check-image-margin: 0 0 0 12px !default;
$treeview-rtl-check-image-margin: 5px 12px 0 0 !default;
$treeview-drop-count-border-size: 0 !default;
$treeview-big-drop-count-border-size: 1px !default;
$treeview-drop-count-font-size: 14px !default;
$treeview-drag-item-box-shadow:  0 0 3px 3px rgba(0, 0, 0, 0.25) !default;

$treeview-big-item-height: 65px !default;
$treeview-big-text-height: 65px !default;
$treeview-big-input-height: 38px !default;
$treeview-big-text-padding: 0 12px 0 8px !default;
$treeview-big-drag-text-padding: 2px 12px 0 4px !default;
$treeview-big-drag-icon-margin: -6px 0 5px -14px !default;
$treeview-big-drag-icon-padding: 0 12px 0 4px !default;
$treeview-big-drag-before-icon-padding: 12px !default;
$treeview-big-drag-item-text-padding-left: 12px !default;
$treeview-drag-text-padding: 0 8px 0 4px !default;
$treeview-big-input-padding: 0 9px !default;
$treeview-big-icon-padding: 7px !default;
$treeview-big-image-text-padding: 0 12px !default;
$treeview-big-image-margin: 4px 0 0 10px !default;
$treeview-big-rtl-icon-margin: 0 -24px 1px 0;
$treeview-big-rtl-image-margin: 2px 10px 0 0 !default;
$treeview-big-icon-image-margin: 0 0 0 10px !default;
$treeview-big-rtl-icon-image-margin: 0 10px 0 0 !default;
$treeview-big-check-margin: 0 0 0 10px !default;
$treeview-big-check-text-padding: 0 12px !default;
$treeview-big-rtl-check-margin: 0 10px 0 0 !default;
$treeview-big-check-image-margin: 0 0 0 16px !default;
$treeview-big-rtl-check-image-margin: 4px 16px 2px 0 !default;
$treeview-drag-icon-padding: 8px !default;
$treeview-drag-icon-padding-right: 4px !default;
$treeview-drag-icon-font-size: 14px;
$treeview-font-family: $font-family !default;
$treeview-drag-icon-font-size: 12px !default;

$treeview-font-family: $font-family !default;
$treeview-drag-icon-font-size: 12px !default;
$treeview-big-drag-icon-padding: 6px 4px 6px 12px !default;
$treeview-big-drag-icon-font-size: 16px;
$ripple-size: -7px !default;
$ripple-height: 32px !default;
$ripple-width: 32px !default;

#{&}.e-treeview {
  .e-list-item {
    font-weight: 500;
    padding: 0 !important;

    div.e-icons.interaction {
      border-radius: 15px;
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    }

    .e-text-content {
      align-items: center;
      display: flex;
      min-height: 65px;
    }

    .e-list-text {
      border-bottom: 1px solid var(-white-300-color);
      width: 100%;
    }

    &.e-active {
      border: 0 !important;

      .e-fullrow {
        border: 0;
      }

      .e-list-icon {
        color: normal-color(--black);
      }
    }

    .e-icons.e-icon-collapsible {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    .e-list-icon {
      color: $treeview-default-text-color;
    }
  }

  &.e-drag-item {
    &.e-rtl {
      .e-icons.e-drop-next {
        transform: rotate(180deg);
      }
    }
  }

  &.e-rtl {
    div.e-icons {
      transform: rotate(180deg);
    }
  }
}
