// Adjust column spacing for symmetry
$spacer: 1rem;
$grid-gutter-width: $spacer * 1.5;

// Variables for rounding boxes
$zdb-card-border-radius: 1rem;

// Spacing variables for navigation
$topnav-base-padding-top: 2rem;
$topnav-base-height: 4.475rem;
$sidenav-base-width: 275px;
