/* Variables */
@import "ej2-navigators/accordion";
@import "ej2-navigators/h-scroll";
@import "ej2-navigators/v-scroll";
@import "ej2-navigators/context-menu";
@import "ej2-navigators/menu";
@import "ej2-navigators/toolbar";
@import "ej2-navigators/sidebar";
@import "ej2-navigators/tab";
@import "ej2-navigators/treeview";

/* Layouts Themes and Icons */

/* Accordion */
@import "~@syncfusion/ej2-navigations/styles/accordion/icons/bootstrap4";
@import "~@syncfusion/ej2-navigations/styles/accordion/layout";
@import "~@syncfusion/ej2-navigations/styles/accordion/theme";

/* Context Menu */
@import "~@syncfusion/ej2-navigations/styles/context-menu/theme";
@import "~@syncfusion/ej2-navigations/styles/context-menu/layout";
@import "~@syncfusion/ej2-navigations/styles/context-menu/icons/bootstrap4";

/* H Scroll */
@import "~@syncfusion/ej2-navigations/styles/h-scroll/theme";
@import "~@syncfusion/ej2-navigations/styles/h-scroll/layout";
@import "~@syncfusion/ej2-navigations/styles/h-scroll/icons/bootstrap4";

/* Menu */
@import "~@syncfusion/ej2-navigations/styles/menu/theme";
@import "~@syncfusion/ej2-navigations/styles/menu/layout";
@import "~@syncfusion/ej2-navigations/styles/menu/icons/bootstrap4";

/* Sidebar */
@import "~@syncfusion/ej2-navigations/styles/sidebar/layout";
@import "~@syncfusion/ej2-navigations/styles/sidebar/theme";

/* Tab */
@import "~@syncfusion/ej2-navigations/styles/tab/theme";
@import "~@syncfusion/ej2-navigations/styles/tab/layout";
@import "~@syncfusion/ej2-navigations/styles/tab/icons/bootstrap4";

/* Toolbar */
@import "~@syncfusion/ej2-navigations/styles/toolbar/theme";
@import "~@syncfusion/ej2-navigations/styles/toolbar/layout";
@import "~@syncfusion/ej2-navigations/styles/toolbar/icons/bootstrap4";

/* Tree View */
@import "~@syncfusion/ej2-navigations/styles/treeview/icons/bootstrap4";
@import "~@syncfusion/ej2-navigations/styles/treeview/layout";
@import "~@syncfusion/ej2-navigations/styles/treeview/theme";

/* V Scroll */
@import "~@syncfusion/ej2-navigations/styles/v-scroll/theme";
@import "~@syncfusion/ej2-navigations/styles/v-scroll/layout";
@import "~@syncfusion/ej2-navigations/styles/v-scroll/icons/bootstrap4";
