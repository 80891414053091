@import "~bootstrap/scss/tooltip";

.tooltip-inner {
  box-shadow: 0 0 4px set-opacity(--black, 0.16), 0 0 16px set-opacity(--primary, 0.25);
  line-height: 17px;

  .arrow {
    border-color: normal-color(--primary);
    box-shadow: 0 0 4px set-opacity(--black, 0.16), 0 2px 2px set-opacity(--primary, 0.25);
  }
}
