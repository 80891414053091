$dialog-btn-bg-bgcolor: transparent !default;
$dialog-btn-box-shadow: none !default;
$dialog-bg-color: $content-popup-bg !default;
$dialog-icon-color: $black !default;
$dialog-active-icon-color: $black !default;
$dialog-close-icon-normal-opacity: 0.5 !default;
$dialog-close-icon-active-opacity: 0.75 !default;
$dialog-close-button-left: 0 !default;
$dialog-close-button-right: 16px !default;
$dialog-header-border: 1px solid normal-color(--white-100) !default;
$dialog-close-icon-font-size: 14px !default;
$dialog-close-icon-button-padding: 0 13px !default;
$dialog-bigger-close-icon-button-padding: 0 2px !default;
$dialog-bigger-close-icon-font-size: 17px !default;
$dialog-hover-icon-color: hover-color(--black-700) !default;
$dialog-icon-bg-normal: transparent !default;
$dialog-header-bg-color: $content-popup-bg !default;
$dialog-content-bg-color: $content-popup-bg !default;
$dialog-footer-bg-color: $content-popup-bg !default;
$dialog-shadow-color: 0 5px 15px set-opacity(--black-700, 0.5) !default;
$dialog-header-font-size: 16px !default;
$dialog-header-font-weight: 500 !default;
$dialog-header-font-color: normal-color(--black-700) !default;
$footer-content-border-radius: 6px !default;
$header-content-border-radius: 6px 6px 0 0 !default;
$dialog-border: 1px solid set-opacity(--black-700, 0.2) !default;
$dialog-content-font-size: 14px !default;
$dialog-header-line-height: 1.2 !default;
$dialog-content-line-height: 1.5 !default;
$dialog-content-padding-top: 14px !default;
$dialog-bigger-content-padding-top: 16px !default;
$dialog-content-font-weight: 400 !default;
$dialog-device-content-font-size: 12px !default;
$dialog-content-font-color: normal-color(--black-700) !default;
$dialog-border-radius: 3px !default;
$dialog-headericon-border-radius: 50% !default;
$dialog-overlay-bg-color: normal-color(--black-700) !default;
$dialog-overlay-opacity: 0.5 !default;
$dialog-closeicon-btn-hover-color: hover-color(--black-700) !default;
$dialog-closeicon-btn-active-color: active-color(--white) !default;
$dialog-close-button-height: 20px !default;
$dialog-close-button-width: 22px !default;
$dialog-header-content-padding: 14px !default;
$dialog-content-padding: 14px !default;
$dialog-footer-content-padding: 14px !default;
$dialog-footer-content-border-top: 1px solid normal-color(--white-100) !default;
$dialog-bigger-header-content-font-size: 20px !default;
$dialog-bigger-content-font-size: 16px !default;
$dialog-bigger-header-content-padding: 16px !default;
$dialog-bigger-content-padding: 16px !default;
$dialog-bigger-footer-content-padding: 16px !default;
$dialog-bigger-footer-button-margin-left: 10px !default;
$dialog-footer-button-margin-left: 8px !default;
$dialog-footer-button-margin-right: 8px !default;
$dialog-bigger-footer-button-margin-right: 10px !default;
$dialog-close-icon-margin-left: -7px !default;
$dialog-close-icon-height: 16px !default;
$dialog-close-icon-width: 12px !default;
$dialog-bigger-close-icon-height: 16px !default;
$dialog-bigger-close-icon-width: 12px !default;
$dialog-close-icon-top: -4px !default;
$dialog-bigger-close-icon-top: -5px !default;
$dialog-bigger-close-icon-margin-left: -8px !default;
$dialog-bigger-close-icon-margin-right: -1px !default;
$dialog-bigger-close-button-bottom: 5px !default;
$dialog-bigger-close-button-height: 25px !default;
$dialog-bigger-close-button-left: 0 !default;
$dialog-bigger-close-button-width: 26px !default;
$dialog-btn-border-color: transparent !default;
$dialog-btn-bg-color: transparent !default;
$dialog-active-btn-border-color: transparent !default;
$dialog-active-btn-bg-color: transparent !default;
$dialog-headericon-bottom: 6px !default;
$dialog-resize-handler-width: 13px;
$dialog-resize-handler-height: 13px;
$dialog-resize-handler-position: 2px;
$resize-hanlder-bottom-rtl: 2px;
