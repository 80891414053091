$ddl-last-child-bottom-border: 0 !default;
$ddl-default-font-family: inherit !default;
$ddl-list-border-size: 1px !default;
$ddl-list-bottom-border: $ddl-list-border-size !default;
$ddl-list-border-color: set-opacity(--black-700, 0.15) !default;
$ddl-list-font-family: inherit !default;
$ddl-list-gradient-color: normal-color(--white) !default;
$ddl-list-bg-color: normal-color(--white) !default;
$ddl-list-header-bg-color: normal-color(--white) !default;
$ddl-list-default-font-color: normal-color(--black) !default;
$ddl-list-active-border-color: normal-color(--white) !default;
$ddl-list-active-font-color: normal-color(--white) !default;
$ddl-list-active-bg-color: normal-color(--primary) !default;
$ddl-list-hover-bg-color: normal-color(--primary) !default;
$ddl-list-hover-border-color: normal-color(--primary) !default;
$ddl-list-hover-font-color: normal-color(--white) !default;
$ddl-nodata-font-color: normal-color(--black-700) !default;
$ddl-group-list-padding-left: 30px !default;
$ddl-header-font-weight: normal !default;
$ddl-list-header-padding-left: 20px !default;
$ddl-list-font-size: 14px !default;
$ddl-group-list-font-size: 12px !default;
$ddl-list-header-font-color: normal-color(--black-700) !default;
$ddl-list-text-indent: 20px !default;
$ddl-list-rtl-padding-right: 0 !default;
$ddl-list-line-height: 39px !default;
$ddl-list-padding-right: 24px !default;
$ddl-list-rtl-padding-left: 24px !default;
$ddl-bigger-list-header-font-size: $ddl-list-font-size !default;
$ddl-multi-column-border-width: 0 0 1px 0 !default;
$ddl-multi-column-border-color: set-opacity(--black-700, 0.12) !default;

// small size

$ddl-small-icon-font-size: 14px !default;
$ddl-small-line-height: 22px !default;
$ddl-small-list-font-color: normal-color(--black-700) !default;
$ddl-small-list-text-indent: 12px !default;

// Touch Small

$ddl-bigger-small-icon-font-size: 18px !default;
$ddl-bigger-small-line-height: 34px !default;
$ddl-bigger-small-list-font-color: normal-color(--black-700) !default;
$ddl-bigger-small-list-text-indent: 16px !default;
$ddl-bigger-small-list-header-font-size: 14px !default;

@include export-module("dropdownbase-bootstrap4") {
  .e-dropdownbase .e-list-item .e-list-icon {
    padding: 0 8px 0 0;
  }

  .e-bigger .e-content.e-dropdownbase {
    padding: 8px 0;
  }

  .e-content.e-dropdownbase {
    padding: 6px 0;
  }

  .e-bigger .e-dropdownbase .e-list-item .e-list-icon {
    font-size: 16px;
  }

  .e-bigger .e-dropdownbase .e-list-item .e-list-icon,
  .e-bigger.e-small .e-dropdownbase .e-list-item .e-list-icon {
    padding: 0 12px 0 0;
  }

  .e-small .e-dropdownbase .e-list-item .e-list-icon {
    padding: 0 8px 0 0;
  }

  .e-input-group.e-ddl .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle,
  .e-ddl.e-popup .e-filter-parent .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle {
    stroke-width: 1px;
  }
}
