// Global styling for this template

html,
body {
  height: 100%;
  font-family: $font-family-sans-serif !important;
  background-color: #F7F9FE;
  overflow-wrap: break-word;
}

a {
  color: $primary;
  text-decoration: none;
  line-height: inherit;
  -webkit-transition: all 200ms ease-in;
   -moz-transition: all 200ms ease-in;
   -ms-transition: all 200ms ease-in;
   -o-transition: all 200ms ease-in;
   transition: all 200ms ease-in;
}

a:active,
a:hover,
a:focus {
  outline: 0;
  text-decoration: none;
  color: $secondary;
}

// Set spacing on containers for page symmetry

.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width/1.5;
}

// FIXME: where should we put this one?
// It doesn't work if we add it to the modal component itself
.modal {
  z-index: 9999;
}

.e-calendar {
  z-index: 99;
}
// END FIXME


.checkmark {
  background-color: #fff;
  border: none;
  border: 2px solid $primary !important;
}

.checkbox input:checked + .checkmark {
  background-color: $primary !important;
  border: 2px solid $primary !important;
}

.btn {
  font-family: $font-family-sans-serif !important;
  text-transform: uppercase;
  border: none;
  border-radius: 0.75rem;
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.012),
  0 6.7px 5.3px rgba(0, 0, 0, 0.15),
  0 12.5px 8px rgba(0, 0, 0, 0.1) !important;
}

.btn-lg {
  font-weight: 500;
}

.btn-link {
  font-family: $font-family-sans-serif !important;
  text-transform: unset;
  color: $primary;
  box-shadow: none !important;
  text-decoration: none;

  &:hover {
    color: $secondary;
    text-decoration: none;
  };
}

.btn-secondary {
  background-color: $primary !important;

  &:hover, &:focus {
    color: $fontColor;
    background-color: #5acddf !important;
    path {
        transition: 0.2s;
        fill: #fff;
    }
  }

  &:disabled {
    color: #444444;
    background-color: #5acddfc4;
  }
  path {
      transition: 0.2s;
  }
}

.btn-primary-button {
  color: #303030;
  background-color: #ffd100;

  &:hover, &:focus {
      color: #fff;
      background-color: #facb53;
      path {
          transition: 0.2s;
          fill: #fff;
      }
  }

  &:disabled {
    color: #444444;
    background-color:#fad883;
  }
  path {
      transition: 0.2s;
  }
}

.btn-outline-primary {
  border-radius: 0.75rem;
  color: $primary !important;
  border: 1px solid $primary !important;
  background-color: #fff;
  transition: 0.2s;

  &:hover {
    transition: 0.2s;
    background-color: rgba(93, 174, 187, 0.1);
  }

  &:disabled {
    color: #444444 !important;
    border: none !important;
    background-color:rgba(93, 174, 187, 0.1);
  }
  path {
    transition: 0.2s;
    fill: $primary;
  }
}

.form-container {
  h3, h4 {
      color: $darkBlue;
  }

  h4 {
    font-size: 1.2rem;
  }

  .text-primary {
    color: $darkBlue !important;
  }
}

h1, h2, h3, h4, h5 {
  color: $darkBlue;
  font-weight: 600;
}

h5, h4 {
  .title-label {
    display: none;
  }
}

.label-wrapper label {
  color: #1B7989 !important;
}

.modal-backdrop {
  z-index: 9999 !important;
}

.text-body {
  color: $fontColor !important;
  font-size: 1rem !important;
}

.badge-purple {
  background-color: $purple;
  color: var(--white-color);
}

.badge-primary {
  background-color: $primary;
  color: var(--white-color);
}

.gauge-chart > span {
  font-weight: 500;
}

.gauge-chart .gauge-chart__label {
  font-weight: 800;
}

.avatar.patient, .avatar-initials.patient {
  border: 1rem solid $orange;
  border-radius: 15rem;
}

/* Smooth scrolling IF user doesn't have a preference due to motion sensitivities */
@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

#dialog-container {
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  width: 100%;
}
