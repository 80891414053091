@import "~bootstrap/scss/buttons";

.btn {
  &:not(.btn-sm) {
    min-height: 40px;
    min-width: max-content;
  }

  &-rounded {
    border-radius: 25px !important;
  }

  span {
    font-size: map-get($font-sizes, sm);
    vertical-align: middle;
  }
}

.btn-icon {
  pointer-events: none;
}
