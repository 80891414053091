﻿@include export-module('excel-filter-theme') {
  /*! Excel-Filter theme */
  #{&}.e-excelfilter {
    .e-footer-content {
      border-color: $grid-columnchooser-footer-border-color;
      opacity: $grid-columnchooser-footer-border-opacity;
    }

    .e-filtered::before {
      color: $grid-filtered-color;
    }
  }

  .e-xlsel {
    background-color: $grid-autofill-color;
    border-color: $grid-autofill-color;
  }
}
