$listview-font-family: $font-family !default;
$listview-text-color: normal-color(--black) !default;
$listview-text-disabled: disabled-color(--black-300) !default;
$listview-icon-color: normal-color(--black) !default;
$listview-icon-disabled: disabled-color(--black-300) !default;
$listview-icon-margin-right: 10px !default;
$listview-icon-line-height: 34px !default;
$listview-icon-back-margin: 0 !default;
$listview-rtl-icon-back-margin: 0 !default;
$listview-background: $content-bg !default;
$listview-line-color: normal-color(--black-700) !default;
$listview-item-hover-bg: hover-color(--white) !default;
$listview-item-height: 40px !default;
$listview-item-line-height: 1.5 !default;
$listview-item-padding: 8px 16px 8px 16px !default;

$listview-root-border-color: normal-color(--black-700);
$listview-root-border-radius: 4px;
$listview-nested-icon-collapsible: 10px;
$listview-focused-collapsible-color: $white !default;

$checkmark-bgcolor: $content-bg !default;
$checkmark-border-color: $content-bg !default;
$checkmark-color: normal-color(--primary) !default;
$listview-border-bottom: 1px !default;
$listview-border-top: 0 !default;
$listview-border-left: 0 !default;
$listview-border-right: 0 !default;
$listview-hover-border-color: normal-color(--black-700) !default;
$listview-border-bottom-color: normal-color(--black-700) !default;
$listview-border-top-color: transparent !default;
$listview-border-right-color: normal-color(--black-700) !default;
$listview-border-left-color: normal-color(--black-700) !default;
$listview-group-first-border-bottom: 1px !default;
$listview-group-border-top: 0 !default;
$listview-group-border-bottom: 1px !default;
$listview-text-hover-color: hover-color(--black) !default;
$listview-item-active-bg: active-color(--primary) !default;
$listview-text-active-color: normal-color(--white) !default;
$listview-header-text-color: normal-color(--black) !default;
$listview-header-text-disabled: disabled-color(--black-300) !default;
$listview-header-text-padding: 16px !default;
$listview-header-bg: $content-bg !default;
$listview-header-icon-color: normal-color(--black) !default;
$listview-header-icon-disabled: disabled-color(--black-300) !default;
$listview-header-border: normal-color(--black-700) !default;
$listview-header-font-size: 16px !default;
$listview-header-height: 40px !default;
$listview-header-font-weight: bold !default;
$listview-header-line-height: 1.2 !default;
$listview-header-padding-bottom: 4px !default;
$listview-groupheader-text-color: normal-color(--black-700) !default;
$listview-groupheader-bg: $content-bg !default;
$listview-groupheader-border: normal-color(--black-700) !default;
$listview-groupheader-icon: normal-color(--black) !default;
$listview-groupheader-item-height: 40px !default;
$listview-groupheader-item-line-height: 1.5 !default;
$listview-groupheader-font-size: 14px !default;
$listview-border-size: 1px !default;
$listview-font-size: 14px !default;
$listview-back-padding-right: 10px !default;
$listview-back-icon-height: 0 !default;
$listview-back-icon-font-size: 14px !default;
$listview-touch-item-height: 50px !default;
$listview-touch-item-line-height: 1.5 !default;
$listview-touch-item-font-size: 16px !default;
$listview-touch-groupheader-height: 50px !default;
$listview-touch-groupheader-line-height: 1.5 !default;
$listview-touch-header-height: 50px !default;
$listview-touch-header-font-weight: bold !default;
$listview-touch-back-icon-padding: 2px !default;
$listview-touch-header-font-size: 20px !default;
$listview-touch-header-line-height: 6px !default;
$listview-touch-header-icon-margin-top: 2px !default;
$listview-checkbox-left-margin: 0 10px 0 0 !default;
$listview-checkbox-right-margin: 0 0 0 10px !default;
$listview-rtl-checkbox-left-margin: 0 0 0 10px !default;
$listview-rtl-checkbox-right-margin: 0 10px 0 0 !default;
//ListView In-built template variables
$listview-template-padding: 0.7142em 1.0714em !default;
$listview-template-avatar-padding-right: 1.0714em !default;
$listview-template-avatar-padding-left: 4.6428em !default;
$listview-template-avatar-rightposition-padding-right: 4.6428em !default;
$listview-template-avatar-rightposition-padding-left: 1.0714em !default;
$listview-template-avatar-size: 2.8571em !default;
$listview-template-avatar-top: 0.7142em !default;
$listview-template-avatar-left: 1.0667em !default;
$listview-template-avatar-rightposition-right: 1.0667em !default;
$listview-template-badge-height: 1.333em !default;
$listview-template-badge-width: 2em !default;
$listview-template-badge-line-height: 1.433em !default;
$listview-template-badge-font-size: 15px !default;
$listview-template-badge-right: 1em !default;
$listview-template-avatar-badge-padding-right: 3.9285em !default;
$listview-template-avatar-badge-padding-left: 4.6428em !default;
$listview-template-badgewithoutavatar-padding-right: 3.9285em !default;
$listview-template-badgewithoutavatar-padding-left: 1.0714em !default;
$listview-template-item-padding: 0.83em 0 !default;
$listview-template-multiline-header-color: normal-color(--black) !default;
$listview-template-multiline-content-color: normal-color(--black) !default;
$listview-template-multiline-header-padding: 0.038em 0 !default;
$listview-template-multiline-content-padding: 0.038em 0 !default;
$listview-template-multiline-header-font-size: 18px !default;
$listview-template-multiline-padding: 0.7142em 1.0714em !default;
$listview-template-multiline-content-font-size: 14px !default;
$listview-template-multiline-avatar-top: 0.7142em !default;

.e-list-item {
  cursor: pointer;
  height: auto !important;

  &:hover:not(.e-active) {
    background-color: var(--black) !important;
  }
}
