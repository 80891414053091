//default

$radio-btn-background-color: $content-bg !default;
$radio-btn-border: 1px solid !default;
$radio-btn-border-color: normal-color(--black) !default;
$radio-btn-bigger-font-size: 16px !default;
$radio-btn-bigger-height: 16px !default;
$radio-btn-bigger-line-height: 1 !default;
$radio-btn-bigger-padding: 28px !default;
$radio-btn-bigger-small-height: 15px !default;
$radio-btn-bigger-small-line-height: 1 !default;
$radio-btn-bigger-small-padding: 28px !default;
$radio-btn-bigger-small-width: 15px !default;
$radio-btn-bigger-width: 16px !default;
$radio-btn-bigger-ripple-position: -11px !default;
$radio-btn-bigger-ripple-size: 48px !default;
$radio-btn-bigger-small-ripple-size: 36px !default;
$radio-btn-checked-border-color: normal-color(--success) !default;
$radio-btn-checked-color: normal-color(--success) !default;
$radio-btn-checked-background-color: normal-color(--white) !default;
$radio-btn-checked-ripple-bgcolor: transparent !default;
$radio-btn-check-transition: none !default;
$radio-btn-disabled-border-color: disabled-color(--black-300) !default;
$radio-btn-disabled-checked-border-color: transparent !default;
$radio-btn-disabled-background-color: disabled-color(--white) !default;
$radio-btn-disabled-color: disabled-color(--success) !default;
$radio-btn-disabled-checked-color: disabled-color(--success) !default;
$radio-btn-font-color: normal-color(--black) !default;
$radio-btn-font-size: 14px !default;
$radio-btn-focus-ripple-bgcolor: #000 !default;
$radio-btn-focussed-box-shadow: 0 0 0 1px set-opacity(--primary, 0.25) !default;
$radio-btn-hover-bgcolor: normal-color(--white) !default;
$radio-btn-hover-border-color: hover-color(--black) !default;
$radio-btn-hover-check-bg-color: normal-color(--success) !default;
$radio-btn-hover-check-border-color: normal-color(--success) !default;
$radio-btn-height: 14px !default;
$radio-btn-width: 14px !default;
$radio-btn-small-height: 10px !default;
$radio-btn-small-width: 10px !default;
$radio-btn-icon-left: 3px !default;
$radio-btn-icon-top: 3px !default;
$radio-btn-icon-right: 3px !default;
$radio-btn-ripple-position: -8px !default;
$radio-btn-ripple-size: 34px !default;
$radio-btn-small-icon-left: 3px !default;
$radio-btn-small-icon-top: 3px !default;
$radio-btn-small-icon-right: 3px !default;
$radio-btn-small-ripple-position: -10px !default;
$radio-btn-bigger-icon-left: 5px !default;
$radio-btn-bigger-icon-top: 5px !default;
$radio-btn-bigger-icon-right: 1px !default;
$radio-btn-bigger-small-icon-left: 1px !default;
$radio-btn-bigger-small-icon-top: 1px !default;
$radio-btn-bigger-small-icon-right: 1px !default;
$radio-btn-icon-height: 8px !default;
$radio-btn-icon-width: 8px !default;
$radio-btn-small-icon-height: 4px !default;
$radio-btn-small-icon-width: 4px !default;
$radio-btn-bigger-icon-height: 10px !default;
$radio-btn-bigger-icon-width: 10px !default;
$radio-btn-bigger-small-icon-height: 7px !default;
$radio-btn-bigger-small-icon-width: 7px !default;
$radio-btn-line-height: 1 !default;
$radio-btn-padding-left: 24px !default;
$radio-btn-ripple-bgcolor: transparent !default;
$radio-btn-small-line-height: 1 !default;
$radio-btn-small-padding: 22px !default;
$radio-btn-focus-check-bg-color: $radio-btn-hover-check-bg-color !default;
$radio-btn-focus-check-border-color: $radio-btn-hover-check-border-color !default;
$radio-btn-focus-outline: $radio-btn-background-color 0 solid !default;
$radio-btn-focus-outline-offset: 0 !default;
//enddefault
