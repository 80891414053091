//default

.e-input-group.e-ddl .e-control.e-autocomplete~.e-ddl-icon {
  font-size: 8px;
}

.e-bigger .e-input-group.e-ddl .e-control.e-autocomplete~.e-ddl-icon {
  font-size: 10px;
}

//enddefault
