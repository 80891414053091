.zdb-nav-fixed {
  .zdb-topnav {
    @extend .fixed-top;
    z-index: $zindex-topnav;
  }
  #layoutSidenav {
    #layoutSidenav_nav {
      @extend .fixed-top;
      width: $sidenav-base-width;
      height: 100vh;
      z-index: $zindex-sidenav;
      .zdb-sidenav {
        padding-top: $topnav-base-height;
        .zdb-sidenav-menu {
          padding-top: $topnav-base-padding-top;
          overflow-y: auto;
        }
      }
    }
    #layoutSidenav_content {
      padding-left: $sidenav-base-width;
      margin-top: $topnav-base-height;
      padding-top: $topnav-base-padding-top;
    }
  }
}
