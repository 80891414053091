$switch-wrapper-width: 44px !default;
$switch-wrapper-height: 20px !default;
$switch-inner-width: 100% !default;
$switch-inner-height: 100% !default;
$switch-active-handle-bg: normal-color(--white) !default;
$switch-active-on-position-left: 0 !default;
$switch-inner-border-style: 1px solid normal-color(--secondary) !default;
$switch-inner-active: normal-color(--success) !default;
$switch-inner-active-bg: normal-color(--success) !default;
$switch-focus-inner-box-shadow: 0 0 0 3px set-opacity(--primary, 0.25) !default;
$switch-inner-active-border-color: normal-color(--success) !default;
$switch-active-background: transparent !default;
$switch-handle-top: 2px !default;
$switch-handle-left: 1px !default;
$switch-handle-margin: auto 2px !default;
$switch-inner-top: 0 !default;
$switch-inner-left: 0 !default;
$switch-off-text-indent: 14px !default;
$switch-on-text-indent: -12px !default;
$switch-handle-active: 100% !default;
$switch-handle-margin-left: -14px !default;
$switch-handle-shadow: none !default;
$switch-handle-shadow-disabled: none !default;
$switch-active-on-opacity: 1 !default;
$switch-off-opacity: 1 !default;
$switch-off-bg-color: transparent !default;
$switch-off-active-left: 100% !default;
$switch-inner-bg: $content-bg !default;
$switch-handle-bg-color: normal-color(--primary) !default;
$switch-hover-bg-color: hover-color(--white) !default;
$switch-handle-height: 12px !default;
$switch-handle-width: 12px !default;
$switch-transition: all 0.2s ease-in-out 0.1s !default;
$switch-inner-transition: all 0.2s ease-in-out !default;
$switch-handle-transition: all 0.2s linear !default;
$switch-font-family: $font-family !default;
$switch-font-size: 12px !default;
$switch-inner-hover-bg-color: hover-color(--black) !default;
$switch-inner-hover-border-color: hover-color(--success) !default;
$switch-inner-on-font-color: normal-color(--white) !default;
$switch-inner-off-font-color: normal-color(--black) !default;
$switch-inner-focus-border: focused-color(--success) !default;
$switch-on-hover-bg-color: hover-color(--success) !default;
$switch-on-off-disabled-bg-color: disabled-color(--black-300) !default;
$switch-on-off-disabled-bg-color-opacity: 0.5 !default;
$switch-handle-disabled-bg-color: disabled-color(--black-300) !default;
$switch-handle-on-disabled-bg-color: disabled-color(--black-300) !default;
$switch-border-disabled-bg-color: disabled-color(--black-300) !default;
$switch-disabled-font-color: disabled-color(--black-300) !default;
$switch-on-off-hover-bg-color: hover-color(--black) !default;
$switch-inner-focus-bg-color: focused-color(--black) !default;
$switch-inner-focus-off-bg: $content-bg !default;
$switch-focus-border-color-active: focused-color(--success) !default;
$switch-hover-border: hover-color(--black) !default;
$switch-hover-active-border: hover-color(--success) !default;
$switch-handle-hover-bg-color: hover-color(--white) !default;
$switch-on-position-left: -100% !default;
$switch-on-position-left-rtl: 0 !default;
$switch-off-position-left: 0 !default;
$switch-off-position-left-rtl: -100% !default;
$switch-on-rtl: 100% !default;
$switch-off-rtl: 0 !default;
$switch-handle-radius: 50% !default;
$switch-handle-bottom: 2px !default;
$switch-border-radius: 20px !default;
$switch-small-ripple-height: 36px !default;
$switch-small-ripple-left: -10px !default;
$switch-small-ripple-top: -10px !default;
$switch-small-ripple-width: 36px !default;
$switch-small-wrapper-height: 16px !default;
$switch-small-wrapper-width: 38px !default;
$switch-small-handle-width: 12px !default;
$switch-small-handle-height: 12px !default;
$switch-small-handle-active-left: 100% !default;
$switch-small-handle-margin-left: -14px !default;
$switch-small-handle-active-left-rtl: 16px !default;
$switch-small-font-size: 12px !default;
$small-switch-text-indent: 14px !default;
$switch-small-on-rtl: 100% !default;
$switch-small-off-rtl: 0 !default;
$switch-small-on-position-left-rtl: 0 !default;
$switch-small-off-position-left-rtl: -100% !default;
$switch-small-on-text-indent: -12px !default;
$switch-small-off-text-indent: 14px !default;
$switch-small-disabled-rtl-active-bg-color: transparent !default;
$switch-on-bg-color: normal-color(--success) !default;
$switch-checked-ripple-bg-color: normal-color(--black) !default;
$switch-ripple-bg-color: focused-color(--error) !default;
$switch-on-disabled-bg-color: disabled-color(--success) !default;
$switch-inner-hover-on-font-color: hover-color(--white) !default;
$switch-focused-outline: none !default;
$switch-focused-outline-offset: initial !default;
$switch-focused-outline-active: none !default;
$switch-handle-off-hover-bg-color: hover-color(--black) !default;

$switch-bigger-wrapper-height: 16px !default;
$switch-bigger-wrapper-width: 38px !default;
$switch-bigger-handle-width: 12px !default;
$switch-bigger-handle-height: 12px !default;
$switch-bigger-handle-active-left: 100% !default;
$switch-bigger-handle-margin-left: -14px !default;
$switch-bigger-handle-active-left-rtl: 16px !default;
$switch-bigger-font-size: 12px !default;
$switch-bigger-on-text-indent: -12px !default;
$switch-bigger-off-text-indent: 14px !default;
$switch-bigger-on-rtl: 100% !default;
$switch-bigger-off-rtl: 0 !default;
$switch-bigger-on-position-left-rtl: 0 !default;
$switch-bigger-off-position-left-rtl: -100% !default;
$switch-bigger-handle-top: 2px !default;
$switch-bigger-handle-left: 1px !default;
$switch-bigger-ripple-height: 52px !default;
$switch-bigger-ripple-left: -16px !default;
$switch-bigger-ripple-top: -16px !default;
$switch-bigger-ripple-width: 52px !default;

$switch-bigger-small-wrapper-height: 16px !default;
$switch-bigger-small-wrapper-width: 38px !default;
$switch-bigger-small-handle-width: 12px !default;
$switch-bigger-small-handle-height: 12px !default;
$switch-bigger-small-handle-active-left: 100% !default;
$switch-bigger-small-handle-margin-left: -14px !default;
$switch-bigger-small-handle-active-left-rtl: 16px !default;
$switch-bigger-small-font-size: 12px !default;
$bigger-small-switch-text-indent: 14px !default;
$switch-bigger-small-on-rtl: 100% !default;
$switch-bigger-small-off-rtl: 0 !default;
$switch-bigger-small-on-position-left-rtl: 0 !default;
$switch-bigger-small-off-position-left-rtl: -100% !default;
$switch-bigger-small-on-text-indent: -12px !default;
$switch-bigger-small-off-text-indent: 14px !default;
$switch-bigger-small-handle-to: 2px !default;
$switch-bigger-small-handle-left: 1px !default;
$switch-bigger-small-ripple-height: 36px !default;
$switch-bigger-small-ripple-left: -10px !default;
$switch-bigger-small-ripple-top: -10px !default;
$switch-bigger-small-ripple-width: 36px !default;
