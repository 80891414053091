$ddl-input-font-size: 14px !default;
// $ddl-input-border: $border-size $border-type !default;
$ddl-input-font-family: inherit !default;
$ddl-zero-value: 0 !default;
$ddl-input-margin-bottom: $ddl-zero-value !default;
$ddl-input-padding: 8px !default;
$ddl-input-group-border-width: 1px !default;
$ddl-clear-icon-margin-right: 0 !default;
$ddl-active-font-color: normal-color(--white) !default;
$ddl-list-search-icon-padding: 8px !default;
$ddl-list-filter-text-indent: 0 20px !default;
$ddl-list-icon-color: normal-color(--black-700) !default;
$ddl-bigger-list-font-size: 16px !default;
$ddl-popup-background-color: $content-popup-bg !default;
$ddl-filter-border: set-opacity(--black-700, 0.12) !default;
$ddl-filter-bg: set-opacity(--black-700, 0.03) !default;
$ddl-filter-top-border: 0 !default;
$ddl-filter-padding: 0 !default;
$ddl-filter-box-shadow: none !default;
$ddl-filter-background-color: normal-color(--white) !default;
$ddl-default-border-color: set-opacity(--black-700, 0.15) !default;
$ddl-back-icon-margin: -2px 10px 0 -54px !default;
$ddl-back-icon-padding: 0 !default;
$ddl-icon-color: set-opacity(--black-700, 0.5) !default;
$ddl-icon-hover-color: set-opacity(--black-700, 0.4) !default;

// small size
$ddl-small-list-font-size: 13px !default;

// touch size
$ddl-bigger-small-list-font-size: 14px !default;

@include export-module("dropdownlist-bootstrap4") {
  #{&}.e-popup.e-ddl {
    border-radius: 4px;
    box-shadow: none;
    margin-top: 3px;
    overflow: auto;
    @at-root {
      & .e-input-group {
        width: auto;

        & input {
          line-height: 15px;
        }
      }
    }
  }

  #{&}.e-popup.e-ddl .e-dropdownbase {
    min-height: 26px;
  }

  #{&}.e-popup.e-ddl .e-filter-parent .e-input-group {
    display: flex;
    width: auto;
    @at-root {
      & .e-back-icon {
        border: 0;
      }
    }
  }

  .e-bigger #{&}.e-popup.e-ddl-device-filter {
    margin-top: 0;
  }

  .e-bigger #{&}.e-popup.e-ddl {
    @at-root {
      & .e-list-item {
        font-size: 16px;
        line-height: 32px;
        padding-left: 0;
        text-indent: 24px;
      }

      & .e-list-group-item,
      & .e-fixed-head {
        font-size: 14px;
        line-height: 32px;
        padding-left: 0;
        text-indent: 24px;
      }

      & .e-dd-group .e-list-item {
        padding-left: 4px;
      }

      & .e-input-group {
        padding: 4px 0;
      }

      & .e-input-group input,
      & .e-input-group input.e-input {
        font-size: 16px;
        height: 36px;
      }
    }
  }

  .e-bigger #{&}.e-popup.e-ddl .e-dropdownbase {
    min-height: 40px;
  }

  .e-input-group#{&}.e-control-wrapper.e-ddl .e-input[readonly],
  .e-float-input#{&}.e-control-wrapper.e-ddl input[readonly] {
    background: transparent;
  }

  .e-input-group:not(.e-disabled) .e-control#{&}.e-dropdownlist ~ .e-ddl-icon:active,
  .e-input-group:not(.e-disabled) .e-control#{&}.e-dropdownlist ~ .e-ddl-icon:hover,
  .e-control#{&}.e-dropdownlist .e-input-group:not(.e-disabled) .e-ddl-icon:active,
  .e-control#{&}.e-dropdownlist .e-input-group:not(.e-disabled) .e-ddl-icon:hover,
  .e-ddl#{&}.e-popup .e-input-group:not(.e-disabled) .e-clear-icon:active,
  .e-ddl#{&}.e-popup .e-input-group:not(.e-disabled) .e-clear-icon:hover,
  .e-ddl#{&}.e-popup .e-input-group:not(.e-disabled) .e-back-icon:active,
  .e-ddl#{&}.e-popup .e-input-group:not(.e-disabled) .e-back-icon:hover {
    background: transparent;
    color: $ddl-list-icon-color;
  }

  .e-input-group:not(.e-disabled) .e-control#{&}.e-dropdownlist ~ .e-ddl-icon,
  .e-input-group.e-disabled.e-ddl .e-control#{&}.e-dropdownlist ~ .e-input-group-icon,
  .e-control#{&}.e-dropdownlist .e-input-group.e-disabled.e-ddl .e-input-group-icon,
  .e-control#{&}.e-dropdownlist .e-input-group.e-ddl .e-input-group-icon {
    border: 0;
  }

  .e-input-group:not(.e-disabled) .e-control#{&}.e-dropdownlist~.e-input-group-icon:active,
  .e-control#{&}.e-dropdownlist .e-input-group:not(.e-disabled) .e-input-group-icon:active,
  .e-ddl#{&}.e-popup .e-input-group:not(.e-disabled) .e-back-icon:active,
  .e-ddl#{&}.e-popup .e-input-group:not(.e-disabled) .e-clear-icon:active {
    box-shadow: none;
  }

  .e-ddl#{&}.e-popup .e-input-group:not(.e-disabled) .e-clear-icon {
    background: transparent;
    display: flex;
    position: relative;
  }

  .e-ddl#{&}.e-popup .e-filter-parent .e-input-group,
  .e-ddl#{&}.e-popup .e-filter-parent {
    background: $ddl-filter-bg;
  }

  .e-input-group .e-control#{&}.e-dropdownlist~.e-ddl-icon {
    font-size: 8px;
  }

  .e-bigger .e-input-group .e-control#{&}.e-dropdownlist~.e-ddl-icon {
    font-size: 10px;
  }

  .e-bigger.e-small .e-ddl#{&}.e-popup {
    @at-root {
      & .e-list-item,
      & .e-list-group-item,
      & .e-fixed-head {
        font-size: 14px;
        line-height: 34px;
        padding-left: 0;
        text-indent: 16px;
      }

      & .e-dd-group .e-list-item {
        padding-left: 4px;
      }

      & .e-input-group {
        padding: 4px 0;
      }

      & .e-input-group input,
      & .e-input-group input.e-input {
        height: 30px;
      }
    }
  }

  .e-bigger.e-small #{&}.e-popup.e-ddl .e-dropdownbase {
    min-height: 34px;
  }
}
