/* Variables */

@import "ej2-dropdowns/dropdown-base";
@import "ej2-dropdowns/drop-down-list";
@import "ej2-dropdowns/drop-down-tree";
@import "ej2-dropdowns/auto-complete";
@import "ej2-dropdowns/combo-box";
@import "ej2-dropdowns/list-box";
@import "ej2-dropdowns/multi-select";

/* Layouts Themes and Icons */

/* Drop Down List */
@import "~@syncfusion/ej2-dropdowns/styles/drop-down-list/icons/bootstrap4";
@import "~@syncfusion/ej2-dropdowns/styles/drop-down-list/layout";
@import "~@syncfusion/ej2-dropdowns/styles/drop-down-list/theme";

/* Drop Down Tree */
@import "~@syncfusion/ej2-dropdowns/styles/drop-down-tree/icons/bootstrap4";
@import "~@syncfusion/ej2-dropdowns/styles/drop-down-tree/layout";
@import "~@syncfusion/ej2-dropdowns/styles/drop-down-tree/theme";


/* Dropdown Base */
@import "~@syncfusion/ej2-dropdowns/styles/drop-down-base/layout";
@import "~@syncfusion/ej2-dropdowns/styles/drop-down-base/theme";

/* List Box */
@import "~@syncfusion/ej2-dropdowns/styles/list-box/icons/bootstrap4";
@import "~@syncfusion/ej2-dropdowns/styles/list-box/layout";
@import "~@syncfusion/ej2-dropdowns/styles/list-box/theme";

/* Multi Select */
@import "~@syncfusion/ej2-dropdowns/styles/multi-select/icons/bootstrap4";
@import "~@syncfusion/ej2-dropdowns/styles/multi-select/layout";
@import "~@syncfusion/ej2-dropdowns/styles/multi-select/theme";

/* Customizations */
@import "ej2-dropdowns/customization/drop-down-tree";
@import "ej2-dropdowns/customization/multiselect";
