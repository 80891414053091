.tip-content {
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-family: $font-family;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  min-height: 40px;
}

.e-tooltip-wrap {
  box-shadow: $tooltip-drop-shadow;
}

.e-tooltip-wrap .e-tip-content {
  @extend .tip-content;
}

@each $color, $value in $theme-colors {
  .e-tooltip-#{$color} {
    &.e-tooltip-wrap {
      &.e-popup {
        background-color: normal-color($value);
        border: 0;
      }

      & .e-tip-content {
        @extend .tip-content;

        color: color-yiq($value);
      }

      & .e-arrow-tip-outer.e-tip-bottom {
        border-top-color: set-opacity($value, 0.25);
      }

      /* To customize the arrow tip at top */
      & .e-arrow-tip-outer.e-tip-top {
        border-bottom-color: set-opacity($value, 0.25);
      }

      /* To customize the arrow tip at left */
      & .e-arrow-tip-outer.e-tip-left {
        border-right-color: set-opacity($value, 0.25);
      }

      /* To customize the arrow tip at right */
      & .e-arrow-tip-outer.e-tip-right {
        border-left-color: set-opacity($value, 0.25);
      }

      & .e-arrow-tip-inner.e-tip-top,
      & .e-arrow-tip-inner.e-tip-bottom,
      & .e-arrow-tip-inner.e-tip-right,
      & .e-arrow-tip-inner.e-tip-left {
        color: normal-color($value);
      }
    }
  }
}

