$cmenu-back-icon: "\e728" !default;
$cmenu-back-icon-margin: 12px !default;
$cmenu-bigger-caret-font-size: 12px !default;
$cmenu-bigger-font-size: 16px !default;
$cmenu-bigger-li-height: 32px !default;
$cmenu-bigger-max-width: 280px !default;
$cmenu-bigger-min-width: 112px !default;
$cmenu-blank-icon: 42px !default;
$cmenu-blank-icon-bigger: 42px !default;
$cmenu-caret-blank-icon: 14px !default;
$cmenu-caret-font-size: 9px !default;
$cmenu-caret-color: normal-color(--black) !default;
$cmenu-caret-icon: "\e799" !default;
$cmenu-caret-icon-rtl: "\e78c" !default;
$cmenu-caret-li-padding: 36px !default;
$cmenu-caret-right: 14px !default;
$cmenu-color: normal-color(--black) !default;
$cmenu-disable-text: disabled-color(--black-300) !default;
$cmenu-font-size: 14px !default;
$cmenu-font-weight: normal !default;
$cmenu-icon-margin-right: 8px !default;
$cmenu-li-bgcolor: normal-color(--white-100) !default;
$cmenu-li-border-color: normal-color(--white-100) !default;
$cmenu-li-border-style: solid !default;
$cmenu-li-border-width: 1px !default;
$cmenu-li-height: 26px !default;
$cmenu-li-padding: 0 20px !default;
$cmenu-li-right-padding: 20px !default;
$cmenu-li-selection-bgcolor: normal-color(--primary) !default;
$cmenu-li-selection-font-color: normal-color(--black) !default;
$cmenu-li-hover-outline-offset: 0 !default;
$cmenu-li-selected-outline: 0 solid $cmenu-li-selection-bgcolor !default;
$cmenu-li-selected-outline-offset: 0 !default;
$cmenu-max-width: 240px !default;
$cmenu-icon-color: normal-color(--black) !default;
$cmenu-icon-disabled-color: disabled-color(--black-300) !default;
$cmenu-icon-font-size: 14px !default;
$cmenu-icon-bigger-font-size: 16px !default;
$cmenu-min-width: 120px !default;
$cmenu-parent-ul-box-shadow: none !default;
$cmenu-seperator-bigger-padding: 5px 0 !default;
$cmenu-seperator-padding: 6px 0 !default;
$cmenu-selected-color: $white !default;
$cmenu-sub-ul-box-shadow: none !default;
$cmenu-ul-bgcolor: $content-popup-bg !default;
$cmenu-ul-border: 1px solid set-opacity(--black, 0.15) !default;
$cmenu-ul-border-radius: 4px !default;
$cmenu-ul-padding: 6px 0 !default;
$cmenu-ul-bigger-padding: 8px 0 !default;
$cmenu-li-hover-outline: 0 solid $cmenu-li-border-color !default;
$cmenu-caret-hover-color: hover-color(--black) !default;
$cmenu-hover-icon-color: hover-color(--black) !default;
