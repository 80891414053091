/*! Horizontal Tab */
$tab-skin: "bootstrap4" !default;
$tab-big-vertical-scroll-nav-top-bottom: 23px !default;
$tab-big-vertical-nav-arrow-size: 24px !default;
$tab-big-vertical-nav-arrow-font-size: 12px !default;
$tab-font-family: $font-family !default;
$tab-nrml-vertical-scroll-nav-top-bottom: 15px !default;
$tab-nrml-vertical-nav-arrow-size: 16px !default;
$tab-nrml-vertical-nav-arrow-font-size: 10px !default;

$tab-text-transform: capitalize !default;
$tab-big-height: 42px !default;
$tab-nrml-height: 32px !default;
$tab-focus-big-height: 42px !default;
$tab-focus-nrml-height: 32px !default;
$tab-active-big-height: 42px !default;
$tab-active-nrml-height: 32px !default;
$tab-active-focus-big-height: 42px !default;
$tab-active-focus-nrml-height: 32px !default;
$tab-big-fill-height: 42px !default;
$tab-nrml-fill-height: 32px !default;
$tab-mob-fill-height: 42px !default;
$tab-focus-big-fill-height: 42px !default;
$tab-focus-nrml-fill-height: 32px !default;
$tab-disable-cnt-opacity: 0.38 !default;
$tab-big-tb-icon-height: 72px !default;
$tab-nrml-tb-icon-height: 62px !default;
$tab-content-font-color: #000 !default;

$tab-border-width: 2px !default;
$tab-border-style: $tab-border-width Solid !default;
$tab-focus-outline: 0 !default;
$tab-active-focus-outline: 0 !default;
$tab-active-text-item-border: 0 !default;
$tab-disable-active-text-item-border: 0 !default;
$tab-alt-active-item-border: $tab-border-style #555 !default;
$tab-accent-active-item-border: $tab-border-style #555 !default;
$tab-fill-hdr-border: 0 !default;
$tab-fill-disable-hdr-border: 0 !default;
$tab-anim-border-display: none !default;
$tab-border-color: variant-color(-white-300) !default;
$tab-hover-border-color: hover-color(-white-300) !default;
$tab-disable-border-color: disabled-color(-white-300) !default;
$tab-alt-border-color: variant-color(-white-300) !default;
$tab-alt-disable-border-color: disabled-color(-white-300) !default;
$tab-bg-color: transparent !default;
$tab-border: none !default;

$tab-alt-hdr-bg-color: inherit !default;
$tab-focus-hscroll-content-padding: 1px 0 !default;
$tab-hdr-bdr-color:  normal-color(-white-300) !default;
$tab-hdr-border: 1px solid #ddd !default;
$tab-hdr-border-width: 0 0 1px 0 !default;
$tab-alt-hdr-border: 0 !default;

$tab-items-border: 1px solid #ddd !default;

$tab-big-item-margin: 0 2px 0 0 !default;
$tab-nrml-item-margin: 0 2px 0 0 !default;
$tab-big-item-padding: 0 !default;
$tab-nrml-item-padding: 0 !default;
$tab-big-icon-top-bottom-item-height: 72px !default;
$tab-nrml-icon-top-bottom-item-height: 62px !default;
$tab-pop-big-icon-top-bottom-item-height: 50px !default;
$tab-pop-nrml-icon-top-bottom-item-height: 40px !default;
$tab-big-focus-icon-top-bottom-item-height: 72px !default;
$tab-nrml-focus-icon-top-bottom-item-height: 62px !default;
$tab-big-fill-icon-top-bottom-item-height: 72px !default;
$tab-nrml-fill-icon-top-bottom-item-height: 62px !default;
$tab-big-focus-fill-icon-top-bottom-item-height: 72px !default;
$tab-nrml-focus-fill-icon-top-bottom-item-height: 62px !default;
$tab-big-first-item-margin: 0 !default;
$tab-nrml-first-item-margin: 0 !default;
$tab-big-last-item-margin: 0 2px 0 0 !default;
$tab-nrml-last-item-margin: 0 !default;
$tab-big-active-last-item-margin: 0 2px 0 0 !default;
$tab-nrml-active-last-item-margin: 0 2px 0 0 !default;
$tab-big-scroll-last-item-margin: 0 2px 0 0 !default;
$tab-nrml-scroll-last-item-margin: 0 2px 0 0 !default;
$tab-nrml-active-scroll-last-item-margin: 0 2px 0 0 !default;
$tab-mob-last-item-margin: 0 2px 0 0 !default;
$tab-big-even-last-item-margin: 0 2px 0 0 !default;
$tab-nrml-even-last-item-margin: 0 2px 0 0 !default;
$tab-mob-even-last-item-margin: 0 2px 0 0 !default;
$tab-big-alt-first-item-margin: 0 !default;
$tab-nrml-alt-first-item-margin: 0 !default;
$tab-pop-item-margin: 0 !default;
$tab-big-pop-item-height: 40px !default;
$tab-nrml-pop-item-height: 26px !default;
$tab-alt-item-margin: 0 2px 0 0 !default;
$tab-big-rtl-item-margin: 0 !default;
$tab-nrml-rtl-item-margin: 0 !default;
$tab-big-rtl-alt-item-margin: 0 0 0 2px !default;
$tab-nrml-rtl-alt-item-margin: 0 0 0 2px !default;
$tab-mob-pop-item-height: 50px !default;
$tab-ripple-bg-color: set-opacity(-white-300, 0) !default;
$tab-fill-ripple-bg-color: set-opacity(-white-300, 0) !default;
$tab-alt-ripple-bg-color: set-opacity(-white-300, 0) !default;
$tab-item-border: 1px solid transparent !default;
$tab-alt-item-border: 0 !default;
$tab-active-item-border: 1px solid active-color(-white-300) !default;
$tab-active-item-hide-border: 1px solid normal-color(--black) !default;
$tab-active-item-border-color: normal-color(--black) !default;
$tab-big-boot-item-margin: 0 2px 0 0 !default;
$tab-nrml-boot-item-margin: 0 2px 0 0 !default;
$tab-big-scroll-boot-item-margin: 0 2px 0 0 !default;
$tab-nrml-scroll-boot-item-margin: 0 2px 0 0 !default;
$tab-mob-boot-item-margin: 0 2px 0 0 !default;
$tab-big-even-boot-item-margin: 0 2px 0 0 !default;
$tab-nrml-even-boot-item-margin: 0 2px 0 0 !default;
$tab-mob-even-boot-item-margin: 0 2px 0 0 !default;
$tab-big-boot-active-item-padding-bottom: 0 !default;
$tab-nrml-boot-active-item-padding-bottom: 0 !default;
$tab-big-even-boot-active-item-padding-bottom: 1px !default;
$tab-nrml-even-boot-active-item-padding-bottom: 0 !default;
$tab-active-item-border-radius: 4px !default;
$tab-item-wrap-border-radius: 4px !default;
$tab-top-active-item-border-radius: 4px 4px 0 0 !default;
$tab-bottom-active-item-border-radius: 0 0 4px 4px !default;
$tab-left-active-item-border-radius: 4px 0 0 4px !default;
$tab-right-active-item-border-radius: 0 4px 4px 0 !default;
$tab-hscroll-item-padding: 0 !default;
$tab-big-item-last-child-padding-bottom: 3px !default;
$tab-nrml-item-last-child-padding-bottom: 1px !default;

$tab-big-wrap-padding: 0 16px !default;
$tab-nrml-wrap-padding: 0 12px !default;
$tab-mob-wrap-padding: 0 15px !default;
$tab-pop-padding: 5px 0 !default;
$tab-big-pop-item-wrap-padding: 0 24px !default;
$tab-nrml-pop-item-wrap-padding: 0 20px !default;
$tab-mob-pop-item-wrap-padding: 0 24px !default;
$tab-big-io-text-wrap-width: auto !default;
$tab-nrml-io-text-wrap-width: auto !default;
$tab-big-io-wrap-padding: 0 20px !default;
$tab-nrml-io-wrap-padding: 0 15px !default;
$tab-wrap-focus-margin-right: 0 !default;
$tab-wrap-focus-margin-left: 0 !default;
$tab-big-ileft-active-text-wrap-padding: 0 !default;
$tab-nrml-ileft-active-text-wrap-padding: 0 !default;
$tab-big-even-ileft-active-text-wrap-margin: 0 !default;
$tab-nrml-even-ileft-active-text-wrap-margin: 0 !default;
$tab-big-fill-tb-wrap-height: 50px !default;
$tab-nrml-fill-tb-wrap-height: 44px !default;
$tab-focus-bg-color: focused-color(--white) !default;
$tab-fill-focus-bg-color: focused-color(--white) !default;
$tab-hover-bg-color: hover-color(--white) !default;
$tab-active-bg-color: normal-color(--white-100) !default;
$tab-fill-active-bg-color: normal-color(--primary) !default;
$tab-fill-disable-active-bg-color: disabled-color(--primary) !default;
$tab-alt-active-bg-color: active-color(--white) !default;
$tab-alt-disable-active-bg-color: disabled-color(--white) !default;
$tab-alt-item-bg-color: inherit !default;
$tab-alt-active-focus-bg-color: focused-color(--white) !default;
$tab-key-alt-active-focus-bg-color: focused-color(--white) !default;
$tab-alt-hover-bg-color: hover-color(--white) !default;
$tab-alt-focus-top-bottom-bg-color: inherit !default;
$tab-fill-active-wrap-corner: 3px 3px 0 0 !default;
$tab-fill-even-active-wrap-corner: 0 0 3px 3px !default;
$tab-alt-tb-active-bg-color: active-color(--white) !default;
$tab-fill-big-wrap-padding: 0 20px !default;
$tab-fill-nrml-wrap-padding: 0 15px !default;
$tab-mob-fill-wrap-padding: 0 15px !default;
$tab-bg-big-wrap-padding: 0 20px !default;
$tab-bg-nrml-wrap-padding: 0 15px !default;
$tab-item-hover-bg-color: transparent !default;
$tab-mob-item-hover-bg-color: initial !default;
$tab-pop-text-color: normal-color(--black) !default;
$tab-alt-disable-active-item-opacity: 0.65 !default;
$tab-big-active-item-wrap-margin-bottom: 1px !default;
$tab-nrml-active-item-wrap-margin-bottom: 0 !default;
$tab-fill-big-active-item-wrap-margin-bottom: 0 !default;
$tab-fill-nrml-active-item-wrap-margin-bottom: 0 !default;
$tab-bg-big-active-item-wrap-margin-bottom: 0 !default;
$tab-bg-nrml-active-item-wrap-margin-bottom: 0 !default;
$tab-alt-big-active-item-padding: 0 !default;
$tab-alt-nrml-active-item-padding: 0 !default;

$tab-active-text-container-margin: 0 !default;
$tab-big-text-container-margin-right: 22px !default;
$tab-nrml-text-container-margin-right: 20px !default;
$tab-rtl-big-text-container-margin-right: 0 !default;
$tab-rtl-nrml-text-container-margin-right: 0 !default;
$tab-rtl-big-text-container-margin-left: 22px !default;
$tab-rtl-nrml-text-container-margin-left: 20px !default;
$tab-big-active-text-container-height: 42px !default;
$tab-nrml-active-text-container-height: 32px !default;
$tab-big-active-it-text-container-height: 42px !default;
$tab-nrml-active-it-text-container-height: 32px !default;
$tab-fill-big-active-it-text-container-height: 42px !default;
$tab-fill-nrml-active-it-text-container-height: 32px !default;
$tab-big-even-active-text-container-height: 42px !default;
$tab-nrml-even-active-text-container-height: 32px !default;
$tab-even-active-text-container-padding: 0 !default;
$tab-even-active-text-container-margin: 0 !default;

$tab-big-font-size: 16px !default;
$tab-nrml-font-size: 14px !default;
$tab-font-weight: 400 !default;
$tab-semi-font-weight: 400 !default;
$tab-light-font-weight: 400 !default;
$tab-big-it-text-margin: 0 0 0 12px !default;
$tab-nrml-it-text-margin: 0 0 0 8px !default;
$tab-mob-it-text-margin: 0 0 0 10px !default;
$tab-rtl-mob-it-text-margin: 0 10px 0 0 !default;
$tab-rtl-big-it-text-margin: 0 10px 0 0 !default;
$tab-rtl-nrml-it-text-margin: 0 8px 0 0 !default;
$tab-big-icon-top-margin: 10px 0 0 !default;
$tab-nrml-icon-top-margin: 8px 0 0 !default;
$tab-big-icon-bottom-margin: 0 0 10px !default;
$tab-nrml-icon-bottom-margin: 0 0 8px !default;
$tab-big-pop-text-padding-right: 12px !default;
$tab-nrml-pop-text-padding-right: 10px !default;
$tab-rtl-big-pop-text-padding-left: 12px !default;
$tab-rtl-nrml-pop-text-padding-left: 10px !default;
$tab-big-even-active-text-height: 50px !default;
$tab-nrml-even-active-text-height: 40px !default;
$tab-fill-big-even-active-text-height: 50px !default;
$tab-fill-nrml-even-active-text-height: 40px !default;
$tab-text-color: normal-color(--primary) !default;
$tab-disable-text-color: disabled-color(--primary) !default;
$tab-mob-text-color: normal-color(--primary) !default;
$tab-active-text-color: normal-color(--black) !default;
$tab-disable-active-text-color: disabled-color(--black-300) !default;
$tab-focus-text-color: focused-color(--primary) !default;
$tab-hover-text-color: hover-color(--primary) !default;
$tab-pop-active-item-text-color: $tab-active-text-color !default;
$tab-alt-text-color: normal-color(--primary) !default;
$tab-alt-disable-text-color: disabled-color(--black-300) !default;
$tab-alt-active-text-color: normal-color(--black) !default;
$tab-alt-active-style-hover-text-color: normal-color(--black) !default;
$tab-alt-disable-active-text-color: active-color(--black) !default;
$tab-alt-hover-text-color: hover-color(--black) !default;
$tab-alt-active-hover-text-color: normal-color(--black) !default;

$tab-icon-container-width: auto !default;

$tab-big-nav-icon-container-size: 38px !default;
$tab-nrml-nav-icon-container-size: 32px !default;
$tab-big-nav-icon-size: 12px !default;
$tab-nrml-nav-icon-size: 10px !default;
$tab-mob-nav-icon-size: 16px !default;
$tab-mob-nav-vicon-size: 12px !default;
$tab-big-icon-size: 16px !default;
$tab-nrml-icon-size: 14px !default;
$tab-mob-icon-size: 20px !default;
$tab-big-icon-container-size: 24px !default;
$tab-nrml-icon-container-size: 20px !default;
$tab-mob-icon-container-size: 20px !default;
$tab-big-nav-icon-top: 11px !default;
$tab-nrml-nav-icon-top: 5px !default;
$tab-big-nav-pop-icon-top: 13px !default;
$tab-nrml-nav-pop-icon-top: 8px !default;
$tab-big-hover-nav-pop-icon-top: 0 !default;
$tab-nrml-hover-nav-pop-icon-top: 0 !default;
$tab-big-safari-nav-icon-top: 4px !default;
$tab-nrml-safari-nav-icon-top: 4px !default;
$tab-big-safari-pop-icon-top: 6px !default;
$tab-nrml-safari-pop-icon-top: 6px !default;
$tab-big-ie-nav-icon-line-height: 15px !default;
$tab-nrml-ie-nav-icon-line-height: 14px !default;
$tab-big-ie-pop-icon-line-height: 15px !default;
$tab-nrml-ie-pop-icon-line-height: 15px !default;
$tab-big-edge-nav-icon-line-height: 13px !default;
$tab-nrml-edge-nav-icon-line-height: 11px !default;
$tab-big-edge-pop-icon-line-height: 15px !default;
$tab-nrml-edge-pop-icon-line-height: 14px !default;
$tab-comb-icon-color: normal-color(--black) !default;
$tab-comb-text-color: #777 !default;

$tab-close-icon-size: 12px !default;
$tab-big-close-icon-size: 12px !default;
$tab-nrml-close-icon-size: 10px !default;
$tab-mob-close-icon-size: 12px !default;
$tab-close-icon-minwidth: 12px !default;
$tab-big-close-icon-margin: 0 0 0 8px !default;
$tab-nrml-close-icon-margin: 0 0 0 6px !default;
$tab-mob-close-icon-margin: 0 0 0 8px !default;
$tab-rtl-mob-close-icon-margin: 0 8px 0 0 !default;
$tab-rtl-big-close-icon-margin: 0 10px 0 0 !default;
$tab-rtl-nrml-close-icon-margin: 0 8px 0 0 !default;
$tab-rtl-big-pop-close-icon-margin: 0 !default;
$tab-rtl-nrml-pop-close-icon-margin: 0 !default;
$tab-big-close-icon-top-bottom-right: 20px !default;
$tab-nrml-close-icon-top-bottom-right: 15px !default;
$tab-big-pop-close-icon-top-bottom-right: 24px !default;
$tab-nrml-pop-close-icon-top-bottom-right: 20px !default;
$tab-rtl-big-close-icon-top-bottom-right: 0 !default;
$tab-rtl-nrml-close-icon-top-bottom-right: 0 !default;
$tab-rtl-big-close-icon-top-bottom-left: 20px !default;
$tab-rtl-nrml-close-icon-top-bottom-left: 15px !default;
$tab-rtl-big-pop-close-icon-top-bottom-left: 24px !default;
$tab-rtl-nrml-pop-close-icon-top-bottom-left: 20px !default;
$tab-pop-close-icon-right: 16px !default;
$tab-pop-close-icon-top: 0 !default;
$tab-rtl-pop-close-icon-left: 16px !default;
$tab-mob-close-icon-top-bottom-right: 12px !default;
$tab-rtl-mob-close-icon-top-bottom-left: 12px !default;
$tab-big-pop-close-top: 0 !default;
$tab-nrml-pop-close-top: 0 !default;
$tab-big-safari-close-icon-top: 0 !default;
$tab-nrml-safari-close-icon-top: -1px !default;
$tab-close-icon-color: set-opacity(--black, 0.5) !default;
$tab-mob-close-icon-color: #777 !default;
$tab-close-icon-hover-color: set-opacity(--black, 0.75) !default;
$tab-close-icon-active-color: set-opacity(--black, 0.75) !default;
$tab-alt-close-icon-color: set-opacity(--black, 0.5) !default;
$tab-alt-text-color: normal-color(--black) !default;
$tab-alt-hover-text-color: normal-color(--black) !default;
$tab-alt-active-text-color: normal-color(--black) !default;
$tab-alt-active-close-icon-color: normal-color(--black) !default;
$tab-alt-active-hover-close-icon-color: #fff !default;
$tab-pop-bg-color: normal-color(--white) !default;
$tab-pop-border: 1px $black !default;
$tab-pop-border-radius: 4px !default;

$tab-big-nav-right-icon-padding: 0 !default;
$tab-nrml-nav-right-icon-padding: 0 !default;
$tab-mob-nav-right-icon-padding: 0 !default;
$tab-rtl-big-nav-right-icon-padding: 0 !default;
$tab-rtl-nrml-nav-right-icon-padding: 0 !default;
$tab-big-nav-left-icon-padding: 0 !default;
$tab-nrml-nav-left-icon-padding: 0 !default;
$tab-rtl-big-nav-left-icon-padding: 0 !default;
$tab-rtl-nrml-nav-left-icon-padding: 0 !default;
$tab-big-nav-tb-icon-top: 0 !default;
$tab-nrml-nav-tb-icon-top: 0 !default;
$tab-big-nav-icons-top: -2px !default;
$tab-nrml-nav-icons-top: 0 !default;
$tab-hover-nav-bg-color: hover-color(--white) !default;
$tab-bg-hover-nav-bg-color: inherit !default;
$tab-nav-icon-color: normal-color(--black) !default;
$tab-alt-nav-icon-color: normal-color(--black) !default;
$tab-active-nav-icon-color: normal-color(--black) !default;
$tab-hover-nav-icon-color: normal-color(--black) !default;
$tab-alt-hover-nav-icon-color: normal-color(--black) !default;
$tab-disable-nav-icon-color: disabled-color(--black-300) !default;
$tab-nav-focus-bg-color: focused-color(--white) !default;
$tab-nav-focus-icon-color: normal-color(--black) !default;
$tab-bg-nav-focus-icon-color: normal-color(--white) !default;
$tab-pop-icon-color: normal-color(--black) !default;
$tab-pop-icon-hover-bg-color: hover-color(--white) !default;
$tab-pop-icon-hover-border: 1px solid hover-color(--white) !default;
$tab-pop-icon-focus-border: 1px solid #adadad !default;
$tab-pop-icon-active-border: 1px solid #adadad !default;
$tab-pop-border-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !default;
$tab-bg-hover-nav-icon-color: normal-color(--white) !default;

$tab-nav-pop-press-border: 3px solid transparent !default;
$tab-nav-pop-press-active-border: 3px solid active-color(--black) !default;
$tab-pop-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175) !default;
$tab-pop-icon-transition: none !default;
$tab-pop-up-icon-transform: rotate(0deg) !default;
$tab-pop-down-icon-transform: rotate(0deg) !default;

@mixin tbar-alt-btn-animation-after {
  animation: none;
}

@mixin hscroll-alt-btn-animation-after {
  animation: none;
}

/*! Bootstrap specific themes definition's */
$tab-alt-focused-active-wrap-focus-or-hover-color: active-color(--primary) !default;
$tab-alt-focused-active-wrap-focus-and-hover-color: active-color(--primary) !default;
$tab-even-active-item-bottom-border-color: #ddd !default;
$tab-fill-focused-active-wrap-focus-color: focused-color(--primary) !default;
$tab-alt-active-wrap-hover-close-color: normal-color(--black) !default;
$tab-alt-pop-wrap-hover-color: hover-color(--primary) !default;
$tab-alt-wrap-hover-color: hover-color(--primary) !default;
$tab-nav-active-box-shadow: inset 0 0 0 3px active-color(--black) !default;
$tab-nav-focus-border-color: #e6e6e6 !default;
$tab-alt-nav-hover-bg-color: #e6e6e6 !default;

/*! Vertical Tab */
$tab-vscroll-nrml-padding: 16px 0 !default;
$tab-vscroll-big-padding: 24px 0 !default;
$tab-big-v-wrap-padding: 0 16px !default;
$tab-nrml-v-wrap-padding: 0 12px !default;
$tab-indicator-display: none !default;
$tab-nrml-more-btn-line-height: 36px !default;
$tab-big-more-btn-line-height: 48px !default;
$tab-pop-more-icon-margin: 0 0 0 8px !default;
$tab-rtl-pop-more-icon-margin: 0 8px 0 0 !default;

$tab-vscroll-nav-border: 1px solid transparent !default;
$tab-vscroll-nav-border-color: #ddd !default;
$tab-v-nav-focus-bg-color: #eee !default;
$tab-v-nav-hover-bg-color: #eee !default;
$tab-v-popup-box-shadow: $tab-pop-box-shadow !default;
$tab-v-nav-icon-border-width: 0 !default;

$tab-v-big-close-icon-top-bottom-right: -20px !default;
$tab-v-nrml-close-icon-top-bottom-right: -15px !default;
$tab-v-rtl-big-close-icon-top-bottom-right: 0 !default;
$tab-v-rtl-nrml-close-icon-top-bottom-right: 0 !default;
$tab-v-rtl-big-close-icon-top-bottom-left: -20px !default;
$tab-v-rtl-nrml-close-icon-top-bottom-left: -15px !default;

$tab-v-pop-focus-outline: 0 !default;
