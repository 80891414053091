$drop-down-btn-arrow-content: "\e969" !default;
$drop-down-btn-vertical-btn-padding: 6px 12px !default;
$drop-down-btn-vertical-bigger-padding: 8px 16px !default;
$drop-down-btn-bigger-font-size: 16px !default;
$drop-down-btn-bigger-li-height: 32px !default;
$drop-down-btn-bigger-max-width: 280px !default;
$drop-down-btn-bigger-min-width: 112px !default;
$drop-down-btn-color: normal-color(--primary) !default;
$drop-down-btn-disable-text: disabled-color(--black-300) !default;
$drop-down-btn-font-size: 14px !default;
$drop-down-btn-font-weight: normal !default;
$drop-down-btn-icon-font-size: 14px !default;
$drop-down-btn-icon-margin-right: 8px !default;
$drop-down-btn-bigger-icon-margin-right: 0 !default;
$drop-down-btn-li-bgcolor: normal-color(--white) !default;
$drop-down-btn-li-box-shadow: none !default;
$drop-down-btn-li-border-color: normal-color(--black-700) !default;
$drop-down-btn-li-border-style: solid !default;
$drop-down-btn-li-border-width: 1px !default;
$drop-down-btn-li-focus-bgcolor: normal-color(--white) !default;
$drop-down-btn-li-height: 39px !default;
$drop-down-btn-li-padding: 0 20px !default;
$drop-down-btn-bigger-li-padding: 0 24px !default;
$drop-down-btn-small-li-padding: 0 16px !default;
$drop-down-btn-li-selection-bgcolor: normal-color(--primary) !default;
$drop-down-btn-li-selection-font-color: normal-color(--black) !default;
$drop-down-btn-max-width: 240px !default;
$drop-down-btn-menu-icon-bigger-font-size: 16px !default;
$drop-down-btn-menu-icon-color: normal-color(--primary) !default;
$drop-down-btn-menu-icon-disabled-color: normal-color(--black-700) !default;
$drop-down-btn-menu-icon-font-size: 16px !default;
$drop-down-btn-min-width: 120px !default;
$drop-down-btn-parent-ul-box-shadow: none !default;
$drop-down-btn-seperator-padding: 3px 0 !default;
$drop-down-btn-selected-color: $white !default;
$drop-down-btn-sub-ul-box-shadow: none !default;
$drop-down-btn-ul-bgcolor: $content-popup-bg !default;
$drop-down-btn-ul-border: 1px solid set-opacity(--black, 0.15) !default;
$drop-down-btn-ul-border-radius: 4px !default;
$drop-down-btn-ul-padding: 6px 0 !default;
$drop-down-btn-ul-bigger-padding: 8px 0 !default;
$drop-down-btn-ul-small-padding: 4px 0 !default;
$drop-down-btn-caret-icon-font-size: 8px !default;
$drop-down-btn-icon-font-size-bigger: 16px !default;
$drop-down-btn-caret-icon-font-size-bigger: 10px !default;
