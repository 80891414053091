@mixin badge-variant($bg) {
  background-color: normal-color($bg);
  color: color-yiq($bg);

  @at-root a#{&} {
    @include hover-focus() {
      background-color: hover-color($bg);
      color: color-yiq($bg);
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 $badge-focus-width set-opacity($bg, 0.5);
      outline: 0;
    }
  }
}
