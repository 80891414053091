.label {
  align-items: center;
  border-radius: 3px;
  display: inline-flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  line-height: 15px;
  padding: 4px 16px;
  width: 100%;

  &-sm {
    padding: 0 10px;
  }

  &-large {
    padding: 6px 20px;
  }
}

@each $color, $value in $theme-colors {
  .label-#{$color} {
    background-color: normal-color($value);
    color: normal-color(--white);
  }
}

@each $color, $value in $theme-colors {
  .label-outline-#{$color} {
    background-color: normal-color(--white);
    border: 1px solid variant-color($value);
    color: normal-color($value);
  }
}
