//default
$split-btn-zindex: 2;
$split-btn-border-radius: 4px;
$split-btn-focus-border-color: transparent !default;
$split-btn-focus-vertical-border-color: transparent !default;
$split-btn-focus-outline-offset: 0;
$split-btn-vertical-secondary-icon-line-height: 0.334em;
$split-btn-seperator-border: 1px solid;
$split-btn-seperator-border-color: transparent !default;
$split-btn-seperator-default-border-color: transparent !default;
$split-btn-seperator-vertical-border-color: transparent !default;
$split-btn-hover-border-color: transparent !default;
$split-btn-hover-vertical-border-color: transparent !default;
$split-btn-active-border-color: transparent !default;
$split-btn-active-vertical-border-color: transparent !default;
$split-btn-disabled-vertical-border-color: transparent !default;
$split-btn-active-box-shadow: $btn-active-box-shadow;
$split-btn-disabled-left-border-color: transparent !default;
$split-btn-hover-left-border-color: transparent !default;
$split-btn-focus-left-border-color: transparent !default;
$split-btn-active-left-border-color: transparent !default;
$split-btn-sec-btn-margin-left: -1px;
$split-btn-icon-btn-padding: 8px;
$split-btn-icon-btn-padding-bigger: 12px;
//enddefault
