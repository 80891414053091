@include export-module("grid-layout") {
  /*! Grid layout */
  .e-disableuserselect {
    user-select: none;
  }

  /*! Blazor column menu custom css */
  .e-contextmenu-container.e-sfcontextmenu.e-hide-menu {
    visibility: hidden;
  }

  .e-emptyicon {
    opacity: 0;
  }

  .e-device .e-flmenu-valuediv {
    padding: $grid-flmenu-device-padding;
  }

  .e-bigger #{&}.e-grid,
  #{&}.e-grid.e-bigger {
    font-size: $grid-bigger-font-size;

    .e-toolbar-items .e-toolbar-item.e-search-wrapper {
      padding-bottom: $grid-bigger-toolbar-search-wrapper-padding-bottom;
      padding-top: $grid-bigger-toolbar-search-wrapper-padding-top;

      & .e-search .e-search-icon {
        min-width: $grid-bigger-toolbar-search-icon-min-width;
      }
    }

    .e-toolbar .e-toolbar-items .e-tbar-btn .e-icons {
      font-size: 18px;
    }

    .e-flmenu-valuediv {
      padding: $grid-bigger-flmenu-padding;
    }

    .e-headercell {
      height: $grid-bigger-headercell-line-height;
      padding: 0 $grid-bigger-headercell-padding $grid-bigger-headercell-bootom-padding;
    }

    .e-headercelldiv {
      font-size: $grid-bigger-header-font-size;
      height: $grid-bigger-header-height;
      line-height: $grid-bigger-headercelldiv-line-height;
    }

    .e-headercell,
    .e-detailheadercell {
      font-size: $grid-bigger-header-font-size;
    }

    .e-icons {
      font-size: $grid-bigger-icons-font-size;
    }

    .e-gridheader thead .e-icons {
      font-size: $grid-bigger-header-icons-font-size;
    }

    .e-icon-gdownarrow {
      font-size: $grid-bigger-gdownarrow-icon-font-size;
    }

    .e-icon-grightarrow {
      font-size: $grid-bigger-grightarrow-icon-font-size;
    }

    .e-toolbar .e-btn-icon.e-icons.e-columnchooser-btn {
      font-size: $grid-bigger-columnchooser-btn-font-size;
    }

    .e-gridheader {
      & tr th:last-child {
        padding-right: $grid-bigger-header-first-last-cell-padding;
      }

      & tr th:last-child.e-filterbarcell {
        padding-left: $grid-filterbarcell-first-last-child-padding-left;
      }

      & tr th:first-child {
        padding-left: $grid-bigger-header-first-last-cell-padding;
      }

      & tr th:first-child.e-filterbarcell {
        padding-left: $grid-filterbarcell-first-last-child-padding-left;
      }
    }

    .e-headercelldiv {
      font-size: $grid-bigger-headercell-font-size;
    }

    .e-rowcell,
    .e-summarycell {
      font-size: $grid-bigger-content-font-size;
      padding: $grid-bigger-content-padding $grid-bigger-content-right-padding;
    }

    .e-rowcell:first-child,
    .e-summarycell:first-child {
      padding-left: $grid-bigger-first-last-rowcell-padding;
    }

    .e-rowcell:last-child,
    .e-summarycell:last-child {
      padding-right: $grid-bigger-first-last-rowcell-padding;
    }

    .e-filterbarcell,
    .e-filterbarcelldisabled {
      height: $grid-bigger-headercell-line-height;
      padding: 0 $grid-bigger-headercell-padding;
    }

    .e-filterbarcell input {
      height: $grid-bigger-filterbarcell-text-input;
    }

    .e-ftrchk {
      padding-bottom: $grid-bigger-checkbox-padding-topbottom;
      padding-top: $grid-bigger-checkbox-padding-topbottom;
    }

    .e-columnmenu {
      bottom: $grid-column-menu-top-touch;
    }

    &.e-device.e-noselect {
      user-select: none;
    }

    .e-dialog.e-checkboxfilter,
    .e-dialog.e-flmenu {
      min-width: 260px;
    }

    .e-dialog.e-checkboxfilter {
      min-height: $grid-bigger-checkbox-filter-min-height;
    }

    .e-checkboxfilter {
      & .e-dlg-content {
        padding-left: $grid-bigger-checkbox-content-padding-left;
      }

      & .e-ftrchk,
      & .e-searchbox {
        padding-left: $grid-bigger-checkbox-padding-topbottom;
      }
    }

    &.e-rtl .e-checkboxfilter {
      & .e-dlg-content {
        padding-left: $grid-rtl-bigger-checkbox-content-padding-left;
        padding-right: $grid-bigger-checkbox-content-padding-left;
      }

      & .e-ftrchk,
      & .e-searchbox {
        padding-left: 0;
        padding-right: $grid-bigger-checkbox-padding-topbottom;
      }
    }

    .e-filtermenudiv {
      margin: $grid-bigger-fltrdiv-margin;
    }

    .e-sortfilterdiv {
      margin: $grid-bigger-sortdiv-margin;
    }

    .e-gridheader .e-fltr-icon .e-sortfilterdiv {
      margin: $grid-bigger-fltricon-sortdiv-margin;
    }

    .e-gridheader .e-columnheader.e-wrap .e-sortfilterdiv,
    .e-wrap .e-sortfilterdiv {
      margin: $grid-bigger-wrap-sortdiv-margin;
    }

    .e-gridheader .e-columnheader.e-wrap .e-rightalign .e-sortfilterdiv
    .e-wrap .e-rightalign .e-sortfilterdiv {
      margin: $grid-bigger-wrap-rightalign-sortdiv-margin;
    }

    .e-gridheader .e-columnheader.e-wrap .e-fltr-icon .e-sortfilterdiv
    .e-wrap .e-fltr-icon .e-sortfilterdiv {
      margin: $grid-bigger-wrap-fltricon-sortdiv-margin;
    }

    .e-gridheader .e-columnheader.e-wrap .e-rightalign.e-fltr-icon .e-sortfilterdiv,
    .e-wrap .e-rightalign.e-fltr-icon .e-sortfilterdiv {
      margin: $grid-bigger-wrap-rightalign-fltricon-sortdiv-margin;
    }

    &.e-wrap {
      & .e-rowcell,
      & .e-columnheader .e-stackedheadercelldiv,
      & .e-columnheader .e-headercelldiv {
        line-height: $grid-bigger-wrap-line-height;
      }
    }

    .e-gridheader .e-rightalign .e-sortfilterdiv {
      margin: $grid-bigger-sortdiv-rightalign-margin;
    }

    .e-gridheader .e-rightalign .e-sortnumber {
      float: left;
      margin: $grid-bigger-sortnumber-rightalign-margin;
    }

    .e-sortnumber {
      border-radius: $grid-sortnumber-border-radius;
      display: inline-block;
      float: right;
      font-size: 9px;
      height: 15px;
      line-height: $grid-sortnumer-line-height;
      margin: $grid-bigger-sortnumber-margin;
      text-align: center;
      width: 15px;
    }

    .e-gridheader .e-rightalign.e-fltr-icon .e-sortfilterdiv {
      margin: $grid-bigger-fltricon-sortdiv-rightalign-margin;
    }

    &.e-rtl .e-headercell .e-sortfilterdiv,
    &.e-rtl .e-detailheadercell .e-sortfilterdiv {
      margin: $grid-rtl-bigger-sortcelldiv-margin;
    }

    &.e-rtl .e-gridheader .e-rightalign .e-sortfilterdiv {
      margin: $grid-rtl-bigger-sortcelldiv-right-align-margin;
    }

    &.e-rtl .e-gridheader .e-fltr-icon .e-sortfilterdiv {
      margin: $grid-rtl-bigger-fltricon-sortdiv-margin;
    }

    &.e-rtl .e-gridheader .e-rightalign.e-fltr-icon .e-sortfilterdiv {
      margin: $grid-rtl-bigger-fltricon-sortdiv-rightalign-margin;
    }

    &.e-rtl .e-filtermenudiv {
      margin: $grid-rtl-bigger-fltrdiv-margin;
    }

    &.e-rtl .e-rightalign .e-filtermenudiv {
      margin: $grid-rtl-bigger-rightalign-fltrdiv-margin;
    }

    &.e-rtl .e-headercell .e-headercelldiv .e-sortnumber,
    &.e-rtl .e-detailheadercell .e-headercelldiv .e-sortnumber {
      margin: $grid-rtl-bigger-sortnumber-margin;
    }

    .e-rowcell,
    .e-summarycell,
    .e-emptyrow td,
    .e-frozencontent table tr td:first-child:empty,
    .e-movablecontent table tr td:first-child:empty,
    &:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty,
    &:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
      line-height: $grid-bigger-rowcell-line-height;
    }

    .e-filterbarcell input {
      font-size: $grid-bigger-font-size;
    }

    .e-groupdroparea {
      font-size: $grid-bigger-grouparea-font-size;
      min-height: $grid-bigger-group-line-height;
      padding: $grid-bigger-groupdroparea-padding;
    }

    .e-cloneproperties {
      padding: $grid-bigger-clone-padding;
    }

    .e-headerclone {
      font-size: $grid-bigger-header-font-size;
    }

    .e-groupdroparea.e-grouped {
      padding: $grid-bigger-groupedcell-padding;
    }

    .e-frozenheader table tr td.e-rowcell {
      height: $grid-bigger-rowcell-line-height + $grid-bigger-content-padding + $grid-bigger-content-padding -6;
    }

    // sass-lint:disable-all
    .e-frozencontent table tr td:first-child:empty,
    .e-movablecontent table tr td:first-child:empty,
    .e-frozenhdrcont table tr td:first-child:empty,
    &:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty,
    &:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
      height: $grid-bigger-rowcell-line-height + $grid-bigger-content-padding + $grid-bigger-content-padding;
    }

    &.e-bigger .e-columnmenu {
      bottom: $grid-bigger-columnmenu-bottom;
      margin: $grid-bigger-column-menu-margin;
    }

    .e-columnmenu {
      right: 10px;
    }

    .e-groupheadercell,
    .e-groupheadercell:hover {
      border-radius: $grid-bigger-grouped-headercell-border-radius;
      font-size: $grouped-bigger-text-font-size;
      height: $grid-bigger-group-headercell-height;
      margin: $grid-bigger-group-headercell-margin;
      padding: $grid-bigger-group-headercell-padding;
    }

    .e-groupheadercell:hover {
      padding: $grid-bigger-group-headercell-hover-padding;
    }

    .e-gdclone {
      border-radius: $grid-bigger-group-clone-broder-radius;
      padding: $grid-bigger-group-clone-padding;
    }

    .e-groupheadercell span {
      height: $grid-bigger-groupcell-span-line-height;
      line-height: $grid-bigger-groupcell-span-line-height;
      padding: $grid-bigger-groupcell-span-padding;
    }

    .e-groupheadercell .e-ungroupbutton {
      font-size: $grid-bigger-ungroup-button-font-size;
    }

    .e-groupheadercell .e-grouptext {
      line-height: $grid-bigger-group-text-line-height;
    }

    .e-row .e-input-group .e-input.e-field,
    .e-row .e-input-focus .e-input.e-field {
      font-family: $grid-font-family;
      font-size: $grid-bigger-font-size;
      padding-bottom: $grid-edit-input-bigger-padding-bottom;
      padding-top: $grid-edit-input-bigger-padding-top;
    }

    &.e-device .e-row .e-input-group .e-input.e-field,
    &.e-device .e-row .e-input-focus .e-input.e-field {
      padding-bottom: $grid-edit-input-bigger-device-padding-bottom;
      padding-top: $grid-edit-input-bigger-device-padding-top;
    }

    .e-row .e-input-group {
      margin-bottom: $grid-edit-input-bigger-margin;
      vertical-align: middle;
    }

    &.e-device .e-headercell {
      padding: $grid-device-headercell-padding;
    }

    &.e-device .e-headercell:first-child {
      padding: $grid-device-headercell-fistchild-padding;
    }

    &.e-device .e-headercell:last-child {
      padding: $grid-device-headercell-lastchild-padding;
    }

    &.e-device .e-groupheadercell span {
      line-height: $grid-device-groupheadercell-span-line-height;
    }

    &.e-device .e-rowcell,
    &.e-device .e-summarycell {
      padding: $grid-device-rowcell-padding;
    }

    &.e-device .e-rowcell:first-child,
    &.e-device .e-summarycell:first-child {
      padding: $grid-device-rowcell-firstchild-padding;
    }

    &.e-device .e-rowcell:last-child,
    &.e-device .e-summarycell:last-child {
      padding: $grid-device-rowcell-lastchild-padding;
    }

    &.e-device .e-filterbarcell {
      padding: $grid-device-filterbarcell-padding;
    }

    &.e-device .e-filterbarcell:first-child {
      padding: $grid-device-filterbarcell-firstchild-padding;
    }

    &.e-device .e-filterbarcell:last-child {
      padding: $grid-device-filterbarcell-lastchild-padding;
    }

    &.e-device .e-groupheadercell .e-ungroupbutton {
      line-height: $grid-device-ungroupbutton-line-height;
    }

    &.e-device .e-normaledit .e-rowcell {
      padding-bottom: 0;
      padding-top: 0;
    }

    &.e-device .e-editedbatchcell.e-rowcell {
      padding-bottom: 0;
      padding-top: 0;
    }

    .e-unboundcell,
    .e-editedrow .e-normaledit .e-unboundcell,
    .e-addedrow .e-normaledit .e-unboundcell {
      padding-bottom: 6px;
      padding-top: 6px;
    }

    .e-grouptext {
      margin-right: $grid-bigger-grouptext-marign;
      width: $grid-bigger-grouptext-width;
    }

    .e-gridheader table th[rowspan],
    &.e-device .e-gridheader table th[rowspan] {
      padding-bottom: $grid-device-gridheader-row-span-padding;
    }

    .e-groupsort,
    span.e-ungroupbutton,
    .e-toggleungroup {
      margin-left: $grid-bigger-groupedcell-icons-marign-left;
      margin-top: $grid-bigger-sorticon-margin-top;
    }

    span.e-ungroupbutton.e-icons {
      font-size: $grid-bigger-ungroupbutton-icons-font-size;
      margin-left: $grid-bigger-ungroupbutton-icon-margin-left;
      margin-right: $grid-bigger-ungroupbutton-icon-margin-right;
      margin-top: $grid-bigger-ungroupbutton-icon-margin-top;
    }

    .e-groupsort {
      margin-right: $group-bigger-sorticon-margin-right;
      margin-top: $grid-bigger-sorticon-margin-top;
    }

    &.e-rtl .e-groupheadercell,
    &.e-rtl .e-groupheadercell:hover {
      margin: $grid-bigger-rtl-group-headercell-margin;
      padding: $grid-bigger-rtl-group-headercell-padding;
    }

    &.e-rtl span.e-ungroupbutton.e-icons {
      margin-left: $grid-bigger-rtl-ungroup-icon-margin-left;
      margin-right: $grid-bigger-rtl-ungroup-icon-margin-right;
      margin-top: $grid-bigger-rtl-ungroup-icon-margin-top;
      padding-top: $grid-ungroup-rtl-padding-top;
    }

    .e-groupcaption {
      line-height: $grid-bigger-groupcaption-line-height;
    }

    .e-ccdlg {
      .e-dlg-content {
        margin: $grid-bigger-columnchooser-content-margin;
        padding: $grid-bigger-columnchooser-content-padding;
      }

      .e-ccul-ele {
        padding: $grid-bigger-columnchooser-ul-padding;
      }

      .e-cc-searchdiv {
        padding-left: $grid-bigger-searchdiv-padding-left;
      }

      .e-checkbox-wrapper.e-control.e-keyboard {
        padding-left: $grid-bigger-cc-checkbox-padding-left;
      }

      li.e-cclist {
        padding: $grid-bigger-columnchooser-li-padding;
      }

      .e-toolbar .e-ccdiv {
        margin-top: $grid-bigger-columnchooser-toolbar-div-margin-top;
      }
    }
  }

  #{&}.e-grid {
    border-color: #c0dbe3;
    border-radius: $grid-border-radius;
    border-style: none $grid-border-type $grid-border-type;
    border-width: $grid-border-size;
    display: block;
    font-family: $grid-font-family;
    font-size: $grid-font-size;
    height: auto;
    position: relative;

    .e-gridheader {
      user-select: none;
      border-top: {
        left-radius: $grid-border-radius;
        right-radius: $grid-border-radius;
      };
    }

    .e-gridheader .e-firstrow-dragborder,
    &.e-rtl .e-gridheader .e-firstrow-dragborder,
    .e-gridcontent .e-lastrow-dragborder,
    &.e-rtl .e-gridcontent .e-lastrow-dragborder {
      background-color: $grid-e-firstrow-dragborder-bcolor;
      bottom: 0;
      height: 2px;
      position: absolute;
      z-index: 10;
    }

    .e-grid-relative {
      position: relative;
    }

    .e-dropitemscount {
      border: 1px solid $grid-clone-dropitemscount-border;
      border-radius: 17px;
      box-sizing: content-box;
      font-size: 13px;
      line-height: normal;
      margin-left: -8px;
      min-width: 12px;
      padding: 3px 7px 4px;
      position: absolute;
      text-align: center;
      top: -10px;
      z-index: 5;
    }

    &.e-verticallines .e-cloneproperties.e-draganddrop .e-rowdragdrop,
    &.e-bothlines .e-cloneproperties.e-draganddrop .e-rowdragdrop {
      border-bottom: 0;
      border-right: 1px solid $grid-cell-border-color;
      border-top: 1px solid $grid-cell-border-color;
    }

    .e-gridcontent, .e-gridheader,
    &.e-rtl .e-gridcontent,
    &.e-rtl .e-gridheader {
      & tr.e-row:first-child .e-rowcell.e-dragborder,
      & .e-rowcell.e-dragborder,
      & .e-rowdragdrop.e-dragborder {
        box-shadow: $grid-dragborder-box-shadow;
        position: $grid-dragborder-position;
        z-index: $grid-dragborder-z-index;
      }
    }

    .e-gridheader thead .e-icons:not(.e-check):not(.e-stop) {
      font-size: $grid-icon-font-size;
    }

    .e-row .e-icon-rowdragicon::before {
      color: $grid-drap-drop-icon-color;
      display: block;
      font-size: $grid-drag-font-size;
      font-weight: $grid-drag-icon-font-weight;
      opacity: $grid-drag-icon-opacity;
      text-indent: 10px;
    }

    .e-row .e-icon-rowdragmoveicon::before {
      font-size: $grid-drag-font-size;
      font-weight: $grid-drag-icon-font-weight;
    }

    .e-row .e-icon-rowdragmoveicon {
      padding-left: $grid-drag-move-icon;
    }

    .e-draganddrop .e-rowcell, &.e-rtl .e-draganddrop .e-rowcell {
      padding-left: 6px;
      padding-right: 6px;
    }

    .e-gridcontent .e-rowdragdrop,
    .e-gridheader .e-rowdragdrop {
      border-color: $grid-dragcell-border-color;
      border-style: $grid-dragcell-border-style;
      border-width: $grid-dragicon-cell-border;
    }

    .e-gridcontent .e-rowdragdrop.e-lastrowcell {
      border-bottom-width: $grid-border-bottom-width;
    }

    & .e-cloneproperties.e-draganddrop .e-row .e-icon-rowdragicon::before {
      left: -5px;
      position: relative;
    }

    .e-icon-rowdragicon {
      font-size: $grid-group-right-arrow-icon-font-size;
      text-indent: $group-expand-icon-text-indent;
    }

    .e-toolbar {
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
      border-top: $grid-toolbar-border-width $grid-header-border-color;
    }

    .e-toolbar-items .e-toolbar-item.e-search-wrapper {
      padding-bottom: $grid-toolbar-search-wrapper-padding-bottom;
      padding-top: $grid-toolbar-search-wrapper-padding-top;

      & .e-search {
        margin-bottom: $grid-toolbar-search-margin-bottom;
        opacity: 0.6;
        width: $grid-toolbar-search-width;

        &.e-input-focus {
          opacity: 1;
        }

        .e-search-icon {
          min-width: $grid-toolbar-search-icon-min-width;
        }
      }
    }

    .e-bigger & .e-group-animator .e-groupheadercell,
    .e-bigger & .e-group-animator .e-groupheadercell:hover {
      border-bottom-width: $grid-animtor-border-bottom-width;
      margin: $grid-group-animator-bigger-cell-margin;
    }

    & .e-groupdroparea.e-group-animate.e-grouped {
      height: $grid-group-animator-bigger-drop-height;
    }

    .e-bigger & .e-group-animate.e-groupdroparea .e-nextgroup {
      margin-top: $grid-animator-bigger-area-margin;
    }

    & .e-group-animator .e-groupheadercell,
    & .e-group-animator .e-groupheadercell:hover {
      border-bottom-width: $grid-animtor-border-bottom-width;
      margin: $grid-group-animator-cell-margin;
    }

    & .e-group-animator:last-child .e-nextgroup.e-icons.e-icon-next {
      display: none;
    }

    & .e-groupdroparea.e-group-animate.e-grouped {
      border-bottom-width: $grid-animator-area-border;
      height: $grid-group-animator-drop-height;
      overflow: hidden;
      padding: $grid-animator-area-padding;
    }

    &.e-rtl .e-group-animator {
      float: right;
    }

    &.e-rtl .e-group-animate .e-groupheadercell, &.e-rtl .e-group-animate .e-groupheadercell:hover {
      margin-left: 0;
      margin-right: 0;
    }

    &.e-rtl .e-group-animate.e-groupdroparea .e-nextgroup {
      margin-top: $grid-animator-margin-top-rtl;
      transform: rotate(180deg);
    }

    &.e-rtl .e-group-animator:first-child .e-groupheadercell {
      margin: $grid-animator-rtl-first-group;
    }

    & .e-group-animator:first-child .e-groupheadercell {
      margin: $grid-animator-first-group;
    }

    .e-group-animate.e-groupdroparea .e-nextgroup {
      margin-top: $grid-animator-area-margin;
    }

    .e-groupdroparea.e-group-animate .e-drag.e-icon-drag {
      display: $grid-animator-chips-display;
      font-size: $grid-animator-font-size-drag;
      height: $grid-animator-drag-icon;
      line-height: $grid-animator-drag-icon-line-height;
      padding: $grid-animator-area-border;
      vertical-align: $grid-columnchooser-toolbar-icon-vertical-align;
    }


    .e-group-animator .e-icon-drag.e-icons {
      margin-left: $grid-animator-drag-margin-left;
    }

    .e-groupdroparea.e-group-animate span.e-drag.e-icons.e-icon-drag {
      cursor: move;
    }

    .e-group-animate .e-drag.e-icon-drag::before {
      opacity: $grid-animator-opacity;
    }

    .e-group-animate span.e-nextgroup.e-icons.e-icon-next {
      display: $grid-animator-chips-display;
      float: $grid-rtlfltrdiv-float;
      height: $grid-animator-drag-icon;
      line-height: $grid-animator-line-height-next;
      padding: $grid-animator-area-border;
      vertical-align: $grid-columnchooser-toolbar-icon-vertical-align;
    }

    .e-groupdroparea.e-grouped {
      padding: $grid-grouped-padding;
    }

    .e-groupdroparea.e-grouped {
      padding: $grid-grouped-padding;
    }

    &.e-default .e-gridheader.e-stackedfilter .e-grouptopleftcell,
    &.e-default.e-horizontallines .e-gridheader.e-stackedfilter .e-grouptopleftcell {
      border-top: 0;
    }

    &.e-default .e-gridheader.e-stackedfilter tr:first-child th.e-grouptopleftcell,
    &.e-default.e-horizontallines .e-gridheader.e-stackedfilter tr:first-child th.e-grouptopleftcell {
      border-bottom: 1px solid;
    }

    &.e-default.e-verticallines .e-gridheader.e-stackedfilter tr:first-child th.e-grouptopleftcell,
    &.e-default.e-hidelines .e-gridheader.e-stackedfilter tr:first-child th.e-grouptopleftcell {
      border-bottom: 0;
    }

    &.e-default .e-gridheader.e-stackedfilter tr:last-child th.e-grouptopleftcell,
    &.e-default.e-horizontallines .e-gridheader.e-stackedfilter tr:last-child th.e-grouptopleftcell {
      border-top: 1px solid;
    }

    &.e-default.e-hidelines .e-gridheader.e-stackedfilter tr:last-child th.e-grouptopleftcell,
    &.e-default.e-verticallines .e-gridheader.e-stackedfilter tr:last-child th.e-grouptopleftcell {
      border-top: 0;
    }

    &.e-default .e-grouptopleftcell {
      border-top: $grid-grouptopleftcell-border-top;
    }

    &.e-default .e-gridheader .e-headercell.e-firstheader,
    &.e-default.e-horizontallines .e-headercell.e-firstheader {
      border-left: 1px solid;
    }

    &.e-default.e-hidelines .e-headercell.e-firstheader {
      border-left: 0;
    }

    &.e-default.e-verticallines .e-grouptopleftcell,
    &.e-default.e-bothlines .e-grouptopleftcell,
    &.e-default.e-hidelines .e-grouptopleftcell {
      border-top-width: 0;
    }

    &.e-default.e-verticallines,
    &.e-default.e-hidelines {
      & .e-detailrowcollapse,
      & .e-detailrowexpand,
      & .e-rowdragdrop {
        border-top-width: 0;
      }
    }

    &.e-default.e-horizontallines .e-grouptopleftcell {
      border-top: 1px solid;
    }

    & .e-gridheader .e-headercell .e-rhandler,
    & .e-gridheader .e-headercell .e-rsuppress {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 4px;
    }

    &.e-device .e-gridheader .e-headercell .e-rhandler {
      width: 14px;
    }

    &.e-rtl .e-gridheader .e-headercell .e-rhandler,
    &.e-rtl .e-gridheader .e-headercell .e-rsuppress {
      left: 0;
      right: auto;
    }

    &.e-resize-lines .e-gridheader th.e-headercell.e-lastcell .e-rhandler {
      border-right-width: 0;
    }

    & .e-rhelper {
      position: absolute;
      width: $grid-resize-border-width;
    }

    &.e-device .e-ricon::before {
      border-bottom: 6px solid transparent;
      border-right: 6px solid;
      border-top: 6px solid transparent;
      content: "";
      display: block;
      height: 0;
      position: absolute;
      right: 4px;
      top: 4px;
      width: 20px;
    }

    &.e-device .e-ricon::after {
      border-bottom: 6px solid transparent;
      border-left: 6px solid;
      border-top: 6px solid transparent;
      content: "";
      display: block;
      height: 0;
      left: 4px;
      position: absolute;
      top: 4px;
      width: 20px;
      z-index: 3;
    }

    &.e-rcursor,
    & .e-gridheader .e-stackedheadercell.e-rcursor,
    & .e-gridheader .e-rcursor {
      cursor: col-resize;
    }

    &.e-editing .e-gridheader .e-rcursor {
      cursor: default;
    }

    .e-table {
      border-collapse: separate;
      table-layout: fixed;
      width: 100%;
    }

    .e-tableborder {
      border-right: 1px solid;
    }

    .e-virtualtable {
      will-change: transform;
      z-index: 1;
    }

    .e-headercelldiv {
      border: 0 none;
      display: block;
      font-size: $grid-header-font-size;
      font-weight: $grid-header-font-weight;
      height: $grid-header-height;
      line-height: $grid-headercelldiv-line-height;
      margin: $grid-headercelldiv-margin;
      overflow: hidden;
      padding: $grid-headercell-div-padding;
      text-align: left;
      user-select: none;
    }

    .e-headercelldiv.e-headerchkcelldiv {
      overflow: visible;
    }

    .e-gridheader .e-headercontent .e-rightalign .e-headercelldiv.e-headerchkcelldiv {
      padding: 0 0.6em;
    }

    .e-gridheader .e-headercontent .e-centeralign .e-headercelldiv.e-headerchkcelldiv {
      padding: 0 0.6em;
    }

    .e-headercell,
    .e-headercell.e-stackedheadercell,
    .e-headercell.e-defaultcursor {
      cursor: default;
    }

    .e-headercell.e-mousepointer,
    .e-headercell.e-stackedheadercell.e-mousepointer {
      cursor: pointer;
    }

    .e-gridheader .e-headercell,
    .e-gridheader .e-detailheadercell {
      user-select: none;
    }

    .e-gridcontent {
      & tr:first-child td {
        border-top: 0 none;
      }
    }


    .e-gridheader {
      & tr:first-child th {
        border-top: 0 none;
      }

      & tr th:last-child.e-filterbarcell {
        padding-right: $grid-filterbarcell-first-last-child-padding-left;
      }

      & tr th:last-child {
        padding-right: $grid-header-first-last-cell-padding;
      }

      & tr th:first-child {
        padding-left: $grid-header-first-last-cell-padding;
      }

      & tr th:first-child.e-filterbarcell {
        padding-left: $grid-filterbarcell-first-last-child-padding-left;
      }
    }

    .e-gridheader {
      border-bottom-style: $grid-border-type;
      border-bottom-width: $grid-bottom-border-size;
      border-top-style: $grid-border-type;
      border-top-width: $grid-border-size;
    }

    .e-frozenhdrcont {
      border-bottom-width: 0;
    }

    .e-frozenhdrcont table tr:not(.e-editedrow):first-child td {
      border-top-style: $grid-border-type;
      border-top-width: $grid-bottom-border-size;
    }

    &.e-bothlines .e-filterbarcell,
    &.e-bothlines .e-filterbarcelldisabled {
      border-width: $grid-filtercell-both-border-width;
    }

    .e-headercell,
    .e-detailheadercell {
      border-style: $grid-border-type;
      border-width: $grid-header-border-width;
      font-size: $grid-header-font-size;
      font-weight: $grid-header-font-weight;
      height: $grid-headercell-line-height;
      overflow: hidden;
      padding: 0 $grid-header-padding $grid-header-bottom-padding;
      position: relative;
      text-align: left;
    }

    &.e-device .e-headercell {
      padding: $grid-device-headercell-padding;
    }

    &.e-device .e-headercell:first-child {
      padding: $grid-device-headercell-fistchild-padding;
    }

    &.e-device .e-headercell:last-child {
      padding: $grid-device-headercell-lastchild-padding;
    }

    .e-rowcell {
      border-style: $grid-border-type;
      border-width: $grid-rowcell-broder-width;
      display: table-cell;
      font-size: $grid-content-font-size;
      line-height: $grid-rowcell-line-height;
      overflow: hidden;
      padding: $grid-content-padding $grid-content-right-padding;
      vertical-align: middle;
      white-space: nowrap;
      width: auto;
    }

    .e-frozenheader table .e-insertedrow td.e-rowcell,
    .e-frozencontent table .e-insertedrow td.e-rowcell,
    .e-movableheader table .e-insertedrow td.e-rowcell,
    .e-movablecontent table .e-insertedrow td.e-rowcell,
    &:not(.e-grid-min-height) .e-gridcontent .e-content tr.e-insertedrow .e-rowcell:empty,
    & .e-row.e-emptyrow {
      height: $grid-rowcell-line-height + $grid-content-padding + $grid-content-padding + 1;
    }

    .e-editedrow .e-input-group input.e-input,
    .e-editedrow .e-input-group.e-control-wrapper input.e-input,
    .e-addedrow .e-input-group input.e-input,
    .e-addedrow .e-input-group.e-control-wrapper input.e-input {
      min-height: unset;
    }

    &:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty,
    &:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
      height: $grid-rowcell-line-height + $grid-content-padding + $grid-content-padding;
    }

    &.e-afenabled,
    &.e-enabledboxbdr {
      .e-movablecontent,
      .e-frozencontent,
      .e-movableheader,
      .e-frozenheader,
      .e-headercontent {
        position: relative;
      }
    }

    .e-rowcell:first-child,
    .e-summarycell:first-child {
      padding-left: $grid-first-last-rowcell-padding;
    }

    .e-rowcell:last-child,
    .e-summarycell:last-child {
      padding-right: $grid-first-last-rowcell-padding;
    }

    .e-unboundcell,
    .e-editedrow .e-normaledit .e-unboundcell,
    .e-addedrow .e-normaledit .e-unboundcell {
      padding-bottom: 4px;
      padding-top: 4px;
    }

    .e-unboundcelldiv > button {
      margin: $grid-unboundcelldiv-margin;
    }

    .e-unboundcelldiv {
      margin: 0 -3.5px;
    }

    .e-summarycell {
      border-style: solid;
      border-width: 1px 0 0;
      font-size: $grid-summary-cell-font-size;
      font-weight: $grid-header-font-weight;
      height: auto;
      line-height: $grid-summary-cell-line-height;
      padding: $grid-content-padding $grid-content-right-padding;
      white-space: normal;
      word-wrap: break-word;
    }

    .e-summarycontent {
      & .e-frozenfootercontent {
        float: left;
        width: min-content;
      }

      & .e-movablefootercontent {
        height: inherit;
        overflow: hidden;
      }

      & .e-indentcell {
        border-width: 1px 0 0;
      }

      & .e-detailindentcelltop {
        border-width: 1px 0 0;
      }
    }

    &.e-device .e-rowcell,
    &.e-device .e-summarycell {
      padding: $grid-device-rowcell-padding;
    }

    &.e-device .e-rowcell:first-child,
    &.e-device .e-summarycell:first-child {
      padding: $grid-device-rowcell-firstchild-padding;
    }

    &.e-device .e-rowcell:last-child,
    &.e-device .e-summarycell:last-child {
      padding: $grid-device-rowcell-lastchild-padding;
    }

    td.e-rowcell.e-checkbox {
      padding: $grid-checkbox-cell-padding-top-bottom $grid-content-right-padding;
    }

    td.e-rowcell.e-checkbox input[type="checkbox"] {
      margin-top: $grid-td-checkbox-margin-top;
    }

    &.e-default .e-rowcell.e-lastrowcell {
      border-bottom-width: 1px;
    }

    &.e-default .e-detailrowcollapse.e-lastrowcell {
      border-bottom-width: 1px;
    }

    &.e-default .e-detailrow .e-lastrowcell {
      border-bottom: 1px solid;
      border-bottom-color: $grid-header-border-color;
    }

    &.e-bothlines .e-rowcell.e-lastrowcell {
      border-bottom-width: 1px;
    }

    &.e-bothlines .e-rowcell {
      border-width: $grid-rowcell-both-border-width;
    }

    .e-gridheader table th[rowspan],
    &.e-device .e-gridheader table th[rowspan] {
      padding-bottom: $grid-stackedheadercell-botttom-padding;
      vertical-align: bottom;
    }

    .e-emptyrow td {
      color: var(--secondary-color);
      font-weight: bold;
      line-height: $grid-rowcell-line-height;
      padding: 0.7em;
      text-align: center;
    }

    &.e-responsive .e-rowcell,
    &.e-responsive .e-headercelldiv {
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.e-responsive .e-rowcell.e-gridchkbox,
    &.e-responsive .e-headercelldiv.e-headerchkcelldiv {
      text-overflow: clip;
    }

    &.e-default .e-headercell,
    &.e-default .e-detailheadercell {
      border-width: $grid-header-border-width;
    }

    [aria-selected] + tr .e-detailindentcell {
      border-top-style: $grid-border-type;
      border-top-width: $grid-border-size;
    }

    &.e-default.e-verticallines .e-headercell,
    &.e-default.e-verticallines .e-detailheadercell {
      border-width: 0 0 0 $grid-border-size;
    }

    &.e-default.e-verticallines .e-headercell.e-stackedheadercell {
      border-bottom: $grid-border-size solid;
    }

    &.e-default .e-stackedheadercell {
      border-width: $grid-stackedheadercell-border-size;
    }

    &.e-default.e-verticallines tr th:first-child:not(.e-firstcell) {
      border-left-width: 0;
    }

    &.e-default.e-bothlines .e-headercell,
    &.e-default.e-bothlines .e-detailheadercell {
      border-width: $grid-headercell-both-border-width;
    }

    &.e-default table th[rowspan] {
      border-width: $grid-headercell-both-border-width;
    }

    tr  th.e-firstcell {
      border-left-style: $grid-border-type;
      border-left-width: $grid-border-size;
    }

    &.e-default tr {
      & td:first-child,
      & th.e-headercell:first-child:not(.e-firstcell),
      & th.e-detailheadercell:first-child,
      & th.e-filterbarcell:first-child {
        border-left-width: 0;
      }
    }

    &.e-default .e-gridheader th.e-firstcell.e-movablefirst {
      border-left-width: 0;
    }

    .e-hide {
      display: none;
    }

    .e-gridcontent,
    .e-gridfooter {
      border-bottom: {
        left-radius: $grid-border-radius;
        right-radius: $grid-border-radius;
      };
    }

    .e-rowcell,
    .e-gridcontent,
    .e-gridheader,
    .e-headercontent,
    .e-groupdroparea,
    .e-gridfooter,
    .e-summarycontent {
      overflow: hidden;
      vertical-align: middle;

    }

    .e-sortfilterdiv {
      float: right;
      height: $grid-sortdiv-height;
      margin: $grid-sortdiv-margin;
      padding: $grid-sortdiv-padding;
      width: $grid-sortdiv-width;
    }

    .e-gridheader .e-rightalign .e-sortfilterdiv {
      float: left;
      margin: $grid-sortdiv-right-align-margin;
    }

    .e-gridheader .e-sortfilter .e-rightalign .e-headercelldiv,
    .e-gridheader .e-rightalign .e-stackedheadercelldiv {
      padding: $grid-headercelldiv-right-align-padding;
    }

    .e-gridheader .e-sortfilter .e-rightalign.e-fltr-icon .e-headercelldiv {
      margin: $grid-headercelldiv-rightalign-fltricon-margin;
    }


    .e-gridheader .e-sortfilter .e-fltr-icon .e-headercelldiv {
      margin: $grid-headercelldiv-fltricon-margin;
    }

    .e-gridheader .e-sortfilter .e-headercelldiv,
    .e-gridheader .e-stackedheadercelldiv,
    .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
      padding: $grid-headercelldiv-padding;
    }

    .e-filtermenudiv {
      float: $grid-fltrdiv-float;
      margin: $grid-fltrdiv-margin;
      padding: $grid-fltrdiv-padding;
      text-align: $grid-fltrdiv-text-align;
    }

    .e-filtermenudiv:hover {
      cursor: pointer;
    }

    &.e-print-grid-layout {
      .e-pager, .e-filterbar, .e-icons:not(.e-frame), .e-grouptopleftcell, .e-recordpluscollapse, .e-indentcell, .e-recordplusexpand {
        display: none;
      }

      .e-indentcell.e-detailindentcelltop {
        display: table-cell;
      }

      .e-content {
        overflow-y: hidden;
      }

      .e-grouptext {
        width: auto;
      }

      .e-detailcell {
        padding: 0.8em 0.6em;
      }
    }

    &.e-print-grid {
      left: -1000px;
      top: -1000px;
    }

    .e-flmenu-valuediv {
      padding: $grid-flmenu-padding;
    }

    .e-flbldcontent {
      padding: $grid-flmenu-boolean-content-padding;
    }

    .e-flblbtn {
      width: $grid-flmenu-boolean-button-width;
    }


    .e-sortnumber {
      border-radius: $grid-sortnumber-border-radius;
      display: inline-block;
      float: right;
      font-size: 9px;
      height: 15px;
      line-height: $grid-sortnumer-line-height;
      margin: $grid-sortnumber-margin;
      text-align: center;
      width: 15px;
    }

    .e-gridheader .e-rightalign .e-sortnumber {
      float: left;
      margin: $grid-sortnumber-rightalign-margin;
    }

    .e-gridheader .e-fltr-icon .e-sortfilterdiv {
      margin: $grid-sortdiv-filtericon-margin;
    }

    .e-gridheader .e-rightalign.e-fltr-icon .e-sortfilterdiv {
      margin: $grid-sortdiv-filtericon-rightalign-margin;
    }


    &.e-wrap .e-gridheader .e-rightalign .e-sortnumber {
      margin: $grid-textwrap-srotnumber-rightalign-margin;
    }

    &.e-wrap .e-gridheader .e-sortnumber {
      margin: $grid-textwrap-srotnumber-margin;
    }

    &.e-wrap .e-gridheader .e-sortfilterdiv {
      margin: $grid-textwrap-sorticon-margin;
    }

    .e-movableheader .e-editcell.e-normaledit,
    .e-frozenheader .e-editcell.e-normaledit {
      border-top-color: transparent;
    }

    .e-columnmenu {
      float: right;
      margin: $grid-column-menu-margin;
      padding: 6px;
      position: absolute;
      right: 23px;
    }

    &.e-wrap .e-columnmenu {
      margin: $grid-textwrap-column-menu-margin;
    }

    &.e-rtl .e-columnmenu {
      left: $grid-rtl-column-menu-left;
      margin: $grid-rtl-column-menu-margin;
      right: auto;
    }

    &.e-wrap {
      &.e-rtl  .e-columnmenu {
        margin: $grid-rtl-textwrap-column-menu-margin;
      }
    }

    [class^="e-"] {
      box-sizing: border-box;
    }

    .e-detailrowcollapse,
    .e-detailrowexpand {
      border-style: $grid-border-type;
      border-width: $grid-rowcell-broder-width;
      cursor: pointer;
    }

    .e-detailindentcell {
      border-right-style: $grid-border-type;
      border-right-width: $grid-border-size;
    }

    .e-detailcell {
      border-top-style: $grid-border-type;
      border-top-width: $grid-border-size;
      padding: 0.3em;
    }

    &.e-verticallines .e-rowcell,
    &.e-verticallines .e-filterbarcell {
      border-width: 0 0 0 $grid-border-size;
    }

    &.e-hidelines .e-rowcell,
    &.e-horizontallines .e-headercell,
    &.e-hidelines .e-headercell,
    &.e-horizontallines .e-detailheadercell,
    &.e-hidelines .e-detailheadercell,
    &.e-hidelines tr th.e-firstcell,
    &.e-hidelines .e-filterbarcell {
      border-width: 0;
    }

    &.e-horizontallines .e-headercell.e-stackedheadercell {
      border-width: 0 0  $grid-border-size  $grid-border-size;
    }

    &.e-horizontallines .e-rowcell {
      border-width: $grid-border-size 0 0;
    }

    &.e-horizontallines .e-filterbarcell {
      border-width: $grid-bottom-border-size 0 0;
    }

    &.e-horizontallines,
    &.e-verticallines,
    &.e-hidelines {
      & .e-rowcell.e-lastrowcell {
        border-bottom-width: $grid-border-size;
      }
    }

    &.e-horizontallines,
    &.e-verticallines,
    &.e-hidelines,
    &.e-bothlines {
      & .e-detailrowvisible {
        border-left-width: 0;
      }
    }

    &.e-verticallines,
    &.e-hidelines {
      & .e-firstchildrow .e-rowcell,
      & .e-firstchildrow .e-detailrowcollapse,
      & .e-firstchildrow .e-detailrowexpand {
        border-top-width: 1px;
      }
    }

    .e-filterbarcell .e-icons::before {
      display: block;
      margin: 0 auto;
    }

    // sass-lint:disable-all
    .e-filterbarcell .e-filtertext::-webkit-search-cancel-button,
    .e-search input::-webkit-search-cancel-button {
      display: none;
    }

    .e-filterbarcell .e-filtertext::-ms-clear,
    .e-search input::-ms-clear {
      display: none;
    }

    // sass-lint:enable-all
    .e-filterbarcell,
    .e-filterbarcelldisabled {
      border-collapse: collapse;
      border-style: $grid-border-type;
      border-width: $grid-filterbarcell-border-width;
      cursor: default;
      height: $grid-headercell-line-height;
      overflow: hidden;
      padding: $grid-filterbarcell-padding;
      vertical-align: middle;
    }

    .e-rowdragheader {
      border-color: $grid-header-border-color;
      border-style: $grid-border-type;
      border-width: $grid-filterbarcell-border-width;
    }

    .e-filterbarcell input {
      border-radius: $grid-filterbar-border-radius;
      border-style: solid;
      border-width: $grid-filterbar-input-border-width;
      font-size: $grid-font-size;
      font-weight: normal;
      height: $grid-filterbarcell-text-input;
      padding-right: 24px;
      text-indent: $grid-filterbarcell-text-indent;
      width: 100%;
    }

    &.e-device .e-filterbarcell {
      padding: $grid-device-filterbarcell-padding;
    }

    &.e-device .e-filterbarcell:first-child {
      padding: $grid-device-filterbarcell-firstchild-padding;
    }

    &.e-device .e-filterbarcell:last-child {
      padding: $grid-device-filterbarcell-lastchild-padding;
    }

    .e-searchclear {
      float: right;
      position: relative;
    }

    &.e-rtl .e-searchclear {
      float: left;
      position: relative;
      top: $grid-rtl-searchclear;
    }

    .e-checkboxlist {
      height: 200px;
      margin-top: 5px;
      min-height: 160px;
      overflow-y: auto;
    }

    .e-chk-hidden {
      -moz-appearance: none; // sass-lint:disable-line no-vendor-prefixes
      height: 1px;
      opacity: 0;
      width: 1px;
    }

    .e-checkselect,
    .e-checkselectall {
      margin: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }

    .e-rowcell .e-checkbox-wrapper,
    .e-rowcell .e-css.e-checkbox-wrapper {
      height: $grid-checkbox-wrapper-height;
      line-height: $grid-checkbox-wrapper-line-height;
    }

    .e-dialog.e-checkboxfilter,
    .e-dialog.e-flmenu {
      min-width: 250px;
    }

    .e-dialog.e-checkboxfilter {
      min-height: $grid-checkbox-filter-min-height;
    }

    .e-checkboxfilter .e-searchbox {
      display: block;
    }

    .e-checkboxfilter {
      & .e-dlg-content {
        padding-left: $grid-checkbox-content-padding-left;
      }

      & .e-ftrchk,
      & .e-searchbox {
        padding-left: $grid-checkbox-padding-topbottom;
      }
    }

    .e-ftrchk {
      padding-bottom: $grid-checkbox-padding-topbottom;
      padding-top: $grid-checkbox-padding-topbottom;
    }

    .e-filterdiv,
    .e-fltrtempdiv {
      padding: $grid-filterdiv-padding;
      position: relative;
      text-align: center;
      width: 100%;
    }

    .e-pager {
      border-bottom: transparent;
      border-left: transparent;
      border-right: transparent;
    }

    .e-gridpopup {
      font-weight: normal;
      position: absolute;
      user-select: none;
      z-index: 99999;
    }

    .e-gridpopup .e-content {
      border-radius: 4px;
      border-style: solid;
      border-width: $grid-border-size;
      font-size: 14px;
      padding: 4px;
    }

    .e-lastsummarycell {
      border-right: 1px solid;
    }

    .e-footerpadding {
      padding-right: 14px; //need to consider from javascript
    }

    .e-gridpopup span {
      border: 1px solid transparent;
      cursor: pointer;
      display: inline-block;
      height: 26px;
      padding: 4px;
      width: 26px;
    }

    .e-gridpopup .e-tail::before,
    .e-gridpopup .e-tail::after {
      border: 10px solid transparent;
      content: "";
      height: 0;
      left: 8px;
      position: absolute;
      width: 0;
    }

    .e-gridpopup .e-downtail::after {
      top: 34px;
    }

    .e-gridpopup .e-uptail::after {
      top: -17px;
    }

    .e-gridpopup .e-uptail::before {
      top: -19px;
    }

    .e-gridpopup .e-sortdirect,
    .e-gridpopup .e-rowselect {
      line-height: 18px;
      text-indent: $grid-rowselect-text-indent;
    }

    .e-cloneproperties {
      border-style: solid;
      border-width: $grid-border-size;
      box-shadow: $grid-group-clone-box-shadow;
      font-size: $grid-header-font-size;
      font-weight: $grid-drag-clone-font-weight;
      opacity: $grid-drag-clone-opacity;
      overflow: hidden;
      padding: $grid-clone-padding;
      text-align: $grid-group-clone-text-align;
      user-select: none;
      vertical-align: middle;
      white-space: nowrap;
      z-index: 10;
    }

    .e-cloneproperties.e-draganddrop {
      border-spacing: 0;
      font-size: $grid-header-font-size;
      font-weight: normal;
      overflow: visible;
    }

    .e-cloneproperties.e-draganddrop table {
      border-spacing: 0;
    }

    .e-defaultcur {
      cursor: default;
    }

    .e-notallowedcur {
      cursor: not-allowed;
    }

    .e-cloneproperties.e-headerclone table {
      border-spacing: 0;
    }

    .e-headerclone {
      border-radius: $group-header-border-radius;
      font-size: $grid-header-font-size;
      font-weight: $grid-drag-clone-font-weight;
      line-height: 29px;
    }

    .e-draganddrop {
      border-width: 0 1px 1px;
      font-weight: normal;
      padding: 0;
    }

    .e-draganddrop .e-rowcell {
      opacity: 0.95;
    }

    &.e-default .e-gridheader th.e-firstcell {
      border-left-style: $grid-border-type;
      border-left-width: $grid-border-size;
    }

    &.e-bothlines .e-gridheader th.e-stackedheadercell {
      border-bottom-style: $grid-border-type;
      border-bottom-width: $grid-border-size;
      border-left-style: $grid-border-type;
      border-left-width: $grid-border-size;
    }

    &.e-default.e-hidelines .e-gridheader th.e-firstcell {
      border-left-width: 0;
    }

    .e-gridheader .e-headercontent .e-reorderuparrow,
    .e-gridheader .e-headercontent .e-reorderdownarrow {
      font-size: $grid-reorderarrow-font-size;
      position: absolute;
      z-index: 10;
    }

    td.e-active {
      font-weight: $grid-selection-font-weight;
    }

    td.e-cellselectionbackground {
      font-weight: $grid-selection-font-weight;
    }

    .e-groupdroparea {
      height: auto;
      min-height: $grid-group-height;
    }

    .e-griddragarea {
      border: 1px solid;
      opacity: 0.6;
      position: absolute;
    }

    .e-gdclone {
      border-radius: $grid-gd-clone-border-radius;
      padding: 2px;
    }

    .e-content {
      -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
      overflow-x: auto;
      overflow-y: scroll;
      position: relative;
    }

    &.e-ungroupdrag .e-columnheader,
    &.e-ungroupdrag .e-groupdroparea,
    &.e-rowdrag .e-columnheader,
    &.e-rowdrag .e-groupdroparea {
      cursor: not-allowed;
    }

    .e-groupdroparea,
    &.e-ungroupdrag .e-gridcontent,
    &.e-rowdrag .e-gridcontent {
      cursor: default;
    }

    .e-groupdroparea {
      border-style: solid none;
      border-width: 1px 0 0;
    }

    .e-groupdroparea {
      border-top-width: $grid-border-size;
      font-size: $grid-grouparea-font-size;
      font-weight: $grid-grouparea-font-weight;
      opacity: $grid-conent-font-opacity;
      padding: $group-area-padding;
      text-align: $group-text-align;
      text-indent: 1pt;
      user-select: none;
      width: 100%;
    }

    .e-grouptext {
      display: inline-block;
      margin-right: $grid-grouptext-margin-right;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: $grid-group-text-width;
    }

    .e-grid-icon {
      float: left;
    }

    .e-groupheadercell,
    .e-groupheadercell:hover {
      border: $group-border-width;
      border-collapse: collapse;
      border-radius: $group-header-border-radius;
      cursor: pointer;
      font-size: $grouped-text-font-size;
      font-weight: normal;
      height: $grid-headecell-height;
      margin: $grid-grouparea-margin;
      overflow: hidden;
      padding: $grid-grouped-headercell-padding;
      vertical-align: middle;
    }

    .e-groupheadercell:hover {
      border: $group-hover-border;
      padding: $grid-grouped-headercell-hover-padding;
    }

    .e-groupheadercell span {
      display: inline-block;
      float: left;
      height: $grid-headecell-span-height;
      line-height: $grid-group-headercell-line-height;
      padding: $grid-grouped-headercell-span-padding;
      vertical-align: middle;
    }

    .e-groupheadercell .e-grouptext {
      line-height: $grid-group-text-line-height;
    }

    .e-groupheadercell .e-ungroupbutton {
      font-size: $grid-ungroup-button-font-size;
      line-height: $grid-ungroup-button-line-height;
    }

    &.e-device .e-groupheadercell span {
      line-height: 26px;
    }

    &.e-device .e-groupheadercell .e-ungroupbutton {
      line-height: 28px;
    }

    .e-groupheadercell .e-cancel {
      padding-right: $group-sorticon-font-size;
    }

    .e-groupheadercell .e-icons::before {
      display: inline;
    }

    .e-groupsort,
    .e-ungroupbutton,
    .e-toggleungroup {
      font-size: $group-sorticon-font-size;
      margin-left: $group-sorticon-margin-left;
    }

    .e-groupsort {
      margin-right: $group-sorticon-margin-right;
      margin-top: $group-sorticon-margin-top;

    }

    span.e-ungroupbutton.e-icons {
      margin-left: $group-unpgrouicon-margin-left;
      margin-top: $grid-group-unpgroupicon-margin-top;
    }

    .e-grptogglebtn {
      padding: 7px;
    }

    .e-icon-gdownarrow {
      font-size: $grid-group-down-arrow-icon-font-size;
      text-indent: $group-collapse-icon-text-indent;
    }

    .e-icon-grightarrow {
      font-size: $grid-group-right-arrow-icon-font-size;
      text-indent: $group-expand-icon-text-indent;
    }

    .e-recordplusexpand {
      padding-top: 5px;
    }

    .e-indentcell {
      border-style: $grid-border-type;
      border-width: 0 $grid-border-size 0 0;

      &.e-indentcelltop,
      &.e-detailindentcelltop {
        border-width: $grid-border-size 0 0;
      }
    }

    .e-recordplusexpand,
    .e-recordpluscollapse {
      border-style: $grid-border-type;
      border-width: $grid-border-size 0 0;
      cursor: pointer;
    }

    .e-groupcaption {
      border-style: $grid-border-type;
      border-width: $grid-border-size 0 0;
      display: table-cell;
      font-size: $grid-group-caption-font-size;
      line-height: $grid-group-captioncell-line-height;
      overflow: hidden;
      padding: 0.7em;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
      width: auto;
    }

    .e-virtualtable .e-groupcaption {
      line-height: $grid-rowcell-line-height;
      padding: $grid-content-padding $grid-content-right-padding;
    }

    .e-autofill {
      border: 1px solid;
      height: 8px;
      position: absolute;
      user-select: none;
      width: 8px;
    }

    .e-headercontent {
      border-style: solid;
      border-width: 0;
    }

    .e-stackedheadercell {
      border-width: $grid-stackedheadercell-border-size;
      white-space: nowrap;
    }

    .e-noselect {
      user-select: none;
    }

    .e-toolbar .e-btn-icon.e-icons.e-columnchooser-btn {
      font-size: $grid-columnchooser-btn-font-size;
      line-height: $grid-columnchooser-btn-line-height;
    }

    .e-toolbar-item.e-cc.e-ccdiv.e-cc-toolbar {
      margin-top: $grid-columnchooser-toolbardiv-margin-top;
      padding: $grid-columnchooser-toolbardiv-padding;
    }

    .e-edit-dialog .e-dlg-content {
      position: relative;
    }

    .e-edit-dialog {
      min-height: 350px;
    }

    // sass-lint:disable-all
    .e-griderror label {
      display: inline !important;
    }
    // sass-lint:enable-all

    .e-tooltip-wrap.e-griderror {
      z-index: 1000;
    }

    .e-tooltip-wrap.e-griderror .e-arrow-tip.e-tip-top {
      left: 44%;
    }

    .e-normaledit .e-rowcell {
      padding-bottom: 0;
      padding-top: 0;
    }

    &.e-device .e-normaledit .e-rowcell {
      padding-bottom: $grid-edit-cell-padding;
      padding-top: $grid-edit-cell-padding;
    }

    .e-gridcontent .e-normaledit .e-rowcell.e-lastrowadded {
      border-bottom: $grid-border-size $grid-border-type $grid-cell-border-color;
      border-top: 0 none $grid-cell-border-color;
    }

    .e-normaledit {
      border-top: 0;
      padding: 0;
    }

    .e-gridcontent .e-normaledit .e-rowcell {
      border-top: $grid-border-size $grid-border-type;
    }

    .e-gridcontent tr.e-row .e-rowcell {
      max-height: 60px !important;
      padding: 0;
    }

    .e-gridcontent tr.e-row:first-child .e-rowcell {
      border-top: 0;
    }

    &.e-device .e-editedbatchcell.e-rowcell,
    .e-editedbatchcell.e-rowcell,
    &.e-device .e-gridcontent .e-normaledit .e-rowcell {
      padding-bottom: 0;
      padding-top: 0;
    }

    .e-editedbatchcell,
    td.e-boolcell {
      padding-left: $grid-content-right-padding;
      padding-right: $grid-content-right-padding;
    }

    td.e-boolcell input {
      margin: 3px 3px 3px 4px;
    }

    td.e-boolcell.e-rowcell {
      padding-bottom: 5px;
      padding-top: 5px;
    }

    .e-dialog .e-gridform .e-rowcell {
      border: 0;
      padding: 0;
    }

    .e-row .e-input-group .e-input.e-field,
    .e-row .e-input-focus .e-input.e-field {
      font-family: $grid-font-family;
      font-size: $grid-font-size;
      padding-bottom: $grid-edit-input-padding-bottom;
      padding-top: $grid-edit-input-padding-top;
    }

    .e-row .e-input-group {
      margin-bottom: $grid-edit-input-margin;
      margin-top: $grid-edit-input-margin-top;
      vertical-align: middle;
    }

    .e-defaultcell.e-ralign,
    .e-editedrow .e-defaultcell.e-ralign,
    .e-defaultcell.e-ralign:focus,
    .e-editedrow .e-defaultcell.e-ralign:focus {
      padding-right: 10px;
    }

    .e-dlg-content .e-defaultcell.e-ralign,
    .e-dlg-content .e-defaultcell.e-ralign:focus {
      padding-right: 0;
    }

    .e-hiddenrow {
      display: none;
    }

    .e-columnchooserdiv {
      float: right;
      margin: -12px;
    }

    .e-ccdlg {
      .e-dlg-content {
        margin: $grid-columnchooser-content-margin;
        overflow: visible;
        padding: $grid-columnchooser-content-padding;
      }

      .e-checkbox-wrapper.e-control.e-keyboard {
        padding-left: $grid-cc-checkbox-padding-left;
      }

      .e-main-div {
        box-sizing: border-box;
        position: relative;
      }

      .e-ccul-ele {
        margin: $grid-columnchooser-ul-margin;
        padding: $grid-columnchooser-ul-padding;
      }

      li.e-cclist {
        padding: $grid-columnchooser-li-padding;
      }

      .e-checkbox-wrapper .e-frame {
        margin-left: 0;
      }

      label.e-cc {
        margin: 0;
      }

      .e-footer-content {
        border-style: solid;
        border-width: $grid-column-chooser-footdiv;
      }

      .e-cc-contentdiv {
        height: 196px;
        overflow-y: auto;
      }

      .e-cc-searchdiv {
        border-style: solid;
        border-width: $grid-column-chooser-searchdiv;
        left: $grid-column-chooser-searchdiv-left;
        opacity: $grid-column-chosser-searchdiv-opactiy;
        position: absolute;
        right: 0;
        top: $grid-column-chosser-searchdiv-top;

        span.e-ccsearch-icon.e-icons {
          float: right;
          opacity: 0.6;
          padding: $grid-column-chooser-search-icon-padding;
        }

        span.e-ccsearch-icon.e-cc-cancel {
          font-size: 11px;
          padding: $grid-column-chooser-cancel-icon-padding;
        }
      }

      .e-cc-searchdiv.e-input-focus {
        opacity: $grid-column-chosser-searchdiv-fopactiy;

        span.e-ccsearch-icon.e-icons {
          opacity: $grid-columnchooser-cancel-icon-content-f-opactiy;
        }
      }

      .e-innerdiv.e-ccnmdiv {
        height: 60px;
        padding: 23px;
        text-align: center;
      }

      .e-checkbox-wrapper .e-label {
        text-overflow: ellipsis;
      }

      .e-cc-chbox {
        margin: 3px;
        vertical-align: middle;
      }

      .e-cc-lab-name {
        padding: 7px;
        text-overflow: ellipsis;
        vertical-align: middle;
      }

      .e-cc.e-input,
      .e-cc.e-input:focus {
        border: $grid-columnchooser-input-border;
        padding-bottom: $grid-columnchooser-input-padding-bottom;
        padding-left: $grid-columnchooser-input-padding-left;
        padding-top: $grid-columnchooser-input-padding-top;
      }
    }

    .e-checkboxfiltertext {
      width: $grid-checkboxfiltertext-width;
      word-break: normal;
    }

    .e-frozenhdrcont .e-headercontent > .e-table,
    .e-frozenhdrcont .e-frozenheader > .e-table,
    .e-frozenhdrcont .e-movableheader > .e-table,
    .e-frozenhdrcont .e-headercontent .e-virtualtable > .e-table {
      border-bottom: $frozen-border;
    }

    .e-frozenheader > .e-table,
    .e-frozencontent > .e-table,
    .e-frozenheader .e-virtualtable > .e-table,
    .e-frozencontent .e-virtualtable > .e-table {
      border-left: 0;
      border-right: $frozen-border;
    }

    .e-frozenheader {
      float: left;
      width: min-content;
    }

    &.e-rtl .e-frozenheader > .e-table,
    &.e-rtl .e-frozencontent > .e-table,
    &.e-rtl .e-frozenheader .e-virtualtable > .e-table,
    &.e-rtl .e-frozencontent .e-virtualtable > .e-table {
      border-left: $frozen-border;
      border-right: 0;
    }

    &.e-rtl .e-frozenheader {
      float: right;
    }

    &.e-rtl .e-frozencontent {
      float: right;
    }

    .e-movableheader {
      overflow: hidden;
    }

    .e-frozenhdrcont {
      -ms-touch-action: none; // sass-lint:disable-line no-vendor-prefixes
    }

    .e-frozencontent {
      border-bottom-style: $grid-border-type;
      border-bottom-width: $grid-border-size;
      float: left;
      height: inherit;
      overflow: hidden;
      -ms-touch-action: none; // sass-lint:disable-line no-vendor-prefixes
      width: min-content;
    }

    .e-movablecontent {
      height: inherit;
      overflow: scroll;
    }

    .e-columnchooser::before {
      line-height: $grid-columnchooser-toolbar-icon-line-height;
    }

    .e-toolbar .e-ccdiv .e-columnchooser.e-cctbn-icon {
      font-size: $grid-columnchooser-toolbar-icon-font-size;
      vertical-align: $grid-columnchooser-toolbar-icon-vertical-align;
    }

    .e-toolbar .e-ccdiv {
      margin-top: $grid-columnchooser-toolbar-div-margin-top;
      padding: $grid-columnchooser-toolbar-div-padding;
    }

    &.e-rtl {
      .e-tableborder {
        border-left: 1px solid;
        border-right: 0;
      }

      .e-checkboxfilter {
        & .e-dlg-content {
          padding-left: $grid-rtl-checkbox-content-padding-left;
          padding-right: $grid-checkbox-content-padding-left;
        }

        & .e-ftrchk,
        & .e-searchbox {
          padding-left: 0;
          padding-right: $grid-checkbox-padding-topbottom;
        }
      }

      & .e-headercell,
      & .e-detailheadercell {
        border-width: $grid-rtl-headercell-border-width;
        text-align: right;


        & .e-headercelldiv,
        .e-headercelldiv.e-headerchkcelldiv {
          padding: $grid-rtl-headercelldiv-padding;
          text-align: right;

          & .e-sortnumber {
            float: left;
            margin: $grid-rtl-sortnumber-margin;
          }
        }

        & .e-filterbarcell input {
          border-width: $grid-filterbar-input-border-width;
        }

        & .e-sortfilterdiv {
          float: left;
          margin: $grid-rtl-sortcelldiv-margin;
        }

        &.e-leftalign {
          & .e-sortfilterdiv {
            float: right;
            margin: -14px 3px;
          }

          & .e-headercelldiv {
            padding: 0 25px 0 0.7em;

            & .e-sortnumber {
              float: right;
              margin: 6px 5px 0 0;
            }

          }

        }

        &.e-rightalign {
          & .e-sortnumber {
            float: left;
            margin: $grid-rtl-sortnumber-rightalign-margin;
          }
        }

      }

      .e-rowcell:first-child,
      .e-summarycell:first-child {
        padding-right: $grid-header-first-last-cell-padding;
      }

      .e-rowcell:last-child,
      .e-summarycell:last-child {
        padding-left: $grid-header-first-last-cell-padding;
      }

      &.e-wrap .e-gridheader .e-rightalign .e-sortnumber {
        margin: $grid-rtl-textwrap-srotnumber-rightalign-margin;
      }

      &.e-wrap .e-gridheader .e-sortnumber {
        margin: $grid-rtl-textwrap-srotnumber-margin;
      }

      &.e-wrap .e-gridheader .e-sortfilterdiv {
        margin: $grid-rtl-textwrap-sorticon-margin;
      }

      &.e-wrap .e-gridheader .e-rightalign .e-sortfilterdiv {
        margin: $grid-rtl-textwrap-sorticon-rightalign-margin;
      }

      & .e-gridheader .e-fltr-icon .e-sortfilterdiv {
        margin: $grid-rtl-sortdiv-filtericon-margin;
      }

      & .e-gridheader .e-rightalign.e-fltr-icon .e-sortfilterdiv {
        margin: $grid-rtl-sortdiv-filtericon-rightalign-margin;
      }

      & .e-gridheader .e-sortfilter .e-rightalign.e-fltr-icon .e-headercelldiv {
        margin: $grid-rtl-headercelldiv-rightalign-fltricon-margin;
      }

      & .e-gridheader .e-sortfilter .e-fltr-icon .e-headercelldiv {
        margin: $grid-rtl-headercelldiv-fltricon-margin;
      }


      &.e-verticallines .e-grouptopleftcell,
      &.e-bothlines .e-grouptopleftcell,
      &.e-hidelines .e-grouptopleftcell {
        border-top: 0;
      }

      & .e-grouptopleftcell {
        border-top: $grid-grouptopleftcell-border-top;
      }

      & .e-grouptext {
        margin-left: $grid-rtl-group-text-margin-left;
        margin-right: $grid-rtl-group-text-margin-right;
      }

      & .e-groupheadercell span {
        float: right;
      }

      &.e-horizontallines .e-grouptopleftcell {
        border-top: 1px solid;
      }

      & .e-rowcell {
        border-width: $grid-rowcell-broder-width;
      }

      & .e-filterbarcell,
      & .e-filterbarcelldisabled {
        border-width: $grid-filterbarcell-border-width;
      }

      & .e-lastrowcell {
        border-width: $grid-border-size $grid-border-size $grid-border-size 0;
      }

      & .e-gridheader .e-rightalign .e-sortfilterdiv {
        margin: $grid-rtl-sortcelldiv-right-align-margin;
      }

      & .e-cloneproperties {
        border-width: $grid-border-size $grid-border-size 3px;
      }

      & tr {
        & td:first-child,
        & th:first-child {
          border-left-width: $grid-rtl-th-firstcell-border-left;
        }
      }

      &.e-default.e-bothlines tr {
        & td:first-child:not(.e-summarycell),
        & th:first-child {
          border-left-width: 1px;
        }
      }

      &.e-default.e-bothlines tr td:first-child.e-detailrowcollapse,
      &.e-default.e-bothlines tr td:first-child.e-detailrowexpand {
        border-left-width: 0;
      }

      &.e-default tr {
        & td:last-child,
        & th:last-child {
          border-left: 0;
        }
      }

      &.e-default.e-verticallines tr th:last-child {
        border-left: 1px solid;
      }

      &.e-default .e-gridheader {
        & tr th:last-child {
          padding-left: $grid-header-first-last-cell-padding;
          padding-right: $grid-header-first-last-cell-padding;
        }

        & tr th:first-child {
          padding-left: $grid-header-first-last-cell-padding;
          padding-right: $grid-header-first-last-cell-padding;
        }
      }

      &.e-default th:first-child
      &.e-default .e-headercell,
      &.e-default .e-detailheadercell {
        border-width: $grid-header-border-width;
      }

      &.e-default .e-rowcell {
        border-width: $grid-rowcell-broder-width;
      }

      &.e-default.e-verticallines .e-headercell,
      &.e-default.e-verticallines .e-rowcell,
      &.e-default.e-verticallines .e-filterbarcell,
      &.e-default.e-verticallines .e-detailheadercell,
      &.e-default.e-verticallines .e-gridheader th.e-firstcell {
        border-width: 0 0  0 $grid-border-size;
      }

      &.e-default.e-verticallines tr th:first-child:not(.e-firstcell) {
        border-left-width: $grid-border-size;
      }

      &.e-default .e-stackedheadercell,
      &.e-default.e-horizontallines .e-stackedheadercell {
        border-width: $grid-rtl-stackedheadercell-border-size;
      }

      &.e-default .e-gridheader th.e-firstcell,
      &.e-default.e-horizontallines .e-gridheader th.e-firstcell {
        border-left-width: $grid-rtl-stackedhader-firstcell-left-border-size;
        border-right-width: $grid-rtl-stackedhader-firstcell-right-border-size;
      }

      &.e-default.e-bothlines .e-gridheader th.e-firstcell {
        border-left-width: $grid-rtl-bothlines-stackedhader-firstcell-left-border-size;
        border-right-width: $grid-rtl-bothlines-stackedhader-firstcell-right-border-size;
      }

      &.e-default .e-gridheader .e-headercell.e-firstheader,
      &.e-default.e-horizontallines .e-headercell.e-firstheader {
        border-left: 0;
      }

      &.e-default.e-hidelines .e-gridheader th.e-firstcell {
        border-left: 0;
        border-right: 0;
      }

      &.e-default .e-gridheader .e-headercell.e-firstheader,
      &.e-default.e-horizontallines .e-gridheader .e-headercell.e-firstheader {
        border-right: 1px solid;
      }

      &.e-default.e-verticallines .e-gridheader .e-headercell.e-firstheader {
        border-right: 0;
      }

      &.e-default.e-verticallines .e-gridheader .e-headercell.e-firstheader,
      &.e-default.e-verticallines .e-gridheader th.e-grouptopleftcell.e-lastgrouptopleftcell {
        border-left: 1px solid;
      }

      &.e-default.e-verticallines .e-headercell.e-stackedheadercell {
        border-bottom: 1px solid;
      }

      &.e-default .e-detailcell ,
      &.e-default.e-bothlines .e-detailcell {
        border-right-style: solid;
        border-right-width: 1px;
      }

      & .e-cc-searchdiv span.e-ccsearch-icon.e-icons {
        float: left;
      }

      & .e-groupsort,
      & .e-ungroupbutton,
      & .e-toggleungroup {
        margin-left: $grid-rtl-group-sorticon-margin-left;
        margin-right: $grid-rtl-group-sorticon-margin-right;
      }

      & span.e-ungroupbutton.e-icons {
        margin-left: $grid-ungroup-rtl-padding-left;
        margin-right: $grid-ungroup-rtl-padding-right;
        padding-top: $grid-ungroup-rtl-padding-top;
      }

      &.e-default.e-bothlines .e-headercell,
      &.e-default.e-bothlines .e-detailheadercell {
        border-width: $grid-headercell-both-border-width;
      }

      &.e-default.e-bothlines .e-dragheadercell,
      &.e-default.e-bothlines .e-rowdragheader,
      &.e-default.e-bothlines .e-cloneproperties.e-draganddrop td.e-rowdragdrop,
      &.e-default.e-verticallines .e-cloneproperties.e-draganddrop td.e-rowdragdrop {
        border-left: 1px solid $grid-header-border-color;
        padding-left: 3px;
      }

      & .e-cloneproperties.e-draganddrop .e-row .e-icon-rowdragicon::before {
        left: 4px;
        position: relative;
      }

      &.e-default table th[rowspan] {
        border-width: $grid-rtl-headercell-both-border-width;
      }

      &.e-default.e-bothlines .e-gridheader .e-headercell.e-firstheader,
      &.e-default.e-bothlines .e-gridheader th.e-grouptopleftcell.e-lastgrouptopleftcell {
        border-left: 1px solid;
        border-right: 0;
      }

      &.e-bothlines .e-gridheader th.e-stackedheadercell {
        border-bottom-style: $grid-border-type;
        border-bottom-width: $grid-border-size;
      }

      &.e-bothlines .e-filterbarcell,
      &.e-bothlines .e-filterbarcelldisabled {
        border-width: $grid-filtercell-both-border-width;
      }

      &.e-bothlines .e-rowcell,
      &.e-bothlines .e-rowcell.e-lastrowcell {
        border-width: $grid-rowcell-both-border-width;
      }

      &.e-verticallines .e-rowcell,
      &.e-verticallines .e-filterbarcell {
        border-width: 1px 0 0 $grid-border-size;
      }

      &.e-hidelines .e-rowcell,
      &.e-hidelines .e-headercell,
      &.e-hidelines .e-detailheadercell,
      &.e-hidelines .e-filterbarcell {
        border-width: 0;
      }

      &.e-horizontallines .e-rowcell {
        border-width: $grid-border-size 0 0;
      }

      &.e-horizontallines .e-filterbarcell {
        border-width: $grid-bottom-border-size 0 0;
      }


      &.e-horizontallines,
      &.e-verticallines,
      &.e-hidelines {
        & .e-rowcell.e-lastrowcell {
          border-bottom-width: $grid-border-size;
        }
      }

      &.e-verticallines,
      &.e-hidelines {
        & .e-firstchildrow .e-rowcell {
          border-top-width: 1px;
        }
      }

      & .e-groupheadercell .e-icons::before {
        display: inline-block;
      }

      & .e-groupheadercell .e-cancel {
        padding-left: 23px;
        padding-right: 0;
        padding-top: 2px;
      }

      & .e-groupheadercell,
      & .e-groupheadercell:hover {
        margin-left: $grid-rtl-group-headercell-margin-left;
        margin-right: $grid-rtl-group-headercell-margin-right;
        padding: $grid-rtl-grouped-headercell-padding;
      }

      & .e-groupheadercell,
      & .e-groupheadercell:hover {
        float: right;
      }

      & .e-groupdroparea {
        text-align: $grid-groupdroparea-rtl-text-align;
      }

      & .e-ungroupbutton {
        float: left;
      }

      & tr:not(.e-summaryrow) td.e-indentcell {
        border-style: $grid-border-type;
        border-width: 0 0 0 $grid-border-size;
      }

      & .e-defaultcell.e-ralign,
      & .e-row .e-input.e-defaultcell.e-ralign,
      & .e-defaultcell.e-ralign:focus,
      & .e-editedrow .e-defaultcell.e-ralign:focus {
        padding-left: 10px;
      }

      & .e-detailindentcell {
        border-left-style: $grid-border-type;
        border-left-width: $grid-border-size;
        border-right-width: 0;
      }

      & .e-filtermenudiv {
        float: $grid-rtlfltrdiv-float;
        margin: $grid-rtl-fltrdiv-margin;
        padding: $grid-rtl-fltrdiv-padding;
      }
    }

    &.e-wrap {
      & .e-rowcell,
      & .e-columnheader .e-stackedheadercelldiv,
      & .e-columnheader .e-headercelldiv {
        height: Auto;
        line-height: $grid-rowcell-wrap-height;
        overflow-wrap: break-word;
        text-overflow: clip;
        white-space: normal;
        word-wrap: break-word;
      }

      & .e-stackedheader .e-columnheader .e-stackedheadercelldiv,
      & .e-stackedheader .e-columnheader .e-headercelldiv {
        max-height: $grid-rowcell-wrap-max-height;
      }

    }

    &.e-wrap .e-columnheader .e-headercelldiv,
    & .e-columnheader.e-wrap .e-headercelldiv {
      margin-bottom: $grid-wrap-margin-bottom;
      margin-top: $grid-wrap-margin-top;
    }

    &.e-wrap .e-columnheader .e-filtermenudiv {
      line-height: $grid-wrap-line-height;
    }

    & .e-columnheader.e-wrap .e-filtermenudiv {
      line-height: $grid-wrap-line-height;
    }

    & .e-columnheader.e-wrap .e-headercelldiv,
    & .e-columnheader.e-wrap .e-stackedheadercelldiv,
    & .e-gridcontent.e-wrap .e-rowcell,
    & .e-frozenhdrcont.e-wrap .e-rowcell {
      height: Auto;
      line-height: $grid-wrap-line-height;
      overflow-wrap: break-word;
      text-overflow: clip;
      white-space: normal;
      word-wrap: break-word;
    }

    & .e-stackedheadercelldiv {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .e-stackedheader .e-columnheader.e-wrap .e-headercelldiv,
    & .e-stackedheader .e-columnheader.e-wrap .e-stackedheadercelldiv {
      max-height: $grid-rowcell-wrap-max-height;
    }

    & .e-columnheader.e-wrap .e-sortfilterdiv,
    &.e-wrap .e-columnheader .e-sortfilterdiv {
      margin: $grid-header-wrap-sortfilter-div-margin;
    }

    & .e-columnheader.e-wrap .e-rightalign .e-sortfilterdiv,
    &.e-wrap .e-columnheader .e-rightalign .e-sortfilterdiv {
      margin: $grid-header-wrap-rightalign-sortfilter-div-margin;
    }

    & .e-columnheader.e-wrap .e-fltr-icon .e-sortfilterdiv,
    &.e-wrap .e-columnheader .e-fltr-icon .e-sortfilterdiv {
      margin: $grid-header-wrap-filtericon-sortfilter-div-margin;
    }

    & .e-columnheader.e-wrap .e-fltr-icon.e-rightalign .e-sortfilterdiv,
    &.e-wrap .e-columnheader .e-fltr-icon.e-rightalign .e-sortfilterdiv {
      margin: $grid-header-wrap-filtericon-rightalign-sortfilter-div-margin;
    }

    & .e-columnheader.e-wrap .e-icon-group::before,
    &.e-wrap .e-columnheader .e-icon-group::before {
      display: inline-block;
    }

    &.e-responsive .e-rowcell.e-gridclip,
    &.e-responsive .e-gridclip .e-headercelldiv,
    &.e-responsive .e-stackedheadercelldiv.e-gridclip {
      text-overflow: clip;
    }

    .e-clipboard {
      cursor: default;
      height: 1px;
      left: -1000px;
      overflow: hidden;
      position: fixed;
      resize: none;
      top: -1000px;
      width: 1px;
    }

    &.e-resize-lines,
    &.e-resize-lines.e-rtl {
      & th.e-headercell.e-stackedheadercell,
      & th.e-headercell.e-firstcell {
        border-left: 0;
        border-right: 0;
        border-top: 0;
      }

      & tr.e-columnheader th:last-child.e-stackedheadercell .e-rhandler.e-rcursor {
        border-right: 0;
      }

      &.e-default table th[rowspan] {
        border-left: 0;
      }
    }

    &.e-resize-lines.e-rtl {
      & tr.e-columnheader th:last-child.e-stackedheadercell .e-rhandler.e-rcursor,
      & tr.e-columnheader th.e-lastcell .e-rhandler.e-rcursor {
        border-left: 0;
      }

      &.e-default table th[rowspan] {
        border-right: 0;
      }
    }

    &.sf-grid {
      .e-gridcontent .e-content,
      .e-gridcontent .e-content .e-movablecontent {
        overflow-y: auto;
      }

      .e-gridcontent .e-content.e-yscroll,
      .e-gridcontent .e-content .e-movablecontent.e-yscroll {
        overflow-y: scroll;
      }

      .e-gridcontent .e-content.e-noscroll,
      .e-gridcontent .e-content .e-movablecontent.e-noscroll {
        overflow-y: hidden;
      }
    }
  }
}

#{&}.e-grid-min-height {
  .e-rowcell {
    line-height: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  .e-gridheader .e-headercell,
  .e-gridheader .e-detailheadercell,
  .e-gridheader .e-headercell .e-headercelldiv {
    height: auto;
  }

  .e-gridcontent .e-groupcaption {
    line-height: normal;
    padding: 0 0.7em;
  }

  .e-summarycell {
    line-height: normal;
    padding: 0 8px;
  }
}

.e-device.e-grid-min-height .e-rowcell,
.e-device.e-grid-min-height .e-rowcell:first-child,
.e-device.e-grid-min-height .e-rowcell:last-child {
  padding-bottom: 0;
  padding-top: 0;
}

.e-bigger .e-grid.e-grid-min-height {
  .e-rowcell,
  .e-rowcell:first-child,
  .e-rowcell:last-child {
    line-height: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.e-bigger .e-wrap.e-grid-min-height {
  .e-rowcell {
    line-height: 18px;
  }

  .e-frozencontent table tr td:first-child:empty,
  .e-movablecontent table tr td:first-child:empty,
  .e-frozenhdrcont table tr td:first-child:empty,
  &:not(.e-grid-min-height) .e-gridcontent tr td:not(.e-indentcell):first-child:empty,
  &:not(.e-grid-min-height) .e-gridcontent tr.e-row .e-rowcell:empty {
    height: 18px;
  }
}

.e-rtl {
  & .e-grid {
    & .e-headercell,
    & .e-detailheadercell,
    & .e-headercelldiv,
    & .e-headercelldiv.e-headerchkcelldiv {
      text-align: right;
    }
  }
}

.e-edit-dialog .e-gridform .e-table {
  border-collapse: separate;
  border-spacing: 11px;
  width: 100%;
}

.e-edit-dialog .e-dlg-content {
  position: relative;
}
