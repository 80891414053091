::-webkit-scrollbar-track {
  box-shadow: none;
}

::-webkit-scrollbar {
  max-height: 150px;
  min-height: 6px;
  width: 6px;
}

@media (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 2px;
  }
}

::-webkit-scrollbar-thumb {
  background: rgba(147, 146, 146, 0.5);
  border-radius: 3.5px;
}
