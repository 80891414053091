.navbar-dark .navbar-nav .nav-link.active, .nav-link {
  cursor: pointer;
  margin-left: 3.5rem;

  &.active {
    i {
      color: var(--warning-color);
    }
  }
}

a.nav-cell {
  color: normal-color(--primary);
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: normal-color(--warning);
  }

  &.disabled {
    color: disabled-color(--primary);
    cursor: auto;
    pointer-events: none;
  }
}

