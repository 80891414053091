//default

.e-input-group.e-ddl {
  padding: 9px 42px;

  .e-input-value,
  .e-input-value:focus {
    min-height: 30px;
  }
}

.e-input-group.e-ddl .e-control.e-combobox~.e-ddl-icon {
  font-size: 8px;
}

.e-bigger .e-input-group.e-ddl .e-control.e-combobox~.e-ddl-icon {
  font-size: 10px;
}

//enddefault

